import { GET_SEA_FAILURE,
    GET_SEA_REQUEST,
    GET_SEA_SUCCESS, } from "./constants";

export interface IState {
    gotData: boolean;
    loading: boolean;
    error: null | string;
    data: any;
}

export const initialState: any = {
    gotData: false,
    loading: false,
    error: null,
    scoreData: [],
};

export const SEAReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case GET_SEA_REQUEST:
            return {
                ...state,
                gotData: false,
                loading: true,
                error: null,
            };
        case GET_SEA_SUCCESS:

            const newState = {
                ...state,
                loading: false,
                gotData: true,
                scoreData: action.payload.scoreData,
            };

            return newState;

        case GET_SEA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
