import React, { Fragment } from "react";
import "../Content.scss";
import { iHeadline } from "./interface";

const ContentHeadline: React.FC<iHeadline> = (props) => {
    return (
        <Fragment>
            <div className="content__headline-wrapper">
                <h1 className="content__headline">
                    {props.text}
                </h1>
                {props.children}
            </div>
            {props.subHeader && (
                <p className="content__sub-header">{props.subHeader}</p>
            )}
        </Fragment>
    );
};

export default ContentHeadline;
