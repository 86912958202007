import { ActionType } from "typesafe-actions";
import * as actions from "../../actions/Maintenance/MaintenanceAction";
import { GET_MAINTENANCE_REQUEST, GET_MAINTENANCE_SUCCESS, GET_MAINTENANCE_FAILURE } from "../../constants";
import { IMaintenanceMessage } from "../../actions/Maintenance/MaintenanceAction";

export type MaintenanceAction = ActionType<typeof actions>;

export interface IState {
    isInMaintenanceMode: boolean;
    gotData: boolean;
    error: string | null;
    data: IMaintenanceMessage | null;
}

export const initialState: IState = {
    isInMaintenanceMode: false,
    gotData: false,
    error: null,
    data: null,
};

export const MaintenanceReducer = (state: IState = initialState, action: MaintenanceAction) => {
    switch (action.type) {
        case GET_MAINTENANCE_REQUEST:
            return {
                ...state,
                gotData: false,
            }
        case GET_MAINTENANCE_SUCCESS:
            return {
                ...state,
                gotData: true,
                loading: false,
                isInMaintenanceMode: !!action.payload,
                data: action.payload,
            };
        case GET_MAINTENANCE_FAILURE:
            return {
                ...state,
                gotData: false,
                error: "Failed to load data",
            }
        default:
            return state;
    }
};
