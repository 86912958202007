export function addDay(date: Date, numberOfDays: number): Date {
    return new Date(new Date(date).setDate(date.getDate() + numberOfDays));
}

export function addMonth(date: Date, numberOfMonths: number): Date {
    return new Date(new Date(date).setMonth(date.getMonth() + numberOfMonths));
}

export function FormatDateToISOString(date: Date): string {
    const dateSeparator: string = "-";
    const dd: string = date.getDate() < 10 ? "0" + date.getDate().toString() : date.getDate().toString();
    const m: number = date.getMonth() + 1;
    const mm: string = m < 10 ? "0" + m.toString() : m.toString();
    const yyyy: string = date.getFullYear().toString();

    return yyyy.concat(dateSeparator, mm, dateSeparator, dd);
}

export function StartStopValues(date: Date, lagTime: number, monthsBack: number, daysBack: number) {
    const stop: Date = addDay(date, -lagTime);
    const start: Date = addMonth(date, -monthsBack);
    start.setDate(1);
    const startSubPlots: Date = addDay(stop, -daysBack);

    return {
        start,
        startSubPlots,
        stop,
    };
}

export function StartStopValuesMainPlot(date: Date, lagTime: number, monthsBack: number, daysBack: number) {
    const stop: Date = addDay(date, -lagTime);
    const start: Date = addMonth(date, -monthsBack);
    start.setDate(1);

    return {
        start,
        stop,
    };
}

export function StartStopValuesSubPlot(date: Date, lagTime: number, monthsBack: number, daysBack: number) {
    const subPlotStop: Date = addDay(date, -lagTime);
    const subPlotStart: Date = addDay(subPlotStop, -daysBack);

    return {
        subPlotStart,
        subPlotStop,
    };
}

export function StartStopValuesExt(
    date: Date,
    lagTimeMain: number,
    lagTimeSubPlot1: number,
    lagTimeSubPlot2: number,
    monthsBack: number,
    daysBack: number,
) {
    const stop: Date = addDay(date, -lagTimeMain);
    const start: Date = addMonth(date, -monthsBack);
    start.setDate(1);
    const stopSubPlot1: Date = addDay(date, -lagTimeSubPlot1);
    const startSubPlot1: Date = addDay(stopSubPlot1, -daysBack);
    const stopSubPlot2: Date = addDay(date, -lagTimeSubPlot2);
    const startSubPlot2: Date = addDay(stopSubPlot2, -daysBack);

    return {
        start,
        startSubPlot1,
        stopSubPlot1,
        startSubPlot2,
        stopSubPlot2,
        stop,
    };
}
