import React, { useEffect, Fragment } from "react";
import { ResponsiveLine } from "@nivo/line";
import { Card, CardHeader, CardHeadline, CardGuide } from "../../Card";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { FETCH_STICK_CONSUMPTION_CHART_DATA_REQUEST } from "../../redux/StickConsumption/constants";
import StatusNumber from "../../StatusNumber";
import Tooltip from "../../Tooltip";
import { DataTypeDisplay } from "../../DataType";
import { handleTriggerStartTour } from "../../utills/intercom";
import LattecIcon from "../../LattecIcon";
import { DASHBOARD_TOGGLE, STICK_CONSUMPTION_TOGGLE, COLOR_SCREME_1, COLOR_SCREME_2 } from "../../redux/Navigation/constants";
import "./StickConsumption.scss";
import { iStickConsumption } from "./interface";
import Loader from "../../PlaceholderLoader/index";

const StickConsumption: React.FC<iStickConsumption> = (props) => {

    const stickConsumptionState = useSelector((state: any) => state.stickConsumptionState);
    const navigationState = useSelector((state: any) => state.navigationState);
    const { t } = useTranslation();

    // const [step, handleGoToStep] = useState(0);

    let dataType: number;
    if (props.toogleId === DASHBOARD_TOGGLE) dataType = navigationState.dataType;
    else if (props.toogleId === STICK_CONSUMPTION_TOGGLE) dataType = navigationState.dataStickConsumptionType;
    else dataType = navigationState.dataBaseLineReportType;

    const dispatch = useDispatch();

    useEffect(() => {
        // get questions when component is mounted
        dispatch({
            type: FETCH_STICK_CONSUMPTION_CHART_DATA_REQUEST,
            payload: {
                kpi: "SticksUsedPrPregnancy",
                farmGroup: dataType
            }
        });

        // tell hook to skip re-render if state hasnt changed
    }, [dispatch, navigationState.dataType, navigationState.dataStickConsumptionType, navigationState.dataBaseLineReportType]);

    const yearDataIsEmpty = stickConsumptionState.gotData && stickConsumptionState.data[0].data.length === 0;
    const referenceIsEmpty = stickConsumptionState.gotData && stickConsumptionState.data[1].data.length === 0;

    const lineData = dataType === 0 ? [{ ...stickConsumptionState.data[0] }, { ...stickConsumptionState.data[1] }] : [{ ...stickConsumptionState.data[0] }, { ...stickConsumptionState.data[2] }];

    var statusNumber = stickConsumptionState.StickCount;
    var statusNumberText = t("SticksPregnancyTrend");
    var referenceBenchmarkFarm = stickConsumptionState.StickCountReferenceBenchmarkFarm;

    if (stickConsumptionState.data[0] && stickConsumptionState.tableData) {
        const sticksUsedPrCow = stickConsumptionState.tableData.filter((x: any) => x.KPIName == stickConsumptionState.data[0].kpi)[0];
        statusNumber = sticksUsedPrCow ? sticksUsedPrCow.CurrentPointValue : statusNumber;

        statusNumberText = t(stickConsumptionState.data[0].kpi);
    }

    return (
        <Fragment>
            <div className="stick-consumption" id="sticks-consumption">
                <Card>
                    <CardHeader>
                        <CardHeadline icon={<LattecIcon size={17} icon="stick-consumption" />} text={t("StickConsumption")} link={props.link} />
                        <CardGuide openStepGuide={() => handleTriggerStartTour(props.stepGuideId || 0)} />
                    </CardHeader>
                    {stickConsumptionState.loading || (yearDataIsEmpty && referenceIsEmpty) ? (
                        <Loader height={300} width={1000} type='StickConsumption' />
                    ) : stickConsumptionState.error ? (// Error in data
                        <span>{stickConsumptionState.error}</span>
                    ) : stickConsumptionState.gotData === false ? (// data arrays are empty
                        <span>{t("DataNotAvailable")}</span>
                    ) : stickConsumptionState.gotData ? (// we got data and its not empty
                        <Fragment>
                            <div>
                                <StatusNumber
                                    number={statusNumber}
                                    text={statusNumberText}
                                >
                                    <DataTypeDisplay
                                        arrowId="sticks-consumption-reference-arrow"
                                        id="sticks-consumption-reference-bars"
                                        dataType={dataType}
                                        trend={stickConsumptionState.TrendIndicator}
                                        benchmarkComparison={referenceBenchmarkFarm}
                                        current={parseFloat(statusNumber)}
                                        ownComparison={stickConsumptionState.StickCountReferenceOwnFarm}
                                        reverseTrend={true}
                                        colors={dataType === 0 ? COLOR_SCREME_1 : COLOR_SCREME_2}
                                    />
                                </StatusNumber>
                            </div>
                            <div style={{ position: "relative", height: "100px" }}>
                                <div id="sticks-consumption-chart" style={{ position: "absolute", width: "100%", height: "100%" }}>
                                    <ResponsiveLine
                                        colors={dataType === 0 ? COLOR_SCREME_1 : COLOR_SCREME_2}
                                        data={lineData}
                                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                                        xScale={{ type: "point" }}
                                        yScale={{ type: "linear", min: 0, max: "auto", stacked: false, reverse: false }}
                                        curve="cardinal"
                                        axisTop={null}
                                        axisRight={null}
                                        tooltip={(el: any) => <Tooltip addStyles={true} point={el.point} headline={statusNumberText} xLabel={"Date"} xKey={"date"} moveLeft={222} />}
                                        axisBottom={{
                                            orient: "bottom",
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: "transportation",
                                            legendOffset: 36,
                                            legendPosition: "middle"
                                        }}
                                        axisLeft={{
                                            orient: "left",
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: "count",
                                            legendOffset: -40,
                                            legendPosition: "middle",
                                        }}
                                        enableGridX={false}
                                        enableGridY={false}
                                        lineWidth={0}
                                        enablePoints={false}
                                        pointSize={10}
                                        pointColor="#ffffff"
                                        pointBorderWidth={2}
                                        pointBorderColor={{ from: "serieColor" }}
                                        pointLabel="y"
                                        pointLabelYOffset={-24}
                                        enableArea={true}
                                        areaOpacity={0.5}
                                        useMesh={true}
                                        legends={[]}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    ) : null}
                </Card>
            </div>
        </Fragment>
    )
}

export default StickConsumption;
