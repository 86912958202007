import React, { Fragment } from "react";
import { ContentHeader, ContentHeadline, ContentWrapper } from "../../Content";
import { useTranslation } from 'react-i18next';
import { StickConsumption, StickConsumptionTable, CurrentReproductionPerformance, TopTenCows } from "../../Cards";
import { Column, Row } from "../../Layout";
import { DataTypeToggle } from "../../DataType";
import { STICK_CONSUMPTION_TOGGLE } from "../../redux/Navigation/constants";

const StickConsumptionPage: React.FC = (props) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <ContentHeader>
        <ContentHeadline text={t("StickConsumption")} subHeader={t("StickConsumptionSubHeader")} >
          <DataTypeToggle toogleId={STICK_CONSUMPTION_TOGGLE} />
        </ContentHeadline>
      </ContentHeader>
      <ContentWrapper>
        <Row first>
          <Column first flex={1}>
            <Row first>
              <StickConsumptionTable toogleId={STICK_CONSUMPTION_TOGGLE} />
            </Row>
            <Row>
              <TopTenCows />
            </Row>
          </Column>
          <Column last>
            <Row first>
              <StickConsumption toogleId={STICK_CONSUMPTION_TOGGLE} />
            </Row>
            <Row>
              <CurrentReproductionPerformance simple toogleId={STICK_CONSUMPTION_TOGGLE} />
            </Row>
          </Column>
        </Row>
      </ContentWrapper>
    </Fragment>
  );
};

export default StickConsumptionPage;
