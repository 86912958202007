import React from "react";
import "../Card.scss";

const CardHeader: React.FC = (props) => {
    return (
        <div className="card__header">
            {props.children}
        </div>
    );
};

export default CardHeader;
