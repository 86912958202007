import { ActionType } from "typesafe-actions";
import * as actions from "../../actions/DOA/DOACompInCompDetailAction";
import { GET_FIRST_LUTEAL_PHASE_FAILURE, GET_FIRST_LUTEAL_PHASE_REQUEST, GET_FIRST_LUTEAL_PHASE_SUCCESS } from "./constants";

export type DOACompDetailAction = ActionType<typeof actions>;

export interface IState {
    startDate: Date;
    stopDate: Date;
    gotData: boolean;
    loading: boolean;
    error: null | string;
    data: any;
}

export const initialState: IState = {
    gotData: false,
    loading: false,
    error: null,
    startDate: new Date(),
    stopDate: new Date(),
    data: [],
};

export const FirstLutealPhaseReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case GET_FIRST_LUTEAL_PHASE_REQUEST:
            return {
                ...state,
                gotData: false,
                loading: true,
                error: null,
            };
        case GET_FIRST_LUTEAL_PHASE_SUCCESS:

            // let numberCows: number = 0;

            // action.payload!.doaCompInCompDetailCIC.filter((o: ICompInCompDetailDataSource) => o.IsComplete === true).forEach((element: ICompInCompDetailDataSource) => {
            //     numberCows += element.NumberOfRecords;
            // });

            // const payload = {
            //     ...state,
            //     gotData: true,
            //     loading: false,
            //     startDate: action.payload.startDate,
            //     stopDate: action.payload.stopDate,
            //     doaCompInCompDetailC: action.payload.doaCompInCompDetailCIC.filter((o: ICompInCompDetailDataSource) => o.IsComplete === true).map((o: ICompInCompDetailDataSource) => o),
            //     doaCompInCompDetailIC: action.payload.doaCompInCompDetailCIC.filter((o: ICompInCompDetailDataSource) => o.IsComplete === false).map((o: ICompInCompDetailDataSource) => o),
            //     doaCompInCompDetailUserSelectedTimeC: [],
            //     doaCompInCompDetailUserSelectedTimeIC: [],

            //     refDataSource: action.payload.rawRefDataSource.map((o: RawRefP2P3DataSource, i: number) => {

            //         // TODO: Remove quickfix and change the return value from the server
            //         // Currently we have "<=25" and "<25" this is a small typo on the server and should be changed
            //         let Ranges: string;
            //         if (i === 0) {
            //             Ranges = "<=25";
            //         } else {
            //             Ranges = o.XAxisDisplay;
            //         }
                    
            //         // const Ranges: string = o.XAxisDisplay;
            //         const deNorm =
            //         (o.YValueL1 > 0 ? 1 : 0) +
            //         (o.YValueL2 > 0 ? 1 : 0) +
            //         (o.YValueL3P > 0 ? 1 : 0);

            //         const NumberOfRecords: number = o.YValue * numberCows / (deNorm > 0 ? deNorm : 1);
            //         return {Ranges, NumberOfRecords};
            //     }),
            // };

            const newState = {
                ...state,
                loading: false,
                gotData: true,
                data: action.payload,
            };

            return newState;

        case GET_FIRST_LUTEAL_PHASE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
