import React, { Fragment, useState } from "react";
import Tooltip from "../Tooltip";

// tslint:disable-next-line: no-var-requires
const slackLink = require("../Images/slack.png");

const SlackLink = ({ component: Component, ...rest }: any) => {

    const [visible, handleSetVisibility] = useState(false);

    // <Tooltip point={el.point} headline={statusNumberText} xLabel={"Date"} xKey={"date"} />


    return (
        <Fragment>
            <Tooltip independent={true} visible={visible}>
                Go to slack
            </Tooltip>
            <div onMouseOut={() => handleSetVisibility(false)} onMouseOver={() => handleSetVisibility(true)} style={{ height: "60px", width: "60px", borderRadius: "60px", position: "absolute", right: "98px", bottom: "20px", zIndex: 999, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <a href="https://slack.com" target="__blank">
                    <img height={"100%"} width={"100%"} alt="Slack link" src={slackLink} />
                </a>
            </div>
        </Fragment>
    );
};

export default SlackLink;
