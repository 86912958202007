import React from "react";
import { iIcon } from "./interface";

const CowsListIcon: React.FC<iIcon> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={props.size || 30} height={props.size || 30} viewBox={`0 0 ${props.size || 30} props.size || 30`}>
        <defs>
            <clipPath id="clip-path">
            <rect id="Rectangle_723" data-name="Rectangle 723" width="30" height="30" transform="translate(0 0.317)" fill={props.color ? props.color : "currentColor"}/>
            </clipPath>
        </defs>
        <g id="icon-cowHead" transform="translate(0 -0.317)" clipPath="url(#clip-path)">
            <path id="Path_363" data-name="Path 363" d="M41.6,81.675l-4.381-.834.766-.451-.187-.33-.579-.312-.077-.281-.109-.187-.3.188c-.373.156-.721.526-1.047.656l-.2,1.272c.146-.047.289-.093.439-.144C36.216,81.161,41,81.927,41.6,81.675Zm.771-7.8a2.865,2.865,0,0,0-2.816.261,3.357,3.357,0,0,0-.792,2.476,7.483,7.483,0,0,0,.051.859,6.94,6.94,0,0,0,1.655,3.646,7.32,7.32,0,0,0,1.954,1.368l.339-.91a16.741,16.741,0,0,1-2-1.625l-.953-2.125V75.4a1.205,1.205,0,0,1,1.623-.711s.883.694.889.557a2.606,2.606,0,0,1,.436.154c.05.032.1.056.145.086a4.619,4.619,0,0,1,1.114,1.1l.246.443a4.229,4.229,0,0,0,.713.8c.047.086.23.158.3.224l.781-.507c.009.007-.377-.668-.377-.668a3.681,3.681,0,0,0-.261-.47,9.909,9.909,0,0,0-3.054-2.529ZM56.98,83.485a8.4,8.4,0,0,1-.816-.58l-.333-.283C55.092,82,54.255,81.51,53.6,81.007c-1.351-1.034-3.42-3.108-5.517-4.148,0,0,.007,0-1.331-.815l-.255-.167.556,2.173.754.466c.611.483,1.768,1.053,2.477,1.685a18.775,18.775,0,0,0,2.441,2.031l1.99.877a10.77,10.77,0,0,0,1.975,1.326l.649.3c.414.175,2.008,1.163,2.4,1.388.055.048-.133,1.9-.133,1.976a.806.806,0,0,1-.173.415c-.214.348-1.026.642-1.333.913-.237.2.067,1.33-.2,1.479a13.759,13.759,0,0,1-3.185.655H48.644c-1.995,0-1.763-.526-3.364.656-1.083.806-5.889,5.795-7.027,7.033h1.183l5.844-5.937c.268-.19.974-.865,1.62-.881s2.03.016,2.03.016l5.783-.016a4.875,4.875,0,0,0,2.649-.4,10.537,10.537,0,0,0,1.183-.755l.964-.916a4.689,4.689,0,0,0,.94-1.168,3.388,3.388,0,0,0,.613-1.878,2.917,2.917,0,0,0-1.582-2.587M48.146,84.29a1.337,1.337,0,1,0-1.117-1.528,1.352,1.352,0,0,0,.244,1A1.337,1.337,0,0,0,48.146,84.29Z" transform="translate(-33.487 -71.15)" fill={props.color ? props.color : "currentColor"}/>
        </g>
    </svg>

);

export default CowsListIcon;
