import {
    GET_COWS_LIST_FAILURE,
    GET_COWS_LIST_REQUEST,
    GET_COWS_LIST_SUCCESS,
    HANDLE_FILTER_COW_LIST_COMPLETE,
    HANDLE_FILTER_COW_LIST_GRAPH_P2,
    HANDLE_FILTER_COW_LIST_GRAPH_P3,
    HANDLE_FILTER_COW_LIST_GRAPH_MAX,
    HANDLE_FILTER_COW_LIST_SCATTER_P1,
    HANDLE_FILTER_COW_LIST_SCATTER_P2,
    HANDLE_FILTER_COW_LIST_INITIALIZE,
    HANDLE_REMOVE_COW_FILTER,
    HANDLE_FILTER_COW_LIST_SCATTER_P1_HNIR,
    HANDLE_COW_LIST_REFERENCE,
    HANDLE_COW_LIST_SELECT,
    HANDLE_COW_LIST_HOVERED,
} from "./constants";

export interface IState {
    loading: boolean;
    error: string | null;
    gotData: boolean;
    data: any;
    filteredData: any;
    filterMode: boolean;
    graphP2Data: any,
    graphP3Data: any,
    graphP2Max: number | null,
    graphP3Max: number | null,
    scatterP1: any,
    scatterP2: any,
    scatterP1HNIR: any,
    p2reference: any,
    p3reference: any,
    selected: SVGRectElement | undefined,
    hovered: SVGRectElement | undefined,
}

export const initialState: IState = {
    gotData: false,
    loading: false,
    error: null,
    data: [],
    filteredData: [],
    filterMode: false,
    graphP2Data: [],
    graphP3Data: [],
    graphP2Max: null,
    graphP3Max: null,
    scatterP1: [],
    scatterP1HNIR: [],
    scatterP2: [],
    p2reference: [],
    p3reference: [],
    selected: undefined,
    hovered: undefined,
};

export const CowsListReducer =
    (state: IState = initialState, action: any) => {
        switch (action.type) {
            case HANDLE_FILTER_COW_LIST_INITIALIZE:
            case GET_COWS_LIST_REQUEST:
                return {
                    ...state,
                    loading: true,
                    gotData: false,
                };
            case GET_COWS_LIST_SUCCESS:
                return {
                    ...state,
                    gotData: true,
                    loading: false,
                    data: action.payload,
                    filteredData: action.filteredPayload,
                    filterMode: true,
                };
            case GET_COWS_LIST_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                    gotData: false,
                };

            case HANDLE_FILTER_COW_LIST_COMPLETE:
                return {
                    ...state,
                    filteredData: action.payload,
                    filterMode: true,
                };

            case HANDLE_FILTER_COW_LIST_GRAPH_P2:
                return {
                    ...state,
                    graphP2Data: action.payload,
                };

            case HANDLE_FILTER_COW_LIST_GRAPH_MAX:
                return {
                    ...state,
                    graphP2Max: action.payload.graphP2Max,
                    graphP3Max: action.payload.graphP3Max,
                }

            case HANDLE_FILTER_COW_LIST_GRAPH_P3:
                return {
                    ...state,
                    graphP3Data: action.payload,
                };

            case HANDLE_FILTER_COW_LIST_SCATTER_P1:
                return {
                    ...state,
                    scatterP1: action.payload,
                };

            case HANDLE_FILTER_COW_LIST_SCATTER_P1_HNIR:
                return {
                    ...state,
                    scatterP1HNIR: action.payload,
                }

            case HANDLE_FILTER_COW_LIST_SCATTER_P2:
                return {
                    ...state,
                    scatterP2: action.payload,
                };

            case HANDLE_COW_LIST_REFERENCE:
                return {
                    ...state,
                    p2reference: action.payload.p2reference,
                    p3reference: action.payload.p3reference,
                };

            case HANDLE_REMOVE_COW_FILTER:
                return {
                    ...state,
                    filterMode: false,
                };

            case HANDLE_COW_LIST_SELECT:
                return {
                    ...state,
                    selected: action.payload,
                }

            case HANDLE_COW_LIST_HOVERED:
                return {
                    ...state,
                    hovered: action.payload,
                }

            default:
                return state;
        }
    };
