import { ResponsiveBar } from "@nivo/bar";
import React from "react";
import Tooltip from "../../Tooltip";
import "../DataType.scss";
import { iDataTypeDisplay } from "./interface";


const DataTypeDisplay: React.FC<iDataTypeDisplay> = (props) => {

    // dataType 1 represents data comparison from the users own farm
    // dataType 0 benchmark farms
    const trendColors = {
        green: "green",
        yellow: "#edf353",
        red: "red",
    }


    // const trendColor = (props.reverseTrend && props.trend === -1) ? "green" : (props.reverseTrend && props.trend === 1) ? "red" : (props.reverseTrend && props.trend === 0) ? "yellow" : props.trend === -1 ? "red" : props.trend === 0 ? "yellow" : props.trend === 1 ? "green" : "gray";
    const trendColor = (props.reverseTrend && props.trend < 0) ? trendColors.green : (props.reverseTrend && props.trend > 0) ? trendColors.red : props.trend < 0 ? trendColors.red : props.trend > 0 ? trendColors.green : trendColors.yellow;
    const arrowIcon = props.trend < 0 ? "fas fa-arrow-alt-circle-down" : props.trend > 0 ? "fas fa-arrow-alt-circle-up" : "fas fa-grip-lines"; // (props.reverseTrend && props.trend < 0) ? "fas fa-arrow-alt-circle-up" : (props.reverseTrend && props.trend > 0) ? "fas fa-arrow-alt-circle-down" <-- use this if reverseTrend should also affect the arrow direction

    let maxVAlue = props.dataType === 1 ? (props.ownComparison || 0) * 1.1 : props.benchmarkComparison * 1.1;
    maxVAlue = maxVAlue < props.current + 10 ? props.current * 1.1 : maxVAlue;
    maxVAlue = maxVAlue || 0;
    
    return (
        <div className="data-type__wrapper" id={props.id}>
            <div className={`data-type__benchmark-sticks-wrapper ${props.layout === "horizontal" ? "data-type__benchmark-sticks-wrapper--horizontal" : props.layout === "vertical" ? "data-type__benchmark-sticks-wrapper--vertical" : ""}`}>
                <div className="data-type__benchmark-stick" id={props.currentValueId}>
                    <ResponsiveBar
                        layout={props.layout ? props.layout : "vertical"}
                        data={[{
                            index: "Your farm now",
                            sticks: props.current || 0,
                        }]}
                        keys={["sticks"]}
                        indexBy="index"
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                        colors={props.colors[0]}
                        maxValue={maxVAlue}
                        enableGridY={false}
                        enableLabel={false}
                        labelTextColor={{ from: "color", modifiers: [["darker", 0]] }}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        tooltip={(el: any) => {
                            return (
                                <Tooltip disableWrapper={true}>
                                    <h4 style={{ color: "black", fontSize: "16px" }}>{el.indexValue}: {el.value}</h4>
                                </Tooltip>
                            );
                        }}
                    />
                </div>


                {/* benchmark */}
                <div className="data-type__benchmark-stick" id={props.referenceValudId}>
                    <ResponsiveBar
                        layout={props.layout ? props.layout : "vertical"}
                        data={[{
                            index: props.dataType === 1 ? "Your farm previously" : "Benchmark",
                            sticks: (props.dataType === 1 ? props.ownComparison : props.benchmarkComparison) || 0,
                        }]}
                        keys={["sticks"]}
                        indexBy="index"
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                        colors={props.colors[1]}
                        maxValue={maxVAlue}
                        enableGridY={false}
                        enableLabel={false}
                        labelTextColor={{ from: "color", modifiers: [["darker", 0]] }}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        tooltip={(el: any) => {
                            return (
                                <Tooltip disableWrapper={true}>
                                    <h4 style={{ color: "black", fontSize: "16px" }}>{el.indexValue}: {el.value}</h4>
                                </Tooltip>
                            );
                        }}
                    />
                </div>
                {props.dataType === 1 && props.layout !== "horizontal" && props.ownComparison && (
                    <span className="data-type__own-farm-arrow-wrapper" id={props.arrowId}>
                        <i style={{ color: trendColor }} className={`${arrowIcon} data-type__own-farm-arrow`} />
                    </span>
                )}
            </div>
            {/* {props.dataType === 1 && props.layout === "horizontal" && (
                <span className="data-type__own-farm-arrow-wrapper">
                    <i color={trendColor} className={`${arrowIcon} data-type__own-farm-arrow`} />
                </span>
            )} */}
        </div>
    );
};

export default DataTypeDisplay;
