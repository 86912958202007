import React, { Component } from "react";
// import "../../styles/HNAdviceStyle.css";

export class NotFoundPageContainer extends Component<{}> {
    public render() {
        return (
            <div className="hnadvice">
               <h5>The page was not found 404</h5>
            </div>
        );
    }
}
