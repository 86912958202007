import React from "react";
import "./Progressbar.scss";
import { iProgressBar } from "./interface";

const Progressbar: React.FC<iProgressBar> = (props) => {

    const width = (100 / props.steps) * props.progress;
    return (
        <div className="progressbar">
          <div className="progressbar__progress" style={{ width: `${width}%` }} />
        </div>
    );
};

export default Progressbar;
