import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { postXML } from "../../api/requests";
import { convertToOverviewChartDataOnShortDate } from "../../utills/dataFormatting";
import { CycleRegularitySpecTag } from "../../api/SpecTags"

import {
    GET_SEA_SUCCESS,
    GET_SEA_REQUEST,
    GET_SEA_FAILURE
} from "./constants";

function* getSEASaga(action: Action<any>): SagaIterator {
    try {
        const scoreData = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/sea/cicdistribution", {
            startDate: action.payload.startDate,
            stopDate: action.payload.stopDate,
            specTag: CycleRegularitySpecTag,
        });

        const convertedChartData = convertToOverviewChartDataOnShortDate(scoreData);

        yield put({ type: GET_SEA_SUCCESS, payload: { scoreData: convertedChartData} });
    } catch (error) {
        console.log(GET_SEA_FAILURE, error);
        yield put({ type: GET_SEA_FAILURE, payload: "Unable to get data" });
    }
}

export function* watchGetSEASaga(): SagaIterator {
    yield takeEvery(GET_SEA_REQUEST, getSEASaga);
}
