import {
    GET_CURRENT_REPRUDCTION_PERFORMANCE_FAILURE,
    GET_CURRENT_REPRUDCTION_PERFORMANCE_REQUEST,
    GET_CURRENT_REPRUDCTION_PERFORMANCE_SUCCESS,
    GET_KPI_CHART_DATA_REQUEST,
    GET_KPI_CHART_DATA_SUCCESS,
    GET_KPI_CHART_DATA_FAILURE,
} from "./constants";

export interface State {
    loading: boolean;
    data: any;
    error: string | null;
    gotData: boolean;
    kpiChartData: any;
}

export const initialState: State = {
    loading: false,
    data: {},
    kpiChartData: [],
    error: null,
    gotData: false,
};

export const CurrentReproductionPerformanceReducer = (state: State = initialState, action: any) => {
    switch (action.type) {
        case GET_KPI_CHART_DATA_REQUEST:
        case GET_CURRENT_REPRUDCTION_PERFORMANCE_REQUEST:
            return {
                ...state,
                loading: true,
                gotData: false,
                error: null,
            };
        case GET_CURRENT_REPRUDCTION_PERFORMANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                gotData: true,
                data: action.payload,
            };

        case GET_KPI_CHART_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                gotData: true,
                kpiChartData: action.payload,
            };

        case GET_KPI_CHART_DATA_FAILURE:
        case GET_CURRENT_REPRUDCTION_PERFORMANCE_FAILURE:
            return {
                ...state,
                loading: false,
                gotData: false,
                error: action.payload,
            };

        default:
            return state;

    }
};
