import { StepGuidesTranslations } from "./StepGuidesTranslations";
import danish from "./da";
import english from "./en";
export const translations = {
  'da': {
    translations: danish,
  },
  'options': {
    ...StepGuidesTranslations.options,
    'plural_rule': 'n != 1',
    'plural_number': '2',
  },
  'en': {
    translations: english,
  },
}
