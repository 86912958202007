import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import { i18nState } from "redux-i18n";
import { InseminationSuccessReducer } from "./AIS/reducer";
import { AnoestrusLengthReducer } from "./AnoestrusLength/reducer";
import { AnoestrusLengthOverviewReducer } from "./AnoestrusLengthOverview/reducer";
import { BaselineReportReducer } from "./BaselineReort/reducer";
import { CowsListReducer } from "./CowsList/reducer";
import { CRBWPReducer } from "./CRBWP/reducer";
import { CurrentReproductionPerformanceReducer } from "./CurrentReproductionPerformance/reducer";
import { FiltersReducer } from "./Filters/reducer";
import { FirstLutealPhaseReducer } from "./FirstLutealPhase/reducer";
import { HNInsightsReducer } from "./HNInsights/reducer";
import { HNIRReducer } from "./HNIR/reducer";
import { MaintenanceReducer } from "./Maintenance/reducer";
import { NavigationReducer } from "./Navigation/reducer";
import { ODReducer } from "./OD/reducer";
import { PBSSReducer } from "./PBSS/reducer";
import { ProportionOfDelayedSamplesReducer } from "./ProportionOfDelayedSamples/reducer";
import { SEAReducer } from "./SEA/reducer";
import { SettingsReducer } from "./Settings/reducer";
import { StickConsumptionReducer } from "./StickConsumption/reducer";
import { FarmsReducer } from "./Farms/reducer";
import { PerformanceScoreHistoryReducer } from "./PerformanceScoreHistory/reducer";
import { AuthentificationReducer } from "./Authenticate/reducer";

const reducers = (history: History) => combineReducers({
  i18nState: i18nState as any,
  router: connectRouter(history),
  hnInsightsState: HNInsightsReducer,
  navigationState: NavigationReducer,
  stickConsumptionState: StickConsumptionReducer,
  proportionOfDelayedSamplesState: ProportionOfDelayedSamplesReducer,
  baselineReportState: BaselineReportReducer,
  currentReproductionPerformanceState: CurrentReproductionPerformanceReducer,
  cowsListState: CowsListReducer,
  anoestrusLengthState: AnoestrusLengthReducer,
  firstLutealPhaseState: FirstLutealPhaseReducer,
  anoestrusLengthOverviewState: AnoestrusLengthOverviewReducer,
  crbwpState: CRBWPReducer,
  hnirState: HNIRReducer,
  seaState: SEAReducer,
  inseminationSuccessState: InseminationSuccessReducer,
  filtersState: FiltersReducer,
  pbssState: PBSSReducer,
  odState: ODReducer,
  settingsState: SettingsReducer,
  maintenanceState: MaintenanceReducer,
  farms: FarmsReducer,
  performanceScoreHistoryState: PerformanceScoreHistoryReducer,
  authenticationState: AuthentificationReducer,
});

export default reducers;
