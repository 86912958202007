import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, ButtonGroup } from "../../Input";
import LattecIcon from "../../LattecIcon";
import { SET_SIDEBAR_EXPANDED, TOGGLE_COW_LIST, HIDE_COW_LIST } from "../../redux/Navigation/constants";
import { legalPaths } from "../constants";
import KPISidebar from "../KPISidebar";
import "../Layout.scss";

const LayoutSidebar: React.FC = (props) => {

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const navigationState = useSelector((state: any) => state.navigationState);

    // const handleToggleKPISidenav = () => dispatch({ type: TOGGLE_KPI_SIDENAV });
    const handleToggleCowList = () => dispatch({ type: TOGGLE_COW_LIST });
    const hanleSetSidebarExpanded = (payload: boolean) => dispatch({ type: SET_SIDEBAR_EXPANDED, payload });

    const history = useHistory();

    const selectedRoute = history.location.pathname.split("/")[2];

    const isLegalPath = legalPaths.some((legalPath) => selectedRoute.indexOf(legalPath) !== -1);
    if (!isLegalPath && navigationState.cowListOpen) dispatch({ type: HIDE_COW_LIST });

    return (
        <div
            className="layout__sidebar"
            onMouseEnter={() => hanleSetSidebarExpanded(true)}
            onMouseLeave={() => hanleSetSidebarExpanded(false)}
            style={{ width: navigationState.sidebarExpanded ? "310px" : "74px" }}
        >
            <div className="layout__navigation">
                <ButtonGroup>
                    <Button
                        type="menu-item"
                        className="layout__navigation-item"
                        onClick={() => history.push("/hnadvice/dashboard")}
                        style={{ backgroundColor: selectedRoute === "dashboard" ? "#062f71" : "" }}
                    >
                        <div className="layout__sidebar-element-content">
                            <LattecIcon icon="dashboard" size={21} />
                            {navigationState.sidebarExpanded && (
                                <span className="layout__sidebar-element-label">{t("Dashboard")}</span>
                            )}
                        </div>
                    </Button>

                    <Button
                        style={{ backgroundColor: selectedRoute === "stick-consumption" ? "#062f71" : "" }}
                        type="menu-item"
                        className="layout__navigation-item"
                        onClick={() => history.push("/hnadvice/stick-consumption")}
                    >
                        <div className="layout__sidebar-element-content">
                            <LattecIcon icon="stick-consumption" size={21} />
                            {navigationState.sidebarExpanded && (
                                <span className="layout__sidebar-element-label">{t("StickConsumption")}</span>
                            )}
                        </div>
                    </Button>

                    <Button
                        style={{ backgroundColor: selectedRoute === "baseline-report" ? "#062f71" : "" }}
                        type="menu-item"
                        className="layout__navigation-item"
                        onClick={() => history.push("/hnadvice/baseline-report")}
                    >
                        <div className="layout__sidebar-element-content">
                            <LattecIcon icon="baseline" size={21} />
                            {navigationState.sidebarExpanded && (
                                <span className="layout__sidebar-element-label">{t("BaselineReport")}</span>
                            )}
                        </div>
                    </Button>
                </ButtonGroup>

                <ButtonGroup>
                    <Button
                        className="layout__navigation-item"
                        onClick={handleToggleCowList}
                        disabled={!isLegalPath}
                        type="menu-item"
                    >
                        <div className="layout__sidebar-element-content">
                            <LattecIcon icon="cows-list" />
                            {navigationState.sidebarExpanded && (
                                <span className="layout__sidebar-element-label">Cow list</span>
                            )}
                        </div>
                    </Button>
                </ ButtonGroup>

            </div>
            {navigationState.kpiSidenavOpen && (
                <KPISidebar />
            )}
        </div>
    );
};

export default LayoutSidebar;
