// @ts-ignore
import Plotly, { Data } from 'plotly.js-basic-dist';
import React from 'react';
import plotComponentFactory from 'react-plotly.js/factory'
import { iPoint, iScatterChart } from "./interface";
import './style.scss'

const ScatterChart: React.FC<iScatterChart> = (props) => {
  let data: Data[] = props.data.map((dataObj, index) => {
    let marker = {
      color: props.colors[index],
      size: 12,
      symbol: dataObj.id === "Not Inseminated" || dataObj.id === "Not pregnant" ? 'x-thin-open' : 'circle',
      line: dataObj.id === "Not Inseminated" || dataObj.id === "Not pregnant" ? { width: 3 } : undefined
    };
    let newData: Data = {
      x: dataObj.data.map(datas => datas.x),
      y: dataObj.data.map(datas => datas.y),
      type: 'scatter',
      mode: 'markers',
      marker,
      name: dataObj.id,
      text: dataObj.data.map(datas =>
        datas.cowNo + ' : ' + dataObj.id + '<br>' + 'DIM on heat: ' + datas.actualX + '<br>' + 'Heat Number: ' + datas.actualY),
      hoverinfo: 'text',
    }
    return newData
  })
  if (props.reverseOrder) data.reverse();

  let Plot = plotComponentFactory(Plotly);

  return (
    <Plot
      className="scatter-plot"
      onInitialized={(f, g) => {
        g.style.cursor = 'crosshair';
      }}
      data={data}
      onClick={(event) => {
        // take the last data points x and y coordinates
        const x = event.points.length > 0 ? Number(event.points[event.points.length - 1].x) : 0
        const y = event.points.length > 0 ? Number(event.points[event.points.length - 1].y) : 0

        const selectedCow = { data: { x, y, } }
        props.filter(selectedCow);
      }}
      onHover={event => {
        // @ts-ignore
        event.event.target.style.cursor = 'pointer'
      }}

      onUnhover={event => {
        // @ts-ignore
        event.event.target.style.cursor = 'crosshair'
      }}

      onSelected={(event) => {
        if (event && event.points.length > 0 && props.onSelection) {
          const data: iPoint[] = event.points.map(point => { return { x: point.x, y: point.y } });

          props.onSelection(data)
        }
      }}

      useResizeHandler
      style={{ width: '100%', height: '100%' }}
      layout={{
        hovermode: 'closest',
        margin: { t: 0 },
        xaxis: {
          title: props.xLegend,
          dtick: 20,
          showgrid: false
        },
        yaxis: {
          title: props.yLegend,
          dtick: 1
        },
        dragmode: "lasso",
        autosize: true,
        showlegend: false
      }}
      config={{ displayModeBar: false, responsive: true, showTips: true, scrollZoom: true, autosizable: true }}
    />
  );
}

export default ScatterChart;