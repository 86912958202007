import React from "react";
import { iIcon } from "./interface";

const BaselineIcon: React.FC<iIcon> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size ? props.size : "25"} height={props.size ? Number(props.size) + 2 : "27"} viewBox="0 0 24.673 27.836">
        <g id="Group_739" data-name="Group 739" transform="translate(-33.094 -336.164)">
            <g id="Path_610" data-name="Path 610" transform="translate(13378.999 23597)" fill="none" strokeLinejoin="round">
                <path d="M-13344.905-23259.836V-23234h21.017v-25.836Z" stroke="none"/>
                <path d="M -13344.9052734375 -23259.8359375 L -13344.9052734375 -23234 L -13323.888671875 -23234 L -13323.888671875 -23259.8359375 L -13344.9052734375 -23259.8359375 M -13344.9052734375 -23260.8359375 L -13323.888671875 -23260.8359375 C -13323.3359375 -23260.8359375 -13322.888671875 -23260.388671875 -13322.888671875 -23259.8359375 L -13322.888671875 -23234 C -13322.888671875 -23233.447265625 -13323.3359375 -23233 -13323.888671875 -23233 L -13344.9052734375 -23233 C -13345.4580078125 -23233 -13345.9052734375 -23233.447265625 -13345.9052734375 -23234 L -13345.9052734375 -23259.8359375 C -13345.9052734375 -23260.388671875 -13345.4580078125 -23260.8359375 -13344.9052734375 -23260.8359375 Z" stroke="none" fill={props.color ? props.color : "currentColor"}/>
            </g>
            <path id="Path_611" data-name="Path 611" d="M-13340.187-23253.375h11.625" transform="translate(13378.993 23596.303)" fill="none" stroke={props.color ? props.color : "currentColor"} strokeWidth="1"/>
            <path id="Path_612" data-name="Path 612" d="M-13340.187-23250.312h11.813" transform="translate(13378.994 23595.973)" fill="none" stroke={props.color ? props.color : "currentColor"} strokeWidth="1"/>
            <path id="Path_613" data-name="Path 613" d="M-13340.187-23247.25h6.75" transform="translate(13378.996 23595.643)" fill="none" stroke={props.color ? props.color : "currentColor"} strokeWidth="1"/>
            <path id="Path_614" data-name="Path 614" d="M-13333.25-23237.875l2.313-2.187,3.063,2.938,5.25-5" transform="translate(13378.985 23594.689)" fill="none" stroke={props.color ? props.color : "currentColor"} strokeWidth="1"/>
            <path id="Path_615" data-name="Path 615" d="M-13362-23217h3v3Z" transform="translate(13416.267 23568.973)" fill={props.color ? props.color : "currentColor"} stroke={props.color ? props.color : "currentColor"} strokeLinejoin="round" strokeWidth="1"/>
        </g>
    </svg>
);

export default BaselineIcon;
