export const filter = (arr: any, key: string) => arr.filter((el: any) => el[key] !== 0 && el[key] !== null);
export const boil = (arr: any, compareKey: string, conditionKey: string, dataKey: string, labelKey: string, dataLabels?: any) => {

    const output: any = [];

    arr.forEach((item: any) => {
        // check if the item is in the output array
        const existing = output.filter((v: any) => v[compareKey] === item[compareKey]);
        const existingIndex = output.indexOf(existing[0]);

        if (existing.length) {
            // throw item attributes into output[0]

            if (item[conditionKey] === true || item[conditionKey] === 0) {

                output[existingIndex][dataLabels[0]] = item[dataKey];
            } else if (item[conditionKey] === false || item[conditionKey] === 1) {
                output[existingIndex][dataLabels[1]] = item[dataKey];
            } else {
                output[existingIndex][dataLabels[2]] = item[dataKey];
            }

            output[existingIndex][labelKey] = item[labelKey];
            output[existingIndex][compareKey] = item[compareKey];

            // set Ranges
            // set Bin number

            // const existingIndex = output.indexOf(existing[0]);
            // output[existingIndex].value = output[existingIndex].value.concat(item.value);
        } else {

            // add item to array
            const newItem: any = {
                [dataLabels[0]]: 0,
                [dataLabels[1]]: 0,
                [dataLabels[2]]: 0,
                [labelKey]: item[labelKey],
                [compareKey]: item[compareKey],

            };

            if (item[conditionKey] === true || item[conditionKey] === 0) {
                newItem[dataLabels[0]] = item[dataKey];
            } else if (item[conditionKey] === false || item[conditionKey] === 1) {
                newItem[dataLabels[1]] = item[dataKey];
            } else {
                newItem[dataLabels[2]] = item[dataKey];
            }

            output.push(newItem);
        }
    });

    return output;
};
export const merge = (arr1: any, arr2: any, mergeKey: string) => {
    return arr1.map((el: any, index: number) => {
        return {
            ...el,
            reference: arr2[index][mergeKey] * 10,
        };
    });
};
export const convertToOverviewChartData = (arr: any) => {
    arr = arr.filter(function (item: any) {
        return item.ScoreValue !== null;
    });
    return arr.map((el: any) => {
        return {
            x: `${new Date(el.BinDataEndDate).toLocaleDateString("en-GB")}`,
            y: Number((el.ScoreValue * 100).toFixed(0)),
        };
    });
};
export const convertToOverviewChartDataOnShortDate = (arr: any) => {
    arr = arr.filter(function (item: any) {
        return item.ScoreValue !== null;
    });
    return arr.map((el: any) => {
        return {
            x: `${new Date(el.ShortDate).toLocaleDateString("en-GB")}`,
            y: Number((el.ScoreValue * 100).toFixed(0)),
        };
    });
};
export const splitIntoGroupsOf = (arr: any, keys: any) => {

    // ["Non-pregnant", "Pregnant", "Potentially pregnant"]
    const groupOne: any = { id: "Non-pregnant", data: [] };
    const groupTwo: any = { id: "Pregnant", data: [] };
    const groupThree: any = { id: "Potentially pregnant", data: [] };

    for (const element of arr) {
        if (element[keys[0]] === 0) {
            groupOne.data.push({
                x: element[keys[1]],
                y: element[keys[2]],
            });
        }

        if (element[keys[0]] === 1) {
            groupTwo.data.push({
                x: element[keys[1]],
                y: element[keys[2]],
            });
        }

        if (element[keys[0]] === 2) {
            groupThree.data.push({
                x: element[keys[1]],
                y: element[keys[2]],
            });
        }
    }

    return [groupOne, groupTwo, groupThree];
};

export const convertRangesToNumbers = (val: any) => {
    if (val.split) {
        let valList = val.split("-");
        if (valList.length === 2) {
            return {
                min: parseInt(valList[0], 10),
                max: parseInt(valList[1], 10),
            };
        }

        valList = val.split("<=");
        if (valList.length === 2) {
            return {
                min: 0,
                max: parseInt(valList[1], 10),
            };
        }

        valList = val.split("+");
        if (valList.length === 2) {
            return {
                min: parseInt(valList[0], 10),
                max: 9999,
            };
        }

        valList = val.split("<");
        if (valList.length === 2) {
            return {
                min: 0,
                max: parseInt(valList[1], 10),
            };
        }

        valList = val.split("-");
        if (valList.length === 1) {
            return {
                min: parseInt(valList[0], 10),
                max: parseInt(valList[0], 10),
            };
        }
    } else if (!isNaN(val)) {
        return val;
    }
};

const delayToText = (delay: number): string | undefined => {
    if (!delay) { return undefined; }
    switch (delay) {
        case 1: return "Not delayed"; // "Less than 24 hours";
        case 2: return "Not delayed"; // "Between 24 and 36 hours";
        case 3: return "Between 36 and 48 hours";
        case 4: return "Between 48 and 60 hours";
        case 5: return "Between 60 and 72 hours";
        case 6: return "Between 72 and 84 hours";
        case 7: return "Between 84 and 96 hours";
        case 8: return "More than 96 hours";
    }
};

export const convertToKPISpecificData = (list: any, kpi: string) => {
    const newPayload: any = [];

    switch (kpi) {
        case "doa":
            list.forEach((element: any) => {
                newPayload.push({
                    CowNo: element.CowNo,
                    CowId: element.DimCowId,
                    LactationNo: element.LactationNo,
                    AnoestrusLength: parseFloat(element.DOA).toFixed(0),
                    FirstLutealPhaseLength: element.FLP !== null ? `${parseInt(element.FLP.toFixed(0), 10)}` : undefined,
                    DOAState: parseInt(element.IsComplete, 10) === 0 ? "Anoestrus" : "Cyclic",
                    FPLState: element.FLPIsComplete !== null ? (parseInt(element.FLPIsComplete, 10) === 0 ? "Not completed" : "Completed") : undefined,
                    DOADelay: delayToText(element.DOADelay),
                    DOADelayValue: element.DOADelay,
                    FLPDelay: delayToText(element.DOAFLPDelay),
                    FLPDelayValue: element.DOAFLPDelay,
                    CullState: parseInt(element.CullState, 10) === 0 ? "Not culled" : parseInt(element.CullState, 10) === 2 ? "Culled" : "To be culled",
                });
            });

            break;
        case "crbwp":
            list.forEach((element: any) => {
                // if cow already in list, then use this cow
                let cowExists = false;
                newPayload.forEach((element1: any) => {
                    if (element.CowNo === element1.CowNo && element.DimCowId === element1.CowId) {
                        // merge elements
                        element1.CycleData.push({
                            CycleLength: element.CycleLength !== null ? parseFloat(element.CycleLength).toFixed(0) : undefined,
                            FolPhaseLength: element.FolPhaseLength !== null ? `${parseFloat(element.FolPhaseLength.toFixed(0))}` : undefined,
                            CLState: element.IsComplete === null ? null : parseInt(element.IsComplete, 10) === 0 ? "Not completed" : "Completed",
                            FPLState: element.FPLIsComplete !== null ? parseInt(element.FPLIsComplete, 10) === 0 ? "Not completed" : "Completed" : undefined,
                            FilterSampleDelay: delayToText(element.FilterSampleDelay),
                            FilterSampleDelayValue: element.FilterSampleDelay,
                            CycleOnset: element.CycleOnset,
                        })
                        cowExists = true;
                    }
                });

                if (!cowExists) {
                    newPayload.push({
                        CowNo: element.CowNo,
                        CowId: element.DimCowId,
                        LactationNo: element.LactationNo,
                        CycleData: [{
                            CycleLength: element.CycleLength !== null ? parseFloat(element.CycleLength).toFixed(0) : undefined,
                            FolPhaseLength: element.FolPhaseLength !== null ? `${parseFloat(element.FolPhaseLength.toFixed(0))}` : undefined,
                            CLState: element.IsComplete === null ? null : parseInt(element.IsComplete, 10) === 0 ? "Not completed" : "Completed",
                            FPLState: element.FPLIsComplete !== null ? parseInt(element.FPLIsComplete, 10) === 0 ? "Not completed" : "Completed" : undefined,
                            FilterSampleDelay: delayToText(element.FilterSampleDelay),
                            FilterSampleDelayValue: element.FilterSampleDelay,
                            CycleOnset: element.CycleOnset,
                        }],
                        CullState: parseInt(element.CullState, 10) === 0 ? "Not culled" : parseInt(element.CullState, 10) === 2 ? "Culled" : "To be culled",
                    });
                }
            });

            break;
        case "hnir":
            list.forEach((element: any) => {
                // if cow already in list, then use this cow
                let cowExists = false;

                newPayload.forEach((element1: any) => {
                    if (element.CowNo === element1.CowNo && element.DimCowId === element1.CowId) {
                        // merge elements
                        element1.InseminationData.push({
                            DaysFromCalving: parseFloat(element.DFC).toFixed(0),
                            HNIRState: parseInt(element.IsComplete, 10) === 0 ? "Not Inseminated" : "Inseminated",
                            HeatNo: element.HeatNo,
                            FilterSampleDelay: delayToText(element.FilterSampleDelay),
                            FilterSampleDelayValue: element.FilterSampleDelay,
                        })
                        cowExists = true;
                    }
                });

                if (!cowExists) {
                    newPayload.push({
                        CowNo: element.CowNo,
                        CowId: element.DimCowId,
                        LactationNo: element.LactationNo,
                        InseminationData: [{
                            DaysFromCalving: parseFloat(element.DFC).toFixed(0),
                            HNIRState: parseInt(element.IsComplete, 10) === 0 ? "Not Inseminated" : "Inseminated",
                            HeatNo: element.HeatNo,
                            FilterSampleDelay: delayToText(element.FilterSampleDelay),
                            FilterSampleDelayValue: element.FilterSampleDelay,
                        }],
                        CullState: parseInt(element.CullState, 10) === 0 ? "Not culled" : parseInt(element.CullState, 10) === 2 ? "Culled" : "To be culled",
                    });
                }
            });

            break;
        case "ais":
            // since the cow list are sorted by AI number we know the current insimination state is the last for each cow
            // so we reverse the list order
            let prevCowNo: number;
            let prevInseminationState: string;
            list.reverse().forEach((element: any) => {
                //
                newPayload.push({
                    CowNo: element.CowNo,
                    CowId: element.DimCowId,
                    LactationNo: element.LactationNo,
                    AINumber: element.AINumber,
                    HoursBetweenHeatAndAI: element.HoursBetweenHeatAndAI !== null ? parseFloat(element.HoursBetweenHeatAndAI).toFixed(0) : `${0}`,
                    InseminationState: parseInt(element.CompletionState, 10) === 0 ? "Not pregnant" : parseInt(element.CompletionState, 10) === 2 ? "Potentially pregnant" : "Pregnant",
                    DaysFromCalving: parseFloat(element.DFC).toFixed(0),
                    FPLength: element.FPLength !== null ? parseFloat(element.FPLength).toFixed(0) : undefined,
                    CullState: parseInt(element.CullState, 10) === 0 ? "Not culled" : parseInt(element.CullState, 10) === 2 ? "Culled" : "To be culled",
                    CurrentInseminationState: element.CowNo === prevCowNo ? prevInseminationState : undefined,
                });

                // remember the current state if this cow is also the next element in the list, so we know its current state
                if (prevCowNo !== element.CowNo) {
                    prevInseminationState = parseInt(element.CompletionState, 10) === 0 ? "Not pregnant" : parseInt(element.CompletionState, 10) === 2 ? "Potentially pregnant" : "Pregnant";
                }
                prevCowNo = element.CowNo;
            });

            break;
        case "sea":
            list.forEach((element: any) => {
                // if cow already in list, then use this cow
                let cowExists = false;
                newPayload.forEach((element1: any) => {
                    if (element.CowNo === element1.CowNo && element.AINumber === element1.AINumber && element.LactationNo === element1.LactationNo && element.DFC == element1.DaysFromCalving) {
                        // merge elements
                        element1.PBSSState = element.PBSSIsComplete !== null ? parseInt(element.PBSSIsComplete, 10) === 0 ? "Early abortion" : "Successful" : element1.PBSSState;
                        element1.SEAState = element.SEAIsComplete !== null ? parseInt(element.SEAIsComplete, 10) === 0 ? "Early embryo death" : "Successful" : element1.SEAState;
                        cowExists = true;
                    }
                });
                if (!cowExists) {
                    newPayload.push({
                        CowNo: element.CowNo,
                        CowId: element.DimCowId,
                        LactationNo: element.LactationNo,
                        AINumber: element.AINumber,
                        DaysFromCalving: parseFloat(element.DFC).toFixed(0),
                        PBSSState: element.PBSSIsComplete !== null ? parseInt(element.PBSSIsComplete, 10) === 0 ? "Early abortion" : "Successful" : undefined,
                        SEAState: element.SEAIsComplete !== null ? parseInt(element.SEAIsComplete, 10) === 0 ? "Early embryo death" : "Successful" : undefined,
                        CullState: parseInt(element.CullState, 10) === 0 ? "Not culled" : parseInt(element.CullState, 10) === 2 ? "Culled" : "To be culled",
                    });
                }
            });

            break;
        case "pbss":
            list.forEach((element: any) => {
                newPayload.push({
                    CowNo: element.CowNo,
                    CowId: element.DimCowId,
                    LactationNo: element.LactationNo,
                    AINumber: element.AINumber,
                    DaysFromCalving: parseFloat(element.DFC).toFixed(0),
                    State: parseInt(element.IsComplete, 10) === 0 ? "Not inseminated" : "Inseminated",
                    CullState: parseInt(element.CullState, 10) === 0 ? "Not culled" : parseInt(element.CullState, 10) === 2 ? "Culled" : "To be culled",
                });
            });

            break;
        case "od":
            list.forEach((element: any) => {
                const completionStatus = element.CompletionStatus !== null ? parseInt(element.CompletionStatus, 10) : undefined;
                const aINumber = element.AINumber;
                // if cow already in list, then use this cow
                let cowExists = false;
                newPayload.forEach((element1: any) => {
                    if (element.CowNo === element1.CowNo && element.DimCowId === element1.CowId) {
                        // merge elements
                        if (aINumber > element1.MAXAINumber) {
                            element1.MAXAINumber = aINumber;
                            element1.CompletionStatus = completionStatus === 0 ? "Non-pregnant" : completionStatus === 1 ? "Pregnant" : completionStatus === 2 ? "Potentially pregnant" : undefined;
                            element1.OpenDays = element.OD;
                        }

                        cowExists = true;
                    }
                });

                if (!cowExists) {
                    newPayload.push({
                        CowNo: element.CowNo,
                        CowId: element.DimCowId,
                        OpenDays: element.OD,
                        LactationNo: element.LactationNo,
                        MAXAINumber: element.AINumber,
                        CompletionStatus: completionStatus === 0 ? "Non-pregnant" : completionStatus === 1 ? "Pregnant" : completionStatus === 2 ? "Potentially pregnant" : undefined,
                        CullState: parseInt(element.CullState, 10) === 0 ? "Not culled" : parseInt(element.CullState, 10) === 2 ? "Culled" : "To be culled",
                    });
                }
            });

            break;

        default:
            return "error";
    }

    return newPayload;
};

export const swarmifyData = (arr: any) => {
    const newArr = arr.map((el: any, index: number) => {
        return {
            id: `00${index}`,
            group: el.IsComplete ? "Inseminated" : "Not Inseminated",
            records: el.NumberOfRecords,
            volume: 10 + (el.NumberOfRecords * 5),
        };
    });

    return newArr;
};

export const getDOAGraphData = (data: any, p2reference: any, p3reference: any, l1: boolean, l2: boolean, l3: boolean, delayed: boolean, culled: boolean) => {
    // initialize the buckets
    const graphP2Data = [{ "Cyclic cows": 0, "Anoestrus cows": 0, "undefined": 0, "Ranges": "<=25", "BinNumber": 1 }, { "Cyclic cows": 0, "Anoestrus cows": 0, "undefined": 0, "Ranges": "26 - 35", "BinNumber": 2 }, { "Cyclic cows": 0, "Anoestrus cows": 0, "undefined": 0, "Ranges": "36 - 45", "BinNumber": 3 }, { "Cyclic cows": 0, "Anoestrus cows": 0, "undefined": 0, "Ranges": "46 - 55", "BinNumber": 4 }, { "Cyclic cows": 0, "Anoestrus cows": 0, "undefined": 0, "Ranges": "56 - 65", "BinNumber": 5 }, { "Cyclic cows": 0, "Anoestrus cows": 0, "undefined": 0, "Ranges": "66 - 75", "BinNumber": 6 }, { "Cyclic cows": 0, "Anoestrus cows": 0, "undefined": 0, "Ranges": "76+", "BinNumber": 7 }];
    const graphP3Data = [{ "Completed": 0, "Not completed": 0, "undefined": 0, "Bin": "0 - 4", "BinNumber": 0 }, { "Completed": 0, "Not completed": 0, "undefined": 0, "Bin": "5 - 9", "BinNumber": 1 }, { "Completed": 0, "Not completed": 0, "undefined": 0, "Bin": "10 - 14", "BinNumber": 2 }, { "Completed": 0, "Not completed": 0, "undefined": 0, "Bin": "15 - 19", "BinNumber": 3 }, { "Completed": 0, "Not completed": 0, "undefined": 0, "Bin": "20+", "BinNumber": 4 }];

    // load cows to their buckets
    let lactation1CowsP2: number = 0;
    let lactation2CowsP2: number = 0;
    let lactation3CowsP2: number = 0;
    let lactation1CowsP3: number = 0;
    let lactation2CowsP3: number = 0;
    let lactation3CowsP3: number = 0;
    data.forEach((cow: any) => {
        if (!culled && cow["CullState"] !== "Not culled") return

        if (cow["AnoestrusLength"] !== undefined && cow["DOAState"] !== undefined) {
            if (cow["LactationNo"] === 1) { lactation1CowsP2++; }
            if (cow["LactationNo"] === 2) { lactation2CowsP2++; }
            if (cow["LactationNo"] >= 3) { lactation3CowsP2++; }
            graphP2Data.forEach(function (value: any) {
                if (!delayed && (cow["DOADelayValue"] === null || cow["DOADelayValue"] > 2)) return
                switch (value["BinNumber"]) {
                    case 1: return cow["AnoestrusLength"] <= 25 && (cow["DOAState"] === "Cyclic" ? value["Cyclic cows"]++ : value["Anoestrus cows cows"]++);
                    case 2: return cow["AnoestrusLength"] > 25 && cow["AnoestrusLength"] <= 35 && (cow["DOAState"] === "Cyclic" ? value["Cyclic cows"]++ : value["Anoestrus cows"]++);
                    case 3: return cow["AnoestrusLength"] > 35 && cow["AnoestrusLength"] <= 45 && (cow["DOAState"] === "Cyclic" ? value["Cyclic cows"]++ : value["Anoestrus cows"]++);
                    case 4: return cow["AnoestrusLength"] > 45 && cow["AnoestrusLength"] <= 55 && (cow["DOAState"] === "Cyclic" ? value["Cyclic cows"]++ : value["Anoestrus cows"]++);
                    case 5: return cow["AnoestrusLength"] > 55 && cow["AnoestrusLength"] <= 65 && (cow["DOAState"] === "Cyclic" ? value["Cyclic cows"]++ : value["Anoestrus cows"]++);
                    case 6: return cow["AnoestrusLength"] > 65 && cow["AnoestrusLength"] <= 75 && (cow["DOAState"] === "Cyclic" ? value["Cyclic cows"]++ : value["Anoestrus cows"]++);
                    case 7: return cow["AnoestrusLength"] > 75 && (cow["DOAState"] === "Cyclic" ? value["Cyclic cows"]++ : value["Anoestrus cows"]++);

                }
            });
        }

        if (cow["FirstLutealPhaseLength"] !== undefined && cow["FPLState"] !== undefined) {
            if (cow["LactationNo"] === 1) { lactation1CowsP3++; }
            if (cow["LactationNo"] === 2) { lactation2CowsP3++; }
            if (cow["LactationNo"] >= 3) { lactation3CowsP3++; }
            graphP3Data.forEach(function (value: any) {
                if (!delayed && (cow["FLPDelayValue"] === null || cow["FLPDelayValue"] > 2)) return
                switch (value["BinNumber"]) {
                    case 0: return cow["FirstLutealPhaseLength"] < 5 && (cow["FPLState"] === "Completed" ? value["Completed"]++ : value["Not completed"]++);
                    case 1: return cow["FirstLutealPhaseLength"] >= 5 && cow["FirstLutealPhaseLength"] < 10 && (cow["FPLState"] === "Completed" ? value["Completed"]++ : value["Not completed"]++);
                    case 2: return cow["FirstLutealPhaseLength"] >= 10 && cow["FirstLutealPhaseLength"] < 15 && (cow["FPLState"] === "Completed" ? value["Completed"]++ : value["Not completed"]++);
                    case 3: return cow["FirstLutealPhaseLength"] >= 15 && cow["FirstLutealPhaseLength"] < 20 && (cow["FPLState"] === "Completed" ? value["Completed"]++ : value["Not completed"]++);
                    case 4: return cow["FirstLutealPhaseLength"] >= 20 && (cow["FPLState"] === "Completed" ? value["Completed"]++ : value["Not completed"]++);
                }
            });
        }
    });

    // scale reference data to actual data
    const graphP2DataMerged = graphP2Data.map((el: any, index: number) => {
        if (!p3reference[index]) { return { ...el }; }
        return {
            ...el,
            reference: (l1 ? (p3reference[index]["YValueL1"] ? p3reference[index]["YValueL1"] : 0) * lactation1CowsP2 : 0) +
                (l2 ? (p3reference[index]["YValueL2"] ? p3reference[index]["YValueL2"] : 0) * lactation2CowsP2 : 0) +
                (l3 ? (p3reference[index]["YValueL3P"] ? p3reference[index]["YValueL3P"] : 0) * lactation3CowsP2 : 0),
        };
    });
    const graphP3DataMerged = graphP3Data.map((el: any, index: number) => {
        if (!p2reference[index]) { return { ...el }; }
        return {
            ...el,
            reference: (l1 ? (p2reference[index]["YValueL1"] ? p2reference[index]["YValueL1"] : 0) * lactation1CowsP3 : 0) +
                (l2 ? (p2reference[index]["YValueL2"] ? p2reference[index]["YValueL2"] : 0) * lactation2CowsP3 : 0) +
                (l3 ? (p2reference[index]["YValueL3P"] ? p2reference[index]["YValueL3P"] : 0) * lactation3CowsP3 : 0),
        };
    });

    return { graphP2DataMerged, graphP3DataMerged };
};

export const getODGraphData = (data: any, l1: boolean, l2: boolean, l3: boolean, culled: boolean) => {
    const graphP2DataMerged = [{ "Pregnant": 0, "Potentially pregnant": 0, "Non-pregnant": 0, "Ranges": "<=70", "BinNumber": 1 }, { "Pregnant": 0, "Potentially pregnant": 0, "Non-pregnant": 0, "Ranges": "71-90", "BinNumber": 2 }, { "Pregnant": 0, "Potentially pregnant": 0, "Non-pregnant": 0, "Ranges": "91-110", "BinNumber": 3 }, { "Pregnant": 0, "Potentially pregnant": 0, "Non-pregnant": 0, "Ranges": "111-130", "BinNumber": 4 }, { "Pregnant": 0, "Potentially pregnant": 0, "Non-pregnant": 0, "Ranges": "131-150", "BinNumber": 5 }, { "Pregnant": 0, "Potentially pregnant": 0, "Non-pregnant": 0, "Ranges": "151-170", "BinNumber": 6 }, { "Pregnant": 0, "Potentially pregnant": 0, "Non-pregnant": 0, "Ranges": "171+", "BinNumber": 7 }];
    const graphP3DataMerged = [{ "Pregnant": 0, "Potentially pregnant": 0, "Non-pregnant": 0, "Bin": "1", "BinNumber": 1 }, { "Pregnant": 0, "Potentially pregnant": 0, "Non-pregnant": 0, "Bin": "2", "BinNumber": 2 }, { "Pregnant": 0, "Potentially pregnant": 0, "Non-pregnant": 0, "Bin": "3", "BinNumber": 3 }, { "Pregnant": 0, "Potentially pregnant": 0, "Non-pregnant": 0, "Bin": "4", "BinNumber": 4 }, { "Pregnant": 0, "Potentially pregnant": 0, "Non-pregnant": 0, "Bin": "5", "BinNumber": 5 }, { "Pregnant": 0, "Potentially pregnant": 0, "Non-pregnant": 0, "Bin": "6", "BinNumber": 6 }, { "Pregnant": 0, "Potentially pregnant": 0, "Non-pregnant": 0, "Bin": "7", "BinNumber": 7 }, { "Pregnant": 0, "Potentially pregnant": 0, "Non-pregnant": 0, "Bin": "8", "BinNumber": 8 }, { "Pregnant": 0, "Potentially pregnant": 0, "Non-pregnant": 0, "Bin": "9", "BinNumber": 9 }];

    data.forEach((cow: any) => {
        if (!culled && cow["CullState"] !== "Not culled") { return; }
        graphP2DataMerged.forEach(function (value: any) {
            switch (value["BinNumber"]) {
                case 1: return cow["OpenDays"] <= 70 && (cow["CompletionStatus"] === "Pregnant" ? value["Pregnant"]++ : (cow["CompletionStatus"] === "Potentially pregnant" ? value["Potentially pregnant"]++ : value["Non-pregnant"]++));
                case 2: return cow["OpenDays"] > 70 && cow["OpenDays"] <= 90 && (cow["CompletionStatus"] === "Pregnant" ? value["Pregnant"]++ : (cow["CompletionStatus"] === "Potentially pregnant" ? value["Potentially pregnant"]++ : value["Non-pregnant"]++));
                case 3: return cow["OpenDays"] > 90 && cow["OpenDays"] <= 110 && (cow["CompletionStatus"] === "Pregnant" ? value["Pregnant"]++ : (cow["CompletionStatus"] === "Potentially pregnant" ? value["Potentially pregnant"]++ : value["Non-pregnant"]++));
                case 4: return cow["OpenDays"] > 110 && cow["OpenDays"] <= 130 && (cow["CompletionStatus"] === "Pregnant" ? value["Pregnant"]++ : (cow["CompletionStatus"] === "Potentially pregnant" ? value["Potentially pregnant"]++ : value["Non-pregnant"]++));
                case 5: return cow["OpenDays"] > 130 && cow["OpenDays"] <= 150 && (cow["CompletionStatus"] === "Pregnant" ? value["Pregnant"]++ : (cow["CompletionStatus"] === "Potentially pregnant" ? value["Potentially pregnant"]++ : value["Non-pregnant"]++));
                case 6: return cow["OpenDays"] > 150 && cow["OpenDays"] <= 170 && (cow["CompletionStatus"] === "Pregnant" ? value["Pregnant"]++ : (cow["CompletionStatus"] === "Potentially pregnant" ? value["Potentially pregnant"]++ : value["Non-pregnant"]++));
                case 7: return cow["OpenDays"] > 170 && (cow["CompletionStatus"] === "Pregnant" ? value["Pregnant"]++ : (cow["CompletionStatus"] === "Potentially pregnant" ? value["Potentially pregnant"]++ : value["Non-pregnant"]++));
            }
        });

        graphP3DataMerged.forEach(function (value: any) {
            if (cow["MAXAINumber"] > value["BinNumber"]) { value["Non-pregnant"]++; } else if (cow["MAXAINumber"] === value["BinNumber"]) {
                cow["CompletionStatus"] === "Pregnant" ? value["Pregnant"]++ : (cow["CompletionStatus"] === "Potentially pregnant" ? value["Potentially pregnant"]++ : value["Non-pregnant"]++);
            }
            // else if (cow["MAXAINumber"] > 9 && value["BinNumber"] === 9) cow["CompletionStatus"] === "Pregnant" ? value["Pregnant"]++ : (cow["CompletionStatus"] === "Potentially pregnant" ? value["Potentially pregnant"]++ : value["Non-pregnant"]++);
        });
    });

    return { graphP2DataMerged, graphP3DataMerged };
};

export const getHNIRGraphData = (data: any, l1: boolean, l2: boolean, l3: boolean, culled: boolean, delayed: boolean) => {
    // initialize the buckets
    // const scatterP1 = [{"id": "Inseminated", "data": [] as any}, {"id": "Not Inseminated", "data": [] as any}];
    const graphP3Data = [{ "Inseminated": 0, "Not Inseminated": 0, "Bin": "1", "BinNumber": 0 }, { "Inseminated": 0, "Not Inseminated": 0, "Bin": "2", "BinNumber": 1 }, { "Inseminated": 0, "Not Inseminated": 0, "Bin": "3", "BinNumber": 2 }, { "Inseminated": 0, "Not Inseminated": 0, "Bin": "4", "BinNumber": 3 }, { "Inseminated": 0, "Not Inseminated": 0, "Bin": "5", "BinNumber": 4 }, { "Inseminated": 0, "Not Inseminated": 0, "Bin": "6+", "BinNumber": 5 }];
    const scatterP1 = [{ id: "Inseminated", data: [] }, { id: "Not Inseminated", data: [] }] as any;


    data.forEach((cow: any) => {

        cow.InseminationData.forEach((insemination: any) => {
            if (!delayed && (insemination["FilterSampleDelayValue"] === null || insemination["FilterSampleDelayValue"] > 2)) return
            if (insemination["HeatNo"] !== undefined && insemination["HNIRState"] !== undefined) {
                scatterP1
                    .find((o: any) => o.id === insemination.HNIRState)
                    ?.data
                    .push({
                        x: insemination.DaysFromCalving > 300 ? 300 : insemination.DaysFromCalving,
                        y: insemination.HeatNo > 5 ? 5 : insemination.HeatNo,
                        actualY: insemination.HeatNo,
                        actualX: insemination.DaysFromCalving,
                        cowId: cow.CowId,
                        cowNo: cow.CowNo,
                    });
            }

            graphP3Data.forEach((value: any) => {
                switch (value["BinNumber"]) {
                    case 0: return insemination["HeatNo"] == 1 && (insemination["HNIRState"] === "Inseminated" ? value["Inseminated"]++ : value["Not Inseminated"]++);
                    case 1: return insemination["HeatNo"] == 2 && (insemination["HNIRState"] === "Inseminated" ? value["Inseminated"]++ : value["Not Inseminated"]++);
                    case 2: return insemination["HeatNo"] == 3 && (insemination["HNIRState"] === "Inseminated" ? value["Inseminated"]++ : value["Not Inseminated"]++);
                    case 3: return insemination["HeatNo"] == 4 && (insemination["HNIRState"] === "Inseminated" ? value["Inseminated"]++ : value["Not Inseminated"]++);
                    case 4: return insemination["HeatNo"] == 5 && (insemination["HNIRState"] === "Inseminated" ? value["Inseminated"]++ : value["Not Inseminated"]++);
                    case 5: return insemination["HeatNo"] >= 6 && (insemination["HNIRState"] === "Inseminated" ? value["Inseminated"]++ : value["Not Inseminated"]++);
                }
            });
        });
        if (cow["HeatNo"] !== undefined && cow["HNIRState"] !== undefined) {
            // cowExists = false;
            // const DIM = minDIM + 20 * Math.floor((cow.DaysFromCalving - minDIM) / 20);
            // const heatGroup =  cow["HeatNo"] > 4 ? "Heat 5+" : "Heat " + cow["HeatNo"];
            // scatterP1.forEach((element: any) => {
            //     if (element.DIM === DIM && element.group === heatGroup && element.HNIRState === cow["HNIRState"]) {
            //         // merge elements
            //         element.volume = element.volume + 5;
            //         element.records = element.records + 1;
            //         cowExists =  true;
            //     }
            // });

            /*
            FPLength: "10"
            HoursBetweenHeatAndAI: "67"
            x: "67"
            y: "10"
            */

            /*
                keys={["Inseminated", "Not Inseminated"]}
                indexBy="Bin"
                yLegend="#Cows"
            */
            // if (!cowExists) {
            //     console.log(cow);
            //     if (cow["HNIRState"] === "Inseminated") {
            //         scatterP1[0].data.push({
            //             id: id++,
            //             Inseminated: true,
            //             HNIRState: cow["HNIRState"],
            //             group: heatGroup,
            //             x: cow.DaysFromCalving,
            //             y: cow.LactationNo,
            //             DIM,
            //             // volume: 10,
            //             // records : 1,
            //         });
            //     } else {
            //         scatterP1[1].data.push({
            //             id: id++,
            //             "Not Inseminated": true,
            //             HNIRState: cow["HNIRState"],
            //             group: heatGroup,
            //             DIM,
            //             volume: 10,
            //             records : 1,
            //         });
            //     }
            // scatterP1[0].data.push({})
            // else
            // scatterP1[1].data.push({})

            // scatterP1.push({
            //     id: id++,
            //     HNIRState: cow["HNIRState"],
            //     group: heatGroup,
            //     DIM: DIM,
            //     volume: 10,
            //     records : 1,
            // });
            //scatterP1.find((o: any) => o.id === cow.HNIRState)?.data.push({x: cow.DaysFromCalving > 300 ? 300 : cow.DaysFromCalving, y: cow.HeatNo > 5 ? 5 : cow.HeatNo, actualY: cow.HeatNo, actualX: cow.DaysFromCalving});
        }

        graphP3Data.forEach((value: any) => {
            switch (value["BinNumber"]) {
                case 0: return cow["HeatNo"] == 1 && (cow["HNIRState"] === "Inseminated" ? value["Inseminated"]++ : value["Not Inseminated"]++);
                case 1: return cow["HeatNo"] == 2 && (cow["HNIRState"] === "Inseminated" ? value["Inseminated"]++ : value["Not Inseminated"]++);
                case 2: return cow["HeatNo"] == 3 && (cow["HNIRState"] === "Inseminated" ? value["Inseminated"]++ : value["Not Inseminated"]++);
                case 3: return cow["HeatNo"] == 4 && (cow["HNIRState"] === "Inseminated" ? value["Inseminated"]++ : value["Not Inseminated"]++);
                case 4: return cow["HeatNo"] == 5 && (cow["HNIRState"] === "Inseminated" ? value["Inseminated"]++ : value["Not Inseminated"]++);
                case 5: return cow["HeatNo"] >= 6 && (cow["HNIRState"] === "Inseminated" ? value["Inseminated"]++ : value["Not Inseminated"]++);
            }
        });
    });

    return { scatterP1, graphP3Data };
};

export const getAISGraphData = (data: any, l1: boolean, l2: boolean, l3: boolean, culled: boolean) => {
    const scatterP1 = [{ id: "Not pregnant", data: [] as any }, { id: "Pregnant", data: [] as any }, { id: "Potentially pregnant", data: [] as any }];
    const scatterP2 = [{ id: "Not pregnant", data: [] as any }, { id: "Pregnant", data: [] as any }, { id: "Potentially pregnant", data: [] as any }];

    const graphP3DataMerged = [{ "Not pregnant": 0, "Pregnant": 0, "Potentially pregnant": 0, "Bin": "<24", "BinNumber": 1 }, { "Not pregnant": 0, "Pregnant": 0, "Potentially pregnant": 0, "Bin": "24-30", "BinNumber": 2 }, { "Not pregnant": 0, "Pregnant": 0, "Potentially pregnant": 0, "Bin": "31-36", "BinNumber": 3 }, { "Not pregnant": 0, "Pregnant": 0, "Potentially pregnant": 0, "Bin": "37-42", "BinNumber": 4 }, { "Not pregnant": 0, "Pregnant": 0, "Potentially pregnant": 0, "Bin": "43-48", "BinNumber": 5 }, { "Not pregnant": 0, "Pregnant": 0, "Potentially pregnant": 0, "Bin": "49-54", "BinNumber": 6 }, { "Not pregnant": 0, "Pregnant": 0, "Potentially pregnant": 0, "Bin": "55-60", "BinNumber": 7 }, { "Not pregnant": 0, "Pregnant": 0, "Potentially pregnant": 0, "Bin": "61+", "BinNumber": 8 }];

    data.forEach((cow: any) => {
        if (!culled && cow["CullState"] !== "Not culled") { return; }

        if (cow["AINumber"] !== undefined && cow["InseminationState"] !== undefined) {
            scatterP1.find((o) => o.id === cow.InseminationState)
                ?.data.push({
                    x: cow.DaysFromCalving > 300 ? 300 : cow.DaysFromCalving,
                    y: cow.AINumber > 5 ? 5 : cow.AINumber,
                    actualY: cow.AINumber,
                    actualX: cow.DaysFromCalving,
                    cowId: cow.CowId,
                    cowNo: cow.CowNo
                });
        }

        // some cows does not have an FPLength so exclude them from the plots
        scatterP2.find((o) => o.id === cow.InseminationState && cow.FPLength !== undefined && cow.HoursBetweenHeatAndAI > 0)
            ?.data.push({
                FPLength: cow.FPLength,
                y: cow.FPLength > 20 ? 20 : cow.FPLength,
                HoursBetweenHeatAndAI: cow.HoursBetweenHeatAndAI,
                x: cow.HoursBetweenHeatAndAI > 0 ? cow.HoursBetweenHeatAndAI : 0,
                cowId: cow.CowId,
                cowNo: cow.CowNo
            });

        graphP3DataMerged.forEach(function (value: any) {
            switch (value["BinNumber"]) {
                case 1: return cow["HoursBetweenHeatAndAI"] < 24 && (cow["InseminationState"] === "Not pregnant" ? value["Not pregnant"]++ : (cow["InseminationState"] === "Pregnant" ? value["Pregnant"]++ : value["Potentially pregnant"]++));
                case 2: return cow["HoursBetweenHeatAndAI"] >= 24 && cow["HoursBetweenHeatAndAI"] <= 30 && (cow["InseminationState"] === "Not pregnant" ? value["Not pregnant"]++ : (cow["InseminationState"] === "Pregnant" ? value["Pregnant"]++ : value["Potentially pregnant"]++));
                case 3: return cow["HoursBetweenHeatAndAI"] > 30 && cow["HoursBetweenHeatAndAI"] <= 36 && (cow["InseminationState"] === "Not pregnant" ? value["Not pregnant"]++ : (cow["InseminationState"] === "Pregnant" ? value["Pregnant"]++ : value["Potentially pregnant"]++));
                case 4: return cow["HoursBetweenHeatAndAI"] > 36 && cow["HoursBetweenHeatAndAI"] <= 42 && (cow["InseminationState"] === "Not pregnant" ? value["Not pregnant"]++ : (cow["InseminationState"] === "Pregnant" ? value["Pregnant"]++ : value["Potentially pregnant"]++));
                case 5: return cow["HoursBetweenHeatAndAI"] > 42 && cow["HoursBetweenHeatAndAI"] <= 48 && (cow["InseminationState"] === "Not pregnant" ? value["Not pregnant"]++ : (cow["InseminationState"] === "Pregnant" ? value["Pregnant"]++ : value["Potentially pregnant"]++));
                case 6: return cow["HoursBetweenHeatAndAI"] > 48 && cow["HoursBetweenHeatAndAI"] <= 54 && (cow["InseminationState"] === "Not pregnant" ? value["Not pregnant"]++ : (cow["InseminationState"] === "Pregnant" ? value["Pregnant"]++ : value["Potentially pregnant"]++));
                case 7: return cow["HoursBetweenHeatAndAI"] > 54 && cow["HoursBetweenHeatAndAI"] <= 60 && (cow["InseminationState"] === "Not pregnant" ? value["Not pregnant"]++ : (cow["InseminationState"] === "Pregnant" ? value["Pregnant"]++ : value["Potentially pregnant"]++));
                case 8: return cow["HoursBetweenHeatAndAI"] > 60 && (cow["InseminationState"] === "Not pregnant" ? value["Not pregnant"]++ : (cow["InseminationState"] === "Pregnant" ? value["Pregnant"]++ : value["Potentially pregnant"]++));
            }
        });
    });

    return { scatterP1, scatterP2, graphP3DataMerged };
};

export const getSEAGraphData = (data: any, l1: boolean, l2: boolean, l3: boolean, culled: boolean) => {
    const graphP2DataMerged = [{ "Early embryo death": 0, "Successful": 0, "Bin": "<=60", "BinNumber": 1 }, { "Early embryo death": 0, "Successful": 0, "Bin": "60-79", "BinNumber": 2 }, { "Early embryo death": 0, "Successful": 0, "Bin": "80-99", "BinNumber": 3 }, { "Early embryo death": 0, "Successful": 0, "Bin": "100-119", "BinNumber": 4 }, { "Early embryo death": 0, "Successful": 0, "Bin": "120-139", "BinNumber": 5 }, { "Early embryo death": 0, "Successful": 0, "Bin": "140-159", "BinNumber": 6 }, { "Early embryo death": 0, "Successful": 0, "Bin": "160-179", "BinNumber": 7 }, { "Early embryo death": 0, "Successful": 0, "Bin": "180+", "BinNumber": 8 }];
    const graphP3DataMerged = [{ "Early abortion": 0, "Successful": 0, "Bin": "<=60", "BinNumber": 1 }, { "Early abortion": 0, "Successful": 0, "Bin": "60-79", "BinNumber": 2 }, { "Early abortion": 0, "Successful": 0, "Bin": "80-99", "BinNumber": 3 }, { "Early abortion": 0, "Successful": 0, "Bin": "100-119", "BinNumber": 4 }, { "Early abortion": 0, "Successful": 0, "Bin": "120-139", "BinNumber": 5 }, { "Early abortion": 0, "Successful": 0, "Bin": "140-159", "BinNumber": 6 }, { "Early abortion": 0, "Successful": 0, "Bin": "160-179", "BinNumber": 7 }, { "Early abortion": 0, "Successful": 0, "Bin": "180+", "BinNumber": 8 }];

    data.forEach((cow: any) => {
        if (!culled && cow["CullState"] !== "Not culled") { return; }
        if (cow["SEAState"] != undefined) {
            graphP2DataMerged.forEach(function (value: any) {
                switch (value["BinNumber"]) {
                    case 1: return cow["DaysFromCalving"] < 60 && (cow["SEAState"] === "Early embryo death" ? value["Early embryo death"]++ : value["Successful"]++);
                    case 2: return cow["DaysFromCalving"] >= 60 && cow["DaysFromCalving"] < 80 && (cow["SEAState"] === "Early embryo death" ? value["Early embryo death"]++ : value["Successful"]++);
                    case 3: return cow["DaysFromCalving"] >= 80 && cow["DaysFromCalving"] < 100 && (cow["SEAState"] === "Early embryo death" ? value["Early embryo death"]++ : value["Successful"]++);
                    case 4: return cow["DaysFromCalving"] >= 100 && cow["DaysFromCalving"] < 120 && (cow["SEAState"] === "Early embryo death" ? value["Early embryo death"]++ : value["Successful"]++);
                    case 5: return cow["DaysFromCalving"] >= 120 && cow["DaysFromCalving"] < 140 && (cow["SEAState"] === "Early embryo death" ? value["Early embryo death"]++ : value["Successful"]++);
                    case 6: return cow["DaysFromCalving"] >= 140 && cow["DaysFromCalving"] < 160 && (cow["SEAState"] === "Early embryo death" ? value["Early embryo death"]++ : value["Successful"]++);
                    case 7: return cow["DaysFromCalving"] >= 160 && cow["DaysFromCalving"] < 180 && (cow["SEAState"] === "Early embryo death" ? value["Early embryo death"]++ : value["Successful"]++);
                    case 8: return cow["DaysFromCalving"] >= 180 && (cow["SEAState"] === "Early embryo death" ? value["Early embryo death"]++ : value["Successful"]++);
                }
            });
        }

        if (cow["PBSSState"] != undefined) {
            graphP3DataMerged.forEach(function (value: any) {
                switch (value["BinNumber"]) {
                    case 1: return cow["DaysFromCalving"] < 60 && (cow["PBSSState"] === "Early abortion" ? value["Early abortion"]++ : value["Successful"]++);
                    case 2: return cow["DaysFromCalving"] >= 60 && cow["DaysFromCalving"] < 80 && (cow["PBSSState"] === "Early abortion" ? value["Early abortion"]++ : value["Successful"]++);
                    case 3: return cow["DaysFromCalving"] >= 80 && cow["DaysFromCalving"] < 100 && (cow["PBSSState"] === "Early abortion" ? value["Early abortion"]++ : value["Successful"]++);
                    case 4: return cow["DaysFromCalving"] >= 100 && cow["DaysFromCalving"] < 120 && (cow["PBSSState"] === "Early abortion" ? value["Early abortion"]++ : value["Successful"]++);
                    case 5: return cow["DaysFromCalving"] >= 120 && cow["DaysFromCalving"] < 140 && (cow["PBSSState"] === "Early abortion" ? value["Early abortion"]++ : value["Successful"]++);
                    case 6: return cow["DaysFromCalving"] >= 140 && cow["DaysFromCalving"] < 160 && (cow["PBSSState"] === "Early abortion" ? value["Early abortion"]++ : value["Successful"]++);
                    case 7: return cow["DaysFromCalving"] >= 160 && cow["DaysFromCalving"] < 180 && (cow["PBSSState"] === "Early abortion" ? value["Early abortion"]++ : value["Successful"]++);
                    case 8: return cow["DaysFromCalving"] >= 180 && (cow["PBSSState"] === "Early abortion" ? value["Early abortion"]++ : value["Successful"]++);
                }
            });
        }
    });

    return { graphP2DataMerged, graphP3DataMerged };
};

export const getCRBWPGraphData = (data: any, p2reference: any, p3reference: any, l1: boolean, l2: boolean, l3: boolean, delayed: boolean, culled: boolean) => {
    // initialize the buckets
    const graphP2Data = [{ "Completed": 0, "Not completed": 0, "undefined": 0, "Bin": "<=17", "BinNumber": 1 }, { "Completed": 0, "Not completed": 0, "undefined": 0, "Bin": "18 - 20", "BinNumber": 2 }, { "Completed": 0, "Not completed": 0, "undefined": 0, "Bin": "21 -23", "BinNumber": 3 }, { "Completed": 0, "Not completed": 0, "undefined": 0, "Bin": "24 - 26", "BinNumber": 4 }, { "Completed": 0, "Not completed": 0, "undefined": 0, "Bin": "27+", "BinNumber": 5 }];
    const graphP3Data = [{ "Completed": 0, "Not completed": 0, "undefined": 0, "Bin": "<=4", "BinNumber": 1 }, { "Completed": 0, "Not completed": 0, "undefined": 0, "Bin": "5 - 10", "BinNumber": 2 }, { "Completed": 0, "Not completed": 0, "undefined": 0, "Bin": "11 - 13", "BinNumber": 3 }, { "Completed": 0, "Not completed": 0, "undefined": 0, "Bin": "14 - 15", "BinNumber": 4 }, { "Completed": 0, "Not completed": 0, "undefined": 0, "Bin": "16+", "BinNumber": 5 }];

    // load cows to their buckets
    let lactation1CowsP2: number = 0;
    let lactation2CowsP2: number = 0;
    let lactation3CowsP2: number = 0;
    let lactation1CowsP3: number = 0;
    let lactation2CowsP3: number = 0;
    let lactation3CowsP3: number = 0;
    data.forEach((cow: any) => {
        if (!culled && cow["CullState"] !== "Not culled") return;
        cow.CycleData.forEach((cycleData: any) => {
            if (!delayed && (cycleData["FilterSampleDelayValue"] === null || cycleData["FilterSampleDelayValue"] > 2)) return

            if (cycleData["CycleLength"] != undefined && cycleData["CLState"] !== undefined) {
                if (cow["LactationNo"] === 1) { lactation1CowsP2++; }
                if (cow["LactationNo"] === 2) { lactation2CowsP2++; }
                if (cow["LactationNo"] >= 3) { lactation3CowsP2++; }
                graphP2Data.forEach(function (value: any) {
                    if (cycleData["CLState"] !== null) {
                        switch (value["BinNumber"]) {
                            case 1: return cycleData["CycleLength"] < 18 && (cycleData["CLState"] === "Completed" ? value["Completed"]++ : value["Not completed"]++);
                            case 2: return cycleData["CycleLength"] >= 18 && cycleData["CycleLength"] < 21 && (cycleData["CLState"] === "Completed" ? value["Completed"]++ : value["Not completed"]++);
                            case 3: return cycleData["CycleLength"] >= 21 && cycleData["CycleLength"] < 24 && (cycleData["CLState"] === "Completed" ? value["Completed"]++ : value["Not completed"]++);
                            case 4: return cycleData["CycleLength"] >= 24 && cycleData["CycleLength"] < 27 && (cycleData["CLState"] === "Completed" ? value["Completed"]++ : value["Not completed"]++);
                            case 5: return cycleData["CycleLength"] >= 27 && (cycleData["CLState"] === "Completed" ? value["Completed"]++ : value["Not completed"]++);
                        }
                    }
                });
            }

            if (cycleData["FolPhaseLength"] !== undefined && cycleData["FPLState"] !== undefined) {
                if (cow["LactationNo"] === 1) { lactation1CowsP3++; }
                if (cow["LactationNo"] === 2) { lactation2CowsP3++; }
                if (cow["LactationNo"] >= 3) { lactation3CowsP3++; }
                graphP3Data.forEach(function (value: any) {
                    switch (value["BinNumber"]) {
                        case 1: return cycleData["FolPhaseLength"] < 5 && (cycleData["FPLState"] === "Completed" ? value["Completed"]++ : value["Not completed"]++);
                        case 2: return cycleData["FolPhaseLength"] >= 5 && cycleData["FolPhaseLength"] <= 10 && (cycleData["FPLState"] === "Completed" ? value["Completed"]++ : value["Not completed"]++);
                        case 3: return cycleData["FolPhaseLength"] > 10 && cycleData["FolPhaseLength"] <= 13 && (cycleData["FPLState"] === "Completed" ? value["Completed"]++ : value["Not completed"]++);
                        case 4: return cycleData["FolPhaseLength"] > 13 && cycleData["FolPhaseLength"] <= 15 && (cycleData["FPLState"] === "Completed" ? value["Completed"]++ : value["Not completed"]++);
                        case 5: return cycleData["FolPhaseLength"] > 15 && (cycleData["FPLState"] === "Completed" ? value["Completed"]++ : value["Not completed"]++);
                    }
                });
            }
        });
    });

    // scale reference data to actual data
    const graphP2DataMerged = graphP2Data.map((el: any, index: number) => {
        if (!p3reference[index]) { return { ...el }; }
        return {
            ...el,
            reference: (l1 ? (p3reference[index]["YValueL1"] ? p3reference[index]["YValueL1"] : 0) * lactation1CowsP2 : 0) +
                (l2 ? (p3reference[index]["YValueL2"] ? p3reference[index]["YValueL2"] : 0) * lactation2CowsP2 : 0) +
                (l3 ? (p3reference[index]["YValueL3P"] ? p3reference[index]["YValueL3P"] : 0) * lactation3CowsP2 : 0),
        };
    });
    const graphP3DataMerged = graphP3Data.map((el: any, index: number) => {
        if (!p2reference[index]) { return { ...el }; }
        return {
            ...el,
            reference: (l1 ? (p2reference[index]["YValueL1"] ? p2reference[index]["YValueL1"] : 0) * lactation1CowsP3 : 0) +
                (l2 ? (p2reference[index]["YValueL2"] ? p2reference[index]["YValueL2"] : 0) * lactation2CowsP3 : 0) +
                (l3 ? (p2reference[index]["YValueL3P"] ? p2reference[index]["YValueL3P"] : 0) * lactation3CowsP3 : 0),
        };
    });

    return { graphP2DataMerged, graphP3DataMerged };
};
