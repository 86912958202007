import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { postXML } from "../../api/requests";
import { InseminationSuccessSpecTag } from "../../api/SpecTags"
import { convertToOverviewChartDataOnShortDate } from "../../utills/dataFormatting";

import {
    GET_INSEMINATION_SUCCESS_FAILURE,
    GET_INSEMINATION_SUCCESS_REQUEST,
    GET_INSEMINATION_SUCCESS_SUCCESS,
} from "./constants";

function* getInseminationSuccessSaga(action: Action<any>): SagaIterator {
    try {
        const scoreData = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/ais/cicdistribution", {
            startDate: action.payload.startDate,
            stopDate: action.payload.stopDate,
            specTag: InseminationSuccessSpecTag,
        });

        const convertedChartData = convertToOverviewChartDataOnShortDate(scoreData);

        yield put({ type: GET_INSEMINATION_SUCCESS_SUCCESS, payload: { scoreData: convertedChartData} });

    } catch (error) {
        console.log(GET_INSEMINATION_SUCCESS_FAILURE, error);
        yield put({ type: GET_INSEMINATION_SUCCESS_FAILURE, payload: "Unable to get data" });
    }
}

export function* watchGetInseminationSuccessSaga(): SagaIterator {
    yield takeEvery(GET_INSEMINATION_SUCCESS_REQUEST, getInseminationSuccessSaga);
}
