import React from "react";
import ContentLoader from "react-content-loader";
import iPlaceholderLoader from "./interface";
import './style.scss'

const PlaceholderLoader: React.FC<iPlaceholderLoader> = (props) => {
  let width = props.width || '100%';
  let height = props.height || '100%';
  let myRandId = Math.random().toString(36).substring(3);

  function bodyBuilder() {
    switch (props.type) {
      case 'StickConsumption':
        return buildStickConsumption();
      case 'AnoestrusLength':
        return buildAnoestrusLength();
      case 'Spiderchart':
        return buildSpiderChart();
      case 'Baseline':
        return buildBaseline();
      case 'CurrentReproductionPerformance':
        return buildCurReproPerf();
      case 'KpiOverview':
        return buildKpiOverview();
      default:
        break;
    }
  }

  function buildAnoestrusLength() {
    return <>


      <g transform="translate(60,60)">
        <g>
          <line opacity="1" x1="0" x2="1170.699951171875" y1="295" y2="295" stroke="#dddddd" strokeWidth="1"></line>
          <line opacity="1" x1="0" x2="1170.699951171875" y1="268" y2="268" stroke="#dddddd" strokeWidth="1"></line>
          <line opacity="1" x1="0" x2="1170.699951171875" y1="241" y2="241" stroke="#dddddd" strokeWidth="1"></line>
          <line opacity="1" x1="0" x2="1170.699951171875" y1="215" y2="215" stroke="#dddddd" strokeWidth="1"></line>
          <line opacity="1" x1="0" x2="1170.699951171875" y1="188" y2="188" stroke="#dddddd" strokeWidth="1"></line>
          <line opacity="1" x1="0" x2="1170.699951171875" y1="161" y2="161" stroke="#dddddd" strokeWidth="1"></line>
          <line opacity="1" x1="0" x2="1170.699951171875" y1="134" y2="134" stroke="#dddddd" strokeWidth="1"></line>
          <line opacity="1" x1="0" x2="1170.699951171875" y1="107" y2="107" stroke="#dddddd" strokeWidth="1"></line>
          <line opacity="1" x1="0" x2="1170.699951171875" y1="80" y2="80" stroke="#dddddd" strokeWidth="1"></line>
          <line opacity="1" x1="0" x2="1170.699951171875" y1="54" y2="54" stroke="#dddddd" strokeWidth="1"></line>
          <line opacity="1" x1="0" x2="1170.699951171875" y1="27" y2="27" stroke="#dddddd" strokeWidth="1"></line>
          <line opacity="1" x1="0" x2="1170.699951171875" y1="0" y2="0" stroke="#dddddd" strokeWidth="1"></line>
        </g>
        {/* style="mix-blend-mode: multiply; pointer-events: none;" */}
        <path fill="#bcc9d1" d="M49,-118C108.55555555555554,-107.16666666666667,168.11111111111111,-96.33333333333333,227.66666666666666,-53C287.22222222222223,-9.666666666666671,346.77777777777777,98.33333333333334,406.3333333333333,154C465.88888888888886,209.66666666666669,525.4444444444445,281,585,281C644.5555555555555,281,704.1111111111111,263,763.6666666666666,263C823.2222222222222,263,882.7777777777778,283.3333333333333,942.3333333333334,288C1001.8888888888889,292.6666666666667,1061.4444444444443,293.8333333333333,1121,295L1121,295C1061.4444444444443,295,1001.8888888888889,295,942.3333333333334,295C882.7777777777778,295,823.2222222222222,295,763.6666666666666,295C704.1111111111111,295,644.5555555555555,295,585,295C525.4444444444445,295,465.88888888888886,295,406.3333333333333,295C346.77777777777777,295,287.22222222222223,295,227.66666666666666,295C168.11111111111111,295,108.55555555555554,295,49,295Z"></path>
        <g>
          <g transform="translate(49, 0)">
            <rect width="112" height="295" rx="0" ry="0" fill="#0062A3" strokeWidth="0" stroke="#0062A3" ></rect>
          </g>
          <g transform="translate(209, 134)">
            <rect width="112" height="161" rx="0" ry="0" fill="#0062A3" strokeWidth="0" stroke="#0062A3" ></rect>
          </g>
          <g transform="translate(369, 188)">
            <rect width="112" height="107" rx="0" ry="0" fill="#0062A3" strokeWidth="0" stroke="#0062A3"></rect>
          </g>
          <g transform="translate(529, 215)">
            <rect width="112" height="80" rx="0" ry="0" fill="#0062A3" strokeWidth="0" stroke="#0062A3"></rect>
          </g>
          <g transform="translate(689, 268)">
            <rect width="112" height="27" rx="0" ry="0" fill="#0062A3" strokeWidth="0" stroke="#0062A3"></rect>
          </g>
          <g transform="translate(849, 268)">
            <rect width="112" height="27" rx="0" ry="0" fill="#0062A3" strokeWidth="0" stroke="#0062A3"></rect>
          </g>
          <g transform="translate(1009, 268)">
            <rect width="112" height="27" rx="0" ry="0" fill="#0062A3" strokeWidth="0" stroke="#0062A3"></rect>
          </g>
          <g transform="translate(49, 295)">
            <rect width="112" height="0" rx="0" ry="0" fill="#0081D5" strokeWidth="0" stroke="#0081D5"></rect>
          </g>
          <g transform="translate(209, 54)">
            <rect width="112" height="80" rx="0" ry="0" fill="#0081D5" strokeWidth="0" stroke="#0081D5" ></rect>
          </g>
          <g transform="translate(369, 80)">
            <rect width="112" height="108" rx="0" ry="0" fill="#0081D5" strokeWidth="0" stroke="#0081D5"></rect>
          </g>
          <g transform="translate(529, 134)">
            <rect width="112" height="81" rx="0" ry="0" fill="#0081D5" strokeWidth="0" stroke="#0081D5"></rect>
          </g>
          <g transform="translate(689, 241)">
            <rect width="112" height="27" rx="0" ry="0" fill="#0081D5" strokeWidth="0" stroke="#0081D5"></rect>
          </g>
          <g transform="translate(849, 295)">
            <rect width="112" height="0" rx="0" ry="0" fill="#0081D5" strokeWidth="0" stroke="#0081D5"></rect>
          </g>
          <g transform="translate(1009, 295)">
            <rect width="112" height="0" rx="0" ry="0" fill="#0081D5" strokeWidth="0" stroke="#0081D5"></rect>
          </g>
        </g>
      </g>


    </>;
  }

  function buildStickConsumption() {
    return (
      <g>
        <rect x="10" y="15" rx="0" ry="0" width="50" height="40" />
        <rect x="10" y="60" rx="13" ry="13" width="220" height="15" />
        <rect x="80" y="15" rx="0" ry="0" width="14" height="40" />
        <rect x="100" y="15" rx="0" ry="0" width="14" height="40" />
        <g transform={`scale( ${2} 1)`}>
          <path d="M0 79L115 101L231 80L347 114L462 86L578 91L578 200L0 200Z" />
        </g>
      </g>
    )
  }

  function buildSpiderChart() {
    return <>
      <g transform="translate(25,25)">
        <g transform="translate(255.66665649414062, 255.5)">
          <g>
            <circle fill="none" r="255.5" strokeWidth="3">
            </circle>
            <circle fill="none" r="204.4" strokeWidth="3">
            </circle>
            <circle fill="none" r="153.3" strokeWidth="3">
            </circle>
            <circle fill="none" r="102.2" strokeWidth="3">
            </circle>
            <circle fill="none" r="51.1" strokeWidth="3">
            </circle>
          </g>
        </g>
      </g>
    </>
  }

  function buildBaseline() {
    return <>
      <rect x="17" y="34" rx="6" ry="6" width="176" height="16" />
      <rect x="205" y="34" rx="0" ry="0" width="166" height="14" />
      <rect x="205" y="51" rx="0" ry="0" width="166" height="14" />
      <rect x="399" y="44" rx="6" ry="6" width="46" height="17" />
    </>
  }

  function buildCurReproPerf() {
    return <>
      <rect x="128" y="13" rx="0" ry="0" width="11" height="35" />
      <rect x="143" y="13" rx="0" ry="0" width="11" height="35" />
      <rect x="14" y="15" rx="5" ry="5" width="93" height="36" />
      <rect x="18" y="61" rx="5" ry="5" width="132" height="13" />
    </>
  }

  function buildKpiOverview() {
    return <>
      <rect x="72" y="11" rx="0" ry="0" width="11" height="35" />
      <rect x="86" y="10" rx="0" ry="0" width="11" height="35" />
      <rect x="9" y="9" rx="5" ry="5" width="42" height="36" />
      <rect x="13" y="50" rx="5" ry="5" width="137" height="14" />
      <g transform={`scale( ${2} 1)`}>
        <path d="M0 79L115 101L231 80L347 114L462 86L578 91L578 400L0 400Z" />
      </g>
    </>
  }

  return <div className="svg-container" style={{ paddingBottom: props.pixelHeight }}>

    {/* <svg version="1.1" viewBox="0 0 500 500"
        preserveAspectRatio="xMinYMin meet" className="svg-content">
        <circle fill="#F7941E" stroke="#231F20" strokeWidth="10"
          stroke-miterlimit="10" cx="250" cy="250" r="200" opacity="0.6" />
      </svg> */}


    <svg
      version="1.1"
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="xMinYMin meet"
      className="svg-content"
    >
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" className="darkShimmer" />
          <stop offset="45%" className="darkShimmer" />
          <stop offset="50%" className="lightShimmer" />
          <stop offset="55%" className="darkShimmer" />
          <stop offset="100%" className="darkShimmer" />
        </linearGradient>
      </defs>
      <rect x="-120%" y="0" rx="0" ry="0" width="300%" height="100%" fill="url(#grad1)" id="shimmering-bg" />
      <mask id={"myMask" + myRandId} >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" fill="white" />
        <g fill="black" stroke="black">
          {bodyBuilder()}
        </g>
      </mask>
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" fill="white" mask={`url(#${'myMask' + myRandId})`} />
    </svg>
  </div>
}

export default PlaceholderLoader;