import React, { useState } from "react";
import Button from "../../Input/Button";
import HoverGuide from "../../HoverGuide";
import "../Card.scss";
import { iGuide } from "./interface";

const CardGuide: React.FC<iGuide> = (props) => {
  // @ts-ignore //FIXME: why would this be set but never used?
  const [hovered, handleSetHover] = useState(false);
  // const targetRef = useRef(null) as any;


  return (
    <>
      <div className="card__guide">
        {props.onOpenLib && (
          <HoverGuide content="Go to library">
            <Button onClick={props.onOpenLib}>
              <span className="fa-stack">
                <i className="card__icon fal fa-book fa-1x" />
              </span>
            </Button>
          </HoverGuide>
        )}

        {props.openStepGuide && (
          <HoverGuide content="How to read this">
            <Button onClick={props.openStepGuide}>
              <span className="fa-stack">
                <i
                  onMouseOver={() => handleSetHover(true)}
                  onMouseOut={() => handleSetHover(false)}
                  className="card__icon fal fa-info-circle"
                />
              </span>
            </Button>
          </HoverGuide>
        )}

        {props.toggleExpand && (
          <HoverGuide content={props.isExpanded ? "Shrink chart" : "Expand chart"}>
            <Button onClick={props.toggleExpand}>
              <span className="fa-stack card__icon card__icon__scale" >
                <i className="fal fa-expand fa-stack-1x" />
                {
                  props.isExpanded === true ?
                    <i className="fa fa-compress-alt fa-stack-1x arrows" />
                    :
                    <i className="fa fa-expand-alt fa-stack-1x arrows" />
                }
              </span>
            </Button>
          </HoverGuide>
        )}
      </div>
    </>
  );
};

export default CardGuide;
