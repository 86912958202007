import React from "react";
import "../Layout.scss";

const LayoutContent: React.FC = (props) => {
    return (
        <div className="layout__content">
            {props.children}
        </div>
    );
};

export default LayoutContent;
