import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader, CardHeadline, CardGuide, CardContent } from "../../Card";
import Tooltip from "../../Tooltip";
import { ResponsiveLine } from "@nivo/line";
import { handleTriggerStartTour } from "../../utills/intercom";
import StatusNumber from "../../StatusNumber";
import { DataTypeDisplay } from "../../DataType";
import { COLOR_SCREME_1 } from "../../redux/Navigation/constants";
import { GET_KPI_CHART_DATA_REQUEST } from "../../redux/CurrentReproductionPerformance/constants";
import { iOverView12Month } from "./interface";
import { SELECT_DATA_TME_PERIOD, SELECT_DATA_TME_START } from "../../redux/Filters/constants";
import PlaceholderLoader from "../../PlaceholderLoader";
import './styles.scss'

const OverView12Month: React.FC<iOverView12Month> = (props) => {

    const dispatch = useDispatch();
    const currentReproductionPerformanceState = useSelector((state: any) => state.currentReproductionPerformanceState);

    useEffect(() => {
        dispatch({ type: GET_KPI_CHART_DATA_REQUEST, payload: { dataType: 0 } });
    }, [dispatch]);

    const benchmarkComparison = currentReproductionPerformanceState.gotData && currentReproductionPerformanceState.kpiChartData.find((element: any) => element.key === props.kpi) ? currentReproductionPerformanceState.kpiChartData.find((element: any) => element.key === props.kpi).reference : 0;

    const handleSetStartDate = (point: any, event: any) => {
        if (point.data && point.data.date && Object.keys(point.data.date).length > 2) {
            const myDate = new Date();
            myDate.setFullYear(point.data.date.split('/')[2]);
            myDate.setMonth(point.data.date.split('/')[1] - 1);
            myDate.setDate(point.data.date.split('/')[0]);
            dispatch({ type: SELECT_DATA_TME_PERIOD, payload: { index: 0, key: "anoestrusLength" } });
            dispatch({ type: SELECT_DATA_TME_START, payload: { today: myDate } });
        }
    }

    return (
        <Card className="months12">
            <CardHeader>
                <CardHeadline text="Overview of the past 12 months" />
                <CardGuide
                    openStepGuide={() => handleTriggerStartTour(props.stepGuide)}
                />
            </CardHeader>
            {
                props.performanceScore.data === null || props.performanceScore.data.length === 0 ?
                    <PlaceholderLoader type='KpiOverview' width='694' height='236' />
                    :
                    <CardContent>
                        <div className="StatusNumbers">
                            <StatusNumber
                                className="status-number"
                                id="overview-number"
                                number={props.performanceScore.scoreValue}
                                text={"Last score date: " + new Date(props.performanceScore.scoreDate).toLocaleDateString("en-GB")}
                            >
                                <DataTypeDisplay
                                    id="overview-reference-bars"
                                    benchmarkComparison={benchmarkComparison}
                                    current={props.performanceScore.gotData && props.performanceScore.scoreValue}
                                    colors={COLOR_SCREME_1}
                                />
                            </StatusNumber>
                        </div>
                        <div style={{ position: "relative", height: "150px" }}>
                            <div id="overview-chart" style={{ position: "absolute", width: "100%", height: "100%" }}>
                                <ResponsiveLine
                                    colors={COLOR_SCREME_1}
                                    data={props.performanceScore.data ? props.performanceScore.data : []}
                                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                                    xScale={{ type: "point" }}
                                    yScale={{ type: "linear", min: 0, max: 100, stacked: false, reverse: false }}
                                    curve="cardinal"
                                    axisTop={null}
                                    axisRight={null}
                                    tooltip={(el: any) => <Tooltip addStyles={true} point={el.point} headline={"Overview of the past 12 months"} xLabel={"Date"} xKey={"date"} moveLeft={222} />}
                                    axisBottom={{
                                        orient: "bottom",
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: "transportation",
                                        legendOffset: 36,
                                        legendPosition: "middle"
                                    }}
                                    axisLeft={{
                                        orient: "left",
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: "count",
                                        legendOffset: -40,
                                        legendPosition: "middle",
                                    }}
                                    enableGridX={false}
                                    enableGridY={false}
                                    lineWidth={0}
                                    enablePoints={false}
                                    pointSize={10}
                                    pointColor="#ffffff"
                                    pointBorderWidth={2}
                                    pointBorderColor={{ from: "serieColor" }}
                                    pointLabel="y"
                                    pointLabelYOffset={-24}
                                    enableArea={true}
                                    areaOpacity={0.5}
                                    useMesh={true}
                                    legends={[]}
                                    onClick={(point: any, event: any) => handleSetStartDate(point, event)}
                                />
                            </div>
                        </div>
                    </CardContent>
            }
        </Card>
    );
};

export default OverView12Month;
