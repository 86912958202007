export const getTimeDifferenceToString = (date: Date, daysDelay?: number) => {

    const delay: number = daysDelay !== undefined ? daysDelay : 0;

    
    const newDate: Date = new Date(date);
    newDate.setDate(newDate.getDate() + delay);
    return `(${newDate.toLocaleDateString("en-GB")})`;
};

export const formatDateToISOString = (date: Date): string => {
    const dateSeparator: string = "-";
    const dd: string = date.getDate() < 10 ? "0" + date.getDate().toString() : date.getDate().toString();
    const m: number = date.getMonth() + 1;
    const mm: string = m < 10 ? "0" + m.toString() : m.toString();
    const yyyy: string = date.getFullYear().toString();

    return yyyy.concat(dateSeparator, mm, dateSeparator, dd);
}

export const getMonthPrior = (monthsAgo: number) => {
    const today = new Date();
    today.setMonth(today.getMonth() - monthsAgo);

    return today;
};

export const isValidDate = (date: any) => {
    return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}
