import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import "whatwg-fetch";
import { Api } from "../../api/Api";
import { getMaintenance } from "../../api/Common/RestUrlBuilder";
import {
    GET_MAINTENANCE_FAILURE,
    GET_MAINTENANCE_REQUEST,
    GET_MAINTENANCE_SUCCESS,
} from "./constants";

// Action<{ payload: { dimFarmId: number, farmName: string }; meta: string }>
function* doTryGetMaintenance(action: any): SagaIterator {
    try {
        const url: string = getMaintenance();
        const maintenanceRequest = yield call(Api.utilities.SoftPostRequest, url);

        yield put({ type: GET_MAINTENANCE_SUCCESS, payload: maintenanceRequest[0] });
    } catch (error) {
        yield put({ type: GET_MAINTENANCE_FAILURE, payload: new Error("It was not possible to fetch data") });
    }
}

export function* watchTryGetMaintenance(): SagaIterator {
    yield takeEvery(GET_MAINTENANCE_REQUEST, doTryGetMaintenance);
}
