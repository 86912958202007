import React from "react";
import "./Candle.scss";

interface iCandle {
    disabled?: boolean,
    icon: JSX.Element,
    text: string
}

const Candle: React.FC<iCandle> = (props) => {
    return (
        <div className={`candle ${props.disabled ? "candle--disabled" : ""}`}>
            <span className="candle__icon">{props.icon}</span>
            <span className="candle__text">{props.text}</span>
        </div>
    );
};

export default Candle;
