import React from "react";
import "./Loader.scss";
import { iLoader } from "./interface";

const Loader: React.FC<iLoader> = (props) => {
    return (
        <div className="loader" style={{ height: props.height ? `${props.height}px` : "auto", width: props.width ? `${props.width}px` : "auto" }}>
             <i className="fas fa-spinner fa-pulse" />{" "}<span style={{ marginLeft: "9px" }}>Loading data...</span>
        </div>
    );
};

export default Loader;

