import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { postJSON } from "../../api/requests";
import {
    GET_HNINSIGHTS_QUESTION_FAILURE,
    GET_HNINSIGHTS_QUESTION_REQUEST,
    GET_HNINSIGHTS_QUESTION_SUCCESS,
    GET_HNINSIGHTS_QUESTIONS_FAILURE,
    GET_HNINSIGHTS_QUESTIONS_REQUEST,
    GET_HNINSIGHTS_QUESTIONS_SUCCESS,
    POST_HNINSIGHTS_ANSWER_FAILURE,
    POST_HNINSIGHTS_ANSWER_REQUEST,
    POST_HNINSIGHTS_ANSWER_SUCCESS,
    SET_HNINSIGHTS_STAGE,
    SET_HNINSIGHTS_STAGE_SUCCESS,
    GET_INSIGHTS_LIST_REQUEST,
    GET_INSIGHTS_LIST_SUCCESS,
    GET_INSIGHTS_LIST_FAILURE,
    // SET_HNINSIGHTS_STAGE_FINISH,
    PIN_INSIGHT_TO_DASHBOARD_REQUEST,
    PIN_INSIGHT_TO_DASHBOARD_SUCCESS,
    PIN_INSIGHT_TO_DASHBOARD_FAILURE,
    SET_SELECTED_ANSWER,
    GO_TO_PREVIOUS_QUESTION_REQUEST,
    GO_TO_PREVIOUS_QUESTION_SUCCESS,
    GO_TO_PREVIOUS_QUESTION_FAILURE,

    REPLACE_INSIGHT_REQUEST,
    REPLACE_INSIGHT_SUCCESS,
    REPLACE_INSIGHT_FAILURE,
    SET_HNINSIGHTS_STATUS,
    STAGES
} from "./constants";
// import { HnInsightsConfigurationQuestionRequestPayload } from "./types";
import { Insight } from './types'

import { getFarmId, getUsername } from "../../utills/authorization";


function* replaceInsight(action: any): SagaIterator {
    try {
        const farmId = getFarmId();
        const username = getUsername();

        const insights: Insight[] = yield call(postJSON, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/insights/updateinsight", {
            FarmId: farmId,
            UserId: username,
            InsightId: action.payload.id,
            LanguageCode: "ENG",
            DashBoard: false,
            Status: false,
        });

        const parsedInsights = insights.map((el: Insight, index: number):Insight => {
            return {
                dashBoard: el.dashBoard,
                id: el.id,
                key: el.key,
                rank: el.rank,
                text: JSON.parse(String(el.text)),
                expired: el.expired
            };
            
        });

        const dbInsightsList = parsedInsights.filter((el: Insight, index: number) => el.dashBoard === 1);

        yield put({ type: REPLACE_INSIGHT_SUCCESS, payload: { parsedInsights, dbInsightsList } });

    } catch (error) {
        console.log(error);
        yield put({ type: REPLACE_INSIGHT_FAILURE, payload: "Unable to pin to dashboard" });
    }
}

function* pinInsightToDashboard(action: any): SagaIterator {
    try {
        const farmId = getFarmId();
        const username = getUsername();

        const insights = yield call(postJSON, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/insights/updateinsight", {
            FarmId: farmId,
            UserId: username,
            InsightId: action.payload.id,
            LanguageCode: "ENG",
            Status: true,
            DashBoard: action.payload.pin,
        });

        const parsedInsights = insights.map((el: Insight, index: number):Insight => {
            return {
                dashBoard: el.dashBoard,
                id: el.id,
                key: el.key,
                rank: el.rank,
                text: JSON.parse(String(el.text)),
                expired: el.expired
            };
        });

        const dbInsightsList = parsedInsights.filter((el: Insight, index: number) => el.dashBoard === 1);

        yield put({ type: PIN_INSIGHT_TO_DASHBOARD_SUCCESS, payload: { parsedInsights, dbInsightsList } });

    } catch (error) {
        console.log(error);
        yield put({ type: PIN_INSIGHT_TO_DASHBOARD_FAILURE, payload: "Unable to pin to dashboard" });
    }
}







function* getHNInsightsQuestionsSaga(action: any): SagaIterator {
    try {
        const farmId = getFarmId();
        const username = getUsername();

        // we pass a number meaning we want the analysis questions - the number can be 3 for a short list or 0 for the full list
        // this is why we cant just ask if (action.payload) because that will not work with the number 0

        // if action.payload is not undefined and is a number
        if (action.payload !== undefined && !isNaN(action.payload)) {
            const analysisQuestions = yield call(postJSON, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/insights/startquestionaire", {
                FarmId: farmId,
                UserId: username,
                LanguageCode: "ENG",
                NumberOfQuestions: action.payload,
            });

            // if status open, array will have questions
            if (analysisQuestions.status === "open") {

                // set questions 
                yield put({ type: GET_HNINSIGHTS_QUESTIONS_SUCCESS, payload: analysisQuestions });

                // fetch single analysis question
                yield call(() => getHNInsightsQuestionSaga({ type: GET_HNINSIGHTS_QUESTION_REQUEST, payload: { questionId: analysisQuestions.open[0].questionId } }));

                yield put({ type: SET_HNINSIGHTS_STAGE, payload: STAGES.ANALYSIS_QUESTIONAIRE });
            } else if (analysisQuestions.status === "done") {
                // set stage to analysis finish
                yield put({ type: SET_HNINSIGHTS_STAGE, payload: STAGES.ANALYSIS_FINISH });
            } else if (analysisQuestions.status === "openConfigurationQuestion") {
                // GET CONFIG QUESTIONS - we expect them to always be open


                const configurationQuestions = yield call(postJSON, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/insights/getconfigurationquestions", {
                    FarmId: farmId,
                    UserId: username,
                    LanguageCode: "ENG",
                });

                // set questions 
                yield put({ type: GET_HNINSIGHTS_QUESTIONS_SUCCESS, payload: configurationQuestions });

                // fetch single config question
                yield call(() => getHNInsightsQuestionSaga({ type: GET_HNINSIGHTS_QUESTION_REQUEST, payload: { questionId: configurationQuestions.open[0].questionId } }));
                // set stage to intro (for component rendering purpose)
                yield put({ type: SET_HNINSIGHTS_STAGE, payload: STAGES.CONFIG_INTRO });


            } else {
                yield put({ type: GET_HNINSIGHTS_QUESTIONS_FAILURE, payload: "An error occured " });
            }

        } else {
            // ask for configuration questions
            const configurationQuestions = yield call(postJSON, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/insights/getconfigurationquestions", {
                FarmId: farmId,
                UserId: username,
                LanguageCode: "ENG",
            });


            // if status open, array will have questions
            if (configurationQuestions.status === "open" || configurationQuestions.status === "expired") {
                // set questions 

                if (configurationQuestions.status === "expired") {
                    yield put({ type: SET_HNINSIGHTS_STATUS, payload: "config_questions_expired" });
                    // yield put({ type: SET_HNINSIGHTS_STAGE, payload: STAGES.CONFIG_INTRO });
                }
                yield put({ type: GET_HNINSIGHTS_QUESTIONS_SUCCESS, payload: configurationQuestions });

                // fetch single config question

                yield call(() => getHNInsightsQuestionSaga({ type: GET_HNINSIGHTS_QUESTION_REQUEST, payload: { questionId: configurationQuestions.open[0].questionId } }));
                // set stage to intro (for component rendering purpose)
                yield put({ type: SET_HNINSIGHTS_STAGE, payload: STAGES.CONFIG_INTRO });
            } else if (configurationQuestions.status === "done" || configurationQuestions.status === "closeToExpire") {

                if (action.payload === undefined) {

                    yield call(() => getInsightsSaga({ type: GET_INSIGHTS_LIST_REQUEST, payload: null }));
                    yield put({ type: SET_HNINSIGHTS_STAGE, payload: STAGES.ANALYSIS_INTRO });

                } else {
                    const analysisQuestions = yield call(postJSON, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/insights/startquestionaire", {
                        FarmId: farmId,
                        UserId: username,
                        LanguageCode: "ENG",
                        NumberOfQuestions: action.payload,
                    });

                    // if status open, array will have questions
                    if (analysisQuestions.status === "open") {
                        // set questions
                        yield put({ type: GET_HNINSIGHTS_QUESTIONS_SUCCESS, payload: analysisQuestions });

                        // fetch single analysis question
                        yield call(() => getHNInsightsQuestionSaga({ type: GET_HNINSIGHTS_QUESTION_REQUEST, payload: { questionId: analysisQuestions.open[0].questionId } }));

                        yield put({ type: SET_HNINSIGHTS_STAGE, payload: STAGES.ANALYSIS_QUESTIONAIRE });
                    } else if (analysisQuestions.status === "done" || analysisQuestions.status === "closeToExpire") {
                        // set stage to analysis finish
                        yield put({ type: SET_HNINSIGHTS_STAGE, payload: STAGES.ANALYSIS_FINISH });
                    } else {
                        yield put({ type: GET_HNINSIGHTS_QUESTIONS_FAILURE, payload: analysisQuestions.Message });
                    }
                }
            } else {
                // throw error
                yield put({ type: GET_HNINSIGHTS_QUESTIONS_FAILURE, payload: "An error occured" });
            }
        }

    } catch (error) {
        console.log(error);
        yield put({ type: GET_HNINSIGHTS_QUESTIONS_FAILURE, payload: "Unable to fetch HN Insights question list" });
    }
}

function* postHNInsightsAnswerSaga(action: any): SagaIterator {
    try {
        const farmId = getFarmId();
        const email = getUsername();

        /*
            sometimes we get an answer id
            sometimes a new question id
        */

        // when answering a question - if not has parent id, set it as previous answer


        // When we get an answer id

        // if not selectedAnswer id we need to get it from prevAnswered

        if (action.payload.selectedAnswer.answer.answerId) {

            // Post answer to the server
            const answer = yield call(postJSON, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/insights/setanswer", {
                FarmId: farmId,
                AnswerId: action.payload.selectedAnswer.answer.answerId,
                UserId: email,
            });

            // If that went well
            if (answer.status === "ok" || answer.status === "done") {
                // add changes to redux state



                // HERE
                yield put({ type: POST_HNINSIGHTS_ANSWER_SUCCESS, payload: null });

                /*
                    if there was no question id in the selected answer
                    and there were no next question, we assume that the
                    configure questions are done.
                */

                if (!action.payload.nextQuestion) {

                    // if action.payload.currentStage === STAGES.ANALYSIS_QUESTIONAIRE

                    if (action.payload.currentStage === STAGES.ANALYSIS_QUESTIONAIRE) {
                        yield put({ type: SET_HNINSIGHTS_STAGE, payload: STAGES.ANALYSIS_FINISH });



                        // HERE!!! GET HN INSIGHTS
                        yield call(() => getInsightsSaga({ type: GET_INSIGHTS_LIST_REQUEST, payload: null }));
                        yield put({ type: SET_HNINSIGHTS_STAGE, payload: STAGES.INSIGHTS_LIST });





                    } else {
                        yield put({ type: SET_HNINSIGHTS_STAGE, payload: STAGES.CONFIG_FINISH });
                    }

                    // if a next question id is provided we ask for that
                    // Note: when calling the saga like this (yield call()) we block the flow further
                    // this is nescesary in order to ensure that we get a new question before the POST_HNINSIGHTS_ANSWER_SUCCESS action
                } else {
                    yield call(() => getHNInsightsQuestionSaga({ type: GET_HNINSIGHTS_QUESTION_REQUEST, payload: { questionId: action.payload.nextQuestion.questionId } }));
                }
            } else {
                yield put({ type: POST_HNINSIGHTS_ANSWER_FAILURE, payload: "Unable to post HN Insights answer" });
            }
            // if we get a question id
        } else if (action.payload.selectedAnswer.questionId) {
            yield put({ type: POST_HNINSIGHTS_ANSWER_SUCCESS, payload: null });
            yield call(() => getHNInsightsQuestionSaga({ type: GET_HNINSIGHTS_QUESTION_REQUEST, payload: { questionId: action.payload.selectedAnswer.questionId } }));
            // this is hit, when the user has not selected an answer
            // since we disable the button if nothing is selected, we can assume that a value must come from
            // previously selected, meaning that no new answer was given and thus we dont need to post anything to the server
        } else if (action.payload.nextQuestion.questionId) {
            // Make sure open and answered are manipulated correctly when we hit this option
            yield put({ type: POST_HNINSIGHTS_ANSWER_SUCCESS, payload: null });
            yield call(() => getHNInsightsQuestionSaga({ type: GET_HNINSIGHTS_QUESTION_REQUEST, payload: { questionId: action.payload.nextQuestion.questionId } }));
        } else {
            yield put({ type: POST_HNINSIGHTS_ANSWER_FAILURE, payload: "Unable to post HN Insights answer" });
        }

    } catch (error) {
        yield put({ type: POST_HNINSIGHTS_ANSWER_FAILURE, payload: "Unable to post HN Insights answer" });
    }
}

// Get a single question with answer options
function* getHNInsightsQuestionSaga(action: any) {
    try {
        const farmId = getFarmId();
        const question = yield call(postJSON, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/insights/getquestion", {
            FarmId: farmId,
            QuestionId: action.payload.questionId,
            LanguageCode: "ENG",
        });

        if (question.status === "ok") {
            const prevAnswer = yield call(postJSON, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/insights/getquestionanswer", {
                FarmId: farmId,
                QuestionId: action.payload.questionId,
                LanguageCode: "ENG",
            });



            if (prevAnswer.status === "ok") { // && !question.parentQuestionId
                yield put({ type: SET_SELECTED_ANSWER, payload: prevAnswer });
            }

            yield put({ type: GET_HNINSIGHTS_QUESTION_SUCCESS, payload: question });
        } else {
            yield put({ type: GET_HNINSIGHTS_QUESTION_FAILURE, payload: "Unable to fetch HN Insights question" });
        }




    } catch (error) {
        yield put({ type: GET_HNINSIGHTS_QUESTION_FAILURE, payload: "Unable to fetch HN Insights question" });
    }
}

export function* setHNInsightsStageSaga(action: any): SagaIterator {
    yield put({ type: SET_HNINSIGHTS_STAGE_SUCCESS, payload: action.payload });
}

export function* getInsightsSaga(action: any): SagaIterator {
    try {

        const farmId = getFarmId();
        // get list of insights
        const insights = yield call(postJSON, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/insights/getinsights", {
            FarmId: farmId,
            LanguageCode: "ENG",
        });

        if (insights && insights.length > 0) {

            const parsedInsights = insights.map((el: Insight, index: number): Insight => {
                return {
                    dashBoard: el.dashBoard,
                    id: el.id,
                    key: el.key,
                    rank: el.rank,
                    text: JSON.parse(String(el.text)),
                    expired: el.expired
                };
                
            });

            const dbInsightsList = parsedInsights.filter((el: Insight, index: number) => el.dashBoard === 1);

            yield put({ type: GET_INSIGHTS_LIST_SUCCESS, payload: parsedInsights, dbInsightsList });

        } else if (insights === null) {
            yield put({ type: GET_INSIGHTS_LIST_SUCCESS, payload: [], dbInsightsList: [] });
        } else {
            yield put({ type: GET_INSIGHTS_LIST_FAILURE, payload: "Unable to get your list of insights" });
        }
    } catch (error) {
        console.log(error, GET_INSIGHTS_LIST_FAILURE);
        yield put({ type: GET_INSIGHTS_LIST_FAILURE, payload: "Unable to get your list of insights" });
    }
}

export function* goToPreviousQuestionSaga(action: any): SagaIterator {
    try {
        const farmId = getFarmId();



        // make sure to ignore previous question when going back
        // we only want to set previous answer if it has answer id
        const question = yield call(postJSON, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/insights/getquestion", {
            FarmId: farmId,
            QuestionId: action.payload.questionId,
            LanguageCode: "ENG",
        });

        if (question.status === "ok") {

            // meaning question is a sub question so we want to get the parent question
            // if(question.parentQuestionId !== null) {
            //     yield call(() => getHNInsightsQuestionSaga({ type: GET_HNINSIGHTS_QUESTION_REQUEST, payload: { questionId: question.parentQuestionId } }));
            // } else {
            const prevAnswer = yield call(postJSON, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/insights/getquestionanswer", {
                FarmId: farmId,
                QuestionId: action.payload.questionId,
                LanguageCode: "ENG",
            });



            if (prevAnswer.status === "ok") {
                yield put({ type: SET_SELECTED_ANSWER, payload: prevAnswer });
            }

            yield put({ type: GET_HNINSIGHTS_QUESTION_SUCCESS, payload: question });
            // }

            yield put({ type: GO_TO_PREVIOUS_QUESTION_SUCCESS, payload: question });
        }
    } catch (error) {
        yield put({ type: GO_TO_PREVIOUS_QUESTION_FAILURE, payload: "Unable to go to previous question" });
    }
}



export function* watchHNInsightsSaga(): SagaIterator {
    yield takeEvery(GET_HNINSIGHTS_QUESTIONS_REQUEST, getHNInsightsQuestionsSaga);
    yield takeEvery(GET_INSIGHTS_LIST_REQUEST, getInsightsSaga)
    yield takeEvery(GET_HNINSIGHTS_QUESTION_REQUEST, getHNInsightsQuestionSaga);
    yield takeEvery(POST_HNINSIGHTS_ANSWER_REQUEST, postHNInsightsAnswerSaga);
    yield takeEvery(SET_HNINSIGHTS_STAGE, setHNInsightsStageSaga);
    yield takeEvery(GO_TO_PREVIOUS_QUESTION_REQUEST, goToPreviousQuestionSaga);
    yield takeEvery(PIN_INSIGHT_TO_DASHBOARD_REQUEST, pinInsightToDashboard);
    yield takeEvery(REPLACE_INSIGHT_REQUEST, replaceInsight);

}


// debug todo
// check if all questions go to answered list after answer
