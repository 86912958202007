export const TOGGLE_KPI_SIDENAV = "@@ddw.herdnet.sandbox.hnadvice/TOGGLE_KPI_SIDENAV";
export const TOGGLE_COW_LIST = "@@ddw.herdnet.sandbox.hnadvice/TOGGLE_COW_LIST";
export const TOGGLE_COW_LIST_PINNED = "@@ddw.herdnet.sandbox.hnadvice/TOGGLE_COW_LIST_PINNED";
export const OPEN_COW_LIST = "@@ddw.herdnet.sandbox.hnadvice/OPEN_COW_LIST";
export const TOGGLE_NOTIFICATION_BAR = "@@ddw.herdnet.sandbox.hnadvice/TOGGLE_NOTIFICATION_BAR";
export const TOGGLE_DATA_TYPE = "@@ddw.herdnet.sandbox.hnadvice/TOGGLE_DATA_TYPE";
export const TOGGLE_BASELINE_REPORT_DATA_TYPE = "@@ddw.herdnet.sandbox.hnadvice/TOGGLE_BASELINE_REPORT_DATA_TYPE";
export const TOGGLE_STICK_CONSUMPTION_DATA_TYPE = "@@ddw.herdnet.sandbox.hnadvice/TOGGLE_STICK_CONSUMPTION_DATA_TYPE";
export const TOGGLE_CHART_TYPE = "@@ddw.herdnet.sandbox.hnadvice/TOGGLE_CHART_TYPE";
export const SET_LIBRARY_OPEN_URL = "@@ddw.herdnet.sandbox.hnadvice/SET_LIBRARY_OPEN_URL";
export const SET_FARMS_LIST = "@@ddw.herdnet.sandbox.hnadvice/SET_FARMS_LIST";
export const TOGGLE_SETTINGS = "@@ddw.herdnet.sandbox.hnadvice/TOGGLE_SETTINGS";
export const TOGGLE_HN_INSIGHTS = "@@ddw.herdnet.sandbox.hnadvice/TOGGLE_HN_INSIGHTS";
export const SET_CRP_DB_HOVERED = "@@ddw.herdnet.sandbox.hnadvice/SET_CRP_DB_HOVERED";
export const TOGGLE_PRODUCT_TOUR = "@@ddw.herdnet.sandbox.hnadvice/TOGGLE_PRODUCT_TOUR";
export const HIDE_COW_LIST = "@@ddw.herdnet.sandbox.hnadvice/HIDE_COW_LIST";

export const SET_OPEN_STEP_GUIDE = "@@ddw.herdnet.sandbox.hnadvice/SET_OPEN_STEP_GUIDE";
export const CLOSE_STEP_GUIDE = "@@ddw.herdnet.sandbox.hnadvice/CLOSE_STEP_GUIDE";
export const SET_SIDEBAR_EXPANDED = "@@ddw.herdnet.sandbox.hnadvice/SET_SIDEBAR_EXPANDED";
export const TOGGLE_ALL_SIDEBARS = "@@ddw.herdnet.sandbox.hnadvice/TOGGLE_ALL_SIDEBARS";

export const COLOR_SCREME_1 = ["#0081D5", "#C2C2C2"];
export const COLOR_SCREME_2 = ["#0081D5", "#79BAE4"];

export const DASHBOARD_TOGGLE = 1;
export const STICK_CONSUMPTION_TOGGLE = 2;
export const BASELINE_REPORT_TOGGLE = 3;
