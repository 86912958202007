import React, { Fragment } from "react";
import "./Section.scss";
import { iSection } from "./interface";

const Section: React.FC<iSection> = (props, ref: any) => {
    return (
        <section className="section__wrapper">
            {props.headline && (
                <Fragment>
                    {props.headlineType === "h5" ? (
                        <h5 className="section__headline section__headline--h5">{props.headline}</h5>
                    ) : (
                        <h3 className="section__headline">{props.headline}</h3>
                    )}
                </Fragment>
            )}

            {props.body && props.body.length > 0 && (
                <div className="section__paragraph-wrapper">
                    {props.body.map((el: string, index: number) => {
                        return (
                            <Fragment key={`text-${index}`}>
                                <p className="section__paragraph">{el}</p>
                                <br />
                            </Fragment>
                        )
                    })}
                </div>
            ) }
            {props.images && props.images.length > 0 && props.images.map((el: any, index: number) => {
                if (el === "placeholder") {
                    return (
                        <div className="section__image-placeholder" />
                    );
                }
                return (
                    <img
                        className="section__image"
                        key={`${el.src}-image-${index}`}
                        src={el.src}
                        alt={el.alt}
                    />
                );
            })}
        </section>
    );
};

export default Section;
