import React from "react";
import "../Content.scss";

const ContentHeader: React.FC = (props) => {
    return (
        <div className="content__header">
            {props.children}
        </div>
    );
};

export default ContentHeader;
