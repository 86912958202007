import { TOGGLE_HN_INSIGHTS } from "../../redux/Navigation/constants";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardGuide, CardHeader, CardHeadline } from "../../Card";
import { Button } from "../../Input";
import { GET_INSIGHTS_LIST_REQUEST, PIN_INSIGHT_TO_DASHBOARD_REQUEST } from "../../redux/HNInsights/constants";
import { handleTriggerStartTour } from "../../utills/intercom";
import "./Insights.scss";

const Insights: React.FC = (props) => {

    const hnInsightsState = useSelector((state: any) => state.hnInsightsState);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    useEffect(() => {
        // get questions when component is mounted
        dispatch({ type: GET_INSIGHTS_LIST_REQUEST, payload: null });

        // tell hook to skip re-render if state hasnt changed
    }, [dispatch]);
    const handleToggleHnInsights = () => dispatch({ type: TOGGLE_HN_INSIGHTS });

    const initialState = {};

    hnInsightsState.dbInsightsList.forEach((el: any, index: number) => {
        initialState[el.id] = false;
    });

    const handleUnpinToDashboard = (id: string) => {
        dispatch({ type: PIN_INSIGHT_TO_DASHBOARD_REQUEST, payload: { id, pin: false } });
    };

    return (
        <Card id="insights">
            <CardHeader>

                {/* TODO: re-add translation (somehow) */}
                {/* <CardHeadline text={t("TopInsights")} onClick={() => handleToggleHnInsights()} icon={<i className="fal fa-lightbulb-on" />} /> */}
                <CardHeadline text="Reproduction insights" onClick={() => handleToggleHnInsights()} icon={<i className="fal fa-lightbulb-on" />} />
                <CardGuide openStepGuide={() => handleTriggerStartTour(173885)} />
            </CardHeader>
            <div className="insights">
                {hnInsightsState.dbInsightsList.length > 0 ? hnInsightsState.dbInsightsList.map((el: any, index: number) => {
                    return (
                        <div key={`insight__${index}`} className="insights__element-wrapper">
                            <div style={{ flex: "10" }}>
                                <span>{index + 1}.</span>
                                <span>{el.text.header}</span>
                            </div>
                            <Button style={{ zIndex: 0, flex: "1" }} onClick={() => handleUnpinToDashboard(el.id)} onMouseOut={(e: any) => e.preventDefault()}>
                                <i className="fas fa-times" color="lightgray" />
                            </Button>
                        </div>
                    );
                }) : (
                        <div className="insights__fallback" >
                            <span onClick={() => handleToggleHnInsights()} >{t("NoPinnedInsights") + ""}<i className="far fa-chevron-right" /></span>
                        </div>
                    )}
            </div>
        </Card>
    );
};

export default Insights;
