import { detect } from "detect-browser";

interface IResult {
    name?: string | undefined;
    version?: string | undefined;
    versionNumber?: number | undefined;
    mobile?: boolean | undefined;
    os?: string | undefined;
}

export const browserIsSupported = (): boolean => {
    const result = detect();

    // also support edge
    
    if (result !== null && (result.name === "chrome" || result.name === "edge-chromium" || result.name === 'firefox') && result.version !== null) {

        const version = result.version?.split(".");

        if (parseInt(version[0], 10) > 70) {
            return true;
        } else {
            return false;
        }
    }

    return false;
};

export const getBrowser = (): string => {
    const result = detect();
    let answer: string = "";

    if (result !== null && result.name) {
        answer += `${result.name}`;
    }

    if(result !== null && result.version) {
        answer += ` ${result.version}`;
    }

    return answer;
}
