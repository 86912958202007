import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { Provider } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom"; // Redirect
import Authenticate from "./Authenticate";
import { TopBannerWarning } from "./components/Common/TopBannerWarning/TopBannerWarning";
import { NotFoundPageContainer as NotFoundPage } from "./containers/Common/NotFoundPageContainer";
import LayoutRoute from "./Layout/Route";
import Maintenance from "./Maintenance";
import { AIS, BaselineReport, CRBWP, Dashboard, DOA, HNIR, OD, PBSS, SEA, StickConsumption } from "./Pages";
import store, { history } from "./redux/store";
import { browserIsSupported, getBrowser } from "./utills/browserSupportCheck";

const hasBrowserSuppoort = browserIsSupported();

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <TopBannerWarning
        visible={!hasBrowserSuppoort}
        text={`You are currently using ${getBrowser()} as your browser. Please move to a version of Google Chrome, newer than 70 for a better user experience`}
      />
      <Maintenance>
        <Switch>
          <Route path={"/login"} component={Authenticate} />
          <LayoutRoute path="/hnadvice/dashboard" component={Dashboard} />
          <LayoutRoute path="/hnadvice/stick-consumption" component={StickConsumption} />
          <LayoutRoute path="/hnadvice/baseline-report" component={BaselineReport} />
          <LayoutRoute path="/hnadvice/doa" component={DOA}  />
          <LayoutRoute path="/hnadvice/cr" component={CRBWP} />
          <LayoutRoute path="/hnadvice/hnir" component={HNIR} />
          <LayoutRoute path="/hnadvice/ais" component={AIS} />
          <LayoutRoute path="/hnadvice/sea" component={SEA} />
          <LayoutRoute path="/hnadvice/pbss" component={PBSS} />
          <LayoutRoute path="/hnadvice/od" component={OD} />
          <Redirect to="/login" />
          <Route component={NotFoundPage} />
        </Switch>
      </Maintenance>
    </ConnectedRouter>
  </Provider>
);

export default App;
