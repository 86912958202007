export const GET_DOA_COMPSUMMARY_REQUEST = "@@ddw.herdnet.sandbox.hnadvice/GET_DOACOMPSUMMARY_REQUEST";
export const GET_DOA_COMPSUMMARY_SUCCESS = "@@ddw.herdnet.sandbox.hnadvice/GET_DOACOMPSUMMARY_SUCCESS";
export const GET_DOA_COMPSUMMARY_FAILURE = "@@ddw.herdnet.sandbox.hnadvice/GET_DOACOMPSUMMARY_FAILURE";

export const GET_ANOESTRUS_LENGTH_REQUEST = "@@ddw.herdnet.sandbox.hnadvice/GET_ANOESTRUS_LENGTH_REQUEST";
export const GET_ANOESTRUS_LENGTH_SUCCESS = "@@ddw.herdnet.sandbox.hnadvice/GET_ANOESTRUS_LENGTH_SUCCESS";
export const GET_ANOESTRUS_LENGTH_FAILURE = "@@ddw.herdnet.sandbox.hnadvice/GET_ANOESTRUS_LENGTH_FAILURE";

export const GET_DOA_FLP_REQUEST = "@@ddw.herdnet.sandbox.hnadvice/GET_DOA_FLP_REQUEST";
export const GET_DOA_FLP_SUCCESS = "@@ddw.herdnet.sandbox.hnadvice/GET_DOA_FLP_SUCCESS";
export const GET_DOA_FLP_FAILURE = "@@ddw.herdnet.sandbox.hnadvice/GET_DOA_FLP_FAILURE";

export const DOA_SUMMARY = "doasummary";
