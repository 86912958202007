import {
    GET_ADVANCED_BASELINE_REPORT_REQUEST,
    GET_ADVANCED_BASELINE_REPORT_SUCCESS,
    GET_ADVANCED_BASELINE_REPORT_FAILURE,
    GET_SIMPLE_BASELINE_REPORT_REQUEST,
    GET_SIMPLE_BASELINE_REPORT_SUCCESS,
    GET_SIMPLE_BASELINE_REPORT_FAILURE,
} from "./constants";

export interface State {
    loading: boolean;
    data: any;
    error: string | null;
    gotData: boolean;
}

export const initialState: State = {
    loading: false,
    data: [],
    error: null,
    gotData: false,

};

export const BaselineReportReducer = (state: State = initialState, action: any) => {
    switch (action.type) {
        case GET_SIMPLE_BASELINE_REPORT_REQUEST:
        case GET_ADVANCED_BASELINE_REPORT_REQUEST:
            return {
                ...state,
                loading: true,
                gotData: false,
                error: null,
            };
        case GET_SIMPLE_BASELINE_REPORT_SUCCESS:
        case GET_ADVANCED_BASELINE_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                gotData: true,
            };
        case GET_SIMPLE_BASELINE_REPORT_FAILURE:
        case GET_ADVANCED_BASELINE_REPORT_FAILURE:
            return {
                ...state,
                gotData: false,
                loading: false,
                error: action.payload,
            };
        default:
            return state;

    }
};
