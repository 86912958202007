import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Input/Button";
import { TOGGLE_DATA_TYPE, TOGGLE_STICK_CONSUMPTION_DATA_TYPE, TOGGLE_BASELINE_REPORT_DATA_TYPE, DASHBOARD_TOGGLE, STICK_CONSUMPTION_TOGGLE } from "../../redux/Navigation/constants";
import "../DataType.scss";
import { iDataTypeToggle } from "./interface";

const DataTypeToggle: React.FC<iDataTypeToggle> = (props) => {

    const navigationState = useSelector((state: any) => state.navigationState);

    const dispatch = useDispatch();

    const handleToggleDataTypeToggle = (key: number) => {
        if (props.toogleId === DASHBOARD_TOGGLE) dispatch({ type: TOGGLE_DATA_TYPE, payload: key });
        else if (props.toogleId === STICK_CONSUMPTION_TOGGLE) dispatch({ type: TOGGLE_STICK_CONSUMPTION_DATA_TYPE, payload: key });
        else dispatch({ type: TOGGLE_BASELINE_REPORT_DATA_TYPE, payload: key });
    }

    let dataType;
    if (props.toogleId === DASHBOARD_TOGGLE) dataType = navigationState.dataType;
    else if (props.toogleId === STICK_CONSUMPTION_TOGGLE) dataType = navigationState.dataStickConsumptionType;
    else dataType = navigationState.dataBaseLineReportType;

    return (
        <div className="data-type">
            <div className="data-type__toggle-actions-wrapper">
                <Button onClick={() => handleToggleDataTypeToggle(0)}>
                    <span className={`data-type__action ${dataType === 0 ? " data-type__action--active" : "" }`}>Benchmark farms</span>
                </Button>
            </div>
            {" | "}
            <div className="data-type__toggle-actions-wrapper">
                <Button onClick={() => handleToggleDataTypeToggle(1)}>
                    <span className={`data-type__action ${dataType === 1 ? " data-type__action--active" : "" }`}>Own farm</span>
                </Button>
            </div>
        </div>
    );
};

export default DataTypeToggle;
