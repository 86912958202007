import { ResponsiveRadar } from "@nivo/radar";
import React, { useState, useLayoutEffect, useRef, } from "react";
import "../Charts.scss";
import { iKPISpider } from "./interface";

const KPISpider: React.FC<iKPISpider> = (props) => {
  const targetRef = useRef<HTMLHeadingElement>(null);
  const [dimensions, setDimensions] = useState({ height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current && targetRef.current.parentElement) {
      setDimensions({
        height: targetRef.current.parentElement.offsetWidth,
      });
    }
  }, []);

  const LabelComponent = ({ id }: any) => {

    // We can not use a loop in here to map through the indexes
    // this is because Nive loops through each datapoint internally
    // so the code below is a "naive" way to fix the problem.

    let fillColor;
    let dataDiff;

    if (id === props.data[0].kpi) {
      dataDiff = props.data[0].value - props.data[0].reference;
      fillColor = Math.sign(dataDiff) < 0 ? "red" : Math.sign(dataDiff) > 0 ? "green" : "gray"
    } else if (id === props.data[1].kpi) {
      dataDiff = props.data[1].value - props.data[1].reference;
      fillColor = Math.sign(dataDiff) < 0 ? "red" : Math.sign(dataDiff) > 0 ? "green" : "gray"
    } else if (id === props.data[2].kpi) {
      dataDiff = props.data[2].value - props.data[2].reference;
      fillColor = Math.sign(dataDiff) < 0 ? "red" : Math.sign(dataDiff) > 0 ? "green" : "gray"
    } if (id === props.data[3].kpi) {
      dataDiff = props.data[3].value - props.data[3].reference;
      fillColor = Math.sign(dataDiff) < 0 ? "red" : Math.sign(dataDiff) > 0 ? "green" : "gray"
    } if (id === props.data[4].kpi) {
      dataDiff = props.data[4].value - props.data[4].reference;
      fillColor = Math.sign(dataDiff) < 0 ? "red" : Math.sign(dataDiff) > 0 ? "green" : "gray"
    } else if (id === props.data[5].kpi) {
      dataDiff = props.data[5].value - props.data[5].reference;
      fillColor = Math.sign(dataDiff) < 0 ? "red" : Math.sign(dataDiff) > 0 ? "green" : "gray"
    } else if (id === props.data[6].kpi) {
      dataDiff = props.data[6].value - props.data[6].reference;
      fillColor = Math.sign(dataDiff) < 0 ? "red" : Math.sign(dataDiff) > 0 ? "green" : "gray"
    }

    return (
      <g style={{ position: "absolute" }} transform={`translate(-15, -15)`}>

        <text
          y={24}
          style={{
            fontSize: 17,
            fontWeight: 'bold',
            fill: fillColor,
          }}
        >
          {dataDiff}
        </text>
      </g>
    )
  }



  return (
    <div ref={targetRef} style={{ height: `${dimensions.height}px` }}> {/* id="current-reproduction-performance-chart" */}
      <ResponsiveRadar
        maxValue={100}
        data={props.data}
        keys={["reference", "value"]}
        margin={{ top: 25, right: 25, bottom: 25, left: 25 }}
        indexBy="kpi"
        animate={true}
        gridLabel={LabelComponent}
        colors={[props.colors[1], props.colors[0]]}
        legends={[]}
        fillOpacity={.5}
        borderWidth={0}
        tooltipFormat={(val) => {
          return <>{val || 'Not available'}</>
        }}
      />
    </div>
  );
};

export default KPISpider;
