import React from "react";
import { iIcon } from "./interface";

const KPIIcon: React.FC<iIcon> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 25} height={props.size || 25} viewBox="0 0 40 40">
        <g id="KPI_Chart_Outlined" transform="translate(-279 -401.5)">
            <rect id="Rectangle_665" data-name="Rectangle 665" width={props.size || 25} height={props.size || 25} transform="translate(279 401.5)" fill="none"/>
            <path id="Path_500" data-name="Path 500" d="M298,401.5a19,19,0,1,0,19,19A18.992,18.992,0,0,0,298,401.5ZM315.7,424l-3.2-.7h0l-7.8-7.4,7.7-6.2a17.844,17.844,0,0,1,3.7,10.8C316,421.7,315.9,422.9,315.7,424Zm-17.2-12,2.9,5.2-2.9,2.3Zm-1,7.5-7.8-6.2,7.8-3Zm-.6.7-12.1,2.7,4-9.2Zm.2,1-4.4,9.2L285,424Zm.9.5,3.9,8.1-8.2.7Zm.9-.5,8.9,2-5,6Zm.2-1,2.9-2.3,4.3,3.9Zm12.6-11.3-7.7,6.2-5.5-8.9v-3.7A18.037,18.037,0,0,1,311.7,408.9Zm-14.2-6.4v4.1l-10.8,4.3-2.4-1.9A17.785,17.785,0,0,1,297.5,402.5Zm-13.8,7.2,2.5,2L280.8,424l-.4.1a19.825,19.825,0,0,1-.3-3.5A17.03,17.03,0,0,1,283.7,409.7ZM280.6,425l.4-.1,10.2,8.6-1.4,3A18,18,0,0,1,280.6,425Zm10,11.9L292,434l11.4-1,1.9,3.9a18.186,18.186,0,0,1-7.4,1.6A18.449,18.449,0,0,1,290.6,436.9Zm15.7-.4-1.9-3.9,7.3-8.4,3.7.8A18.161,18.161,0,0,1,306.3,436.5Z" fill={props.color ? props.color : "currentColor"} />
        </g>
    </svg>
);

export default KPIIcon;
