
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { convertRangesToNumbers, convertToKPISpecificData } from "../../utills/dataFormatting";
import {
    GET_COWS_LIST_FAILURE,
    GET_COWS_LIST_SUCCESS,
    GET_COWS_LIST_REQUEST,
    HANDLE_FILTER_COW_LIST_SCATTER_P1_HNIR,
    HANDLE_FILTER_COW_LIST_INITIALIZE,
    HANDLE_FILTER_COW_LIST_INITIALIZE_LOCAL,
    HANDLE_FILTER_COW_LIST_COMPLETE,
    HANDLE_FILTER_COW_LIST_GRAPH_P2,
    HANDLE_FILTER_COW_LIST_GRAPH_P3,
    HANDLE_FILTER_COW_LIST_GRAPH_MAX,
    HANDLE_FILTER_COW_LIST_SCATTER_P1,
    HANDLE_FILTER_COW_LIST_SCATTER_P2,
    HANDLE_COW_LIST_REFERENCE,
    HANDLE_SELECTION_COW

} from "./constants";
import { postXML } from "../../api/requests";
import { getDOAGraphData, getCRBWPGraphData, getHNIRGraphData, getAISGraphData, getSEAGraphData, getODGraphData } from "../../utills/dataFormatting"
import { iPoint } from "@src/Charts/ScatterChart/interface";

function* getCowsListSaga(action: any): SagaIterator {
    try {
        var payload = yield call(postXML, `https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/crd/${action.payload.path}`, {
            startDate: action.payload.startDate,
            stopDate: action.payload.stopDate,
            L1: action.payload.lactationFilters.l1Checked ? 1 : 0,
            L2: action.payload.lactationFilters.l2Checked ? 1 : 0,
            L3P: action.payload.lactationFilters.l3Checked ? 1 : 0,
        });

        const newPayload = convertToKPISpecificData(payload, action.payload.path);
        const filteredPayload = newPayload.filter((cow: any) => {
            return !(cow["DOADelayValue"] > 2 || cow["FLPDelayValue"] > 2 || cow["FilterSampleDelay"] > 2)
        });

        yield put({ type: GET_COWS_LIST_SUCCESS, payload: newPayload, filteredPayload: filteredPayload });

        // clear earlier filters set, by setting the filtered list to the new list
        yield put({ type: HANDLE_FILTER_COW_LIST_COMPLETE, payload: filteredPayload });

        var p2reference: any[] = [];
        var p3reference: any[] = [];

        // only doa and cr/crbwp have reference data
        if (action.payload.path === "doa" || action.payload.path === "crbwp") {
            const referencePath = action.payload.path === "doa" ? "doa" : "cr";

            p2reference = yield call(postXML, `https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/refp2p3/${referencePath}/p2`);
            p3reference = yield call(postXML, `https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/refp2p3/${referencePath}/p3`);
        }
        yield put({ type: HANDLE_COW_LIST_REFERENCE, payload: { p2reference, p3reference } });

        let graphP2Max: number = 0
        let graphP3Max: number = 0

        if (action.payload.path === "od") {
            const graphData = getODGraphData(newPayload, true, true, true, true);

            if (graphData && graphData.graphP3DataMerged) graphData.graphP3DataMerged.forEach((data: any) => {
                if (data["Non-pregnant"] + data["Pregnant"] + data["Potentially pregnant"] > graphP2Max) graphP2Max = data["Non-pregnant"] + data["Pregnant"] + data["Potentially pregnant"];
            })
            if (graphData && graphData.graphP3DataMerged) graphData.graphP3DataMerged.forEach((data: any) => {
                if (data["Non-pregnant"] + data["Pregnant"] + data["Potentially pregnant"] > graphP3Max) graphP3Max = data["Non-pregnant"] + data["Pregnant"] + data["Potentially pregnant"];
            })

            yield put({ type: HANDLE_FILTER_COW_LIST_GRAPH_MAX, payload: { graphP2Max, graphP3Max } });
            yield put({ type: HANDLE_FILTER_COW_LIST_GRAPH_P2, payload: graphData && graphData.graphP2DataMerged });
            yield put({ type: HANDLE_FILTER_COW_LIST_GRAPH_P3, payload: graphData && graphData.graphP3DataMerged });
        } else if (action.payload.path === "hnir") {
            const graphData = getHNIRGraphData(newPayload, true, true, true, true, false);
            const p1Payload = graphData && graphData.scatterP1;
            const p3Payload = graphData && graphData.graphP3Data;

            if (p3Payload) p3Payload.forEach((data: any) => {
                if (data["Inseminated"] + data["Not Inseminated"] > graphP3Max) graphP3Max = data["Inseminated"] + data["Not Inseminated"];
            })

            yield put({ type: HANDLE_FILTER_COW_LIST_GRAPH_MAX, payload: { graphP2Max, graphP3Max } });
            yield put({ type: HANDLE_FILTER_COW_LIST_SCATTER_P1_HNIR, payload: p1Payload });
            yield put({ type: HANDLE_FILTER_COW_LIST_GRAPH_P3, payload: p3Payload });
        } else if (action.payload.path === "ais") {
            const graphData = getAISGraphData(newPayload, true, true, true, true);

            if (graphData && graphData.graphP3DataMerged) graphData.graphP3DataMerged.forEach((data: any) => {
                if (data["Not pregnant"] + data["Pregnant"] + data["Potentially pregnant"] > graphP3Max) graphP3Max = data["Not pregnant"] + data["Pregnant"] + data["Potentially pregnant"];
            })

            yield put({ type: HANDLE_FILTER_COW_LIST_GRAPH_MAX, payload: { graphP2Max, graphP3Max } });
            yield put({ type: HANDLE_FILTER_COW_LIST_SCATTER_P1, payload: graphData && graphData.scatterP1 });
            yield put({ type: HANDLE_FILTER_COW_LIST_SCATTER_P2, payload: graphData && graphData.scatterP2 });
            yield put({ type: HANDLE_FILTER_COW_LIST_GRAPH_P3, payload: graphData && graphData.graphP3DataMerged });
        } else {
            let graphData
            if (action.payload.path === "doa") {
                // when calculating max we must include delayed
                graphData = getDOAGraphData(newPayload, p2reference, p3reference, true, true, true, true, true);
                if (graphData && graphData.graphP2DataMerged) graphData.graphP2DataMerged.forEach((data: any) => {
                    if (data["Cyclic cows"] + data["Anoestrus cows"] > graphP2Max) graphP2Max = data["Cyclic cows"] + data["Anoestrus cows"];
                })
                if (graphData && graphData.graphP3DataMerged) graphData.graphP3DataMerged.forEach((data: any) => {
                    if (data["Completed"] + data["Not completed"] > graphP3Max) graphP3Max = data["Completed"] + data["Not completed"];
                })
                graphData = getDOAGraphData(newPayload, p2reference, p3reference, true, true, true, false, true);
            }
            if (action.payload.path === "sea") {
                graphData = getSEAGraphData(newPayload, true, true, true, true)
                if (graphData && graphData.graphP2DataMerged) graphData.graphP2DataMerged.forEach((data: any) => {
                    if (data["Early embryo death"] + data["Successful"] > graphP2Max) graphP2Max = data["Early embryo death"] + data["Successful"];
                })
                if (graphData && graphData.graphP3DataMerged) graphData.graphP3DataMerged.forEach((data: any) => {
                    if (data["Early abortion"] + data["Successful"] > graphP3Max) graphP3Max = data["Early abortion"] + data["Successful"];
                })
            }
            if (action.payload.path === "crbwp") {
                // when calculating max we must include delayed
                graphData = getCRBWPGraphData(newPayload, p2reference, p3reference, true, true, true, true, true)
                if (graphData && graphData.graphP2DataMerged) graphData.graphP2DataMerged.forEach((data: any) => {
                    if (data["Completed"] + data["Not completed"] > graphP2Max) graphP2Max = data["Completed"] + data["Not completed"];
                })
                if (graphData && graphData.graphP3DataMerged) graphData.graphP3DataMerged.forEach((data: any) => {
                    if (data["Completed"] + data["Not completed"] > graphP3Max) graphP3Max = data["Completed"] + data["Not completed"];
                })
                graphData = getCRBWPGraphData(newPayload, p2reference, p3reference, true, true, true, false, true)
            }
            yield put({ type: HANDLE_FILTER_COW_LIST_GRAPH_MAX, payload: { graphP2Max, graphP3Max } });
            yield put({ type: HANDLE_FILTER_COW_LIST_GRAPH_P2, payload: graphData && graphData.graphP2DataMerged });
            yield put({ type: HANDLE_FILTER_COW_LIST_GRAPH_P3, payload: graphData && graphData.graphP3DataMerged });
        }
    } catch (error) {
        console.log(GET_COWS_LIST_FAILURE, error);
        yield put({ type: GET_COWS_LIST_FAILURE, payload: "Unable to get data" });
    }
}

function* handleFilterCowList(action: any): SagaIterator {
    try {
        let newList = yield call(postXML, `https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/crd/${action.payload.path}`, {
            startDate: action.payload.startDate,
            stopDate: action.payload.stopDate,
            L1: action.payload.lactationFilters.l1Checked ? 1 : 0,
            L2: action.payload.lactationFilters.l2Checked ? 1 : 0,
            L3P: action.payload.lactationFilters.l3Checked ? 1 : 0,
        });

        newList = convertToKPISpecificData(newList, action.payload.path);

        if (newList.length > 0) {
            if (action.payload.filters.ranges) {

                const convertedMinAndMax: any = convertRangesToNumbers(action.payload.filters.ranges);

                if ((convertedMinAndMax.min || convertedMinAndMax.min === 0) && convertedMinAndMax.max) {
                    newList = newList.filter((el: any) => {
                        return el[action.payload.keys[0]] >= convertedMinAndMax.min && el[action.payload.keys[0]] <= convertedMinAndMax.max;
                    });
                } else {
                    newList = newList.filter((el: any) => {
                        return el[action.payload.keys[0]] === convertedMinAndMax;
                    });
                }
            }

            if (action.payload.filters.state) {
                newList = newList.filter((el: any) => {
                    return el[action.payload.keys[1]] === action.payload.filters.state;
                });
            }

            yield put({ type: HANDLE_FILTER_COW_LIST_COMPLETE, payload: newList });
        }
    } catch (error) {
        yield put({ type: "HANDLE_FILTER_COW_LIST_ERROR", payload: "An error occured" });
    }
}

function* handleFilterCowListLocal(action: any): SagaIterator {
    try {
        let newList = action.payload.data;

        // use lactation and culled filter on list
        newList = newList.filter((cow: any) => {
            return ((cow["LactationNo"] === 1 && action.payload.lactationFilters.l1Checked) ||
                (cow["LactationNo"] === 2 && action.payload.lactationFilters.l2Checked) ||
                (cow["LactationNo"] >= 3 && action.payload.lactationFilters.l3Checked)) &&
                (action.payload.lactationFilters.culledChecked || cow["CullState"] === "Not culled")
        });

        if (action.payload.path === "ais") {
            const graphData = getAISGraphData(newList, action.payload.lactationFilters.l1Checked, action.payload.lactationFilters.l2Checked, action.payload.lactationFilters.l3Checked, action.payload.lactationFilters.culledChecked);
            yield put({ type: HANDLE_FILTER_COW_LIST_SCATTER_P1, payload: graphData && graphData.scatterP1 });
            yield put({ type: HANDLE_FILTER_COW_LIST_SCATTER_P2, payload: graphData && graphData.scatterP2 });

            yield put({ type: HANDLE_FILTER_COW_LIST_GRAPH_P3, payload: graphData && graphData.graphP3DataMerged });

            if (action.payload.filters.data && action.payload.filters.data.x !== undefined && action.payload.filters.data.y !== undefined && action.payload.keys) {
                newList = newList.filter((el: any) => {
                    // since when HoursBetweenHeatAndAI are negative they are mapped to zero on the plot, we use HoursBetweenHeatAndAI not y to find the cow in the list
                    if (action.payload.keys[1] === "HoursBetweenHeatAndAI") return el[action.payload.keys[0]] == action.payload.filters.data.y && el[action.payload.keys[1]] == action.payload.filters.data.x;
                    return el[action.payload.keys[1]] == action.payload.filters.data.y && el[action.payload.keys[0]] == action.payload.filters.data.x;
                });
            }

            if (action.payload.filters.ranges) {
                const convertedMinAndMax: any = convertRangesToNumbers(action.payload.filters.ranges);
                if (convertedMinAndMax.min === 0) convertedMinAndMax.min = -9999;

                if (convertedMinAndMax.min && convertedMinAndMax.max) {
                    newList = newList.filter((el: any) => {
                        return el[action.payload.keys[0]] >= convertedMinAndMax.min && el[action.payload.keys[0]] <= convertedMinAndMax.max;
                    });
                } else {
                    newList = newList.filter((el: any) => {
                        return el[action.payload.keys[0]] === convertedMinAndMax;
                    });
                }
            }

            if (action.payload.filters.InseminationState) {
                newList = newList.filter((el: any) => {
                    return el[action.payload.keys[1]] === action.payload.filters.InseminationState;
                });
            }

            yield put({ type: HANDLE_FILTER_COW_LIST_COMPLETE, payload: newList });
        } else if (action.payload.path === "hnir") {
            const graphData = getHNIRGraphData(newList, action.payload.lactationFilters.l1Checked, action.payload.lactationFilters.l2Checked, action.payload.lactationFilters.l3Checked, action.payload.lactationFilters.culledChecked, action.payload.lactationFilters.delayedChecked);
            yield put({ type: HANDLE_FILTER_COW_LIST_SCATTER_P1_HNIR, payload: graphData && graphData.scatterP1 });
            yield put({ type: HANDLE_FILTER_COW_LIST_GRAPH_P3, payload: graphData && graphData.graphP3Data });

            if (action.payload.filters.data && action.payload.filters.data.x && action.payload.filters.data.y) {
                newList = newList.filter((el: any) => {
                    return el.InseminationData.some((insemination: any) => {
                        // take x max 300 and y max 5 into consideration also
                        return (insemination.DaysFromCalving == action.payload.filters.data.x || (action.payload.filters.data.x == 300 && insemination.DaysFromCalving > 300)) &&
                            (insemination.HeatNo == action.payload.filters.data.y || (action.payload.filters.data.y == 5 && insemination.HeatNo > 5));
                    })
                });
            }

            if (action.payload.filters.ranges && action.payload.filters.HNIRState) {
                const convertedMinAndMax: any = convertRangesToNumbers(action.payload.filters.ranges);

                if ((convertedMinAndMax.min || convertedMinAndMax.min === 0) && convertedMinAndMax.max) {
                    newList = newList.filter((el: any) => {
                        return el.InseminationData.some((insemination: any) => {
                            return insemination[action.payload.keys[0]] >= convertedMinAndMax.min && insemination[action.payload.keys[0]] <= convertedMinAndMax.max &&
                                insemination[action.payload.keys[1]] === action.payload.filters.HNIRState &&
                                (action.payload.lactationFilters.delayedChecked || (insemination["FilterSampleDelayValue"] !== null && insemination["FilterSampleDelayValue"] < 3))
                        })
                    });
                } else {
                    newList = newList.filter((el: any) => {
                        return el.InseminationData.some((insemination: any) => {
                            return insemination[action.payload.keys[0]] === convertedMinAndMax && insemination[action.payload.keys[1]] === action.payload.filters.HNIRState &&
                                (action.payload.lactationFilters.delayedChecked || (insemination["FilterSampleDelayValue"] !== null && insemination["FilterSampleDelayValue"] < 3))
                        })
                    });
                }
            }

            yield put({ type: HANDLE_FILTER_COW_LIST_COMPLETE, payload: newList });
        } else if (action.payload.path === "crbwp") {
            const graphData = getCRBWPGraphData(newList, action.payload.p2reference, action.payload.p3reference, action.payload.lactationFilters.l1Checked, action.payload.lactationFilters.l2Checked, action.payload.lactationFilters.l3Checked, action.payload.lactationFilters.delayedChecked, action.payload.lactationFilters.culledChecked);

            yield put({ type: HANDLE_FILTER_COW_LIST_GRAPH_P2, payload: graphData && graphData.graphP2DataMerged });
            yield put({ type: HANDLE_FILTER_COW_LIST_GRAPH_P3, payload: graphData && graphData.graphP3DataMerged });

            if (action.payload.filters.FPLState || action.payload.filters.CLState) {
                const convertedMinAndMax: any = convertRangesToNumbers(action.payload.filters.ranges);

                if ((convertedMinAndMax.min || convertedMinAndMax.min === 0) && convertedMinAndMax.max) {
                    newList = newList.filter((el: any) => {
                        return el.CycleData.some((cycle: any) => {
                            return cycle[action.payload.keys[0]] >= convertedMinAndMax.min && cycle[action.payload.keys[0]] <= convertedMinAndMax.max &&
                                cycle[action.payload.keys[1]] === (action.payload.keys[1] === "CLState" ? action.payload.filters.CLState : action.payload.filters.FPLState);
                        })
                    });
                } else {
                    newList = newList.filter((el: any) => {
                        return el.CycleData.some((cycle: any) => {
                            return cycle[action.payload.keys[0]] === convertedMinAndMax;
                        })
                    });
                }
            }

            yield put({ type: HANDLE_FILTER_COW_LIST_COMPLETE, payload: newList });
        } else {
            const graphData = action.payload.path === "doa" ? getDOAGraphData(newList, action.payload.p2reference, action.payload.p3reference, action.payload.lactationFilters.l1Checked, action.payload.lactationFilters.l2Checked, action.payload.lactationFilters.l3Checked, action.payload.lactationFilters.delayedChecked, action.payload.lactationFilters.culledChecked) :
                action.payload.path === "sea" ?
                    getSEAGraphData(newList, action.payload.lactationFilters.l1Checked, action.payload.lactationFilters.l2Checked, action.payload.lactationFilters.l3Checked, action.payload.lactationFilters.culledChecked) :
                    getODGraphData(newList, action.payload.lactationFilters.l1Checked, action.payload.lactationFilters.l2Checked, action.payload.lactationFilters.l3Checked, action.payload.lactationFilters.culledChecked)

            yield put({ type: HANDLE_FILTER_COW_LIST_GRAPH_P2, payload: graphData && graphData.graphP2DataMerged });
            yield put({ type: HANDLE_FILTER_COW_LIST_GRAPH_P3, payload: graphData && graphData.graphP3DataMerged });

            // removed delayed 
            if (action.payload.path === "doa" && !action.payload.lactationFilters.delayedChecked) {
                newList = newList.filter((cow: any) => {
                    if (action.payload.filters.DOAState !== undefined) return cow["DOADelayValue"] < 2;
                    if (action.payload.filters.FPLState !== undefined) return cow["FLPDelayValue"] < 2;
                    return true
                });
            }

            if (action.payload.filters.ranges) {
                const convertedMinAndMax: any = convertRangesToNumbers(action.payload.filters.ranges);

                if ((convertedMinAndMax.min || convertedMinAndMax.min === 0) && convertedMinAndMax.max) {
                    newList = newList.filter((el: any) => {
                        return el[action.payload.keys[0]] >= convertedMinAndMax.min && el[action.payload.keys[0]] <= convertedMinAndMax.max;
                    });
                } else {
                    newList = newList.filter((el: any) => {
                        return el[action.payload.keys[0]] === convertedMinAndMax;
                    });
                }
            }

            if (action.payload.filters.DOAState) {
                const DOAState = action.payload.filters.DOAState === "Anoestrus cows" ? "Anoestrus" : "Cyclic"
                newList = newList.filter((el: any) => {
                    return el[action.payload.keys[1]] === DOAState;
                });
            }

            if (action.payload.filters.FPLState) {
                newList = newList.filter((el: any) => {
                    return el[action.payload.keys[1]] === action.payload.filters.FPLState;
                });
            }

            if (action.payload.filters.SEAState) {
                newList = newList.filter((el: any) => {
                    return el[action.payload.keys[1]] === action.payload.filters.SEAState;
                });
            }

            if (action.payload.filters.PBSSState) {
                newList = newList.filter((el: any) => {
                    return el[action.payload.keys[1]] === action.payload.filters.PBSSState;
                });
            }

            if (action.payload.filters.CompletionStatus && !action.payload.filters.MAXAINumber) {
                newList = newList.filter((el: any) => {
                    return el[action.payload.keys[1]] === action.payload.filters.CompletionStatus;
                });
            }

            if (action.payload.filters.MAXAINumber) {
                newList = newList.filter((el: any) => {
                    if (action.payload.filters.CompletionStatus !== "Non-pregnant") {
                        return (el.CompletionStatus === action.payload.filters.CompletionStatus && el[action.payload.keys[0]] == action.payload.filters.MAXAINumber);
                    }
                    else {
                        return (el.CompletionStatus === "Non-pregnant" && el[action.payload.keys[0]] == action.payload.filters.MAXAINumber) || el[action.payload.keys[0]] > action.payload.filters.MAXAINumber;
                    }
                });
            }

            yield put({ type: HANDLE_FILTER_COW_LIST_COMPLETE, payload: newList });
        }
    } catch (error) {
        yield put({ type: "HANDLE_FILTER_COW_LIST_ERROR", payload: "An error occured" });
    }
}

function* handleSelectionCow(action: any): SagaIterator {
    try {
        let newList = action.payload.data;

        if (action.payload.path === "hnir") {
            newList = newList.filter((cow: any) => {
                return action.payload.selection.some((point: iPoint) => {
                    return cow.InseminationData.some((insemination: any) => {
                        // take x max 300 and y max 5 into consideration also
                        return (insemination.DaysFromCalving == point.x || (point.x == 300 && insemination.DaysFromCalving > 300)) &&
                            (insemination.HeatNo == point.y || (point.y == 5 && insemination.HeatNo > 5));
                    })
                })
            })

        }

        if (action.payload.path === "ais") {

            newList = newList.filter((cow: any) => {
                return action.payload.selection.some((point: iPoint) => {
                    if (action.payload.keys[0] === "DaysFromCalving") return (cow[action.payload.keys[0]] == point.x || cow[action.payload.keys[0]] > 300 && point.x == 300) && cow[action.payload.keys[1]] == point.y
                    if (action.payload.keys[1] === "FPLength") return (cow[action.payload.keys[1]] == point.y || cow[action.payload.keys[1]] > 20 && point.y == 20) && cow[action.payload.keys[0]] == point.x
                    return cow[action.payload.keys[0]] == point.x && cow[action.payload.keys[1]] == point.y
                })
            })
        }

        yield put({ type: HANDLE_FILTER_COW_LIST_COMPLETE, payload: newList });
    } catch (error) {
        yield put({ type: "HANDLE_FILTER_COW_LIST_ERROR", payload: "An error occured" });
    }
}

export function* watchGetCowsList(): SagaIterator {
    yield takeEvery(GET_COWS_LIST_REQUEST, getCowsListSaga);
    yield takeEvery(HANDLE_FILTER_COW_LIST_INITIALIZE, handleFilterCowList);
    yield takeEvery(HANDLE_FILTER_COW_LIST_INITIALIZE_LOCAL, handleFilterCowListLocal);
    yield takeEvery(HANDLE_SELECTION_COW, handleSelectionCow);
}
