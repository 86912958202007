import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { postXML } from "../../api/requests";
import { convertToOverviewChartDataOnShortDate } from "../../utills/dataFormatting";

import {
    GET_PBSS_REQUEST,
    GET_PBSS_SUCCESS,
    GET_PBSS_FAILURE,
} from "./constants";

function* getPBSSSaga(action: Action<any>): SagaIterator {
    try {
        const scoreData = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/pbss/cicdistribution", {
            startDate: action.payload.startDate,
            stopDate: action.payload.stopDate,
        });

        const convertedChartData = convertToOverviewChartDataOnShortDate(scoreData);

        yield put({ type: GET_PBSS_SUCCESS, payload: { scoreData: convertedChartData} });
    } catch (error) {
        console.log(GET_PBSS_FAILURE, error);
        yield put({ type: GET_PBSS_FAILURE, payload: "Unable to get data" });
    }
}

export function* watchGetPBSSSaga(): SagaIterator {
    yield takeEvery(GET_PBSS_REQUEST, getPBSSSaga);
}
