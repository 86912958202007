import React from "react";
import "../Layout.scss";

const LayoutContainer: React.FC = (props) => {
    return (
        <div className="layout__container">
            {props.children}
        </div>
    );
};

export default LayoutContainer;
