import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { IBasicPayload } from "../../actions";

import i18n from "i18next";
import { postXML } from "../../api/requests";
import {
    GET_CURRENT_REPRUDCTION_PERFORMANCE_FAILURE,
    GET_CURRENT_REPRUDCTION_PERFORMANCE_REQUEST,
    GET_CURRENT_REPRUDCTION_PERFORMANCE_SUCCESS,
    GET_KPI_CHART_DATA_FAILURE,
    GET_KPI_CHART_DATA_REQUEST,
    GET_KPI_CHART_DATA_SUCCESS,
} from "./constants";

function* getCurrentReproductionPerformanceSaga(action: Action<IBasicPayload>): SagaIterator {
    try {
        const payload = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/dashboard/currentreproductionperformance");

        yield put({ type: GET_CURRENT_REPRUDCTION_PERFORMANCE_SUCCESS, payload: payload.length > 0 ? payload[0] : [] });
    } catch (error) {
        console.log(GET_CURRENT_REPRUDCTION_PERFORMANCE_FAILURE, error);
        yield put({ type: GET_CURRENT_REPRUDCTION_PERFORMANCE_FAILURE, payload: "Unable to get data" });
    }
}

function* getKPIChartData(action: Action<any>): SagaIterator {
    try {

        const payload = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/performancescoreext");

        let newPayload: any[] = [];
        const decimals = 0;

        if (payload.length > 0) {
            newPayload = [{
                key: "doa",
                kpi: i18n.t("AnoestrusLength"),
                value: payload[0].DOAScore ? parseFloat(payload[0].DOAScore).toFixed(decimals) : null,
                reference: action.payload && action.payload.dataType === 0 ? (payload[0].DOARefScoreBenchmarkFarms ? parseFloat(payload[0].DOARefScoreBenchmarkFarms).toFixed(decimals) : null) : (payload[0].DOARefScoreOwnFarm ? parseFloat(payload[0].DOARefScoreOwnFarm).toFixed(decimals) : null),
                trend: payload[0].DOATrend,
            }, {
                key: "cr",
                kpi: i18n.t("CycleRegularity"),
                value: payload[0].CRScore ? parseFloat(payload[0].CRScore).toFixed(decimals) : null,
                reference: action.payload && action.payload.dataType === 0 ? (payload[0].CRRefScoreBenchmarkFarms ? parseFloat(payload[0].CRRefScoreBenchmarkFarms).toFixed(decimals) : null) : (payload[0].CRRefScoreOwnFarm ? parseFloat(payload[0].CRRefScoreOwnFarm).toFixed(decimals) : null),
                trend: payload[0].CRTrend,
            }, {
                key: "hnir",
                kpi: i18n.t("HNInseminationRate"),
                value: payload[0].HNIRScore ? parseFloat(payload[0].HNIRScore).toFixed(decimals) : null,
                reference: action.payload && action.payload.dataType === 0 ? (payload[0].HNIRRefScoreBenchmarkFarms ? parseFloat(payload[0].HNIRRefScoreBenchmarkFarms).toFixed(decimals) : null) : (payload[0].HNIRRefScoreOwnFarm ? parseFloat(payload[0].HNIRRefScoreOwnFarm).toFixed(decimals) : null),
                trend: payload[0].HNIRTrend,
            }, {
                key: "ais",
                kpi: i18n.t("InseminationSuccess"),
                value: payload[0].AISScore ? parseFloat(payload[0].AISScore).toFixed(decimals) : null,
                reference: action.payload && action.payload.dataType === 0 ? (payload[0].AISRefScoreBenchmarkFarms ? parseFloat(payload[0].AISRefScoreBenchmarkFarms).toFixed(decimals) : null) : (payload[0].AISRefScoreOwnFarm ? parseFloat(payload[0].AISRefScoreOwnFarm).toFixed(decimals) : null),
                trend: payload[0].AISTrend,
            }, {
                key: "sea",
                kpi: i18n.t("EmbryoAttachment"),
                value: payload[0].SEAScore ? parseFloat(payload[0].SEAScore).toFixed(decimals) : null,
                reference: action.payload && action.payload.dataType === 0 ? (payload[0].SEARefScoreBenchmarkFarms ? parseFloat(payload[0].SEARefScoreBenchmarkFarms).toFixed(decimals) : null) : (payload[0].SEARefScoreOwnFarm ? parseFloat(payload[0].SEARefScoreOwnFarm).toFixed(decimals) : null),
                trend: payload[0].SEATrend,
            }, {
                key: "pbss",
                kpi: i18n.t("EstablishmentOfPregnancy"),
                value: payload[0].PBSSScore ? parseFloat(payload[0].PBSSScore).toFixed(decimals) : null,
                reference: action.payload && action.payload.dataType === 0 ? (payload[0].PBSSRefScoreBenchmarkFarms ? parseFloat(payload[0].PBSSRefScoreBenchmarkFarms).toFixed(decimals) : null) : (payload[0].PBSSRefScoreOwnFarm ? parseFloat(payload[0].PBSSRefScoreOwnFarm).toFixed(decimals) : null),
                trend: payload[0].PBSSTrend,
            }, {
                key: "od",
                kpi: i18n.t("ProjectedOpenDays"),
                value: payload[0].ODScore ? parseFloat(payload[0].ODScore).toFixed(decimals) : null,
                reference: action.payload && action.payload.dataType === 0 ? (payload[0].ODRefScoreBenchmarkFarms ? parseFloat(payload[0].ODRefScoreBenchmarkFarms).toFixed(decimals) : null) : (payload[0].ODRefScoreOwnFarm ? parseFloat(payload[0].ODRefScoreOwnFarm).toFixed(decimals) : null),
                trend: payload[0].ODTrend,
            }];
        }

        yield put({ type: GET_KPI_CHART_DATA_SUCCESS, payload: newPayload });
    } catch (error) {
        console.log(GET_KPI_CHART_DATA_FAILURE, error);
        yield put({ type: GET_KPI_CHART_DATA_FAILURE, payload: "Unable to get data" });
    }
}

export function* watchGetCurrentReproductionPerformance(): SagaIterator {
    yield takeEvery(GET_CURRENT_REPRUDCTION_PERFORMANCE_REQUEST, getCurrentReproductionPerformanceSaga);
    yield takeEvery(GET_KPI_CHART_DATA_REQUEST, getKPIChartData);
}
