const delavalblue: string = '#103D82';
const delavalcyan: string = '#00A6FF';
// const delavalmediumcyan: string = '#7FD2FF';
// const delavallightcyan: string = '#BFE8FF';
// const delavalblack: string = '#000000'; 
const delavaldarkgray: string = '#575757';
const delavalgray: string = '#999999';
const delavalmediumgray: string = '#CCCCCC';
const delavallightgray: string = '#E5E5E5';
const delavalwhite: string = '#FFFFFF';
const delavalgreen: string = '#77AD1C';
const delavalyellow: string = '#FFD300';
// const delavalorange: string = '#FA961E';
// const delavaldarkorange: string = '#FF8C00';
const delavalred: string = '#FF3300';

export const lattecchartcolor1: string = delavalblue;
export const lattecchartcolor2: string = delavalcyan;
export const delavallightcyan: string = '#BFE8FF';
export const lattecchartcolor3: string = delavalmediumgray;
export const lattecchartcolor4: string = delavalgreen;

export const lattecbordercolor1: string = delavalwhite;
export const lattecbordercolor2: string = delavalblue;
export const lattecbordercolor3: string = delavaldarkgray;

export const lattecscoregreen: string = delavalgreen;
export const lattecscoreyellow: string = delavalyellow;
export const lattecscorered: string = delavalred;
export const lattecscoreblue: string = delavalblue;

const lattecreferencegreen: string = '#008000';
export const lattecreferenceshadow: string = delavalblue;
export const lattecreferenceline: string = delavaldarkgray;

export const colorPalette2: string[] = [lattecchartcolor1, lattecchartcolor3];
export const colorPalette2Ext: string[] = [lattecchartcolor1, lattecchartcolor3, delavalcyan];
export const colorPalette3: string[] = [lattecchartcolor1, lattecchartcolor1, delavalgray, lattecchartcolor3];
export const colorPaletteRadar: string[] = [lattecchartcolor1, lattecchartcolor1, lattecreferencegreen];
export const colorPalette5: string[] = [lattecchartcolor1, lattecchartcolor2, delavalgray, delavalgreen, delavalred];

export const colorPalette2ForRef: string[] = 
[lattecchartcolor1, lattecchartcolor1];

export const colorPalette3ForRef: string[] = 
[lattecchartcolor1, lattecchartcolor1, lattecchartcolor3];

export const colorPalettePie: string[] = [lattecchartcolor1];

export const chartBackGround: string = delavallightgray;

export const targetPlotOpacity: number = 0.5;
export const refPlotOpacity: number = 0.2;


// COLORS
const orange = "#E88E42";
const darkBlue = "#0062A3";
const midBlue = "#0081D5";
const lightBlue = "#1FA5FF";

export const gray = "#bcc9d1";

export const defaultColorPalette = [darkBlue, midBlue, lightBlue];
export const contrastedTwoColorPalette = [darkBlue, orange];
export const contrastedThreeColorPalette = [orange, darkBlue, lightBlue];
export const contrastedThreeColorPaletteReversed = [darkBlue, midBlue, orange]
