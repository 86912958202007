const TOKEN: string = "token";
const EXPIRATION_DAY: string = "expiration-day";
const USERNAME: string = "userName";
const FARMID: string = "farmId";
const FARMNAME: string = "farmName";

export const getToken = (): any => window.localStorage.getItem(TOKEN);
export const setToken = (value: string): void => window.localStorage.setItem(TOKEN, value);
export const remToken = () => localStorage.removeItem(TOKEN);

export const getUsername = () => window.localStorage.getItem(USERNAME);
export const setUsername = (value: string) => window.localStorage.setItem(USERNAME, value);
export const remUsername = () => localStorage.removeItem(USERNAME);

export const setFarmId = (value: string) => window.localStorage.setItem(FARMID, value);
export const getFarmId = () => {
    const farmId = window.localStorage.getItem(FARMID);

    if (farmId) {
        return parseInt(farmId, 10);
    }

    return null;
};

export const setFarmName = (value: string) => window.localStorage.setItem(FARMNAME, value);
export const getFarmName = () => window.localStorage.getItem(FARMNAME);

export const clearLocalStorage = () => window.localStorage.clear();

export const setExpirationDay = () => {
    const date = new Date();
    const numberOfDaysToAdd = 2;
    const expirationDay = date.setDate(date.getDate() + numberOfDaysToAdd);

    const expirationDate = new Date(expirationDay);

    window.localStorage.setItem(EXPIRATION_DAY, expirationDate.toString());
};

export const getExpirationDay = () => window.localStorage.getItem(EXPIRATION_DAY) || new Date().toString();
