import { StepGuidesTranslations } from "./StepGuidesTranslations";

const da = {
    ...StepGuidesTranslations.da,
    "BaselineReport": "Baseline report",
    "BaselineReportSubHeader": "Compare your progress using standard reproduction KPIs.",
    "KPI": "KPI",
    "BaselineStart": "Baseline start",
    "Today": "Today",
    "BenchmarkData": "Benchmark data",

    // KPI translations
    "ProportionOfCowsBred": "Proportion of cows bred [%]",
    "CalvingToFirstAIInterval": "Calving to first AI interval [days]",
    "FirstToLastAIInterval": "First to last AI interval [days]",
    "AIsPrCow": "AIs per cow",
    "InseminationRate": "Insemination Rate [%]",
    "AIsPrPregnancy": "AIs per pregnancy",
    "ConceptionRate": "Conception rate [%]",
    "PregnancyRate": "Pregnancy rate [%]",
    "OpenDays": "Days open [days]",

    "CurrentReproductionPerformance": "Current Reproduction Performance",
    "HNInseminationRate": "HN Insemination rate",
    "SuccessOnFirstAI": "Success on first AI",
    "RegularCycles": "Regular cycles",
    "AnoestrusLength": "Anoestrus Length",
    "CycleRegularity": "Cycle regularity",
    "EmbryoAttachment": "Embryo attachment",
    "InseminationSuccess": "Insemination success",
    "EstablishmentOfPregnancy": "Establishment of pregnancy",
    "ProjectedOpenDays": "Projected open days",
    "StickConsumption": "Stick consumption",
    "StickConsumptionKPI": "Stick consumption KPI's",
    "StickConsumptionSubHeader": "Compare your farm's stick consumption to the stick consumption on comparable farms.",
    "BenchmarkFarms": "Benchmark farms",
    "Trend": "Trend",
    "DataNotAvailable": "Data not available",
    "SticksPregnancyTrend": "Sticks / pregnancy trend",
    "TopTenHighConsumers": "Top 10 high consumers (non pregnant)",
    "CowNumber": "Cow number",
    "ScoreDevelopment": "Overview of the past 12 months",
    "ScrollToSeeMore": "Scroll to see more",
    "Dashboard": "Dashboard",
    "SticksUsed": "Sticks used",
    "TopInsights": "Top Insights",
    "NoPinnedInsights": "You have no pinned insights yet",
    "Rank": "Rank",
    "SticksUsedOnLutealCysts": "Average sticks used on luteal cysts",
    "SticksUsedPrCow": "Average sticks used per cow",
    "SticksUsedPrPregnancy": "Average sticks used per pregnancy",
    "SticksUsedFromFirstToLastAI": "Average sticks used from first to last AI",
    "SticksUsedOnFollicularCysts": "Average sticks used on follicular cysts",



    
    // 'AIS-CompInCompHistory-ToolTip-Title': 'Complete / In-complete history',
    // 'AIS-CompInCompHistory-ToolTip-Date': 'Evaluation Date',
    // 'AIS-CompInCompHistory-ToolTip-Complete': 'Successful:',
    // 'AIS-CompInCompHistory-ToolTip-InComplete': 'Non-successful:',
    // 'AIS-CompInCompHistory-ToolTip-ScoreValue': 'Score:',
    // 'AIS-TimingOfInsemination-Title': 'Timing of Insemination',
    // 'AIS-TimingOfInsemination-XAxis-Title': 'Hours from HN heat alarm',
    // 'AIS-TimingOfInsemination-YAxis-Title': '#Inseminations',
    // 'AIS-TimingOfInsemination-SeriesName-Complete': 'Pregnant',
    // 'AIS-TimingOfInsemination-SeriesName-InComplete': 'Non-pregnant',
    // 'AIS-TimingOfInsemination-ToolTip-Title': 'Timing of Insemination',
    // 'AIS-TimingOfInsemination-ToolTip-Complete': 'Pregnant:',
    // 'AIS-TimingOfInsemination-ToolTip-InComplete': 'Non-pregnant:',
    // 'AIS-TimingOfInsemination-ToolTip-StartDate': 'StartDate',
    // 'AIS-TimingOfInsemination-ToolTip-StopDate': 'StopDate',
    // 'AIS-Pregnancy-Title': 'Insemination Success',
    // 'AIS-Pregnancy-XAxis-Title': 'Days from Calving',
    // 'AIS-Pregnancy-YAxis-Title': '#Inseminations',
    // 'AIS-Pregnancy-SeriesName-Complete': 'Pregnant',
    // 'AIS-Pregnancy-SeriesName-InComplete': 'Non-pregnant',
    // 'AIS-Pregnancy-ToolTip-Title': 'Insemination Success',
    // 'AIS-Pregnancy-ToolTip-Complete': 'Pregnant:',
    // 'AIS-Pregnancy-ToolTip-InComplete': 'Non-pregnant:',
    // 'AIS-Pregnancy-ToolTip-StartDate': 'Start date:',
    // 'AIS-Pregnancy-ToolTip-StopDate': 'Stop date:',
    // 'SV-Button-Update': 'Update',
    // 'SV-Button-Cancel': 'Cancel',
    // 'RP-ScoreOverview-Chart-PathName-DOA': 'Duration of Anoestrus',
    // 'RP-ScoreOverview-Chart-PathName-SubTitle-DOA': 'Number of days from calving to cyclic activity. This describes the cows’ ability to resume cyclicity.',
    // 'RP-ScoreOverview-Chart-PathName-PathSpecific-DOA': 'Cows non-cyclic/cyclic:',
    // 'RP-ScoreOverview-Chart-PathName-CRBWP': 'Cycle Regularity',
    // 'RP-ScoreOverview-Chart-PathName-SubTitle-CRBWP': 'Evaluates the regularity of the oestrus cycles based on their length. Abnormal cycles indicate reproductive issues.',
    // 'RP-ScoreOverview-Chart-PathName-PathSpecific-CRBWP': 'Irregular cycles/Regular cycles:',
    // 'RP-ScoreOverview-Chart-PathName-DAI': 'Days to First Insemination',
    // 'RP-ScoreOverview-Chart-PathName-SubTitle-DAI': 'Number of days from calving to first insemination.',
    // 'RP-ScoreOverview-Chart-PathName-PathSpecific-DAI': 'Open/Bred:',
    // 'RP-ScoreOverview-Chart-PathName-AIS': 'Insemination Success',
    // 'RP-ScoreOverview-Chart-PathName-SubTitle-AIS': 'Evaluates the number of inseminations where the egg is successfully fertilizated based on progesterone level at 25 days from insemination.',
    // 'RP-ScoreOverview-Chart-PathName-PathSpecific-AIS': 'Not successful/Successful:',
    // 'RP-ScoreOverview-Chart-PathName-SEA': 'Successful Embryo Attachment',
    // 'RP-ScoreOverview-Chart-PathName-SubTitle-SEA': 'Evaluates the number of inseminations that result in a successful embryo attachment based on progesterone level at 35 days from insemination.',
    // 'RP-ScoreOverview-Chart-PathName-PathSpecific-SEA': 'Not successful/Successful:',
    // 'RP-ScoreOverview-Chart-PathName-PBSS': 'Successful Establishment of Pregnancy',
    // 'RP-ScoreOverview-Chart-PathName-SubTitle-PBSS': 'Evaluates the number of inseminations that results in a successful establishment of pregnancy based on progesterone level at 55 days from insemination.',
    // 'RP-ScoreOverview-Chart-PathName-PathSpecific-PBSS': 'Not successful/Successful:',
    // 'RP-ScoreOverview-Chart-PathName-OD': 'Open Days',
    // 'RP-ScoreOverview-Chart-PathName-SubTitle-OD': 'Days from calving to the date of insemination where the cow became pregnant.',
    // 'RP-ScoreOverview-Chart-PathName-PathSpecific-OD': 'Open/Bred/Pregnant:',
    // 'RP-ScoreOverview-Chart-Score': 'Score',
    // 'RP-ScoreOverview-Chart-RefScore': 'Reference score',
    // 'RP-ScoreOverview-Chart-CowsIncluded': 'Cows included:',
    // 'RP-Spin-DisplayName-DOA': 'Anoestrus length',
    // 'RP-Spin-DisplayName-CR': 'Cycle regularity',
    // 'RP-Spin-DisplayName-DAI': 'Days to first insemination',
    // 'RP-Spin-DisplayName-AIS': 'Insemination success',
    // 'RP-Spin-DisplayName-SEA': 'Embryo attachment',
    // 'RP-Spin-DisplayName-PBSS': 'Establishment of pregnancy',
    // 'RP-Spin-DisplayName-OD': 'Projected open days',
    // 'CRD-ToolTip-Header': 'Cow',
    // 'CRD-ToolTip-SamplingTime': 'Sampling time:',
    // 'CRD-ToolTip-DFC': 'DFC',
    // 'CRD-ToolTip-Yield': 'Yield:',
    // 'CRD-ToolTip-ProgLevel': 'Progesterone:',
    // 'CRD-ChartTitle': 'Cow data',
    // 'CRD-xAxisTitle-DFC': 'Days from Calving',
    // 'CRD-xAxisTitle-Date': 'Date',
    // 'CRD-SampleData-yAxisTitle': 'Progesterone',
    // 'CRD-MilkData-yAxisTitle': 'Milk Yield',
    // 'CRD-SampleData-SeriesName': 'Sample Data',
    // 'CRD-MilkData-SeriesName': 'Milk Data',
    // 'CRD-SelectDFC': 'Select "Days from Calving" on X  axis',
    // 'CRD-CowList-Title': 'Cow list',
    // 'CRD-CowNo-ColumnHeader': 'Cow No',
    // 'CRD-LactationNo-ColumnHeader': 'Lactation number',
    // 'CRD-DOA-Title': 'Duration of Anoestrus',
    // 'CRD-DOA-DOA-ColumnHeader': 'DOA',
    // 'CRD-DOA-IsComplete-ColumnHeader': 'State',
    // 'CRD-CRBWP-Title': 'Cycle Regularity',
    // 'CRD-CRBWP-CycleOnset-ColumnHeader': 'Cycle onset',
    // 'CRD-CRBWP-CycleLength-ColumnHeader': 'Cycle Length',
    // 'CRD-CRBWP-FolPhaseLength-ColumnHeader': 'Follicular Phase Length',
    // 'CRD-CRBWP-IsComplete-ColumnHeader': 'State',
    // 'CRD-DAI-Title': 'Days to First AI',
    // 'CRD-DAI-DAI-ColumnHeader': 'DAI',
    // 'CRD-DAI-IsComplete-ColumnHeader': 'State',
    // 'CRD-AIS-Title': 'AI Success',
    // 'CRD-AIS-AINumber-ColumnHeader': 'AI Number',
    // 'CRD-AIS-HoursBetweenHeatAndAI-ColumnHeader': 'Hours between Heat and AI',
    // 'CRD-AIS-IsComplete-ColumnHeader': 'State',
    // 'CRD-SEA-Title': 'Embryo Attachment',
    // 'CRD-SEA-AINumber-ColumnHeader': 'AI Number',
    // 'CRD-SEA-DFC-ColumnHeader': 'Days from Calving',
    // 'CRD-SEA-IsComplete-ColumnHeader': 'State',
    // 'CRD-PBSS-Title': 'Establishment of Pregnancy',
    // 'CRD-PBSS-AINumber-ColumnHeader': 'AI Number',
    // 'CRD-PBSS-DFC-ColumnHeader': 'Days from Calving',
    // 'CRD-PBSS-IsComplete-ColumnHeader': 'State',
    // 'CRD-OD-Title': 'Open Days',
    // 'CRD-OD-OpenDays-ColumnHeader': 'OD',
    // 'CRD-OD-CompletionStatus-ColumnHeader': 'State',
    // 'Cow repro details': 'Cow Repro Details',
    // 'CRD-OptionalColumn2Value-DOA-InComplete': 'Non-cyclic',
    // 'CRD-OptionalColumn2Value-DOA-Complete': 'Cyclic',
    // 'CRD-OptionalColumn2Value-CRBWP-InComplete': 'Irregular',
    // 'CRD-OptionalColumn2Value-CRBWP-Complete': 'Regular',
    // 'CRD-OptionalColumn2Value-DAI-InComplete': 'Non-inseminated',
    // 'CRD-OptionalColumn2Value-DAI-Complete': 'Inseminated',
    // 'CRD-OptionalColumn2Value-AIS-InComplete': 'Non-successful',
    // 'CRD-OptionalColumn2Value-AIS-Complete': 'Successful',
    // 'CRD-OptionalColumn2Value-SEA-InComplete': 'Non-successful',
    // 'CRD-OptionalColumn2Value-SEA-Complete': 'Successful',
    // 'CRD-OptionalColumn2Value-PBSS-InComplete': 'Non-successful',
    // 'CRD-OptionalColumn2Value-PBSS-Complete': 'Successful',
    // 'CRD-OptionalColumn2Value-OD-InComplete': 'Non-inseminated',
    // 'CRD-OptionalColumn2Value-OD-Complete': 'Pregnant',
    // 'CRD-OptionalColumn2Value-OD-Potential': 'Inseminated',
    // 'CF-Lactations': 'Lactation filters',
    // 'CF-Lactation 1': 'Lactation 1',
    // 'CF-Lactation 2': 'Lactation 2',
    // 'CF-Lactation 3+': 'Lactation 3+',
    // 'CF-All Lactations': 'All Lactations',
    // 'CRBWP-PageInfo-Title': 'Cycle Regularity',
    // 'CRBWP-PageInfo-CurrentScore': 'Current Score',
    // 'CR-UserInteraction-Button-AdvicePage': 'Checklist',
    // 'CR-UserInteraction-Button-FactSheet': 'Fact sheet',
    // 'CR-UserInteraction-Button-CowDetails': 'Cow details',
    // 'CRBWP-CompSummary-Empty-Chart': 'Cycle Length: Development over time',
    // 'CRBWP-CompSummary-Title': 'Cycle Length: Development over time',
    // 'CRBWP-CompSummary-XAxis-Title': 'Evaluation Date',
    // 'CRBWP-CompSummary-YAxis-Title': 'Days',
    // 'CRBWP-CompSummary-ToolTip-Title': 'Evaluation',
    // 'CRBWP-CompSummary-ToolTip-BinDataBeginDate': 'Period start:',
    // 'CRBWP-CompSummary-ToolTip-BinDataEndDate': 'Period end:',
    // 'CRBWP-CompSummary-ToolTip-NoOfCows': 'Regular cycles:',
    // 'CRBWP-CompSummary-ToolTip-NoOfInCompleteCows': 'Irregular cycles:',
    // 'CRBWP-CompSummary-ToolTip-Open': '25 percentile:',
    // 'CRBWP-CompSummary-ToolTip-Close': '75 percentile:',
    // 'CRBWP-CompSummary-ToolTip-Median': 'Median:',
    // 'CRBWP-CompSummary-ToolTip-ScoreValue': 'Score:',
    // 'CRBWP-FolPhaseLength-Title': 'Follicular Phase length',
    // 'CRBWP-FolPhaseLength-SeriesName': 'Follicular Phase Length',
    // 'CRBWP-FolPhaseLength-XAxis-Title': 'Days',
    // 'CRBWP-FolPhaseLength-YAxis-Title': '#Cycles',
    // 'CRBWP-FolPhaseLength-ToolTip-Title': 'Follicular Phase length',
    // 'CRBWP-FolPhaseLength-ToolTip-CowsInBin': 'Cows:',
    // 'CRBWP-FolPhaseLength-ToolTip-StartDate': 'Period start:',
    // 'CRBWP-FolPhaseLength-ToolTip-StopDate': 'Period stop:',
    // 'CRBWP-CycleLength-Title': 'Cycle Length',
    // 'CRBWP-CycleLength-SeriesName': 'Cycle Length',
    // 'CRBWP-CycleLength-XAxis': 'Days between heats',
    // 'CRBWP-CycleLength-YAxis': '#Cycles',
    // 'CRBWP-CycleLength-ToolTip-Title': 'Cycle Length',
    // 'CRBWP-CycleLength-ToolTip-CowsInBin': 'Cows:',
    // 'CRBWP-CycleLength-ToolTip-StartDate': 'Period start:',
    // 'CRBWP-CycleLength-ToolTip-StopDate': 'Period stop:',
    // 'DAI-PageInfo-Title': 'Days to First Insemination',
    // 'DAI-PageInfo-CurrentScore': 'Current Score',
    // 'DAI-UserInteraction-Button-AdvicePage': 'Checklist',
    // 'DAI-UserInteraction-Button-FactSheet': 'Fact sheet',
    // 'DAI-UserInteraction-Button-CowDetails': 'Cow details',
    // 'DAI-CompSummary-Empty-Chart': 'Days to First Insemination: Development over time',
    // 'DAI-CompSummary-Title': 'Days to First Insemination: Development over time',
    // 'DAI-CompSummary-XAxis-Title': 'Evaluation Date',
    // 'DAI-CompSummary-YAxis-Title': 'Days from calving',
    // 'DAI-CompSummary-ToolTip-Title': 'evaluation',
    // 'DAI-CompSummary-ToolTip-BinDataBeginDate': 'Period start:',
    // 'DAI-CompSummary-ToolTip-BinDataEndDate': 'Period stop:',
    // 'DAI-CompSummary-ToolTip-NoOfCows': 'Bred:',
    // 'DAI-CompSummary-ToolTip-NoOfInCompleteCows': 'Predicted:',
    // 'DAI-CompSummary-ToolTip-Open': '25 Percentile:',
    // 'DAI-CompSummary-ToolTip-Close': '75 Percentile:',
    // 'DAI-CompSummary-ToolTip-Median': 'Median:',
    // 'DAI-CompSummary-ToolTip-ScoreValue': 'Score:',
    // 'DAI-PregnancyDetail-Title': 'Pregnancy at First Insemination',
    // 'DAI-PregnancyDetail-XAxis-Title': 'Days from Calving',
    // 'DAI-PregnancyDetail-YAxis-Title': '#Cows',
    // 'DAI-PregnancyDetail-SeriesName-Complete': 'Pregnant',
    // 'DAI-PregnancyDetail-SeriesName-InComplete': 'Non-pregnant',
    // 'DAI-PregnancyDetail-SeriesName-Potential': 'Bred',
    // 'DAI-PregnancyDetail-ToolTip-Title': 'Pregnancy at First Insemination',
    // 'DAI-PregnancyDetail-ToolTip-Complete': 'Pregnant:',
    // 'DAI-PregnancyDetail-ToolTip-InComplete': 'Non-pregnant:',
    // 'DAI-PregnancyDetail-ToolTip-Potential': 'Bred',
    // 'DAI-PregnancyDetail-ToolTip-StartDate': 'AI period start:',
    // 'DAI-PregnancyDetail-ToolTip-StopDate': 'AI period stop:',
    // 'DAI-CompInComp-Title': 'Days to First Insemination',
    // 'DAI-CompInComp-XAxis-Title': 'Days from Calving',
    // 'DAI-CompInComp-YAxis-Title': '#Cows',
    // 'DAI-CompInComp-SeriesName-Complete': 'Bred',
    // 'DAI-CompInComp-SeriesName-InComplete': 'Predicted (Non-inseminated)',
    // 'DAI-CompInComp-ToolTip-Title': 'Days to First Insemination',
    // 'DAI-CompInComp-ToolTip-Complete': 'Bred:',
    // 'DAI-CompInComp-ToolTip-InComplete': 'Predicted:',
    // 'DAI-CompInComp-ToolTip-StartDate': 'AI period start:',
    // 'DAI-CompInComp-ToolTip-StopDate': 'AI period stop:',
    // 'DOA-PageInfo-Title': 'Duration of Anoestrus',
    // 'DOA-PageInfo-CurrentScore': 'Current Score',
    // 'DOA-UserInteraction-Button-AdvicePage': 'Checklist',
    // 'DOA-UserInteraction-Button-FactSheet': 'Fact sheet',
    // 'DOA-UserInteraction-Button-CowDetails': 'Cow details',
    // 'DOA-CompSummary-Empty-Chart': 'Anoestrus length: Development over time',
    // 'DOA-CompSummary-Title': 'Anoestrus length: Development over time',
    // 'DOA-CompSummary-XAxis-Title': 'Evaluation Date',
    // 'DOA-CompSummary-YAxis-Title': 'Anoestrus length [days]',
    // 'DOA-CompSummary-ToolTip-Title': 'evaluation',
    // 'DOA-CompSummary-ToolTip-BinDataBeginDate': 'Period start:',
    // 'DOA-CompSummary-ToolTip-BinDataEndDate': 'Period end:',
    // 'DOA-CompSummary-ToolTip-NoOfCows': 'Cyclic',
    // 'DOA-CompSummary-ToolTip-NoOfInCompleteCows': 'Non-cyclic',
    // 'DOA-CompSummary-ToolTip-Open': '25 Percentile:',
    // 'DOA-CompSummary-ToolTip-Close': '75 Percentile:',
    // 'DOA-CompSummary-ToolTip-Median': 'Median:',
    // 'DOA-CompSummary-ToolTip-ScoreValue': 'Score:',
    // 'DOA-FLP-Title': 'First luteal phase',
    // 'DOA-FLP-SeriesName': 'First luteal phase',
    // 'DOA-FLP-XAxis-Title': 'Days',
    // 'DOA-FLP-YAxis-Title': '#Cows',
    // 'DOA-FLP-ToolTip-Title': 'First luteal phase',
    // 'DOA-FLP-ToolTip-CowsInBin': 'Cows:',
    // 'DOA-FLP-ToolTip-StartDate': 'Period start:',
    // 'DOA-FLP-ToolTip-StopDate': 'Period stop:',
    // 'DOA-CompInComp-Title': 'Anoestrus length',
    // 'DOA-CompInComp-XAxis-Title': 'Days from Calving',
    // 'DOA-CompInComp-YAxis-Title': '#Cows',
    // 'DOA-CompInComp-SeriesName-Complete': 'Cyclic cows',
    // 'DOA-CompInComp-SeriesName-InComplete': 'Non-cyclic cows',
    // 'DOA-CompInComp-ToolTip-Title': 'Anoestrus length',
    // 'DOA-CompInComp-ToolTip-Complete': 'Cyclic',
    // 'DOA-CompInComp-ToolTip-InComplete': 'Non-cyclic',
    // 'DOA-CompInComp-ToolTip-StartDate': 'Period start:',
    // 'DOA-CompInComp-ToolTip-StopDate': 'Period stop:',
    // 'FS-Label-SelectedFarm': 'Selected farm',
    // 'FS-Button-LoadFarm': 'Load Farm',
    // 'HV-NavbarItem-Header-Home': 'Home',
    // 'HV-NavbarItem-Header-FarmSelection': 'Farms',
    // 'HV-NavbarLink-Header-Paths': 'Key Figures',
    // 'HV-NavbarDropDown-Element-DOA': 'Duration of Anoestrus',
    // 'HV-NavbarDropDown-Element-CRBWP': 'Cycle Regularity',
    // 'HV-NavbarDropDown-Element-DAI': 'Days to First Insemination',
    // 'HV-NavbarDropDown-Element-AIS': 'Insemination Success',
    // 'HV-NavbarDropDown-Element-SEA': 'Successful Embryo Attachment',
    // 'HV-NavbarDropDown-Element-PBSS': 'Successful Establishment of Pregnancy',
    // 'HV-NavbarDropDown-Element-OD': 'Open Days',
    // 'HV-NavbarDropDown-Element-Library-DOA': 'Duration of Anoestrus',
    // 'HV-NavbarDropDown-Element-Library-CR': 'Cycle Regularity',
    // 'HV-NavbarDropDown-Element-Library-DAI': 'Days to First Insemination',
    // 'HV-NavbarDropDown-Element-Library-AIS': 'Insemination Success',
    // 'HV-NavbarDropDown-Element-Library-SEA': 'Successful Embryo Attachment',
    // 'HV-NavbarDropDown-Element-Library-PBSS': 'Successful Establishment of Pregnancy',
    // 'HV-NavbarDropDown-Element-Library-OD': 'Open Days',
    // 'HV-NavbarDropDown-Element-Language-DK': 'DK',
    // 'HV-NavbarDropDown-Element-Language-EN': 'EN',
    // 'SV-Header': 'Settings',
    // 'CF-Cow Filter': 'Cow Filter',
    // 'LI-Label-ErrorInUserNameOrPassword': 'Error in Username or Password',
    // 'LI-Label-Email': 'Email',
    // 'LI-Input-UserNameHint': 'Username Hint',
    // 'LI-Label-Password': 'Password',
    // 'LI-Button-LogIn': 'Login',
    // 'LO-Label-GoodBye': 'Goodbye - see you later ;-)',
    // 'OD-PageInfo-Title': 'Open Days',
    // 'OD-PageInfo-CurrentScore': 'Current Score',
    // 'OD-UserInteraction-Button-AdvicePage': 'Checklist',
    // 'OD-UserInteraction-Button-FactSheet': 'Fact sheet',
    // 'OD-UserInteraction-Button-CowDetails': 'Cow details',
    // 'OD-CICPSummary-Title': 'Open Days: Development over time',
    // 'OD-CICPSummary-SeriesName-ICPSummary': 'Distribution (Open and Bred cows)',
    // 'OD-CICPSummary-SeriesName-ICPMedian': 'Predicted open days',
    // 'OD-CICPSummary-SeriesName-CMedian': 'Open days',
    // 'OD-CICPSummary-XAxis-Title': 'Evaluation Date',
    // 'OD-CICPSummary-YAxis-Title': 'Days from calving',
    // 'OD-CICPSummary-ToolTip-Title': 'evaluation',
    // 'OD-CICPSummary-ToolTip-BinDataBeginDate': 'Period start:',
    // 'OD-CICPSummary-ToolTip-BinDataEndDate': 'Period end:',
    // 'OD-CICPSummary-ToolTip-IncompleteCowsInBin': 'Open:',
    // 'OD-CICPSummary-ToolTip-PotentialCowsInBin': 'Bred:',
    // 'OD-CICPSummary-ToolTip-CompleteCowsInBin': 'Pregnant:',
    // 'OD-CICPSummary-ToolTip-Open': '25 Percentile:',
    // 'OD-CICPSummary-ToolTip-Close': '75 Percentile:',
    // 'OD-CICPSummary-ToolTip-Median': 'Predicted Days Open:',
    // 'OD-CICPSummary-ToolTip-MedianComplete': 'Open Days:',
    // 'OD-CICPSummary-ToolTip-ScoreValue': 'Score:',
    // 'OD-AIandPregnancy-Title': 'Pregnancies relative to Insemination number',
    // 'OD-AIandPregnancy-XAxis-Title': 'Insemination number',
    // 'OD-AIandPregnancy-YAxis-Title': '#Inseminations',
    // 'OD-AIandPregnancy-SeriesName-Complete': 'Pregnant',
    // 'OD-AIandPregnancy-SeriesName-InComplete': 'Non-pregnant',
    // 'OD-AIandPregnancy-SeriesName-Potential': 'Potential pregnant',
    // 'OD-AIandPregnancy-ToolTip-Title': 'Pregnancies',
    // 'OD-AIandPregnancy-ToolTip-Complete': 'Pregnant:',
    // 'OD-AIandPregnancy-ToolTip-InComplete': 'Non-pregnant:',
    // 'OD-AIandPregnancy-ToolTip-Potential': 'Potentially pregnant:',
    // 'OD-AIandPregnancy-ToolTip-StartDate': 'Start date:',
    // 'OD-AIandPregnancy-ToolTip-StopDate': 'Stop date:',
    // 'OD-DFCandPregnancy-Title': 'Open Days',
    // 'OD-DFCandPregnancy-XAxis-Title': 'Days from Calving',
    // 'OD-DFCandPregnancy-YAxis-Title': '#Cows',
    // 'OD-DFCandPregnancy-SeriesName-Complete': 'Pregnant',
    // 'OD-DFCandPregnancy-SeriesName-InComplete': 'Predicted (Non-pregnant)',
    // 'OD-DFCandPregnancy-SeriesName-Potential': 'Potential pregnant',
    // 'OD-DFCandPregnancy-ToolTip-Title': 'Open Days',
    // 'OD-DFCandPregnancy-ToolTip-Complete': 'Pregnant:',
    // 'OD-DFCandPregnancy-ToolTip-InComplete': 'Predicted (Non-pregnant):',
    // 'OD-DFCandPregnancy-ToolTip-Potential': 'Potential pregnant:',
    // 'OD-DFCandPregnancy-ToolTip-StartDate': 'Start date:',
    // 'OD-DFCandPregnancy-ToolTip-StopDate': 'Stop date:',
    // 'PBSS-PageInfo-Title': 'Successful Establishment of Pregnancy',
    // 'PBSS-PageInfo-CurrentScore': 'Current Score',
    // 'PBSS-UserInteraction-Button-AdvicePage': 'Checklist',
    // 'PBSS-UserInteraction-Button-FactSheet': 'Fact sheet',
    // 'PBSS-UserInteraction-Button-CowDetails': 'Cow details',
    // 'PBSS-CompInCompHistory-Title': 'Successful Establishment of Pregnancy',
    // 'PBSS-CompInCompHistory-SeriesName-Complete': 'Successful',
    // 'PBSS-CompInCompHistory-SeriesName-InComplete': 'Early abortion',
    // 'PBSS-CompInCompHistory-yAxisTitle': 'Percentage',
    // 'PBSS-CompInCompHistory-ToolTip-Title': 'Establishment of pregnancy',
    // 'PBSS-CompInCompHistory-ToolTip-Date': 'Date:',
    // 'PBSS-CompInCompHistory-ToolTip-Complete': 'Pregnant:',
    // 'PBSS-CompInCompHistory-ToolTip-InComplete': 'Early abortion:',
    // 'PBSS-CompInCompHistory-ToolTip-ScoreValue': 'Score:',
    // 'SEA-EmbryoAttachment-Title': 'Successful Embryo Attachment',
    // 'SEA-EmbryoAttachment-XAxis-Title': 'Days from Calving',
    // 'SEA-EmbryoAttachment-YAxis-Title': '#Cows',
    // 'SEA-EmbryoAttachment-SeriesName-Complete': 'Successful',
    // 'SEA-EmbryoAttachment-SeriesName-InComplete': 'Early embryo death',
    // 'SEA-EmbryoAttachment-ToolTip-Title': 'Embryo Attachment',
    // 'SEA-EmbryoAttachment-ToolTip-Complete': 'Successful:',
    // 'SEA-EmbryoAttachment-ToolTip-InComplete': 'Early embryo death:',
    // 'SEA-EmbryoAttachment-ToolTip-StartDate': 'Period start:',
    // 'SEA-EmbryoAttachment-ToolTip-StopDate': 'Period stop:',
    // 'PBSS-Pregnancy-Title': 'Successful Establishment of Pregnancy',
    // 'PBSS-Pregnancy-XAxis-Title': 'Days from Calving',
    // 'PBSS-Pregnancy-YAxis-Title': '#Cows',
    // 'PBSS-Pregnancy-SeriesName-Complete': 'Successful',
    // 'PBSS-Pregnancy-SeriesName-InComplete': 'Early abortion',
    // 'PBSS-Pregnancy-ToolTip-Title': 'Establishment of Pregnancy',
    // 'PBSS-Pregnancy-ToolTip-Complete': 'Successful:',
    // 'PBSS-Pregnancy-ToolTip-InComplete': 'Early abortion:',
    // 'PBSS-Pregnancy-ToolTip-StartDate': 'Period start:',
    // 'PBSS-Pregnancy-ToolTip-StopDate': 'Period stop:',
    // 'RP-PageInfo-Title': 'Reproduction performance',
    // 'RP-Chart-Empty': '',
    // 'RP-LactationDist-Chart-Title': 'Lactation distribution',
    // 'RP-LactationDist-Chart_AllAnimals': 'All animals',
    // 'RP-LactationDist-ToolTip-Title': 'Lactation distribution',
    // 'RP-LactationDist-ToolTip-X': 'Lactation:',
    // 'RP-LactationDist-ToolTip-Y': 'Cows:',
    // 'RP-LactationDist-ToolTip-PercentageOf': 'Percentage of herd:',
    // 'RP-ReproStatusDist-Chart-Title': 'Reproduction status distribution',
    // 'RP-ReproStatusDist-Chart_AllAnimals': 'All animals',
    // 'RP-ReproStatusDist-ToolTip-Title': 'Reproduction status distribution',
    // 'RP-ReproStatusDist-ToolTip-X': 'Status:',
    // 'RP-ReproStatusDist-ToolTip-Y': 'Cows:',
    // 'RP-ReproStatusDist-ToolTip-PercentageOf': 'Percentage of herd:',
    // 'SEA-PageInfo-Title': 'Successful Embryo Attachment',
    // 'SEA-PageInfo-CurrentScore': 'Current Score',
    // 'SEA-UserInteraction-Button-AdvicePage': 'Checklist',
    // 'SEA-UserInteraction-Button-FactSheet': 'Fact sheet',
    // 'SEA-UserInteraction-Button-CowDetails': 'Cow details',
    // 'SEA-CompInCompHistory-Title': 'Successful Embryo Attachment: Development over time',
    // 'SEA-CompInCompHistory-SeriesName-Complete': 'Successful',
    // 'SEA-CompInCompHistory-SeriesName-InComplete': 'Early embryo death',
    // 'SEA-CompInCompHistory-yAxisTitle': 'Percentage',
    // 'SEA-CompInCompHistory-ToolTip-Title': 'Embryo Attachment',
    // 'SEA-CompInCompHistory-ToolTip-Date': 'Evaluation Date:',
    // 'SEA-CompInCompHistory-ToolTip-Complete': 'Successful:',
    // 'SEA-CompInCompHistory-ToolTip-InComplete': 'Early embryo death:',
    // 'SEA-CompInCompHistory-ToolTip-ScoreValue': 'Score:',
    // 'SV-Header-Ketosis': 'Ketosis',
    // 'SV-Label-ThresholdKetosis': 'Threshold - Ketosis',
    // 'SV-Header-Reproduction': 'Reproduction',
    // 'SV-Label-ThresholdAnoestrus': 'Threshold - Anoestrus',
    // 'SV-Label-ThresholdLutealCyst': 'Threshold - Luteal cyst',
    // 'SV-Label-ThresholdFollicleCyst': 'Threshold - Follicle cyst',
    // 'SV-Label-VoluntaryWaitingPeriodL1': 'Voluntary waiting period (lactation 1)',
    // 'SV-Label-VoluntaryWaitingPeriodL2P': 'Voluntary waiting period (lactation 2 or more)',
    // 'SV-Label-DaysToPregnancyLagTime': 'Days to pregnancy - Lag time',
    // 'SV-Label-DaysToPregnancyPeriodBackward': 'Days to pregnancy - Period backward',
    // 'SV-Header-Mastitis': 'Mastitis',
    // 'SV-Label-ThresholdMastitis': 'Threshold - Mastitis',
    // 'SV-Header-ScoreRefType': 'Reference',
    // 'SV-Label-SelectedScoreRefType': 'Reference group',
    // 'ReplacementRate': 'Replacement rate',
    // 'Lactation1Rate': 'Lactation 1 rate',
    // 'AgeOfActiveCows': 'Age of active cows',
    // 'AgeAtCulling': 'Age at culling',
    // 'PregnancyRateAt120DFC': 'Proportion pregnant at 120 DIM',
    // 'PregnancyRate21Days': 'Pregnancy rate 21 days',
    // 'LactationNumberOfActiveCows': 'Lactation number of active cows',
    // 'LactationNumberAtCulling': 'Lactation number at culling',
    // "Success-on-first-ai": "Success on first AI",
    // "Regular-cycles": "Regular cycles",
    // "Early-abortions": "Early abortions",
    // "Stick-consumption-title": "Stick consumption",
    // "Proportion-of-delayed-samples-title": "Proportion of delayed samples",
    // "Zero-measurement": "Zero measurement",
    // "Current-reproduction-performance": "Current reproduction performance",
    // "Sticks-per-pregnancy": "Sticks / pregnancy",
    // "TopTenCowsTitle": "Top 10 high consumers",
    // "Cow no": "Cow number",
    // "Go-back": "Go back",
    // "Settings": "Settings",
    // "Log-out": "Log out",
    // "Reference-data-label": "Reference",
    // "Farm-data-label": "Farm data",
    // Sticks used
    // "SticksUsedPrCow": "Average sticks per cow",
    // "SticksUsedPrPregnancy": "Average sticks per pregnancy",
    // "SticksUsedFromFirstToLastAI": "Average sticks from first to last AI",
    // "SticksUsedOnFollicularCysts": "Average sticks on follicular cysts",
  };

  export default da;