import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, CardGuide, CardHeader, CardHeadline } from "../../Card";
import { BarAreaStacked, Line } from "../../Charts";
import { SetBarNormal, SetBarHovered, SetBarSelected } from "../../Charts/BarAreaStacked";
import { ContentHeader, ContentHeadline } from "../../Content";
import { GET_COWS_LIST_REQUEST, HANDLE_FILTER_COW_LIST_INITIALIZE_LOCAL, HANDLE_COW_LIST_SELECT, HANDLE_COW_LIST_HOVERED } from "../../redux/CowsList/constants";
import { SELECT_DATA_TME_PERIOD, SELECT_LACTATION_FILTERS } from "../../redux/Filters/constants";
import { IFilterState } from "../../redux/Filters/reducer";
import { GET_OD_REQUEST } from "../../redux/OD/constants";
import { OPEN_COW_LIST, SET_LIBRARY_OPEN_URL } from "../../redux/Navigation/constants";
import { handleTriggerStartTour } from "../../utills/intercom";
import { GET_PERFORMANCE_SCORE_HISTORY_REQUEST } from "../../redux/PerformanceScoreHistory/constants";
import KPIPageFilters from "../../KPIPageFilters";
import LattecIcon from "../../LattecIcon";
import { Column, Row } from "../../Layout";
import OverView12Month from "../../Cards/OverView12Month"
import { BarExtendedDatum } from "@nivo/bar";

const OD: React.FC = (innerPRops) => {

    const odState = useSelector((state: any) => state.odState);
    const filtersState: IFilterState = useSelector((state: any) => state.filtersState);
    const cowsListState = useSelector((state: any) => state.cowsListState);
    const performanceScoreHistoryState = useSelector((state: any) => state.performanceScoreHistoryState);
    const [secondaryExpandedState, handleToggleSecondaryExpandedState] = useState(false);

    const dispatch = useDispatch();

    const keys = ["OpenDays", "CompletionStatus"];
    const filters: any = {};

    useEffect(() => {
        const today = new Date();
        const oneYearAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

        const startDate = new Date(filtersState.today);
        startDate.setDate(startDate.getDate() - filtersState.anoestrusLengthSelectedTimePeriod.days);

        filtersState.lactationFilters.culledChecked = filtersState.lactationFilters.l1Checked = filtersState.lactationFilters.l2Checked = filtersState.lactationFilters.l3Checked = true;

        // get the cowlist
        dispatch({
            type: GET_COWS_LIST_REQUEST,
            payload: {
                filters,
                keys,
                path: "od",
                startDate: startDate,
                stopDate: filtersState.today,
                lactationFilters: filtersState.lactationFilters,
            },
        });

        dispatch({ type: GET_PERFORMANCE_SCORE_HISTORY_REQUEST, payload: { kpi: "OD" } });
        dispatch({ type: GET_OD_REQUEST, payload: { startDate: oneYearAgo, stopDate: today } });
    }, [dispatch, filtersState.anoestrusLengthSelectedTimePeriod, filtersState.today]);

    const handleSelectTimePeriod = (e: any, key: string) => dispatch({ type: SELECT_DATA_TME_PERIOD, payload: { index: e.target.selectedIndex, key } });

    const handleToggleLactationFilter = (key: string) => {
        SetBarNormal(cowsListState.selected)
        dispatch({ type: HANDLE_COW_LIST_SELECT, payload: undefined });

        dispatch({ type: SELECT_LACTATION_FILTERS, payload: { key } });
        const newFilter = filtersState.lactationFilters;
        newFilter[`${key}Checked`] = !newFilter[`${key}Checked`];
        dispatch({
            type: HANDLE_FILTER_COW_LIST_INITIALIZE_LOCAL,
            payload: {
                filters,
                keys,
                path: "od",
                data: cowsListState.data,
                lactationFilters: newFilter,
                p2reference: cowsListState.p2reference,
                p3reference: cowsListState.p3reference,
            },
        });
    }

    const handleSetCowListFilter = (filters: any, keys: string[]) => {
        dispatch({
            type: HANDLE_FILTER_COW_LIST_INITIALIZE_LOCAL,
            payload: {
                filters: (cowsListState.selected === cowsListState.hovered ? {} : filters),
                keys,
                path: "od",
                data: cowsListState.data,
                lactationFilters: filtersState.lactationFilters,
                p2reference: cowsListState.p2reference,
                p3reference: cowsListState.p3reference,
            },
        });
    }

    const handleShowCows = (elem: any) => {
        if (cowsListState.selected !== cowsListState.hovered) {
            if (cowsListState.hovered !== undefined) {
                // set selected
                SetBarSelected(cowsListState.hovered);
            }
            if (cowsListState.selected !== undefined) {
                // clear selected
                SetBarNormal(cowsListState.selected);
            }
            dispatch({ type: HANDLE_COW_LIST_SELECT, payload: cowsListState.hovered });
        } else {
            if (cowsListState.selected !== undefined) {
                // de-select selected, so set hovered
                SetBarHovered(cowsListState.selected)
            }
            dispatch({ type: HANDLE_COW_LIST_SELECT, payload: undefined });
        }

        dispatch({ type: OPEN_COW_LIST });
    }

    const handleMouseEnter = (elem: BarExtendedDatum, event: React.MouseEvent) => {
        dispatch({ type: HANDLE_COW_LIST_HOVERED, payload: event.currentTarget });
    };

    const displayData: any = [];
    const colorArray = [];
    // (innerPRops: any) => {
    //     return (
    //         <circle
    //             r={innerPRops.size / 5}
    //             strokeWidth={innerPRops.borderWidth}
    //             stroke={innerPRops.borderColor}
    //             fill={innerPRops.color}
    //             fillOpacity={0.35}
    //         />
    //     )
    // }
    if (filtersState.lactationFilters.lAllChecked) {
        displayData.push(odState.graphData[0]);
        displayData.push(odState.graphData[1]);
        colorArray.push("#1FA5FF");
        colorArray.push("#0062A3");
    }
    if (filtersState.lactationFilters.l1Checked) {
        displayData.push(odState.graphData[2])
        displayData.push(odState.graphData[3])
        colorArray.push("#1FA5FF")
        colorArray.push("#0062A3")
    }
    if (filtersState.lactationFilters.l2Checked) {
        displayData.push(odState.graphData[4])
        displayData.push(odState.graphData[5])
        colorArray.push("#1FA5FF")
        colorArray.push("#0062A3")
    }
    if (filtersState.lactationFilters.l3Checked) {
        displayData.push(odState.graphData[6])
        displayData.push(odState.graphData[7])
        colorArray.push("#1FA5FF")
        colorArray.push("#0062A3")
    }

    const handleSetCustomIcon = (innerPRops: any) => {

        if (innerPRops.datum.xFormatted !== undefined && innerPRops.datum.shape === "circle") {
            // render circle


            return (
                <circle
                    r={innerPRops.size / 5}
                    strokeWidth={0}
                    stroke={innerPRops.borderColor}
                    fill={innerPRops.color}
                    fillOpacity={1}
                />
            )
        } else if (innerPRops.datum.xFormatted !== undefined && innerPRops.datum.shape === "plus") {
            return <LattecIcon icon={"plus"} color={innerPRops.datum.color} size={innerPRops.size + 3} />;
        } else if (innerPRops.datum.xFormatted !== undefined && innerPRops.datum.shape === "cross") {
            return <LattecIcon icon={"cross"} color={innerPRops.datum.color} size={innerPRops.size + 3} />;
        } else if (innerPRops.datum.xFormatted !== undefined && innerPRops.datum.shape === "triangle") {
            return <LattecIcon icon={"triangle"} color={innerPRops.datum.color} size={innerPRops.size + 3} />;

        } else {
            return (
                <circle
                    r={innerPRops.size / 5}
                    strokeWidth={innerPRops.borderWidth}
                    stroke={innerPRops.borderColor}
                    fill={innerPRops.color}
                    fillOpacity={0.35}
                />
            )
        }
        // data75

        // if (filtersState.lactationFilters.l2Checked){

        // }
        // if (filtersState.lactationFilters.l3Checked){

        // }
    }


    // // we need to set some conditions to make sure the line chart knows about what shape it should render

    const handleSetUrl = (url: string) => dispatch({ type: SET_LIBRARY_OPEN_URL, payload: url });

    return (
        <Fragment>
            <Row first={true} flex={1}>
                <Column flex={3} first={true}>
                    <ContentHeader>
                        <ContentHeadline text="Projected days open" subHeader="Evaluates herd performance on days open of non pregnant cows based on their projected days open." />
                        <KPIPageFilters hasAllFilter={true} handleSelectTimePeriod={handleSelectTimePeriod} filtersState={filtersState} handleToggleLactationFilter={handleToggleLactationFilter} />
                    </ContentHeader>
                </Column>
                <Column flex={2}>
                    <OverView12Month
                        performanceScore={performanceScoreHistoryState}
                        stepGuide={178822}
                        kpi={"od"}
                    />
                </Column>
            </Row>
            <Row last={true} flex={3}>
                <Column first={true} flex={secondaryExpandedState ? 1 : 3}>
                    <Card>
                        <CardHeader>
                            <CardHeadline text={`Overview of days open the past 12 months`} />
                            <CardGuide
                                onOpenLib={() => handleSetUrl("od/lvl2/summary")}
                                openStepGuide={() => handleTriggerStartTour(178816)}
                                toggleExpand={() => handleToggleSecondaryExpandedState(!secondaryExpandedState)}
                                isExpanded={!secondaryExpandedState}
                            />
                        </CardHeader>
                        <CardContent>
                            <Line
                                customSymbol={handleSetCustomIcon}
                                data={displayData}
                                id="Score"
                                yLegend="Percentil"
                                yScale={{ type: "linear", min: 0, max: "auto", stacked: false, reverse: false }}
                                curve="linear"
                                disableArea={true}
                                lineWidth={3}
                                enablePoints={true}
                                colors={colorArray}
                                enableGridY={true}
                                margin={{ top: 60, right: 60, bottom: 90, left: 60 }}
                                Llegend={"Days open"}
                            />
                        </CardContent>
                    </Card>
                </Column>
                <Column last={true} flex={secondaryExpandedState ? 3 : 1}>
                    <Card>
                        <CardHeader>
                            <CardHeadline text="Insemination number" />
                            <CardGuide
                                openStepGuide={() => handleTriggerStartTour(178817)}
                                toggleExpand={() => handleToggleSecondaryExpandedState(!secondaryExpandedState)}
                                isExpanded={secondaryExpandedState}
                            />
                        </CardHeader>
                        <CardContent>
                            <BarAreaStacked
                                filter={(filters: any) => handleSetCowListFilter(filters, ["MAXAINumber", "CompletionStatus"])}
                                filterKeys={[{ key: "CompletionStatus", value: "id" }, { key: "MAXAINumber", value: "indexValue" }]}
                                tooltipHeadline={`Insemination Number`}
                                data={cowsListState.graphP3Data}
                                keys={["Pregnant", "Potentially pregnant", "Non-pregnant"]}
                                indexBy="Bin"
                                yLegend="Number of cows"
                                xLegend="Insemination number"
                                onClick={(elem: any) => handleShowCows(elem)}
                                maxValue={cowsListState.graphP3Max}
                                onMouseEnter={(elem: BarExtendedDatum, event: React.MouseEvent) => handleMouseEnter(elem, event)}
                                selected={cowsListState.selected}
                            />
                        </CardContent>
                    </Card>
                </Column>
            </Row>
        </Fragment>
    );
};

export default OD;
