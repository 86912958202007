import { ResponsiveLine } from "@nivo/line";
import React, { useLayoutEffect, useRef, useState } from "react";
import { defaultColorPalette } from "../../styles/ColorCodes";
import Tooltip from "../../Tooltip";
import { iKPISpider } from "./interface";

const LineChart: React.FC<iKPISpider> = (props) => {

  /*
  All: circles
L1: +
L2: x
L3: trekant, ikke udfyldt. 
  */
  const handleSetCustomSymbol = (innerPRops: any) => props.customSymbol?.(innerPRops); // {

  const targetRef = useRef<HTMLHeadingElement>(null);
  const [dimensions, setDimensions] = useState({ height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current && targetRef.current.parentElement) {
      setDimensions({
        height: targetRef.current.parentElement.offsetHeight,
      });
    }
  }, []);

  return (
    <div ref={targetRef} style={{ height: `${dimensions.height}px`, width: `99%` }}>
      <ResponsiveLine
        tooltip={(el: any) => <Tooltip addStyles={true} point={el.point} headline={"Overview of the past 12 months"} xLabel={"Date"} />}
        colors={props.colors || defaultColorPalette}
        //data={props?.data?.[0].id != undefined ? [{ id: 'props.id', data: props.data }] : []} the above line does not correspond to the line below, and the option of using props.data has gone
        data={props.data[0] === undefined || props.data[0].id === undefined ? [{ id: props.id, data: props.data }] : props.data}
        margin={props.margin || { top: 5, right: 0, bottom: 0, left: 0 }}
        xScale={{ type: "point" }}
        yScale={props.yScale || { type: "linear", min: 0, max: 100, stacked: false, reverse: false }}
        curve={props.curve || "cardinal"}
        axisTop={null}
        axisRight={null}
        pointSymbol={props.customSymbol ? handleSetCustomSymbol : undefined}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 90,
          legend: props.xLegend,
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: props.Llegend || "count",
          legendOffset: -40,
          legendPosition: "middle"
        }}
        enableGridX={false}
        enableGridY={props.enableGridY || false}
        lineWidth={props.lineWidth || 0}
        enablePoints={props.enablePoints || false}
        pointSize={8}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={8}
        pointBorderColor={{ from: "serieColor" }}
        pointLabel="y"
        pointLabelYOffset={-24}
        enableArea={props.disableArea ? false : true}
        areaOpacity={.8}
        useMesh={true}
        legends={[]}
        onClick={(point: any, event: any) => {
          if (props.onClick) {
            props.onClick(point, event);
          }
        }}
      />
    </div>
  );
};

export default LineChart;
