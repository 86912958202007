import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { postXML } from "../../api/requests";

import {
    GET_PERFORMANCE_SCORE_HISTORY_REQUEST,
    GET_PERFORMANCE_SCORE_HISTORY_SUCCESS,
    GET_PERFORMANCE_SCORE_HISTORY_FAILURE,
} from "./constants";

const weekNumber = (date: any): number => {
    let d: any = new Date();
    d.setFullYear(date.split('-')[0]);
    d.setMonth(date.split('-')[1] - 1);
    d.setDate((date.split('-')[2]).split('T')[0]);

    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));

    return Math.ceil((((d) / 86400000) + 1) / 7);
}

function* getPerformanceScoreHistorySaga(action: Action<any>): SagaIterator {
    try {
        const data = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/performancescorehistory", {
            KPIName: action.payload.kpi,
        });

        // sort the data on date ascending
        const dataSorted = data.sort((a: any, b: any) => (a.ScoreDate > b.ScoreDate ? 1 : -1));

        let scoreDate: any = null;
        let scoreValue: any = null;
        dataSorted.forEach((element: any) => {
            if (element.ScoreValue) {
                scoreDate = element.ScoreDate;
                scoreValue = element.ScoreValue;
            }
        });

        // keep only data points, where there are no data, until we hit data, then discard null point, or after last data, 
        // meaning delete data point in the middle of the range, to avoid graph "jumping up and down"
        let gotData = false;
        let lastData = false;
        let dataFilter = dataSorted.filter(function (item: any) {
            gotData = gotData || item.ScoreValue;
            lastData = lastData || scoreDate == item.ScoreDate;
            return lastData || !gotData || item.ScoreValue !== null;
        })

        // return the data in the list as we want them
        const score = dataFilter.map((el: any) => {
            return {
                date: `${new Date(el.ScoreDate).toLocaleDateString("en-GB")}`,
                x: weekNumber(el.ScoreDate),
                y: Number(el.ScoreValue).toFixed(0),
            };
        });

        // return the data in the list as we want them
        const benchmark = dataFilter.map((el: any) => {
            return {
                date: `${new Date(el.ScoreDate).toLocaleDateString("en-GB")}`,
                x: weekNumber(el.ScoreDate),
                y: Number(el.RefScoreBenchmarkFarms).toFixed(0),
            };
        });

        yield put({ type: GET_PERFORMANCE_SCORE_HISTORY_SUCCESS, payload: { data: [{ id: "Score", data: score }, { id: "Benchmark farms", data: benchmark }], scoreValue, scoreDate } });
    } catch (error) {
        console.log(GET_PERFORMANCE_SCORE_HISTORY_FAILURE, error);
        yield put({ type: GET_PERFORMANCE_SCORE_HISTORY_FAILURE, payload: "Unable to get data" });
    }
}

export function* watchGetPerformanceScoreHistorySaga(): SagaIterator {
    yield takeEvery(GET_PERFORMANCE_SCORE_HISTORY_REQUEST, getPerformanceScoreHistorySaga);
}
