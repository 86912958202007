import React from "react";
import "../Card.scss";
import { useHistory } from "react-router-dom";
import { iHeader } from "./interface";

const CardHeadline: React.FC<iHeader> = (props) => {
    const history = useHistory();

    const handleNavigate = props.onClick ? props.onClick : props.link ? () => { props.link && history.push(props.link) } : undefined;

    return (
        <div className="card__headline-wrapper">
            <h2 className={`card__headline ${handleNavigate ? "card__headline--link" : ""}`} onClick={handleNavigate}>
                {props.icon}
                <span style={{ marginLeft: "9px" }}>{props.text}</span>
                {(handleNavigate) &&
                    <span style={{ marginLeft: "9px", fontSize: "13px" }}>
                        <i className="fas fa-chevron-right" />
                    </span>
                }
            </h2>
            {props.children}
        </div>
    );
};

export default CardHeadline;
