import React, { Fragment } from "react";
import { ContentHeader, ContentHeadline, ContentWrapper } from "../../Content";
import { useTranslation } from 'react-i18next';
import { CurrentReproductionPerformance, BaselineReport } from "../../Cards";
import { Column, Row } from "../../Layout";
import { DataTypeToggle } from "../../DataType";
import { BASELINE_REPORT_TOGGLE } from "../../redux/Navigation/constants";


const BaselineReportPage:React.FC = (props) => {
    const { t } = useTranslation();

    // i18n.changeLanguage("da");

    return (
        <Fragment>
            <ContentHeader>
                <ContentHeadline text={t("BaselineReport")} subHeader={t("BaselineReportSubHeader")} >
                <DataTypeToggle toogleId={BASELINE_REPORT_TOGGLE}/>
                </ContentHeadline>
            </ContentHeader>
            <ContentWrapper>
                <Row first={true}>
                    <Column first={true}>
                        <BaselineReport headers={[t("KPI"), t("BaselineStart"), t("Today"), "", ""]} toogleId={BASELINE_REPORT_TOGGLE}/>
                    </Column>
                    <Column last={true}>
                        <CurrentReproductionPerformance toogleId={BASELINE_REPORT_TOGGLE} simple={true} />
                    </Column>
                </Row>
            </ContentWrapper>
        </Fragment>
    );
};

export default BaselineReportPage;
