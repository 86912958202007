import { ActionType } from "typesafe-actions";
import * as actions from "../../actions/DOA/DOACompInCompDetailAction";
import { GET_ANOESTRUS_LENGTH_REQUEST, GET_ANOESTRUS_LENGTH_SUCCESS, GET_ANOESTRUS_LENGTH_FAILURE } from "./constants";

export type DOACompDetailAction = ActionType<typeof actions>;

export interface IState {
    startDate: Date;
    stopDate: Date;
    gotData: boolean;
    loading: boolean;
    error: null | string;
    data: any;
}

export const initialState: IState = {
    gotData: false,
    loading: false,
    error: null,
    startDate: new Date(),
    stopDate: new Date(),
    data: [],
};

export const AnoestrusLengthReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case GET_ANOESTRUS_LENGTH_REQUEST:
            return {
                ...state,
                gotData: false,
                loading: true,
                error: null,
            };
        case GET_ANOESTRUS_LENGTH_SUCCESS:

            const newState = {
                ...state,
                loading: false,
                gotData: true,
                data: action.payload,
            };

            return newState;

        case GET_ANOESTRUS_LENGTH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
