import React, { Fragment, useCallback, useEffect, useState, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CurrentReproductionPerformance } from "../Cards";
import { Button } from "../Input";
import { Column, Row } from "../Layout";
import Progressbar from "../Progressbar";
import {
  GET_HNINSIGHTS_QUESTIONS_REQUEST,
  GET_INSIGHTS_LIST_REQUEST,
  GO_TO_PREVIOUS_QUESTION_REQUEST,
  PIN_INSIGHT_TO_DASHBOARD_REQUEST,
  POST_HNINSIGHTS_ANSWER_REQUEST,
  REPLACE_INSIGHT_REQUEST,
  SET_HNINSIGHTS_STAGE,
  SET_SELECTED_ANSWER,
  STAGES,
} from "../redux/HNInsights/constants";
import { initialState } from "../redux/HNInsights/reducer";
import "./Questionaire.scss";
import Loader from "./QuestionaireLoader";
import { AnswerElement } from "./types";
import { DASHBOARD_TOGGLE } from "../redux/Navigation/constants";
import { iQuestionaire, IInsightsElement } from "./interface";
import HoverGuide from "../HoverGuide";
import { IState } from "../redux/HNInsights/types";

// @ts-ignore
import { ReactComponent as VetIcon } from "./icons/HN_icons_Health check-Blue.svg";
// @ts-ignore
import { ReactComponent as FeedIcon } from "./icons/HN_icons_Feed-Blue.svg";
// @ts-ignore
import { ReactComponent as ManageIcon } from "./icons/HN_icons_Herd operations-Blue.svg";
// @ts-ignore
import { ReactComponent as BreedIcon } from "./icons/HN_icons_Insemination-Blue.svg";

const Questionaire: React.FC<iQuestionaire> = (props): JSX.Element => {
  const hnInsightsState: IState = useSelector((state: IState) => state.hnInsightsState);

  const [activeInsight, handleSetActiveInsight] = useState<IInsightsElement | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: GET_HNINSIGHTS_QUESTIONS_REQUEST });
  }, [dispatch]);

  const handleSelectAnswer = (el: any) => dispatch({ type: SET_SELECTED_ANSWER, payload: el });

  const setStage = useCallback((stage: string) => dispatch({ type: SET_HNINSIGHTS_STAGE, payload: stage }), [dispatch]);

  const handleGoToNextQuestion = () => {
    // post answer to server
    // if question id, next question should come from that

    // if sub question next question should be hnInsightsState.questions.open[0]
    // else hnInsightsState.questions.open[1]

    const isSubQuestion =
      (hnInsightsState.questions.open.length > 0)
      && (hnInsightsState.activeQuestion.questionId !== hnInsightsState.questions.open[0].questionId);
    // when moving to next question - we need to also put the sub question into the answered array

    dispatch({
      type: POST_HNINSIGHTS_ANSWER_REQUEST,
      payload: {
        selectedAnswer: hnInsightsState.selectedAnswer,
        nextQuestion: isSubQuestion ? hnInsightsState.questions.open[0] : hnInsightsState.questions.open[1],
        currentStage: hnInsightsState.stage
      }
    });
  };

  const handleGoToPreviousQuestion = () => {

    // Get the last item in the answered array
    const answeredQuestion = hnInsightsState.questions.answered[hnInsightsState.questions.answered.length - 1];

    // set answered question to null
    handleSelectAnswer(initialState.selectedAnswer);

    dispatch({ type: GO_TO_PREVIOUS_QUESTION_REQUEST, payload: answeredQuestion });

    // handleSelectAnswer(initialSelectedAnswer);
  };

  const handleGetAnalysisQuestions = (numOfQestions: number) => {

    // fetch all questions
    dispatch({ type: GET_HNINSIGHTS_QUESTIONS_REQUEST, payload: numOfQestions });
  };

  const handlePinToDashboard = (id: string) => {
    let elem = hnInsightsState.insights.find(x => x.id == id);
    dispatch({ type: PIN_INSIGHT_TO_DASHBOARD_REQUEST, payload: { id, pin: elem?.dashBoard === 0 } });
  };

  const handleReplaceInsight = (id: string) => {
    dispatch({ type: REPLACE_INSIGHT_REQUEST, payload: { id } });
  };

  const allInsightsExpired = hnInsightsState.insights.findIndex(x => x.expired === 0) === -1;

  const renderQuestionaireButtons = (
    <>
      <br />
      <div className="questionaire__actions">
        <div>
          <Button
            onClick={() => props.close()}
            text="No thanks, I will come back later"
          />
        </div>
        <div>
          <Button
            onClick={() => handleGetAnalysisQuestions(3)}
            text="Quick session"
          />
          <Button
            onClick={() => handleGetAnalysisQuestions(0)}
            text="Full session"
          />
        </div>
      </div>
    </>
  )

  const spiders = (
    <Column last={true} flex={6}>
      {activeInsight !== null ? (
        <div className="questionaire__detailed-insights-wrapper">
          <div className="questionaire__detailed-insights-headline-wrapper">
            <h3>{activeInsight.text.header}</h3>
            <Button iconButton={true} onClick={() => handleSetActiveInsight(null)}>
              <i style={{ color: "#062f71" }} className="far fa-times" />
            </Button>
          </div>
          <div className="questionaire__insight-body-wrapper">
            <p className="questionaire__insight-body">{activeInsight.text.body}</p>
          </div>
          <div className="questionaire__detailed-action-list-wrapper">
            <h4 className="questionaire__detailed-action-list-headline">Possible action points</h4>
            {activeInsight.text.actionlist.map((actionListElement: string, index: number) => {
              return (
                <div className="questionaire__detailed-action-list-element" key={`action-list-element-${index}`}>
                  <p className="questionaire__insight-body">- {actionListElement}</p>
                </div>
              )
            })}
          </div>
        </div>
      ) : (
          <div className="spiderContainer">
            <CurrentReproductionPerformance embedded={true} toogleId={DASHBOARD_TOGGLE} />
          </div>
        )}
    </Column>
  )

  const insights = (
    <Row first={true} flex={1} >
      <Column first={true} flex={4}>
        {hnInsightsState.insights.map((el, index) => {
          return (
            <div key={el.key} className={"questionaire__insights" + (el.dashBoard ? " pinned" : "") + (el.expired ? " expired" : "")}>
              <div className={(el.expired ? " expired" : "")} hidden={el.expired === 0} >
                <p>Expired</p>
              </div>

              <div className="questionaire__insight-wrapper">

                <div className="questionaire__insight-headline-wrapper">
                  <h2 className="insight-rank">{index + 1}.</h2>
                  <h3 className="questionaire__insight-headline">{el.text.header}</h3>
                </div>
                <div className="questionaire__insight-body-wrapper">
                  <p className="questionaire__insight-body">{el.text.summary}</p>
                </div>
                <div className="questionaire__insight-bottom">
                  <div className="questionaire__insight-suggestive-icons">
                    {el.text.icon.map((iconElement: string) => {
                      return (
                        <>
                          {iconElement === "Breed" ? (
                            <HoverGuide content="Breeding advisor" direction="right">
                              <div className="questionaire__insight-suggestive-icon-wrapper" key={iconElement}>
                                <BreedIcon height="35" className="icon" />
                              </div>
                            </HoverGuide>
                          ) : iconElement === "Management" ? (
                            <HoverGuide content="Management/Housing advisor" direction="right">
                              <div className="questionaire__insight-suggestive-icon-wrapper" key={iconElement}>
                                <ManageIcon height="35" className="icon" />
                              </div>
                            </HoverGuide>
                          ) : iconElement === "Feed" ? (
                            <HoverGuide content="Feed advisor" direction="right">
                              <div className="questionaire__insight-suggestive-icon-wrapper" key={iconElement}>
                                <FeedIcon height="35" className="icon" />
                              </div>
                            </HoverGuide>
                          ) : iconElement === "Veterinarian" || iconElement === "Vet" ? (
                            <HoverGuide content="Veterinarian" direction="right">
                              <div className="questionaire__insight-suggestive-icon-wrapper" key={iconElement}>
                                <VetIcon height="35" className="icon" />
                              </div>
                            </HoverGuide>
                          ) : null}
                        </>
                      )
                    })}
                  </div>

                  <HoverGuide content="Read more" direction="bottom">
                    <i className="fas fa-ellipsis-h fa-lg read-more" onClick={() => handleSetActiveInsight(el)} />
                  </HoverGuide>
                </div>
              </div>

              <div className="pinBtn">
                <HoverGuide content="Pin to dashboard" direction="right" >
                  <i className={`${el.dashBoard ? 'fas' : 'fal'} fa-thumbtack fa-2x`} style={{ transform: 'rotate(330deg)' }} onClick={() => handlePinToDashboard(el.id)} />
                </HoverGuide>
              </div>
              <div className="replaceBtn">
                <HoverGuide content="Dismiss insight" direction="left">
                  <i className="fa fa-trash" onClick={() => handleReplaceInsight(el.id)} />
                </HoverGuide>
              </div>
            </div>
          );
        })}
        <p className="questionaire__insight-disclaimer">
          *Note that these insights are based on your answers within this session. The insights are meant as a guide towards areas for you to investigate on your own or with your local adviser. If in doubt, always consult your local adviser. DeLaval cannot be held liable for any adverse effects based on this analysis.
        </p>
      </Column>


    </Row>
  );

  return (
    <div className="questionaire">
      {hnInsightsState.loading && !hnInsightsState.insights? (
        <Loader />
      ) : hnInsightsState.error ? (
        <div>{hnInsightsState.error}</div>
      ) : hnInsightsState.status === "config_questions_expired" && hnInsightsState.stage === STAGES.CONFIG_INTRO ? (
        <div className="questionaire__container">
          <h3 className="questionaire__headline">Management and farm setup questions expired</h3>
          <p className="questionaire__info-text">
            It seems to have been a while since you answered your farm management and setup questions.
            <br />
            To make sure that we still present the most accurate insights, let's step through the questions again.
          </p>
          <div className="questionaire__actions__singular">
            <Button
              style={{ float: 'right' }}
              text="Ok, let's go"
              onClick={() => setStage(STAGES.CONFIG_QUESTIONAIRE)}
            />
          </div>
        </div>
      ) : hnInsightsState.stage === STAGES.CONFIG_INTRO ? (
        <Fragment>
          <div className="questionaire__container">
            <h3 className="questionaire__headline">Before we begin...</h3>
            <p className="questionaire__info-text">
              we need to know some basics about your farm management and setup.
              <br />
              <br />
              This helps us give you the most accurate insights.
              <br />
              <br />
              <i>You will only have to answer these questions on your first visit - and after that just a couple of times a year, so please be accurate in your answers.</i>
            </p>
            <div className="questionaire__actions__singular">
              <Button
                style={{ float: 'right' }}
                text="Ok, let's go"
                onClick={() => setStage(STAGES.CONFIG_QUESTIONAIRE)}
              />
            </div>
          </div>
        </Fragment>
      ) : hnInsightsState.stage === STAGES.CONFIG_QUESTIONAIRE ? (
        <Fragment>
          <div className="questionaire__container">
            <h3 className="questionaire__headline">{hnInsightsState.activeQuestion.text}</h3>
            <Progressbar progress={hnInsightsState.questions.answered.length} steps={hnInsightsState.questions.open.length + hnInsightsState.questions.answered.length} />
            <div className="questionaire__answers-list">
              {hnInsightsState.activeQuestion.answerOptions.map((el: AnswerElement, index: number) => {

                const isSelected = hnInsightsState.selectedAnswer.answer.key === el.key;

                return (
                  <div className="questionaire__answer" key={`answer-${index}`} onClick={() => handleSelectAnswer(el)}>
                    <div className={`questionaire__answer-box ${isSelected ? "questionaire__answer-box--selected" : ""}`} />
                    <span className="questionaire__answer-text">{el.text}</span>
                  </div>
                );
              })}
            </div>
            <div className="questionaire__actions">
              <Button
                onClick={handleGoToPreviousQuestion}
                text="Previous"
                disabled={hnInsightsState.questions.answered.length === 0} // disabled if answer array is empty
              />
              <Button
                onClick={handleGoToNextQuestion}
                text="Next"
                disabled={hnInsightsState.selectedAnswer.answer.key === null || hnInsightsState.selectedAnswer.answer.key === undefined}
              />
            </div>
          </div>
        </Fragment>
      ) : hnInsightsState.stage === STAGES.CONFIG_FINISH || (hnInsightsState.stage === STAGES.ANALYSIS_INTRO && hnInsightsState.insights.length < 1) ? (
        <Fragment>
          <div className="questionaire__container">
            <h3 className="questionaire__headline">Management and farm setup completed</h3>
            <p className="questionaire__info-text">
              Awesome, you have answered your farm management and setup questions. Now we can proceed to the questions which will help us towards increasing your cows' reproduction performance - based on their current performance.
            </p>
            <p className="questionaire__insight-body">
              You can chose between a <b>quick session</b> (~5-10 minutes) or a <b>full session</b> (~20-30 minutes). Within each session you will be asked a number of questions about the current situation of the cows on your farm. For example related to body condition, disease occurrence etc.
              <br />
              <br />
              In the quick session you will be asked a small number of questions, while in the full session you will be asked a larger number of questions.
              <br />
              <br />
              <i>Please be accurate in your replies. The more accurate your answers are, the better we can guide you towards where to focus to improve.</i>
            </p>
            {renderQuestionaireButtons}
          </div>
        </Fragment>

      ) : hnInsightsState.stage === STAGES.ANALYSIS_INTRO && hnInsightsState.insights.length > 0 && allInsightsExpired ? (
        <div className="questionaire__container">
          <h3 className="questionaire__headline">Welcome back!</h3>
          <p className="questionaire__info-text">Your insights from last session are no longer up-to-date.</p>
          <p className="questionaire__insight-body">
            Please go through the analysis again to get insights based on your most recent reproduction performance.
          </p>

          {renderQuestionaireButtons}
        </div>
      ) : hnInsightsState.stage === STAGES.ANALYSIS_INTRO && hnInsightsState.insights.length > 0 ? (
        <div className="questionaire__container">
          <h3 className="questionaire__headline">Welcome back!</h3>
          <p className="questionaire__info-text">These are your insights from last time:</p>
          <div className="insight-Wrapper">
            <svg viewBox="0 0 1800 690">
              <foreignObject width="50%" height="100%" x="5%">
                <div className="preview-insights">
                  {insights}
                </div>
              </foreignObject>
              <foreignObject x="55%" width="45%" height="100%" >
                <div className="preview-insights">
                  {spiders}
                </div>
              </foreignObject>
            </svg>
          </div>
          <p className="questionaire__insight-body">
            Do you want to go through the analysis again and see insights based on your most recent reproduction performance?
          </p>

          {renderQuestionaireButtons}
        </div>

      ) : hnInsightsState.stage === STAGES.ANALYSIS_QUESTIONAIRE ? (
        <Fragment>
          <div className="questionaire__container">
            <h3 className="questionaire__headline">{hnInsightsState.activeQuestion.text}</h3>
            <Progressbar progress={hnInsightsState.questions.answered.length} steps={hnInsightsState.questions.open.length + hnInsightsState.questions.answered.length} />
            <div className="questionaire__answers-list">
              {hnInsightsState.activeQuestion.answerOptions.map((el: AnswerElement, index: number) => {

                const isSelected = hnInsightsState.selectedAnswer.answer.key === el.key;

                return (
                  <div className="questionaire__answer" key={`answer-${index}`} onClick={() => handleSelectAnswer(el)}>
                    <div className={`questionaire__answer-box ${isSelected ? "questionaire__answer-box--selected" : ""}`} />
                    <span className="questionaire__answer-text">{el.text}</span>
                  </div>
                );
              })}
            </div>
            <div className="questionaire__actions">
              <Button
                onClick={handleGoToPreviousQuestion}
                text="Previous"
                disabled={hnInsightsState.questions.answered.length === 0}
              />
              <Button
                onClick={handleGoToNextQuestion}
                text="Next"
                disabled={hnInsightsState.selectedAnswer.answer.key === null || hnInsightsState.selectedAnswer.answer.key === undefined}
              />
            </div>
          </div>
        </Fragment>
      ) : hnInsightsState.stage === STAGES.INSIGHTS_LIST ? (
        <Fragment>
          <h3 className="questionaire__headline">Analysis complete!</h3>
          <p className="questionaire__info-text">
            Based on your current reproduction performance and your answers, these are the three most important.
            <br />
            You can pin the insights to the dashboard or you can dismiss an insight, if you believe it is irrelevant.
          </p>
          <div className="questionaire__container">
            <div className="insight-Wrapper">
              <svg viewBox="0 0 1800 690">
                <foreignObject x="5%" width="50%" height="100%" >
                  <div className="preview-insights">
                    {insights}
                  </div>
                </foreignObject>
                <foreignObject x="55%" width="45%" height="100%" >
                  <div className="preview-insights">
                    {spiders}
                  </div>
                </foreignObject>
              </svg>
            </div>
            <Button
              style={{ float: 'right' }}
              onClick={() => props.close()}
              text="Close"
            />
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};

export default Questionaire;
