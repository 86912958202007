import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { IBasicPayload } from "../../actions";

import {
    GET_PROPORTION_OF_DELAYED_SAMPLES_REQUEST,
    GET_PROPORTION_OF_DELAYED_SAMPLES_SUCCESS,
    GET_PROPORTION_OF_DELAYED_SAMPLES_FAILURE,
} from "./constants";

import { postXML } from "../../api/requests";

function* getProportionOfDelayedSamples(action: Action<IBasicPayload>): SagaIterator {
    try {
        const payload = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/herd/dashboard/proportionofdelayedsamples");

        
        yield put({ type: GET_PROPORTION_OF_DELAYED_SAMPLES_SUCCESS, payload: payload[0] });

    } catch (error) {
        console.log(error);
        
        yield put({ type: GET_PROPORTION_OF_DELAYED_SAMPLES_FAILURE, payload: "Unable to get data" });
    }
}

export function* watchGetProportionOfDelayedSamples(): SagaIterator {
    yield takeEvery(GET_PROPORTION_OF_DELAYED_SAMPLES_REQUEST, getProportionOfDelayedSamples);
}
