import * as saga from "./Common/Saga";
import * as utilities from "./Common/Utilities";
import * as cd from "./CowDetails/CDApi";
import * as dateutilities from "./Date/DateUtilities";
import * as farmselection from "./FarmSelection/FarmSelection";
import * as login from "./LogIn/LogIn";

export class Api {
    public static cd = cd;
    public static farmselection = farmselection;
    public static login = login;
    public static utilities = utilities;
    public static saga = saga;
    public static date = dateutilities;
}
