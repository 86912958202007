import { RawRefP2P3DataSource } from "../../components/Common/RefSetDataSource";

export function DeNormalizeReferenceData(rawRefDataSource: RawRefP2P3DataSource[]): RawRefP2P3DataSource[] {

    const deNormalizedRefDataSource = rawRefDataSource.map((element) => {
        return {
            BinNumber: element.BinNumber,
            XAxisDisplay: element.XAxisDisplay,
            YValue: element.YValueL1 + element.YValueL2 + element.YValueL3P,
            YValueL1: element.YValueL1,
            YValueL2: element.YValueL2,
            YValueL3P: element.YValueL3P,
        };
    });

    return deNormalizedRefDataSource;
}
