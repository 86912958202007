import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { IBasicPayload } from "../../actions";

import {
    GET_ADVANCED_BASELINE_REPORT_FAILURE,
    GET_ADVANCED_BASELINE_REPORT_REQUEST,
    GET_ADVANCED_BASELINE_REPORT_SUCCESS,
    GET_SIMPLE_BASELINE_REPORT_REQUEST,
    GET_SIMPLE_BASELINE_REPORT_SUCCESS,
    GET_SIMPLE_BASELINE_REPORT_FAILURE,
} from "./constants";

import { postXML } from "../../api/requests";

function* getAdvancedBaselineReportSaga(action: Action<IBasicPayload>): SagaIterator {
    try {
        const payload = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/herd/zeromeasurement");

        yield put({ type: GET_ADVANCED_BASELINE_REPORT_SUCCESS, payload });
    } catch (error) {
        console.log(GET_ADVANCED_BASELINE_REPORT_FAILURE, error);
        yield put({ type: GET_ADVANCED_BASELINE_REPORT_FAILURE, payload: "Unable to get data" });
    }
}

function* getBasicBaselineReportSaga(action: Action<IBasicPayload>): SagaIterator {
    try {

        const payload = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/herd/dashboard/zeromeasurement");

        yield put({ type: GET_SIMPLE_BASELINE_REPORT_SUCCESS, payload });
    } catch (error) {
        console.log(GET_SIMPLE_BASELINE_REPORT_FAILURE, error);
        yield put({ type: GET_SIMPLE_BASELINE_REPORT_FAILURE, payload: "Unable to get data" });
    }
}

export function* watchGetBaselineReportSaga(): SagaIterator {
    yield takeEvery(GET_ADVANCED_BASELINE_REPORT_REQUEST, getAdvancedBaselineReportSaga);
    yield takeEvery(GET_SIMPLE_BASELINE_REPORT_REQUEST, getBasicBaselineReportSaga);
}
