import * as xmlBuilder from "xmlbuilder";
import { getFarmId, getUsername } from "../utills/authorization";
import { formatDateToISOString } from "../utills/dateFormatting";

export const getXml = (params: any = {}, filterName: string) => {
    const userName = getUsername();
    const farmId = getFarmId();

    // const state = store.getState();


    const xml = xmlBuilder.create(filterName ? filterName : "CowFilter").ele(filterName ? filterName : "CowFilter");

    if (userName) {
        xml.att("UserId", userName);
    }
    
    if (farmId) {
        xml.att("FarmId", farmId);
    }

    if (params.specTag) {
        xml.att("SpecTag", params.specTag);
    }

    if(params.farmGroup) {
        xml.att("FarmGroup", params.farmGroup);
    }

    if (params.dimCowId) {
        xml.att("CowId", params.dimCowId);
    }

    if (params.lactationNo) {
        xml.att("LN", params.lactationNo);
    }

    if (params.startDate) {
        xml.att("StartD", formatDateToISOString(params.startDate));
    }

    if(params.KPIName) {
        xml.att("KPIName", params.KPIName);
    }

    if (params.stopDate) {
        xml.att("StopD", formatDateToISOString(params.stopDate));
    }

    /*
        
        DimBenchmarkFilterPerformanceLevelId: 0,
    */

    if (params.VoluntaryWaitingPeriodL1) {
        xml.att("VoluntaryWaitingPeriodL1", params.VoluntaryWaitingPeriodL1);
    }

    if (params.VoluntaryWaitingPeriodL2P) {
        xml.att("VoluntaryWaitingPeriodL2P", params.VoluntaryWaitingPeriodL2P);
    }

    if (params.DimBenchmarkFilterGeographicalRegionId || params.DimBenchmarkFilterGeographicalRegionId === 0) {
        xml.att("DimBenchmarkFilterGeographicalRegionId", params.DimBenchmarkFilterGeographicalRegionId);
    }

    if (params.DimZeroMeasurementBasisId || params.DimZeroMeasurementBasisId === 0) {
        xml.att("DimZeroMeasurementBasisId", params.DimZeroMeasurementBasisId);
    }

    if (params.DimBenchmarkFilterPerformanceLevelId || params.DimBenchmarkFilterPerformanceLevelId === 0) {
        xml.att("DimBenchmarkFilterPerformanceLevelId", params.DimBenchmarkFilterPerformanceLevelId);
    }

    // TODO: This should not be added by default

    if (!filterName) {
        xml.att("CowId", -1);
    
        xml.att("L1", params.L1 === 0 ? 0 : 1);
        xml.att("L2", params.L2 === 0 ? 0 : 1);
        xml.att("L3P", params.L3P === 0 ? 0 : 1);
        
        xml.att("M", 1);
        xml.att("MISC", 1);
        xml.att("K", 1);
        xml.att("KT", 1);
        xml.att("A", 1);
        xml.att("LC", 1);
        xml.att("FC", 1);
        xml.att("EOD", 1);
        xml.att("LY", 1);
        xml.att("C", 1);
        xml.att("TBC", 1);
        xml.att("HC", 1);
        xml.att("MW", 1);
    }

        // xml.att("L1", params.L1 ? 1 : 0);
    // xml.att("L2", params.L2 ? 1 : 0);
    // xml.att("L3P", params.L3P ? 1 : 0);
    // xml.att("M", params.M ? 1 : 0);
    // xml.att("MISC", params.MISC);
    // xml.att("K", params.K ? 1 : 0);
    // xml.att("KT", params.KT);
    // xml.att("A", params.A ? 1 : 0);
    // xml.att("LC", params.LC ? 1 : 0);
    // xml.att("FC", params.FC ? 1 : 0);
    // xml.att("EOD", params.EOD ? 1 : 0);
    // xml.att("LY", params.LY ? 1 : 0);
    // xml.att("C", params.C ? 1 : 0);
    // xml.att("TBC", params.TBC ? 1 : 0);
    // xml.att("HC", params.HC ? 1 : 0);
    // xml.att("MW", params.MW);
    xml.end({ pretty: true });

    const stringedXML = xml.toString();

    return stringedXML;
};