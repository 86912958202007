import React from "react";
import { iIcon } from "./interface";

const StickConsumptionIcon: React.FC<iIcon> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size ? props.size : "25"} height={props.size ? props.size : "25"} viewBox="0 0 40 40">
    <g id="Stick_Consumption" transform="translate(-279 -401.5)">
        <rect id="Rectangle_666" data-name="Rectangle 666" width={props.size ? props.size : "25"} height={props.size ? props.size : "25"} transform="translate(279 401.5)" fill="none"/>
        <path id="Path_501" data-name="Path 501" d="M289.5,401.5c-.5,2.7-3.8,5.9-3.8,8.7a3.8,3.8,0,0,0,7.6,0C293.3,407.4,290,404.2,289.5,401.5Z" fill={props.color ? props.color : "currentColor"} fillRule="evenodd"/>
        <g id="Group_679" data-name="Group 679">
        <path id="Path_502" data-name="Path 502" d="M286.8,436l-7-4.9,1-.5,6.1,4.2,28-16,.9.6Z" fill={props.color ? props.color : "currentColor"}/>
        <path id="Path_503" data-name="Path 503" d="M286.8,439.5l-6.4-4.5,1-.5,5.5,3.8,27-15.5,1,.7Z" fill={props.color ? props.color : "currentColor"}/>
        <path id="Path_504" data-name="Path 504" d="M300,422.5l-4.6,2.6-3.8-2.7,4.7-2.5,4.1-2.2,11.1-6-2-1.2L279,427l7.8,5.4L317,415.1l-1.9-1.2-11,6.3Zm-5.6,3.2-2.1,1.2-3.9-2.7,2.2-1.2Zm-11.6,1.5,4.6-2.5,3.9,2.7-4.5,2.5Z" fill={props.color ? props.color : "currentColor"}/>
        </g>
    </g>
    </svg>
);

export default StickConsumptionIcon;
