export const FETCH_STICK_CONSUMPTION_CHART_DATA_REQUEST = "@@ddw.herdnet.sandbox.hnadvice/FETCH_STICK_CONSUMPTION_CHART_DATA_REQUEST";
export const FETCH_STICK_CONSUMPTION_CHART_DATA_SUCCESS = "@@ddw.herdnet.sandbox.hnadvice/FETCH_STICK_CONSUMPTION_CHART_DATA_SUCCESS";
export const FETCH_STICK_CONSUMPTION_CHART_DATA_FAILURE = "@@ddw.herdnet.sandbox.hnadvice/FETCH_STICK_CONSUMPTION_CHART_DATA_FAILURE";

export const GET_STICK_CONSUMPTION_REQUEST = "@@ddw.herdnet.sandbox.hnadvice/GET_STICK_CONSUMPTION_REQUEST";
export const GET_STICK_CONSUMPTION_SUCCESS = "@@ddw.herdnet.sandbox.hnadvice/GET_STICK_CONSUMPTION_SUCCESS";
export const GET_STICK_CONSUMPTION_FAILURE = "@@ddw.herdnet.sandbox.hnadvice/GET_STICK_CONSUMPTION_FAILURE";

export const GET_STICKS_PER_COW_REQUEST = "@@ddw.herdnet.sandbox.hnadvice/GET_STICKS_PER_COW_REQUEST";
export const GET_STICKS_PER_COW_SUCCESS = "@@ddw.herdnet.sandbox.hnadvice/GET_STICKS_PER_COW_SUCCESS";
export const GET_STICKS_PER_COW_FAILURE = "@@ddw.herdnet.sandbox.hnadvice/GET_STICKS_PER_COW_FAILURE";

export const GET_TOP_STICK_CONSUMING_COWS_REQUEST = "@@ddw.herdnet.sandbox.hnadvice/GET_TOP_STICK_CONSUMING_COWS_REQUEST";
export const GET_TOP_STICK_CONSUMING_COWS_SUCCESS = "@@ddw.herdnet.sandbox.hnadvice/GET_TOP_STICK_CONSUMING_COWS_SUCCESS";
export const GET_TOP_STICK_CONSUMING_COWS_FAILURE = "@@ddw.herdnet.sandbox.hnadvice/GET_TOP_STICK_CONSUMING_COWS_FAILURE";