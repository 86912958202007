import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { postXML } from "../../api/requests";
import { convertToOverviewChartData } from "../../utills/dataFormatting";

import {
    GET_ANOESTRUS_LENGTH_OVERVIEW_REQUEST,
    GET_ANOESTRUS_LENGTH_OVERVIEW_SUCCESS,
    GET_ANOESTRUS_LENGTH_OVERVIEW_FAILURE,
} from "./constants";
import { AnoestrusLengthSpecTag } from "../../api/SpecTags";

function* getAnoestrusLengthOverviewSaga(action: Action<any>): SagaIterator {
    try {
        const farmData = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/doa/csummary", {
            startDate: action.payload.startDate,
            stopDate: action.payload.stopDate,
            specTag: AnoestrusLengthSpecTag,
        });

        const convertedChartData = convertToOverviewChartData(farmData);

        yield put({ type: GET_ANOESTRUS_LENGTH_OVERVIEW_SUCCESS, payload: convertedChartData });
    } catch (error) {
        console.log(GET_ANOESTRUS_LENGTH_OVERVIEW_FAILURE, error);
        yield put({ type: GET_ANOESTRUS_LENGTH_OVERVIEW_FAILURE, payload: "Unable to get data" });
    }
}

export function* watchGetAnoestrusLengthOverviewSaga(): SagaIterator {
    yield takeEvery(GET_ANOESTRUS_LENGTH_OVERVIEW_REQUEST, getAnoestrusLengthOverviewSaga);
}
