import React from "react";
import "../Layout.scss";
import { iRow } from "./interface";

const Row: React.FC<iRow> = (props) => {
    return (
        <div
            className={`layout__row ${props.first ? "layout__row--first" : ""} ${props.last ? "layout__row--last" : ""}`}
            style={{ flex: props.flex ? props.flex : 1, ...props.style }}
        >
            {props.children}
        </div>
    );
};

export default Row;
