import React, { Fragment } from "react";
import "./Card.scss";
import { iCardPlaceholder } from "./interface";

const CardPlaceholder: React.FC<iCardPlaceholder> = (props) => (
    <Fragment>
        <div className="card-header">
            <div className="card-headline card-headline--placeholder" />
            <div className="card-header-actions card-header-actions--placeholder">
                <div style={{ height: "22px", width: "22px", backgroundColor: "lightgray", borderRadius: "22px" }} />
            </div>
        </div>
        <div className="card-content card-content--placeholder">
            {props.loading && (
                <h5>Loading...</h5>
            )}
        </div>


    </Fragment>
);

export default CardPlaceholder;
