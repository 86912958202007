// Constants
const forWardSlash: string = "/";
const baseUrl: string = "https://HNNEXTUX0301.herdnavigatorlive.com"; // prod server
const farms: string = "farms";
const farm: string = "farm";
const uriCow: string = "cow";
const reproduction: string = "reproduction";
const uriPerformanceScore: string = "performancescoreext";
const lactationDistribution: string = "lactationdistribution";
const reproductionStatusDistribution: string = "reproductionstatusdistribution";
const ux: string = "ux";
const uriRefP1: string = "refp1";
const uriRefP2P3: string = "refp2p3";
const doa: string = "doa";
const crbwp: string = "crbwp";
const dai: string = "dai";
const ais: string = "ais";
const sea: string = "sea";
const pbss: string = "pbss";
const od: string = "od";
const uriCRD: string = "crd";
const settings: string = "settings";
const newSettings: string = "newsettings";
const scoreRefTypes: string = "scorereftypes";
const cicdistribution: string = "cicdistribution";
const csummary: string = "csummary";
const cicpsummary: string = "cicpsummary";
const uriCICDetail: string = "cicdetail";
const uriFolPhaseLength: string = "folphaselength";
const uriCycleLength: string = "cyclelength";
const uriTimingOfInsemination: string = "timingofinsemination";
const uriPregnancy: string = "pregnancy";
const uriPregnancyDetail: string = "pregnancydetail";
const uriEmbryoAttachment: string = "embryoattachment";
const uriFLP: string = "flp";
const uriAIandPregnancy: string = "aiandpregnancy";
const uriDFCandPregnancy: string = "dfcandpregnancy";
const maintenance: string = "maintenance";
const other: string = "other";
const herd: string = "herd";
const uriDashboard: string = "dashboard";
const uriStickConsumption: string = "stickconsumption";
const messageToUser: string = "messagetouser";
const sticksprpregnancy: string = "sticksprpregnancy";
const uriTopxhighstickconsumingcows: string = "topxhighstickconsumingcows";
const stickconsumptionhistory: string = "stickconsumptionhistory";

// https://HNNEXTUX0301.herdnavigatorlive.com/farms
export const getFarmsUrl = (): string => `${baseUrl}/${farms}`;

// https://HNNEXTUX0301.herdnavigatorlive.com/farm/settings
export const getFarmSettingsUrl = (): string => `${baseUrl}/${farm}/${settings}`;

// https://HNNEXTUX0301.herdnavigatorlive.com/farms
export const getMaintenance = (): string => `${baseUrl}/${ux}/${messageToUser}/${maintenance}`;

// https://HNNEXTUX0301.herdnavigatorlive.com/ux/messagetouser/other
export const getNotfications = (): string => `${baseUrl}/${ux}/${messageToUser}/${other}`;

// https://HNNEXTUX0301.herdnavigatorlive.com/farm/newsettings
export const getFarmNewSettingsUrl = (): string => `${baseUrl}/${farm}/${newSettings}`;

// https://HNNEXTUX0301.herdnavigatorlive.com/farm/scorereftypes
export const getFarmScoreRefTypesUrl = (): string => `${baseUrl}/${farm}/${scoreRefTypes}`;

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/lactationdistribution
export const getLactationDistributionUrl = (): string => `${baseUrl}/${farms}/${reproduction}/${lactationDistribution}`;

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/refp2p3/DOA/P3
export const getSticksPerCow = () => `${baseUrl}/${farms}/${herd}/${uriDashboard}/${sticksprpregnancy}`;




// https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/doa/csummary
export const getDOACSummary = () => `${baseUrl}/${farms}/${reproduction}/${doa}/${csummary}`;

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/crbwp/csummary
export const getCRBWPCSummary = () => `${baseUrl}/${farms}/${reproduction}/${crbwp}/${csummary}`;

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/dai/csummary
export const getDAICSummary = () => `${baseUrl}/${farms}/${reproduction}/${dai}/${csummary}`;

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/ais/cicdistribution
export const getAISCICDistribution = () => `${baseUrl}/${farms}/${reproduction}/${ais}/${cicdistribution}`;

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/sea/cicdistribution
export const getSEACICDistribution = () => `${baseUrl}/${farms}/${reproduction}/${sea}/${cicdistribution}`;

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/od/cicpsummary
export const getODCICPSummary = () => `${baseUrl}/${farms}/${reproduction}/${od}/${cicpsummary}`;

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/pbss/cicdistribution
export const getPBSSCICDistribution = () => `${baseUrl}/${farms}/${reproduction}/${pbss}/${cicdistribution}`;

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/herd/stickconsumptionhistory
export const getStickConsumptionHistory = () => `${baseUrl}/${farms}/${herd}/${stickconsumptionhistory}`;


export function getReproductionStatusDistributionUrl() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + reproductionStatusDistribution;
}

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/performancescoreext
export function getPerformanceScoreUrl() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + uriPerformanceScore;
}

export function getRefP1(pathIdentifier: string) {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + uriRefP1
    + forWardSlash + pathIdentifier;
}

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/refp2p3/doa/p3
export function getRefP2P3(pathIdentifier: string, plotIdentifier: string) {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + uriRefP2P3
    + forWardSlash + pathIdentifier
    + forWardSlash + plotIdentifier;
}

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/doa/cicdetail
export function getDOACICDetail() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + doa
    + forWardSlash + uriCICDetail;
}

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/herd/stickconsumption
export function getStickConsumption() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + herd
    + forWardSlash + uriStickConsumption;
}

// farms/herd/dashboard/proportionofdelayedsamples
export function getProportionOfDelayedSamples() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + herd
    + forWardSlash + uriDashboard
    + forWardSlash + "proportionofdelayedsamples";
}

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/dashboard/currentreproductionperformance
export function getCurrentReproductionPerformance() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + uriDashboard
    + forWardSlash + "currentreproductionperformance";
}

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/herd/dashboard/topxhighstickconsumingcows
export function topHighStickConsumingCows() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + herd
    + forWardSlash + uriDashboard
    + forWardSlash + uriTopxhighstickconsumingcows;
}

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/herd/zeromeasurement
export function getZeroMeasurement() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + herd
    + forWardSlash + "zeromeasurement";
}

export function getDashboardZeroMeasurement() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + herd
    + forWardSlash + uriDashboard
    + forWardSlash + "zeromeasurement";
}

export function getDOAFLP() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + doa
    + forWardSlash + uriFLP;
}


export function getCRBWPFolPhaseLength() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + crbwp
    + forWardSlash + uriFolPhaseLength;
}

export function getCRBWPCycleLength() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + crbwp
    + forWardSlash + uriCycleLength;
}

export function getDAICICDetail() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + dai
    + forWardSlash + uriCICDetail;
}

export function getDAIPregnancyDetail() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + dai
    + forWardSlash + uriPregnancyDetail;
}

export function getAISTimingOfInsemination() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + ais
    + forWardSlash + uriTimingOfInsemination;
}

export function getAISPregnancy() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + ais
    + forWardSlash + uriPregnancy;
}

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/sea/embryoattachment
export function getSEAEmbryoAttachment() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + sea
    + forWardSlash + uriEmbryoAttachment;
}

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/pbss/pregnancy
export function getPBSSPregnancy() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + pbss
    + forWardSlash + uriPregnancy;
}

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/od/aiandpregnancy
export function getODAIandPregnancy() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + od
    + forWardSlash + uriAIandPregnancy;
}

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/od/dfcandpregnancy
export function getODDFCandPregnancy() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + od
    + forWardSlash + uriDFCandPregnancy;
}

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/crd/doa
export function getCowReproDetailsDOA() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + uriCRD
    + forWardSlash + doa;
}

export function getCowReproDetailsCRBWP() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + uriCRD
    + forWardSlash + crbwp;
}

export function getCowReproDetailsDAI() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + uriCRD
    + forWardSlash + dai;
}


export function getCowReproDetailsAIS() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + uriCRD
    + forWardSlash + ais;
}

// https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/crd/sea
export function getCowReproDetailsSEA() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + uriCRD
    + forWardSlash + sea;
}

export function getCowReproDetailsPBSS() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + uriCRD
    + forWardSlash + pbss;
}

export function getCowReproDetailsOD() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + uriCRD
    + forWardSlash + od;
}

export function getCowReproDetailsCow() {
    return baseUrl
    + forWardSlash + farms
    + forWardSlash + reproduction
    + forWardSlash + uriCRD
    + forWardSlash + uriCow;
}
