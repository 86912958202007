import {
    GET_SETTINGS_FAILURE,
    GET_SETTINGS_REQUEST,
    GET_SETTINGS_SUCCESS,
    SELECT_SETTINGS_DROPDOWN_VALUE,
    SET_NEW_SETTINGS_FAILURE,
    SET_NEW_SETTINGS_REQUEST,
    SET_NEW_SETTINGS_SUCCESS,
    UPDATE_TEXT_VALUE,
} from "./constants";

// TODO: the initial value of the dropdowns should be the ones in the settings object
export const initialDimZeroMeasurementBasisIdList = [{
    key: 1,
    value: "Before HN",
}, {
    key: 3,
    value: "6 months ago",
}, {
    key: 4,
    value: "1 year ago",
}, {
    key: 5,
    value: "2 years ago",
}, {
    key: 6,
    value: "3 years ago",
}];

export const initialDimBenchmarkFilterGeographicalRegionIdList = [{
    key: 0,
    value: "All",
}, {
    key: 1,
    value: "Region",
}, {
    key: 2,
    value: "Own country",
}];

export const initialDimBenchmarkFilterPerformanceLevelIdList = [{
    key: 0,
    value: "Average",
}, {
    key: 1,
    value: "50",
}, {
    key: 2,
    value: "25",
}, {
    key: 3,
    value: "10",
}];

export const initialSettings = {
    VoluntaryWaitingPeriodL1: 70,
    VoluntaryWaitingPeriodL2P: 70,
    DimBenchmarkFilterGeographicalRegionId: 2,
    DimBenchmarkFilterPerformanceLevelId: 0,
    DimZeroMeasurementBasisId: 1,

    // DaysToPregnancy_LagTime: 5,
    // DaysToPregnancy_PeriodBackward: 5,
    // DimFarmId: 22,
    // FKCountries: 752,
    // FarmName: "Berte Gård AB",
    // KetosisDaysSplit: 14,
    // PerformanceLevel: 50,
    // ThresholdAnoestrus: 0.7,
    // ThresholdFollicleCyst: 0.7,
    // ThresholdKetosis: 0.7,
    // ThresholdLutealCyst: 0.7,
    // ThresholdMastitis: 0.7,
    // ZeroMeasurementBasisDescription: "1 year ago",
};

export const initialState: any = {
    loading: false,
    error: null,
    gotData: false,
    settings: initialSettings,

    DimZeroMeasurementBasisList: initialDimZeroMeasurementBasisIdList,
    DimZeroMeasurementBasisIdSelected: initialDimZeroMeasurementBasisIdList[1],

    DimBenchmarkFilterGeographicalRegionList: initialDimBenchmarkFilterGeographicalRegionIdList,
    DimBenchmarkFilterGeographicalRegionIdSelected: initialDimBenchmarkFilterGeographicalRegionIdList[2],

    DimBenchmarkFilterPerformanceLevelList: initialDimBenchmarkFilterPerformanceLevelIdList,
    DimBenchmarkFilterPerformanceLevelIdSelected: initialDimBenchmarkFilterPerformanceLevelIdList[0],
};

export const SettingsReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case GET_SETTINGS_REQUEST:
        case SET_NEW_SETTINGS_REQUEST: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }

        case GET_SETTINGS_SUCCESS: {
            return {
                ...state,
                loading: false,
                gotData: true,
                settings: action.payload,

                // for now, we know that index and id is the same value
                DimZeroMeasurementBasisIdSelected: initialDimZeroMeasurementBasisIdList.filter((el) => el.key === action.payload.DimZeroMeasurementBasisId)[0],
                DimBenchmarkFilterGeographicalRegionIdSelected: initialDimBenchmarkFilterGeographicalRegionIdList.filter((el) => el.key === action.payload.DimBenchmarkFilterGeographicalRegionId)[0],
                DimBenchmarkFilterPerformanceLevelIdSelected: initialDimBenchmarkFilterPerformanceLevelIdList.filter((el) => el.key === action.payload.DimBenchmarkFilterPerformanceLevelId)[0], // [action.payload.DimBenchmarkFilterPerformanceLevelId],
            };
        }

        case SET_NEW_SETTINGS_SUCCESS: {
            return {
                ...state,
                loading: false,
                gotData: true,
            };
        }

        case SET_NEW_SETTINGS_FAILURE:
        case GET_SETTINGS_FAILURE: {
            return {
                ...state,
                loading: false,
                gotData: false,
                error: action.payload,
            };
        }

        case UPDATE_TEXT_VALUE: {
            return {
                ...state,
                settings: {
                    ...state.settings,
                    [action.payload.key]: action.payload.value,
                },
            };
        }

        case SELECT_SETTINGS_DROPDOWN_VALUE: {
            const newState = {
                ...state,
                settings: {
                    ...state.settings,
                    [`${action.payload.key}Id`]: state[`${action.payload.key}List`][action.payload.index].key,
                },
                [`${action.payload.key}IdSelected`]: state[`${action.payload.key}List`][action.payload.index],
            };

            return newState;
        }

        default:
            return state;
    }
};

/*
DimBenchmarkFilterGeographicalRegionDescription: "Own Country"
DimBenchmarkFilterGeographicalRegionId: 2
DimBenchmarkFilterPerformanceLevelId: 0

action.payload.key

DimZeroMeasurementBasis IdSelected
*/

// CreationDate: "2019-08-24T03:00:00.357"
// DaysToPregnancy_LagTime: 5
// DaysToPregnancy_PeriodBackward: 5
// DimBenchmarkFilterGeographicalRegionDescription: "Own Country"
// DimBenchmarkFilterGeographicalRegionId: 2
// DimBenchmarkFilterPerformanceLevelId: 0
// DimFarmId: 219
// DimZeroMeasurementBasisId: 3
// FKCountries: 124
// FarmName: "Kelton Holsteins"
// KetosisDaysSplit: 14
// PerformanceLevel: 50
// ThresholdAnoestrus: 0.7
// ThresholdFollicleCyst: 0.7
// ThresholdKetosis: 0.7
// ThresholdLutealCyst: 0.7
// ThresholdMastitis: 0.7
// VoluntaryWaitingPeriodL1: 120
// VoluntaryWaitingPeriodL2P: 80
// ZeroMeasurementBasisDescription: "6 months ago"

// CreationDate: "2019-08-24T03:00:00.357"
// DaysToPregnancy_LagTime: 5
// DaysToPregnancy_PeriodBackward: 5
// DimBenchmarkFilterGeographicalRegionDescription: "Own Country"
// DimBenchmarkFilterGeographicalRegionId: 2
// DimBenchmarkFilterPerformanceLevelId: 0
// DimFarmId: 219
// DimZeroMeasurementBasisId: 3
// FKCountries: 124
// FarmName: "Kelton Holsteins"
// KetosisDaysSplit: 14
// PerformanceLevel: 50
// ThresholdAnoestrus: 0.7
// ThresholdFollicleCyst: 0.7
// ThresholdKetosis: 0.7
// ThresholdLutealCyst: 0.7
// ThresholdMastitis: 0.7
// VoluntaryWaitingPeriodL1: 120
// VoluntaryWaitingPeriodL2P: 80
// ZeroMeasurementBasisDescription: "6 months ago"
