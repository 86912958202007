import React, { Fragment, useEffect, useState } from "react";
import { ContentHeader, ContentHeadline } from "../../Content";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader, CardHeadline, CardGuide, CardContent } from "../../Card";
import { GET_PERFORMANCE_SCORE_HISTORY_REQUEST } from "../../redux/PerformanceScoreHistory/constants";
import { Column, Row } from "../../Layout";
import { BarAreaStacked } from "../../Charts";
import { SetBarNormal, SetBarHovered, SetBarSelected } from "../../Charts/BarAreaStacked";
import { SELECT_DATA_TME_PERIOD, SELECT_LACTATION_FILTERS } from "../../redux/Filters/constants";
import { IFilterState } from "../../redux/Filters/reducer";
import { GET_COWS_LIST_REQUEST, HANDLE_FILTER_COW_LIST_INITIALIZE_LOCAL, HANDLE_COW_LIST_SELECT, HANDLE_COW_LIST_HOVERED } from "../../redux/CowsList/constants";
import { OPEN_COW_LIST, SET_LIBRARY_OPEN_URL } from "../../redux/Navigation/constants";
import { handleTriggerStartTour } from "../../utills/intercom";
import KPIPageFilters from "../../KPIPageFilters"
import OverView12Month from "../../Cards/OverView12Month"
import PlaceholderLoader from "../../PlaceholderLoader";
import { BarExtendedDatum } from "@nivo/bar";
const DOA: React.FC = (props) => {

    const filtersState: IFilterState = useSelector((state: any) => state.filtersState);
    const cowsListState = useSelector((state: any) => state.cowsListState);
    const performanceScoreHistoryState = useSelector((state: any) => state.performanceScoreHistoryState);

    const [secondaryExpandedState, handleToggleSecondaryExpandedState] = useState(false);

    const dispatch = useDispatch();

    const keys = ["AnoestrusLength", "DOAState"];
    const filters: any = {};

    useEffect(() => {
        const startDate = new Date(filtersState.today);
        startDate.setDate(startDate.getDate() - filtersState.anoestrusLengthSelectedTimePeriod.days);

        filtersState.lactationFilters.culledChecked = filtersState.lactationFilters.l1Checked = filtersState.lactationFilters.l2Checked = filtersState.lactationFilters.l3Checked = true;
        filtersState.lactationFilters.delayedChecked = false;

        // get the cowlist
        dispatch({
            type: GET_COWS_LIST_REQUEST,
            payload: {
                filters,
                keys,
                path: "doa",
                startDate: startDate,
                stopDate: filtersState.today,
                lactationFilters: filtersState.lactationFilters,
            },
        });

        dispatch({ type: GET_PERFORMANCE_SCORE_HISTORY_REQUEST, payload: { kpi: "DOA" } });
    }, [dispatch, filtersState.anoestrusLengthSelectedTimePeriod, filtersState.today]);

    const handleSelectTimePeriod = (e: any, key: string) => {
        dispatch({ type: SELECT_DATA_TME_PERIOD, payload: { index: e.target.selectedIndex, key } });
    }

    const handleToggleLactationFilter = (key: string) => {
        SetBarNormal(cowsListState.selected)
        dispatch({ type: HANDLE_COW_LIST_SELECT, payload: undefined });

        dispatch({ type: SELECT_LACTATION_FILTERS, payload: { key } });
        const newFilter = filtersState.lactationFilters;
        newFilter[`${key}Checked`] = !newFilter[`${key}Checked`];
        dispatch({
            type: HANDLE_FILTER_COW_LIST_INITIALIZE_LOCAL,
            payload: {
                filters,
                keys,
                path: "doa",
                data: cowsListState.data,
                lactationFilters: newFilter,
                p2reference: cowsListState.p2reference,
                p3reference: cowsListState.p3reference,
            },
        });
    }

    const handleSetCowListFilter = (filters: any, keys: string[]) => {
        dispatch({
            type: HANDLE_FILTER_COW_LIST_INITIALIZE_LOCAL,
            payload: {
                filters: (cowsListState.selected === cowsListState.hovered ? {} : filters),
                keys,
                path: "doa",
                data: cowsListState.data,
                lactationFilters: filtersState.lactationFilters,
                p2reference: cowsListState.p2reference,
                p3reference: cowsListState.p3reference,
            },
        });
    }

    const handleShowCows = (elem: any) => {
        if (cowsListState.selected !== cowsListState.hovered) {
            if (cowsListState.hovered !== undefined) {
                // set selected
                SetBarSelected(cowsListState.hovered);
            }
            if (cowsListState.selected !== undefined) {
                // clear selected
                SetBarNormal(cowsListState.selected);
            }
            dispatch({ type: HANDLE_COW_LIST_SELECT, payload: cowsListState.hovered });
        } else {
            if (cowsListState.selected !== undefined) {
                // de-select selected, so set hovered
                SetBarHovered(cowsListState.selected)
            }
            dispatch({ type: HANDLE_COW_LIST_SELECT, payload: undefined });
        }

        dispatch({ type: OPEN_COW_LIST });
    }

    const handleMouseEnter = (elem: BarExtendedDatum, event: React.MouseEvent) => {
        dispatch({ type: HANDLE_COW_LIST_HOVERED, payload: event.currentTarget });
    };

    // should set url instead of index
    const handleSetUrl = (url: string) => dispatch({ type: SET_LIBRARY_OPEN_URL, payload: url });

    return (
        <Fragment>
            <Row first={true} flex={1}>
                <Column flex={3} first={true}>
                    <ContentHeader>
                        <ContentHeadline text="Anoestrus length" subHeader="Evaluates herd variation in Anoestrus Length based on the number of days from calving to the first increase in progesterone." />
                        <KPIPageFilters handleSelectTimePeriod={handleSelectTimePeriod} filtersState={filtersState} handleToggleLactationFilter={handleToggleLactationFilter} hasDelayedFilter={true} />
                    </ContentHeader>
                </Column>
                <Column flex={2}>
                    <OverView12Month
                        performanceScore={performanceScoreHistoryState}
                        stepGuide={178781}
                        kpi={"doa"}
                    />
                </Column>
            </Row>
            <Row last={true} flex={3}>
                <Column first={true} flex={secondaryExpandedState ? 1 : 3}>
                    <Card>
                        <CardHeader>
                            <CardHeadline text={`Anoestrus length`} />
                            <CardGuide
                                onOpenLib={() => handleSetUrl("doa/lvl3/al/summary")}
                                openStepGuide={() => handleTriggerStartTour(177885)}
                                toggleExpand={() => handleToggleSecondaryExpandedState(!secondaryExpandedState)}
                                isExpanded={!secondaryExpandedState}
                            />
                        </CardHeader>
                        <CardContent>
                            {/* <PlaceholderLoader type='AnoestrusLength' /> */}
                            {
                                <BarAreaStacked
                                    filter={(filters: any) => handleSetCowListFilter(filters, ["AnoestrusLength", "DOAState"])}
                                    filterKeys={[{ key: "DOAState", value: "id" }, { key: "ranges", value: "indexValue" }]}
                                    tooltipHeadline={`Anoestrus length [days]`}
                                    data={cowsListState.graphP2Data}
                                    keys={["Cyclic cows", "Anoestrus cows"]}
                                    indexBy="Ranges"
                                    yLegend="Number of cows"
                                    xLegend="Days"
                                    onClick={(elem: any) => handleShowCows(elem)}
                                    maxValue={cowsListState.graphP2Max}
                                    onMouseEnter={(elem: BarExtendedDatum, event: React.MouseEvent) => handleMouseEnter(elem, event)}
                                    selected={cowsListState.selected}
                                />}
                        </CardContent>
                    </Card>
                </Column>
                <Column last={true} flex={secondaryExpandedState ? 3 : 1}>
                    <Card>
                        <CardHeader>
                            <CardHeadline text="First luteal phase length" />
                            <CardGuide
                                onOpenLib={() => handleSetUrl("doa/lvl3/flp/summary")}
                                openStepGuide={() => handleTriggerStartTour(178777)}
                                toggleExpand={() => handleToggleSecondaryExpandedState(!secondaryExpandedState)}
                                isExpanded={secondaryExpandedState}
                            />
                        </CardHeader>
                        <CardContent>
                            <BarAreaStacked
                                filter={(filters: any) => handleSetCowListFilter(filters, ["FirstLutealPhaseLength", "FPLState"])}
                                filterKeys={[{ key: "FPLState", value: "id" }, { key: "ranges", value: "indexValue" }]}
                                tooltipHeadline={`First luteal phase length [days]`}
                                data={cowsListState.graphP3Data}
                                keys={["Completed", "Not completed"]}
                                indexBy="Bin"
                                yLegend="Number of cows"
                                xLegend="Days"
                                onClick={(elem: any) => handleShowCows(elem)}
                                maxValue={cowsListState.graphP3Max}
                                onMouseEnter={(elem: BarExtendedDatum, event: React.MouseEvent) => handleMouseEnter(elem, event)}
                                selected={cowsListState.selected}
                            />
                        </CardContent>
                    </Card>
                </Column>
            </Row>
        </Fragment>
    );
};

export default DOA;
