import { put } from "redux-saga/effects";
import {
    GENERAL_ERROR,
    GENERAL_NOACCESS,
    GENERAL_UNHANDLEDERROR,
} from "../../constants";

import { getFarmId } from "../../utills/authorization";

// export function getHeaders(accessToken: Types.Token) {
//     const headers = new Headers({
//         "Authorization": `Bearer ${accessToken}`,
//         "Access-Control-Allow-Origin": "true",
//     });

//     return headers;
// }

// export function getBody(dimFarmId: number, userId: string | null, dimCowId?: number, lactationNo?: number, startDate?: Date, stopDate?: Date, specTag?: string, KPIName?: string) {
//     const state = window.store.getState();
//     const cowFilterState: State = state.hnadvice.cfState;

//     const xml = xmlBuilder
//         .create("CowFilter")
//         .ele("CowFilter");
//     xml.att("FarmId", dimFarmId);
//     xml.att("UserId", userId);
//     xml.att("SpecTag", specTag);
//     xml.att("CowId", dimCowId === undefined ? -1 : dimCowId);
//     xml.att("LN", lactationNo === undefined ? -1 : lactationNo);
//     xml.att("L1", cowFilterState.L1 ? 1 : 0);
//     xml.att("L2", cowFilterState.L2 ? 1 : 0);
//     xml.att("L3P", cowFilterState.L3P ? 1 : 0);
//     xml.att("M", cowFilterState.M ? 1 : 0);
//     xml.att("MISC", cowFilterState.MISC);
//     xml.att("K", cowFilterState.K ? 1 : 0);
//     xml.att("KT", cowFilterState.KT);
//     xml.att("A", cowFilterState.A ? 1 : 0);
//     xml.att("LC", cowFilterState.LC ? 1 : 0);
//     xml.att("FC", cowFilterState.FC ? 1 : 0);
//     xml.att("EOD", cowFilterState.EOD ? 1 : 0);
//     xml.att("LY", cowFilterState.LY ? 1 : 0);
//     xml.att("C", cowFilterState.C ? 1 : 0);
//     xml.att("TBC", cowFilterState.TBC ? 1 : 0);
//     xml.att("HC", cowFilterState.HC ? 1 : 0);
//     xml.att("StartD", Api.date.FormatDateToISOString(startDate === undefined ? cowFilterState.StartD : startDate!));
//     xml.att("StopD", Api.date.FormatDateToISOString(stopDate === undefined ? cowFilterState.StopD : stopDate!));
//     xml.att("MW", cowFilterState.MW);
//     xml.end({ pretty: true });
//     return xml.toString();
// }

export function getBodyExt(startDate: Date, stopDate: Date) {
    return `<CowFilter L1="1" L2="1" L3P="1" M="1" MISC="0" K="1" KT="3" A="1" LC="1" FC="1" EOD="1" LY="1" C="0" TBC="0" HC="1" StartD="${startDate.toISOString()}" StopD="${stopDate.toISOString()}" MW="9"/>`;
}

export function SoftPostRequest(url: string) {
    try {
        const headers = new Headers({
            "Access-Control-Allow-Origin": "true",
        });

        return fetch(url, {
            method: "POST",
            headers,
        })
            .then(statusHelper)
            .then(toJSON);
    } catch (error) {
        return null;
    }
}

// export function PostRequest(token: Types.Token, dimFarmId: number, url: string, startDate?: Date, stopDate?: Date, specTag?: string) {
//     return PostRequestExt(token, dimFarmId, url, undefined, undefined, startDate, stopDate, specTag);
// }

// export function PostRequestExt(token: Types.Token, dimFarmId: number, url: string, dimCowId?: number, lactationNo?: number, startDate?: Date, stopDate?: Date, specTag?: string) {
//     try {
//         const userName = getUsername();
//         const headers = getHeaders(token);
//         const bodyData = getBody(dimFarmId, userName, dimCowId, lactationNo, startDate, stopDate, specTag);

//         return fetch(url, {
//             method: "POST",
//             headers,
//             body: bodyData,
//         })
//             .then(statusHelper)
//             .then(toJSON);
//     } catch (error) {
//         return null;
//     }
// }

// export function PostRequestKPI(token: Types.Token, dimFarmId: number, url: string, KPIName: string) {
//     try {
//         const headers = getHeaders(token);
//         const state = window.store.getState();
//         const cowFilterState: State = state.hnadvice.cfState;

//         const xml = xmlBuilder
//             .create("CowFilter")
//             .ele("CowFilter");
//         xml.att("FarmId", dimFarmId);
//         xml.att("KPIName", KPIName);
//         xml.att("L1", cowFilterState.L1 ? 1 : 0);
//         xml.att("L2", cowFilterState.L2 ? 1 : 0);
//         xml.att("L3P", cowFilterState.L3P ? 1 : 0);
//         xml.att("StartD", Api.date.FormatDateToISOString(cowFilterState.StartD));
//         xml.att("StopD", Api.date.FormatDateToISOString(cowFilterState.StopD));

//         xml.end({ pretty: true });

//         return fetch(url, {
//             method: "POST",
//             headers,
//             body: xml.toString(),
//         })
//             .then(statusHelper)
//             .then(toJSON);
//     } catch (error) {
//         console.log(error);
//         return;
//     }
// }

// export function PostRequestWithNewSettings(token: Types.Token, dimFarmId: number, url: string, farmSettingsXML: string) {
//     try {
//         const headers = getHeaders(token);

//         return fetch(url, {
//             method: "POST",
//             headers,
//             body: farmSettingsXML,
//         })
//             .then(statusHelper)
//             .then(toJSON);
//     } catch (error) {
//         return null;
//     }
// }

// export function PostRequestRefP1(token: Types.Token, url: string) {
//     try {
//         const headers = getHeaders(token);

//         return fetch(url, {
//             method: "POST",
//             headers,
//         })
//             .then(statusHelper)
//             .then(toJSON);
//     } catch (error) {
//         return null;
//     }
// }

export function statusHelper(response: Response) {
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
    } else {
        return Promise.reject(new LattecError(response.status));
    }
}

export function toJSON(response: Response, defaultResponse: object | ArrayLike<any> = {}) {
    if (response.status === 204) {
        return Promise.resolve(defaultResponse);
    } else {
        console.log(response.status);
    }
    return response.json();
}

export class LattecError {
    constructor(public statusCode: number) { }
}

export function* errorHandling(error: any) {
    if (error instanceof LattecError) {

        let specificError;
        specificError = error as LattecError;
        switch (specificError.statusCode) {
            case 401:
                window.location.href = "/login";
                console.log("Not authorized");
                yield put({ type: GENERAL_NOACCESS, payload: "Not authorized" });
                break;
            default:
                console.log("An unhandled error occured");
                yield put({ type: GENERAL_UNHANDLEDERROR, payload: "An unhandled error occured" });
                break;
        }
    } else {
        console.log("type is NOT LattecError");
        yield put({ type: GENERAL_ERROR, payload: "An error occured!" });
    }

}

export function getTokenAndGuid() {
    // const store = window.store;
    // const state = store.getState();
    // const accessToken = state.hnadvice.logInState.accessToken;
    const dimFarmId = getFarmId(); // state.hnadvice.farmSelectionState.dimFarmId;

    return {
        // accessToken,
        dimFarmId,
    };
}
