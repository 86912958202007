import React from "react";
import "../Layout.scss";
import { iColumn } from "./interface";

const Column: React.FC<iColumn> = (props) => {
    return (
        <div
            className={`layout__column ${props.first ? "layout__column--first" : ""} ${props.last ? "layout__column--last" : ""} ${props.dominate ? "layout__column--third" : ""}`}
            style={{ flex: props.flex ? props.flex : 1 }}
        >
            {props.children}
        </div>
    );
};

export default Column;
