import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { Card, CardGuide, CardHeader, CardHeadline } from "../../Card";
import { DataTypeDisplay } from "../../DataType";
import { SET_CRP_DB_HOVERED, COLOR_SCREME_1, COLOR_SCREME_2 } from "../../redux/Navigation/constants";
import { GET_STICK_CONSUMPTION_REQUEST, FETCH_STICK_CONSUMPTION_CHART_DATA_REQUEST } from "../../redux/StickConsumption/constants";
import "./StickConsumption.scss";
import { iStickConsumptionTable } from "./interface";

const StickConsumptionTable: React.FC<iStickConsumptionTable> = (props) => {
    const stickConsumptionState = useSelector((state: any) => state.stickConsumptionState);
    const navigationState = useSelector((state: any) => state.navigationState);

    const { t } = useTranslation();
    const [isClicked, setIsClicked] = useState(false);
    const [selectedElement, setSelectedElement] = useState("");
    const targetRef = useRef<HTMLHeadingElement>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (targetRef.current && (event.target.className !== "table__element" && event.target.className !== "table__content" && event.target.className !== "table__label" && event.target.className !== "table__column" && event.target.className !== "table__benchmark-container")) {
                setIsClicked(false);
                setSelectedElement("");

                dispatch({ type: SET_CRP_DB_HOVERED, payload: [""] });
            }
        };

        // get questions when component is mounted
        dispatch({ type: GET_STICK_CONSUMPTION_REQUEST, payload: null });

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };

        // tell hook to skip re-render if state hasnt changed
    }, [dispatch]);

    const handleHoverRelation = (kpi: string, index: number) => {

        // if (props.setIndex) {
        //     props.setIndex(index);
        // }

        // check if kpi is "string" and create array

        // if byClick === false && index === -1 we know it is mouse out
        // set byClick state to true and check
        // if byClick is true && index is -1 wo want to remove the ability to hover
        let hoveredKpiList = [""];

        switch (kpi) {
            case "ProportionOfCowsBred":
                hoveredKpiList = ["hnir"];
                break;

            case "CalvingToFirstAIInterval":
                hoveredKpiList = ["doa", "hnir"];
                break;

            case "FirstToLastAIInterval":
                hoveredKpiList = ["hnir", "ais", "sea", "pbss"];
                break;

            case "AIsPrCow":
                hoveredKpiList = ["ais", "sea", "pbss"];
                break;

            case "InseminationRate":
                hoveredKpiList = ["doa", "cr", "hnir"];
                break;

            case "ConceptionRate":
            case "AIsPrPregnancy":
                hoveredKpiList = ["ais", "sea", "pbss"];
                break;

            case "PregnancyRate":
                hoveredKpiList = ["hnir", "ais", "sea", "pbss"];
                break;

            case "OpenDays":
                hoveredKpiList = ["od"];
                break;

            case "SticksUsedPrPregnancy":
                hoveredKpiList = ["hnir", "sea", "pbss"];
                break;

            case "SticksUsedPrCow":
                hoveredKpiList = ["hnir", "ais", "sea", "pbss"];
                break;

            case "SticksUsedFromFirstToLastAI":
                hoveredKpiList = ["hnir"];
                break;

            case "SticksUsedOnFollicularCysts":
                hoveredKpiList = ["cr"];
                break;

            case "SticksUsedOnLutealCysts":
                hoveredKpiList = ["cr"];
                break;
        }

        dispatch({ type: SET_CRP_DB_HOVERED, payload: hoveredKpiList });
    };

    const headers = ["", t("BenchmarkFarms"), t("SticksUsed"), "", ""];
    headers[1] = navigationState.dataStickConsumptionType === 0 ? t("BenchmarkFarms") : "Baseline start"

    const handleHoverOnInteractiveElement = (kpi: string, index: number) => {
        if (isClicked === false) {
            handleHoverRelation(kpi, index);
        }
    };

    const handleClickOnInteractiveElement = (kpi: string, index: number, event: any) => {
        setIsClicked(true);
        setSelectedElement(kpi);
        handleHoverRelation(kpi, index);

        // get the graph data matching the selection
        dispatch({
            type: FETCH_STICK_CONSUMPTION_CHART_DATA_REQUEST,
            payload: {
                kpi: kpi,
                farmGroup: navigationState.dataStickConsumptionType
            }
        });
    };

    const adjustColumnWidth = (data: any, index: number) => index === 0 ? `${(100 / data.length) + 20}%` : `${100 / data.length}%`;
    const trendColors = {
        green: "green",
        yellow: "#edf353",
        red: "red",
    }

    const formatNumber = (value: number) => {
        if (value === 0) return "-";
        if (isNaN(value)) return "";
        return (value > 0 ? "+" : "") + value.toFixed(0);
    }

    return (
        <div className="stick-consumption">
            <Card>
                <CardHeader>
                    <CardHeadline text={t("StickConsumptionKPI")} link={props.link} />
                    <CardGuide />
                </CardHeader>
                <div>
                    <div className="table__header" style={{ margin: "9px 0" }}>
                        {headers.map((el: string, index: number) => {
                            return (
                                <h6 key={index} style={{ width: adjustColumnWidth(headers, index) }} className="table__headline">{el} {el === "Baseline start" ? <p className="table__headline">{el === "Baseline start" ? new Date(stickConsumptionState.tableData[0].OneYearAgoValue !== "-" ? stickConsumptionState.tableData[0].OneYearAgoDate : stickConsumptionState.tableData[0].StartingPointDate).toLocaleDateString("en-GB") : ""}</p> : ""}</h6>
                            );
                        })}
                    </div>
                    {stickConsumptionState.gotData && stickConsumptionState.tableData.map((el: any, index: number) => {
                        return (
                            <div ref={targetRef} onClick={(event: any) => handleClickOnInteractiveElement(el.KPIName, index, event)} onMouseOver={() => handleHoverOnInteractiveElement(el.KPIName, index)} onMouseOut={() => handleHoverOnInteractiveElement("", -1)} key={`crp__${index}`} className="table__element" style={{ border: selectedElement === el.KPIName ? "2px solid #38537d" : "" }}>
                                <div className="table__content">
                                    <div className="table__column" style={{ width: adjustColumnWidth(headers, 0) }}>
                                        <p className="table__label" style={{ textAlign: "start" }}>{t(el.KPIName)}</p>
                                    </div>

                                    {!props.simple && (
                                        <div className="table__column" style={{ width: adjustColumnWidth(headers, 1) }}>
                                            <p className="table__label">{Math.round(navigationState.dataStickConsumptionType === 0 ? el.Expected : (el.OneYearAgoValue !== "-" ? el.OneYearAgoValue : el.StartingPointValue))}</p>
                                        </div>
                                    )}

                                    {!props.simple && (
                                        <div className="table__column" style={{ width: adjustColumnWidth(headers, 2) }}>
                                            <p className="table__label">{el.CurrentPointValue}</p>
                                        </div>
                                    )}

                                    <div className="table__column" style={{ width: adjustColumnWidth(headers, 3) }}>
                                        <div className="table__benchmark-container">
                                            <DataTypeDisplay
                                                currentValueId="baseline-report-current-value-bar"
                                                referenceValudId="baseline-report-reference-value-bar"
                                                dataType={navigationState.dataStickConsumptionType}
                                                layout="horizontal"
                                                colors={navigationState.dataStickConsumptionType === 0 ? COLOR_SCREME_1 : COLOR_SCREME_2}
                                                trend={el.Trend}
                                                benchmarkComparison={el.Expected.toFixed(0)}
                                                current={parseFloat(el.CurrentPointValue)}
                                                minValue={0}
                                                ownComparison={parseFloat(el.OneYearAgoValue !== "-" ? el.OneYearAgoValue : el.StartingPointValue)}
                                            />
                                        </div>
                                    </div>
                                    {!props.simple && (
                                        <div className="table__column" style={{ width: adjustColumnWidth(headers, 4) }}>
                                            <span className="table__label" style={{ fontWeight: "bold", color: (navigationState.dataStickConsumptionType === 0 ? parseFloat(el.CurrentPointValue) - parseFloat(el.Expected) : parseFloat(el.CurrentPointValue) - parseFloat(el.OneYearAgoValue !== "-" ? el.OneYearAgoValue : el.StartingPointValue) > 0) ? trendColors.red : ((navigationState.dataStickConsumptionType === 0 ? parseFloat(el.CurrentPointValue) - parseFloat(el.Expected) : parseFloat(el.CurrentPointValue) - parseFloat(el.OneYearAgoValue !== "-" ? el.OneYearAgoValue : el.StartingPointValue) < 0) ? trendColors.green : trendColors.yellow) }}>
                                                {formatNumber(navigationState.dataStickConsumptionType === 0 ? parseFloat(el.CurrentPointValue) - parseFloat(el.Expected) : parseFloat(el.CurrentPointValue) - parseFloat(el.OneYearAgoValue !== "-" ? el.OneYearAgoValue : el.StartingPointValue))}
                                            </span>
                                        </div>
                                    )}

                                    {/*navigationState.dataStickConsumptionType === 1 && el.OneYearAgoValue !== "-" && (
                                        <span className="data-type__own-farm-arrow-wrapper" id={props.arrowId}>
                                            <i color={(el.ColourIndicator > 0) ? trendColors.green : ((el.ColourIndicator < 0) ? trendColors.red : trendColors.yellow)} className={`${el.ColourIndicator > 0 ? "fas fa-arrow-alt-circle-down" : el.ColourIndicator < 0 ? "fas fa-arrow-alt-circle-up" : "fas fa-grip-lines"} data-type__own-farm-arrow`} />
                                        </span>
                                    )*/}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Card>
        </div>
    );
};

export default StickConsumptionTable;