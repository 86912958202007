import React from "react";
import { isValidDate } from "../../utills/dateFormatting";
import "../Input.scss";
import { iDropdown, iDropdownElem } from "./interface";



const Dropdown: React.FC<iDropdown> = (props) => {

    return (
        <div className="input__checkbox-container">
            <select
                style={{ borderColor: "lightgray", borderRadius: "4px", padding: "1px", fontSize : "17px", height :"25px", ...props.style }}
                name="filter"
                value={props.value}
                onChange={props.onChange}
                disabled={props.disabled}
            >
                {props.list.map((el: iDropdownElem, index: number) => (
                    <option key={`dd-${el.key}`} id={`dd-${el.key}-${index}`}>
                        {isValidDate(el.value) ? `${new Date(el.value).toLocaleDateString("en-GB")}` : el.value}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Dropdown;
