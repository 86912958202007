import React from "react";
import "../Card.scss";

const Content: React.FC = (props) => {
    return (
        <div className="card__content">
            {props.children}
        </div>
    );
};

export default Content;
