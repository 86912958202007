import React from "react";
import { useSelector } from "react-redux";
import { Route as AppRoute } from "react-router-dom";
import CowsList from "./CowsList";
import NotificationBar from "./NotificationBar";

import { LayoutContainer, LayoutContent, LayoutHeader, LayoutSidebar } from "./index";
import SlackLink from "../SlackLink";
// tslint:disable-next-line: no-var-requires

const LayoutRoute = ({ component: Component, ...rest }: any) => {

    const navigationState = useSelector((state: any) => state.navigationState);

    return (
        <AppRoute
            {...rest}
            render={(matchProps: any) => (
                <div>
                    <LayoutHeader />
                    <LayoutContainer>
                        <LayoutSidebar />
                        <LayoutContent>
                            <Component {...matchProps} />
                        </LayoutContent>
                        <SlackLink />
                        {navigationState.cowListOpen && (
                            <CowsList />
                        )}
                        {navigationState.notificationBarOpen && (
                            <NotificationBar />
                        )}
                    </LayoutContainer>
                </div>
            )}
        />
    );
};

export default LayoutRoute;
