import { push } from "connected-react-router";
import store from "../store";
import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { tokenRequest, postParams, getParams } from "../../api/requests";
import { authUrl } from "../../api/urls";
import { setFarmId, setFarmName, setToken, setUsername } from "../../utills/authorization";
import { AUTHENTICATE_FAILURE, AUTHENTICATE_REQUEST, AUTHENTICATE_SUCCESS } from "./constants";
import { SET_FARMS_LIST } from "../Navigation/constants";
import { AuthenticateRequestPayload, AuthenticateSuccessPayload, TokenData } from "./types";

// import "whatwg-fetch";

interface IUserRole {
    Value: string;
}

function* authenticateSaga(action: Action<AuthenticateRequestPayload>): SagaIterator {
    try {

        const { username, password } = action.payload;

        const data: TokenData = yield call(tokenRequest, authUrl, { username, password });

        if (data.token) {
            setToken(data.token);
            setUsername(data.username);

            const farms = yield call(postParams, "https://HNNEXTUX0301.herdnavigatorlive.com/farms", data.username);

            if (farms && farms.length > 0) {
                const defaultFarm = farms.filter((el: any) => el.DimFarmId === 11);

                // TODO: this needs to be handled better in frontend and backend
                if (defaultFarm.length > 0) {
                    setFarmName(defaultFarm[0].FarmName);
                    setFarmId(defaultFarm[0].DimFarmId);
                } else {
                    setFarmName(farms[0].FarmName);
                    setFarmId(farms[0].DimFarmId);
                }

                yield put({ type: SET_FARMS_LIST, payload: farms });
            }

            const payload: AuthenticateSuccessPayload = {
                username,
                token: data.token,
            };

            yield put({ type: AUTHENTICATE_SUCCESS, payload });

            const userRoleList = yield call(getParams, "https://HNNEXTUX0301.herdnavigatorlive.com/admin/userroles");

            const betaRole = userRoleList.find((el: IUserRole) => el.Value === "HNAdvice_BetaUser")

            // Check the current user role list. If user is not a beta tester - redirect to the production website after login
            if (window.location.href.includes('beta') || window.location.href.includes('hna2-second') && !betaRole) {
                window.location.href = "https://hninsights.herdnavigator.com";
            } else {
                store.dispatch(push("/hnadvice/dashboard"));
            }

        } else if (data.error) {
            yield put({ type: AUTHENTICATE_FAILURE, payload: data.error_description });
        } else {
            yield put({ type: AUTHENTICATE_FAILURE, payload: new Error("Login failed") });
        }
    } catch (error) {
        yield put({ type: AUTHENTICATE_FAILURE, payload: new Error("Login failed") });
    }
}

export function* watchAuthenticateSaga(): SagaIterator {
    yield takeEvery(AUTHENTICATE_REQUEST, authenticateSaga);
}
