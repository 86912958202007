import React, { CSSProperties, Fragment } from "react";
import "./Tooltip.scss";
import { iTooltip } from "./interface";

const Tooltip: React.FC<iTooltip> = (props) => {
    if (props.independent) {
        return (
            <div className={`${props.independent ? "tooltip--independent" : "tooltip"} ${props.visible ? "tooltip--visible" : "tooltip--hidden"}`}>
                {props.children}
            </div>
        )
    }

    const style: CSSProperties = props.moveLeft ? { position: "absolute", left: -props.moveLeft, bottom: 10, minWidth: 216, width: "auto", } : {};

    return (
        <div className={`tooltip ${props.addStyles ? "tooltip--add-styles" : ""}`}
            style={style}>
            {props.children ? (
                <div>
                    {props.children}
                </div>
            ) : (
                    <Fragment>
                        {props.headline && (
                            <div className="tooltip__headline-wrapper">
                                <p className="tooltip__headline">{props.headline}</p>
                            </div>
                        )}
                        <div className="tooltip__content-wrapper">
                            {props.xLabel && (
                                <div className="tooltip__element-wrapper">
                                    <div className="tooltip__label-element-wrapper">
                                        {!props.seriesColorDisabled && (
                                            <div className="tooltip__label-color" style={{ backgroundColor: "transparent" }} />
                                        )}
                                        <p className="tooltip__label">{props.xLabel}:</p>
                                    </div>
                                    <div>
                                        <p className="tooltip__value">{props.xKey ? props.point.data[props.xKey] : props.point.data.x}</p>
                                    </div>
                                </div>
                            )}
                            <div className="tooltip__element-wrapper">
                                <div className="tooltip__label-element-wrapper">
                                    {!props.seriesColorDisabled && (
                                        <div className="tooltip__label-color" style={{ backgroundColor: props.point.style ? props.point.style.color : (props.point.serieColor || props.point.color || "transparent") }} />
                                    )}
                                    <p className="tooltip__label">{props.yLabel ? props.yLabel : (props.point.serieId || props.point.id)}:</p>
                                </div>
                                <div>
                                    {/* //TODO: figure a better way than -1 in the array xD */}
                                    <p className="tooltip__value">{props.point.data[props.yKey || -1] || props.point.data[props.point.id] || props.point.data.y}</p>
                                </div>
                            </div>
                        </div>

                    </Fragment>
                )}
        </div>
    );
};

export default Tooltip;
