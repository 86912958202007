import {
    SET_LIBRARY_OPEN_URL,
    TOGGLE_COW_LIST,
    TOGGLE_COW_LIST_PINNED,
    OPEN_COW_LIST,
    TOGGLE_DATA_TYPE,
    TOGGLE_BASELINE_REPORT_DATA_TYPE,
    TOGGLE_STICK_CONSUMPTION_DATA_TYPE,
    TOGGLE_CHART_TYPE,
    TOGGLE_KPI_SIDENAV,
    TOGGLE_NOTIFICATION_BAR,
    TOGGLE_SETTINGS,
    SET_CRP_DB_HOVERED,
    TOGGLE_HN_INSIGHTS,
    TOGGLE_PRODUCT_TOUR,
    SET_OPEN_STEP_GUIDE,
    SET_SIDEBAR_EXPANDED,
    CLOSE_STEP_GUIDE,
    TOGGLE_ALL_SIDEBARS,
    HIDE_COW_LIST,
} from "./constants";

export const initialState: any = {
    kpiSidenavOpen: false,
    cowListOpen: false,
    cowListPinned: false,
    notificationBarOpen: false,
    settingsOpen: false,
    productTourOpen: false,
    hnInsightsOpen: false,
    chartType: 0,
    dataChart: 0,
    dataType: 0,
    dataStickConsumptionType: 0,
    dataBaseLineReportType: 1,
    libraryOpenUrl: null,
    farms: [],
    currentReproductionPerformanceDbHovered: [""],
    sidebarExpanded: false,
    openStepGuide: "",
};

export const NavigationReducer = (state: any = initialState, action: any) => {
    switch (action.type) {

        case SET_OPEN_STEP_GUIDE:
            return {
                ...state,
                openStepGuide: action.payload,
            }

        case CLOSE_STEP_GUIDE:
            return {
                ...state,
                openStepGuide: "",
            }
        case TOGGLE_KPI_SIDENAV:
            return {
                ...state,
                kpiSidenavOpen: !state.kpiSidenavOpen,
            };

        case TOGGLE_COW_LIST:
            return {
                ...state,
                cowListOpen: !state.cowListOpen,
                cowListPinned: state.cowListOpen ? false : state.cowListPinned,
            };

        case TOGGLE_COW_LIST_PINNED:
            return {
                ...state,
                cowListPinned: !state.cowListPinned,
            };

        case TOGGLE_ALL_SIDEBARS:
            return {
                ...state,
                cowListOpen: !state.cowListOpen,
                sidebarExpanded: !state.sidebarExpanded,
                kpiSidenavOpen: !state.kpiSidenavOpen,
            }

        case HIDE_COW_LIST:
            return {
                ...state,
                cowListOpen: false,
            }

        case OPEN_COW_LIST:
            return {
                ...state,
                cowListOpen: true,
            }

        case TOGGLE_NOTIFICATION_BAR:
            return {
                ...state,
                notificationBarOpen: !state.notificationBarOpen,
            };

        case TOGGLE_DATA_TYPE:
            return {
                ...state,
                dataType: action.payload,
            };

        case TOGGLE_BASELINE_REPORT_DATA_TYPE:
            return {
                ...state,
                dataBaseLineReportType: action.payload,
            };


        case TOGGLE_STICK_CONSUMPTION_DATA_TYPE:
            return {
                ...state,
                dataStickConsumptionType: action.payload,
            };


        case TOGGLE_CHART_TYPE:
            return {
                ...state,
                chartType: action.payload,
            };


        case SET_LIBRARY_OPEN_URL:
            return {
                ...state,
                libraryOpenUrl: action.payload,
                notificationBarOpen: true,
            };

        case TOGGLE_SETTINGS:
            return {
                ...state,
                settingsOpen: !state.settingsOpen,
            }

        case TOGGLE_PRODUCT_TOUR:
            return {
                ...state,
                productTourOpen: !state.productTourOpen,
            }

        case TOGGLE_HN_INSIGHTS:
            return {
                ...state,
                hnInsightsOpen: !state.hnInsightsOpen,
            }

        case SET_CRP_DB_HOVERED:
            return {
                ...state,
                currentReproductionPerformanceDbHovered: action.payload,
            }

        case SET_SIDEBAR_EXPANDED:
            return {
                ...state,
                sidebarExpanded: action.payload,
                kpiSidenavOpen: action.payload,
            }

        default:
            return state;
    }
};
