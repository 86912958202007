import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AUTHENTICATE_REQUEST } from "../redux/Authenticate/constants";
import "./Authenticate.scss";

const Authenticate: React.FC = (props) => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    // const hnInsightsState = useSelector((state: any) => state.hnInsightsState);
    const dispatch = useDispatch();

    const authenticate = useCallback(() => dispatch({ type: AUTHENTICATE_REQUEST, payload: { username, password } }), [dispatch, password, username]);

    const submit = (e: any) => {
        e.preventDefault();
        authenticate();
    }

    let authState = useSelector((state: any) => state.authenticationState);

    function renderError() {
        if (authState.error) {
            return <p id="error">{authState.error}</p>
        }
        return <></>
    }

    return (
        <div className="authenticate">
            <div className="authenticate__container">
                <form className="authenticate__form" onSubmit={submit}>
                    <label className="authenticate__label">Email</label>
                    <div className="authenticate__field">
                        <input
                            className="authenticate__input"
                            type="email"
                            name="username"
                            placeholder="john@doe.com"
                            required={true}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <span className="authenticate__icon">
                            <i className="fas fa-envelope" />
                        </span>
                    </div>
                    <label className="authenticate__label">Password</label>
                    <div className="authenticate__field">
                        <input
                            className="authenticate__input"
                            type="password"
                            name="password"
                            placeholder="********"
                            required={true}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <span className="authenticate__icon">
                            <i className="fas fa-lock" />
                        </span>
                    </div>
                    <button className="authenticate__button">Login</button>
                </form>
                {renderError()}
            </div>
        </div>
    );
};

export default Authenticate;
