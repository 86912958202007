import React from "react";
import { iIcon } from "./interface";

const DashboardIcon: React.FC<iIcon> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size ? props.size : "25"} height={props.size ? props.size : "25"} viewBox="0 0 25 25">
        <g id="Group_738" data-name="Group 738" transform="translate(0.5 0.5)">
            <path id="Path_416" data-name="Path 416" d="M-19307.906-19765.969v-13h10v13Z" transform="translate(19307.906 19779.027)" fill="none" stroke={props.color ? props.color : "currentColor"} strokeLinejoin="round" strokeWidth="1"/>
            <path id="Path_417" data-name="Path 417" d="M-19294.531-19771.342v-7.689h10v7.689Z" transform="translate(19308.193 19779.031)" fill="none" stroke={props.color ? props.color : "currentColor"} strokeLinejoin="round" strokeWidth="1"/>
            <path id="Path_418" data-name="Path 418" d="M-19294.531-19768.312h10v13h-10Z" transform="translate(19308.193 19779.309)" fill="none" stroke={props.color ? props.color : "currentColor"} strokeLinejoin="round" strokeWidth="1"/>
            <path id="Path_419" data-name="Path 419" d="M-19307.906-19762.906h10v7.631h-10Z" transform="translate(19307.906 19779.307)" fill="none" stroke={props.color ? props.color : "currentColor"} strokeLinejoin="round" strokeWidth="1"/>
        </g>
    </svg>
);

export default DashboardIcon;
