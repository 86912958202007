import { all } from "redux-saga/effects";
import { watchGetBaselineReportSaga } from "./BaselineReort/saga";

import { watchGetInseminationSuccessSaga } from "./AIS/saga";
import { watchGetAnoestrusLength } from "./AnoestrusLength/saga";
import { watchGetAnoestrusLengthOverviewSaga } from "./AnoestrusLengthOverview/saga";
import { watchAuthenticateSaga } from "./Authenticate/saga";
import { watchGetCowsList } from "./CowsList/saga";
import { watchGetCRBWPSaga } from "./CRBWP/saga";
import { watchGetCurrentReproductionPerformance } from "./CurrentReproductionPerformance/saga";
import { watchGetFirstLuthealPhase } from "./FirstLutealPhase/saga";
import { watchHNInsightsSaga } from "./HNInsights/saga";
import { watchGetHNIRSaga } from "./HNIR/saga";
import { watchTryGetMaintenance } from "./Maintenance/saga";
import { watchGetODSaga } from "./OD/saga";
import { watchGetPBSSSaga } from "./PBSS/saga";
import { watchGetProportionOfDelayedSamples } from "./ProportionOfDelayedSamples/saga";
import { watchGetSEASaga } from "./SEA/saga";
import { watchSettingsSaga } from "./Settings/saga";
import { watchGetStickConsumption } from "./StickConsumption/saga";
import { watchFarmSaga } from "./Farms/saga";
import { watchGetPerformanceScoreHistorySaga } from "./PerformanceScoreHistory/saga";

export default function* rootSaga() {
  yield all([
    watchAuthenticateSaga(),
    watchGetStickConsumption(),
    watchGetCowsList(),
    watchGetFirstLuthealPhase(),
    watchGetAnoestrusLengthOverviewSaga(),
    watchGetCRBWPSaga(),
    watchGetHNIRSaga(),
    watchGetInseminationSuccessSaga(),
    watchGetSEASaga(),
    watchGetPBSSSaga(),
    watchGetODSaga(),
    watchSettingsSaga(),
    watchGetCurrentReproductionPerformance(),
    watchHNInsightsSaga(),
    watchGetAnoestrusLength(),
    watchGetProportionOfDelayedSamples(),
    watchGetBaselineReportSaga(),
    watchTryGetMaintenance(),
    watchFarmSaga(),
    watchGetPerformanceScoreHistorySaga(),
  ]);
}
