// import { IParams } from "./requests";
import { push } from "connected-react-router";
import store from "../redux/store";
import { getToken } from "../utills/authorization";
import { getXml } from "../utills/xmlBuilder";

export async function postJSON(url: string, params?: any) {
    const token = getToken();

    const response = await fetch(url, {
        method: "POST",
        headers: new Headers({
            "Authorization": `Bearer ${token}`,
            "Access-Control-Allow-Origin": "true",
            "Content-Type": "application/json",

        }),
        body: JSON.stringify(params),
    });

    const data = await response.json();

    return data;
}

export async function postXML(url: string, params?: any, filterName?: any) {
    const token = getToken();

    const response = await fetch(url, {
        method: "POST",
        headers: new Headers({
            "Authorization": `Bearer ${token}`,
            "Access-Control-Allow-Origin": "true",
        }),
        body: getXml(params, filterName),
    });

    if (response.status === 401) {
        store.dispatch(push("/login"));
    }

    if (response.status !== 204) {
        const data = await response.json();

        return data;
    }

}

export async function getParams(url: string) {
    const token = getToken();

    const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
            "Authorization": `Bearer ${token}`,
            "Access-Control-Allow-Origin": "true",
        }),
    });

    if (response.status === 401) {
        store.dispatch(push("/login"));
    } else {
        const data = await response.json();

        return data;
    }
}

export async function postParams(url: string, param: any) {
    const token = getToken();

    const response = await fetch(url, {
        method: "POST",
        headers: new Headers({
            "Authorization": `Bearer ${token}`,
            "Access-Control-Allow-Origin": "true",
        }),
        body: param, // getXml(params),
    });

    if (response.status === 401) {
        store.dispatch(push("/login"));
    } else {
        const data = await response.json();

        return data;
    }
}

export async function tokenRequest(url: string, params: any) {

    const response = await fetch(url, {
        method: "POST",
        body: `grant_type=password&userName=${params.username}&password=${params.password}`,
    });

    const data = await response.json();
    if (data.error) {
        return {
            error: data.error,
            error_description: data.error_description
        }
    }

    return {
        username: data.userName,
        token: data.access_token,
        expiration: data.expires_in,
    };
}
