import React from "react";
import { iCard } from "./interface";
import "../Card.scss";


const Card: React.FC<iCard> = (props) => {
    let className = `card ${props.embedded ? "card--embedded" : ""}`;
    if (props.className) className += ' ' + props.className;
    return (
        <div id={props.id} className={className}>
            {props.children}
        </div>
    )
};

export default Card;
