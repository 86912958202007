import React from "react";
import Placeholder from "../Placeholder";
import "./Questionaire.scss";


const QuestionaireLoader = (): JSX.Element => {
    return (
        <div className="questionaire">
                <div className="questionaire__container">
                    <Placeholder height={23} width={450} />
                    <div className="questionaire__answers-list">
                        <div className="questionaire__answer">
                            <Placeholder width={40} shape="circle" styles={{ marginRight: "36px" }} />
                            <Placeholder height={23} width={300} />
                        </div>
                        <div className="questionaire__answer">
                            <Placeholder width={40} shape="circle" styles={{ marginRight: "36px" }} />
                            <Placeholder height={23} width={300} />
                        </div>
                        <div className="questionaire__answer">
                            <Placeholder width={40} shape="circle" styles={{ marginRight: "36px" }} />
                            <Placeholder height={23} width={300} />
                        </div>
                        <div className="questionaire__answer">
                            <Placeholder width={40} shape="circle" styles={{ marginRight: "36px" }} />
                            <Placeholder height={23} width={300} />
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default QuestionaireLoader;
