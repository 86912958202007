import React from "react";
import "./StatusNumber.scss";
import { iStatusNumber } from "./interface";

const StatusNumber: React.FC<iStatusNumber> = (props) => {
    return (
        <div className={"status-number" + props.className ? (' ' + props.className) : '' }>
            <div className={props.right ? "status-number__wrapper-right" : "status-number__wrapper"} >
                <p className="status-number__number" id={props.id}>{props.number} {props.unit}</p>
                <div className="status-number__content">
                    {props.children}
                </div>
            </div>
            <div className={props.right ? "status-number__wrapper-right" : "status-number__wrapper"} >
                <div>
                    <h5 className="status-number__text">{props.text}</h5>
                </div>
            </div>
        </div>
    );
};

export default StatusNumber;
