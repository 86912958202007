import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentHeader, ContentHeadline } from "../../Content";
import { DataTypeToggle } from "../../DataType";
import { Column, Row } from "../../Layout";
import { DASHBOARD_TOGGLE } from "../../redux/Navigation/constants";
import "./Dashboard.scss";
import { BaselineReport, CurrentReproductionPerformance, Insights, ProportionOfDelayedSamples, StickConsumption } from "../../Cards";
import { Button } from "../../Input";
import { BaselineIcon, DashboardIcon, StickConsumptionIcon } from "../../Icons";
import Candle from "../../Candle";
import Modal from "../../Modal";
import { useDispatch, useSelector } from "react-redux";
import { TOGGLE_PRODUCT_TOUR } from "../../redux/Navigation/constants";
import { handleTriggerStartTour } from "../../utills/intercom";


const Dashboard: React.FC = () => {
    const { t } = useTranslation();

    const navigationState = useSelector((state: any) => state.navigationState);
    const dispatch = useDispatch();
    const handleToggleProductTourModal = () => dispatch({ type: TOGGLE_PRODUCT_TOUR });
    const handleTriggerHelpingTool = (id: number) => handleTriggerStartTour(id, handleToggleProductTourModal);
    const [isOver, handleMouseOver] = useState("");


    return (
        <Fragment>
            <ContentHeader>
                <ContentHeadline text={t("Dashboard")}>
                    <DataTypeToggle toogleId={DASHBOARD_TOGGLE} />
                    <Button
                        variant="secondary"
                        style={{ marginBottom: '20px' }}
                        onClick={handleToggleProductTourModal}
                        iconButton={true}
                        invisible={false}
                    >
                        <i className="fal fa-info" style={{paddingRight: '10px'}}/>
                            Get started
                    </Button>
                </ContentHeadline>
            </ContentHeader>
            <Row first={true} flex={1}>
                <Column first={true} flex={3}>
                    <Row first={true} flex={2}>
                        <StickConsumption link={{ pathname: "/hnadvice/stick-consumption" }} stepGuideId={173890} toogleId={DASHBOARD_TOGGLE} />
                    </Row>
                    <Row flex={2}>
                        <Insights />
                    </Row>
                    <Row last={true} flex={4}>
                        <BaselineReport
                            link={{ pathname: "/hnadvice/baseline-report" }}
                            simple={true}
                            headers={["", ""]}
                            toogleId={DASHBOARD_TOGGLE}
                        />
                    </Row>
                </Column>
                <Column last={true} flex={6}>
                    <Row>
                        <CurrentReproductionPerformance toogleId={DASHBOARD_TOGGLE} />
                    </Row>
                    <Row style={{ paddingBottom: '100px' }}>
                        <ProportionOfDelayedSamples />
                    </Row>
                </Column>
            </Row>


            <Modal
                visible={navigationState.productTourOpen}
                close={handleToggleProductTourModal}
                headline={"How can we help?"}
            >
                <p style={{ fontSize: "17px" }}>Choose which topic you would like to explore:</p>
                <div style={{ margin: "18px 0" }}>
                    <Button type="candle" onMouseOver={() => handleMouseOver("q1")} onMouseOut={() => handleMouseOver("")} onClick={() => handleTriggerHelpingTool(172605)}>
                        <Candle text="How is my current reproduction performance?" icon={<DashboardIcon size={20} color={isOver === "q1" ? "white" : "#4D4F5C"} />} />
                    </Button>

                    <Button type="candle" onMouseOver={() => handleMouseOver("q2")} onMouseOut={() => handleMouseOver("")} onClick={() => handleTriggerHelpingTool(201238)}>
                        <Candle text="How can I improve my reproduction performance?" icon={<i className="fas fa-chart-line" />} />
                    </Button>

                    <Button type="candle" onClick={() => handleTriggerHelpingTool(172594)} onMouseOver={() => handleMouseOver("q3")} onMouseOut={() => handleMouseOver("")}>
                        <Candle text="Am I spending too many sticks?" icon={<StickConsumptionIcon size={20} color={isOver === "q3" ? "white" : "#4D4F5C"} />} />
                    </Button>

                    <Button type="candle" onClick={() => handleTriggerHelpingTool(173053)} onMouseOver={() => handleMouseOver("q4")} onMouseOut={() => handleMouseOver("")}>
                        <Candle text="Did my reproduction performance improve after buying HN?" icon={<BaselineIcon size={20} color={isOver === "q4" ? "white" : "#4D4F5C"} />} />
                    </Button>
                    <Button type="candle" onClick={() => handleTriggerHelpingTool(173069)} onMouseOver={() => handleMouseOver("q5")} onMouseOut={() => handleMouseOver("")}>
                        <Candle text="I’m not sure where to start" icon={<i className="far fa-question-circle" />} />
                    </Button>
                </div>
            </Modal>
        </Fragment>
    );
}
    ;

export default Dashboard;
