import React from "react";
import { iIcon } from "./interface";

const Cross: React.FC<iIcon> = (props) => (
    <svg fill={props.color ? props.color : "currentColor"} stroke={props.color ? props.color : "currentColor"} width={props.size ? props.size : "38"} height={props.size ? props.size : "38"} version="1.1" id="Capa_1" xmlnsXlink="http://www.w3.org/1999/xlink" x="-6px" y="-6px" viewBox="0 0 172 172" xmlSpace="preserve">
        <path transform={`rotate(${props.rotate || 0} 100 100)`} fill={props.color ? props.color : "currentColor"} stroke={props.color ? props.color : "currentColor"} d="M146.537,1.047c-1.396-1.396-3.681-1.396-5.077,0L89.658,52.849c-1.396,1.396-3.681,1.396-5.077,0L32.78,1.047c-1.396-1.396-3.681-1.396-5.077,0L1.047,27.702c-1.396,1.396-1.396,3.681,0,5.077l51.802,51.802c1.396,1.396,1.396,3.681,0,5.077L1.047,141.46c-1.396,1.396-1.396,3.681,0,5.077l26.655,26.655c1.396,1.396,3.681,1.396,5.077,0l51.802-51.802c1.396-1.396,3.681-1.396,5.077,0l51.801,51.801c1.396,1.396,3.681,1.396,5.077,0l26.655-26.655c1.396-1.396,1.396-3.681,0-5.077l-51.801-51.801c-1.396-1.396-1.396-3.681,0-5.077l51.801-51.801c1.396-1.396,1.396-3.681,0-5.077L146.537,1.047z" />
    </svg>
)

export default Cross;
