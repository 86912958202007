import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { postParams } from "../../api/requests";
import { getUsername } from "../../utills/authorization";
import { FETCH_FARMS_LIST_FAILURE, FETCH_FARMS_LIST_REQUEST, FETCH_FARMS_LIST_SUCCESS } from "./constants";

function* farmsSaga(action: Action<any>): SagaIterator {
    try {
        const username = getUsername();
        
        if (!username) {
            return yield put({ type: FETCH_FARMS_LIST_FAILURE, payload: new Error("fetched farms failed, no username") });
        };
        const farms = yield call(postParams, "https://HNNEXTUX0301.herdnavigatorlive.com/farms", username);
        
        if (farms) {
            yield put({ type: FETCH_FARMS_LIST_SUCCESS, payload: farms });
        } else if (farms.error) {
            yield put({ type: FETCH_FARMS_LIST_FAILURE, payload: new Error("fetched farms failed") });
        }
    } catch (error) {
        yield put({ type: FETCH_FARMS_LIST_FAILURE, payload: error });
    }
}

export function* watchFarmSaga(): SagaIterator {
    yield takeEvery(FETCH_FARMS_LIST_REQUEST, farmsSaga);
}