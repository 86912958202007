import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { IBasicPayload } from "../../actions";
import { Api } from "../../api/Api";
import { postXML } from "../../api/requests";

import {
    FETCH_STICK_CONSUMPTION_CHART_DATA_FAILURE,
    FETCH_STICK_CONSUMPTION_CHART_DATA_REQUEST,
    FETCH_STICK_CONSUMPTION_CHART_DATA_SUCCESS,
    GET_STICK_CONSUMPTION_FAILURE,
    GET_STICK_CONSUMPTION_REQUEST,
    GET_STICK_CONSUMPTION_SUCCESS,
    GET_TOP_STICK_CONSUMING_COWS_FAILURE,
    GET_TOP_STICK_CONSUMING_COWS_REQUEST,
    GET_TOP_STICK_CONSUMING_COWS_SUCCESS,
} from "./constants";


function* getStickConsumptionTableData(action: Action<IBasicPayload>): SagaIterator {
    try {

        const payload = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/herd/stickconsumption");
        yield put({ type: GET_STICK_CONSUMPTION_SUCCESS, payload });
    } catch (error) {
        yield call(Api.utilities.errorHandling, error);
        yield put({ type: GET_STICK_CONSUMPTION_FAILURE, payload: "An error occured" });
    }
}

function* doGetTopStickConsumingCows(action: Action<IBasicPayload>): SagaIterator {
    try {

        const payload = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/herd/dashboard/topxhighstickconsumingcows");

        yield put({ type: GET_TOP_STICK_CONSUMING_COWS_SUCCESS, payload });
    } catch (error) {
        console.log(error);
        yield call(Api.utilities.errorHandling, error);
        yield put({ type: GET_TOP_STICK_CONSUMING_COWS_FAILURE, payload: "Unable to get data" });
    }
}

const weekNumber = (date: any): number => 
{
    let d:any = new Date();
    d.setFullYear(date.split('-')[0]);
    d.setMonth(date.split('-')[1] - 1);
    d.setDate(date.split('-')[2]);

    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));  

    return Math.ceil(( ( (d) / 86400000) + 1)/7);
}

function* getStickConsumptionChartData(action: any): SagaIterator {
    try {
        const payload = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/herd/stickconsumptionhistory", {
            KPIName: action.payload.kpi,
        });

        const newPayload = [{
            kpi: action.payload.kpi, 
            id: "Own performance",
            data: payload.YearData.map((el: any) => {
                return {
                    date : el.Key,
                    x: weekNumber(el.Key),
                    y: el.Value,
                };
            }),
        }, {
            id: "Benchmark",
            data: (payload.YearDataReferenceBenchmarkFarms ? payload.YearDataReferenceBenchmarkFarms.map((el: any) => {
                return {
                    date : el.Key,
                    x: weekNumber(el.Key),
                    y: el.Value,
                };
            }) : []),
        }, {
            id: "Previous own performance",
            data: (payload.YearDataReferenceOwnFarm ? payload.YearDataReferenceOwnFarm.map((el: any) => {
                return {
                    date : el.Key,
                    x: weekNumber(el.Key),
                    y: el.Value,
                };
            }) : []),
        }];

        yield put({
            type: FETCH_STICK_CONSUMPTION_CHART_DATA_SUCCESS,
            payload: { ...payload, newPayload},
        });

    } catch (error) {
        yield put({ type: FETCH_STICK_CONSUMPTION_CHART_DATA_FAILURE, payload: "Unable to get data" });
    }
}

export function* watchGetStickConsumption(): SagaIterator {
    yield takeEvery(GET_STICK_CONSUMPTION_REQUEST, getStickConsumptionTableData);
    yield takeEvery(GET_TOP_STICK_CONSUMING_COWS_REQUEST, doGetTopStickConsumingCows);
    yield takeEvery(FETCH_STICK_CONSUMPTION_CHART_DATA_REQUEST, getStickConsumptionChartData);
}
