import React, { forwardRef, isValidElement } from "react";
import Button from "../Input/Button";
import iCollapsible from './interface'
import "./Collabsible.scss";
import HoverGuide from "../HoverGuide";

const Collabsible = forwardRef((props: iCollapsible, ref: any) => {
  // const padding = props.level && props.level > 1 ? props.level * 18 : 36
  const isReactElement = isValidElement(props.icon)

  return (
    <div className="collabsible" ref={ref} style={{ backgroundColor: props.open && props.level > 1 ? "whitesmoke" : "" }}>
      <div className="header">
        <Button onClick={props.open ? props.onClose : props.onClick}>
          <div className="button-content">
            {isReactElement ? props.icon : <i className={`${props.icon}`} />}
            <p>{props.title}</p>
          </div>
        </Button>
        <div className="button-group">
          {props.button && <HoverGuide content="Go to chart">{props.button}</HoverGuide>}
          {!props.hideIcon && (
            <HoverGuide content={props.open ? 'Collaps menu' : 'Open menu'} >
              <Button iconButton={true} onClick={props.open ? props.onClose : props.onClick}>
                <div className="open-close">
                  {props.open ? (<i className="fas fa-times" />) : (<i className={"fas fa-angle-down"} />)}
                </div>
              </Button>
            </HoverGuide>
          )}
        </div>
      </div>
      {props.open && (
        <div
          style={{ padding: `0 ${props.level * 9}px` }}
        >
          {props.children}
        </div>
      )}
    </div>
  );
});

export default Collabsible;
