import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { contrastedThreeColorPaletteReversed, contrastedThreeColorPalette } from "../../styles/ColorCodes";
import { Card, CardContent, CardGuide, CardHeader, CardHeadline } from "../../Card";
import { GET_COWS_LIST_REQUEST, HANDLE_FILTER_COW_LIST_INITIALIZE_LOCAL, HANDLE_COW_LIST_SELECT, HANDLE_COW_LIST_HOVERED, HANDLE_SELECTION_COW } from "../../redux/CowsList/constants";
import { BarAreaStacked, ScatterChart } from "../../Charts";
import { SetBarNormal, SetBarHovered, SetBarSelected } from "../../Charts/BarAreaStacked";
import { ContentHeader, ContentHeadline } from "../../Content";
import { SELECT_DATA_TME_PERIOD, SELECT_LACTATION_FILTERS } from "../../redux/Filters/constants";
import { IFilterState } from "../../redux/Filters/reducer";
import { OPEN_COW_LIST, SET_LIBRARY_OPEN_URL } from "../../redux/Navigation/constants";
import { ChartTypeToggle } from "../../ChartType";
import { handleTriggerStartTour } from "../../utills/intercom";
import { GET_PERFORMANCE_SCORE_HISTORY_REQUEST } from "../../redux/PerformanceScoreHistory/constants";
import KPIPageFilters from "../../KPIPageFilters";
import { Column, Row } from "../../Layout";
import OverView12Month from "../../Cards/OverView12Month"
import { BarExtendedDatum } from "@nivo/bar";
import { iPoint } from "@src/Charts/ScatterChart/interface";

const AIS: React.FC = (props) => {

    const filtersState: IFilterState = useSelector((state: any) => state.filtersState);
    const cowsListState = useSelector((state: any) => state.cowsListState);
    const navigationState = useSelector((state: any) => state.navigationState);
    const performanceScoreHistoryState = useSelector((state: any) => state.performanceScoreHistoryState);

    const [secondaryExpandedState, handleToggleSecondaryExpandedState] = useState(false);

    // const [ScatterPlotColorPalette, handleUpdateScatterPlotColorPalette] = useState(contrastedThreeColorPaletteReversed)

    const dispatch = useDispatch();
    const keys = ["Inseminated", "Not Inseminated"];
    const filters: any = {};

    useEffect(() => {
        const startDate = new Date(filtersState.today);
        startDate.setDate(startDate.getDate() - filtersState.anoestrusLengthSelectedTimePeriod.days);
        startDate.setDate(startDate.getDate() - 25);// add 25 days
        filtersState.lactationFilters.culledChecked = filtersState.lactationFilters.l1Checked = filtersState.lactationFilters.l2Checked = filtersState.lactationFilters.l3Checked = true;

        // handleUpdateScatterPlotColorPalette(contrastedThreeColorPaletteReversed)
        dispatch({
            type: GET_COWS_LIST_REQUEST,
            payload: {
                filters,
                keys,
                path: "ais",
                startDate: startDate,
                stopDate: filtersState.today,
                lactationFilters: filtersState.lactationFilters,
            },
        });

        dispatch({ type: GET_PERFORMANCE_SCORE_HISTORY_REQUEST, payload: { kpi: "AIS" } });
    }, [dispatch, filtersState.anoestrusLengthSelectedTimePeriod, filtersState.today]);

    const handleSelectTimePeriod = (e: any, key: string) => dispatch({ type: SELECT_DATA_TME_PERIOD, payload: { index: e.target.selectedIndex, key } });

    const handleToggleLactationFilter = (key: string) => {
        SetBarNormal(cowsListState.selected)
        dispatch({ type: HANDLE_COW_LIST_SELECT, payload: undefined });

        dispatch({ type: SELECT_LACTATION_FILTERS, payload: { key } });
        const newFilter = filtersState.lactationFilters;
        newFilter[`${key}Checked`] = !newFilter[`${key}Checked`];
        dispatch({
            type: HANDLE_FILTER_COW_LIST_INITIALIZE_LOCAL,
            payload: {
                filters,
                keys,
                path: "ais",
                data: cowsListState.data,
                lactationFilters: newFilter,
                p2reference: cowsListState.p2reference,
                p3reference: cowsListState.p3reference,
            },
        });
    }

    const handleSetCowListFilter = (filters: any, keys: string[], scatter?: boolean) => {
        if (scatter) dispatch({ type: OPEN_COW_LIST });

        dispatch({
            type: HANDLE_FILTER_COW_LIST_INITIALIZE_LOCAL,
            payload: {
                filters: (scatter ? filters : (cowsListState.selected === cowsListState.hovered ? {} : filters)),
                keys,
                path: "ais",
                data: cowsListState.data,
                lactationFilters: filtersState.lactationFilters,
                p2reference: cowsListState.p2reference,
                p3reference: cowsListState.p3reference,
            },
        });
    }

    const handleSelection = (data: iPoint[], keys: string[]) => {
        SetBarNormal(cowsListState.selected)
        dispatch({ type: OPEN_COW_LIST })
        dispatch({
            type: HANDLE_SELECTION_COW,
            payload: {
                keys,
                path: "ais",
                data: cowsListState.data,
                selection: data
            },
        });
    }

    const handleShowCows = (elem: any) => {
        if (cowsListState.selected !== cowsListState.hovered) {
            if (cowsListState.hovered !== undefined) {
                // set selected
                SetBarSelected(cowsListState.hovered);
            }
            if (cowsListState.selected !== undefined) {
                // clear selected
                SetBarNormal(cowsListState.selected);
            }
            dispatch({ type: HANDLE_COW_LIST_SELECT, payload: cowsListState.hovered });
        } else {
            if (cowsListState.selected !== undefined) {
                // de-select selected, so set hovered
                SetBarHovered(cowsListState.selected)
            }
            dispatch({ type: HANDLE_COW_LIST_SELECT, payload: undefined });
        }

        dispatch({ type: OPEN_COW_LIST });
    }

    const handleMouseEnter = (elem: BarExtendedDatum, event: React.MouseEvent) => {
        dispatch({ type: HANDLE_COW_LIST_HOVERED, payload: event.currentTarget });
    };

    const handleSetUrl = (url: string) => dispatch({ type: SET_LIBRARY_OPEN_URL, payload: url });

    return (
        <Fragment>
            <Row first={true} flex={1}>
                <Column flex={3} first={true}>
                    <ContentHeader>
                        <ContentHeadline text="Insemination success" subHeader="Evaluates the number of Successful Inseminations based on progesterone level on 25 days from insemination (DFAI)." />
                        <KPIPageFilters handleSelectTimePeriod={handleSelectTimePeriod} filtersState={filtersState} handleToggleLactationFilter={handleToggleLactationFilter} />
                    </ContentHeader>
                </Column>
                <Column flex={2}>
                    <OverView12Month
                        performanceScore={performanceScoreHistoryState}
                        stepGuide={178803}
                        kpi={"ais"}
                    />
                </Column>
            </Row>
            <Row last={true} flex={3}>
                <Column first={true} flex={secondaryExpandedState ? 1 : 3}>
                    <Card>
                        <CardHeader>
                            <CardHeadline text="Insemination Success on Days in Milk" />
                            <CardGuide
                                onOpenLib={() => handleSetUrl("ais/lvl3/isodim/summary")}
                                openStepGuide={() => handleTriggerStartTour(178796)}
                                toggleExpand={() => handleToggleSecondaryExpandedState(!secondaryExpandedState)}
                                isExpanded={!secondaryExpandedState}
                            />
                        </CardHeader>
                        <CardContent>
                            <ScatterChart
                                colors={contrastedThreeColorPalette}
                                data={cowsListState.scatterP1}
                                yLegend="Insemination number"
                                xLegend="DIM on Insemination"
                                filter={(filters: any) => handleSetCowListFilter(filters, ["DaysFromCalving", "AINumber"], true)}
                                stepSizeX={20}
                                stepSizeY={2}
                                reverseOrder
                                onSelection={(data: iPoint[]) => handleSelection(data, ["DaysFromCalving", "AINumber"])}
                            />
                        </CardContent>
                    </Card>
                </Column>
                <Column last={true} flex={secondaryExpandedState ? 3 : 1}>
                    <Card>
                        <CardHeader>
                            {/*<CardHeadline text="Timing of Insemination" />*/}
                            <ChartTypeToggle />
                            <CardGuide
                                openStepGuide={() => handleTriggerStartTour(178800)}
                                toggleExpand={() => handleToggleSecondaryExpandedState(!secondaryExpandedState)}
                                isExpanded={secondaryExpandedState}
                            />
                        </CardHeader>
                        <CardContent>
                            {navigationState.chartType === 0 &&
                                <BarAreaStacked
                                    colors={contrastedThreeColorPaletteReversed}
                                    filter={(filters: any) => handleSetCowListFilter(filters, ["HoursBetweenHeatAndAI", "InseminationState"])}
                                    filterKeys={[{ key: "InseminationState", value: "id" }, { key: "ranges", value: "indexValue" }]}
                                    tooltipHeadline={`First luteal phase length [days]`}
                                    data={cowsListState.graphP3Data}
                                    keys={["Pregnant", "Potentially pregnant", "Not pregnant"]}
                                    indexBy="Bin"
                                    yLegend="Number of inseminations"
                                    xLegend="Hours between heat and AI"
                                    onClick={(elem: any) => handleShowCows(elem)}
                                    maxValue={cowsListState.graphP3Max}
                                    onMouseEnter={(elem: BarExtendedDatum, event: React.MouseEvent) => handleMouseEnter(elem, event)}
                                    selected={cowsListState.selected}
                                />}
                            {navigationState.chartType === 1 &&
                                <ScatterChart
                                    colors={contrastedThreeColorPalette}
                                    data={cowsListState.scatterP2}
                                    yLegend="Follicular phase length in days"
                                    xLegend="Hours between heat and AI"
                                    filter={(filters: any) => handleSetCowListFilter(filters, ["FPLength", "HoursBetweenHeatAndAI"], true)}
                                    stepSizeX={10}
                                    stepSizeY={2}
                                    reverseOrder
                                    onSelection={(data: iPoint[]) => handleSelection(data, ["HoursBetweenHeatAndAI", "FPLength"])}
                                />
                            }
                        </CardContent>
                    </Card>
                </Column>
            </Row>
        </Fragment>
    );
};

export default AIS;

