import React from "react";
import "./Placeholder.scss";
import { IProps } from "./types";

const Placeholder = (props: IProps): JSX.Element => {
    const styles = props.shape === "circle" ? {
        ...props.styles,
        borderRadius: `${props.width}px`,
        height: `${props.width}px`,
        width: `${props.width}px`,
    } : {
        ...props.styles,
        height: `${props.height}px`,
        width: `${props.width}px`,
    };

    return (
        <div className="placeholder" style={styles} />
    );
};

export default Placeholder;
