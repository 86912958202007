import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { postXML } from "../../api/requests";
import { boil, merge } from "../../utills/dataFormatting";

import {
    GET_ANOESTRUS_LENGTH_FAILURE,
    GET_ANOESTRUS_LENGTH_REQUEST,
    GET_ANOESTRUS_LENGTH_SUCCESS,
} from "./constants";

function* getAnoestrusLengthSaga(action: Action<any>): SagaIterator {
    try {
        const farmData = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/doa/cicdetail", {
            startDate: action.payload.startDate,
            stopDate: action.payload.stopDate,
            L1: action.payload.filters.l1Checked ? 1 : 0,
            L2: action.payload.filters.l2Checked ? 1 : 0,
            L3P: action.payload.filters.l3Checked ? 1 : 0,
        });
        

        const boiledFarmData = boil(farmData, "BinNumber", "IsComplete", "NumberOfRecords", "Ranges", ["Cyclic", "Non-Cyclic"]);

        const reference = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/refp2p3/doa/p3");

        const mergedData = merge(boiledFarmData, reference, "YValueL3P")

        yield put({ type: GET_ANOESTRUS_LENGTH_SUCCESS, payload: mergedData });
    } catch (error) {
        console.log(GET_ANOESTRUS_LENGTH_FAILURE, error);
        yield put({ type: GET_ANOESTRUS_LENGTH_FAILURE, payload: "Unable to get data" });
    }
}

export function* watchGetAnoestrusLength(): SagaIterator {
    yield takeEvery(GET_ANOESTRUS_LENGTH_REQUEST, getAnoestrusLengthSaga);
}
