import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { postXML } from "../../api/requests";
import { convertToOverviewChartDataOnShortDate } from "../../utills/dataFormatting";

import {
    GET_HNIR_REQUEST,
    GET_HNIR_SUCCESS,
    GET_HNIR_FAILURE,
} from "./constants";

function* getHNIRSaga(action: Action<any>): SagaIterator {
    try {
        const scoreData = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/hnir/cicdistribution", {
            startDate: action.payload.startDate,
            stopDate: action.payload.stopDate,
        });

        const convertedChartData = convertToOverviewChartDataOnShortDate(scoreData);

        yield put({ type: GET_HNIR_SUCCESS, payload: { scoreData: convertedChartData} });
    } catch (error) {
        console.log(GET_HNIR_FAILURE, error);
        yield put({ type: GET_HNIR_FAILURE, payload: "Unable to get data" });
    }
}

export function* watchGetHNIRSaga(): SagaIterator {
    yield takeEvery(GET_HNIR_REQUEST, getHNIRSaga);
}
