import { ResponsiveBar } from "@nivo/bar"
import { area, curveMonotoneX } from "d3-shape";
import React, { useLayoutEffect, useRef, useState } from "react";
import { defaultColorPalette, gray } from "../../styles/ColorCodes";
import Tooltip from "../../Tooltip";
import { iBarAreaStacked } from "./interface";

const Area = (el: any) => {
  const areaGenerator = area().curve(curveMonotoneX)
    .x((bar: any) => {
      if (Object.keys(el.bars).length > 0) {
        if (bar["Ranges"]) return el.xScale(bar["Ranges"]) + (bar["BinNumber"] - 1) / (Object.keys(el.data).length - 1) * el.bars[0].width;
        if (bar["Bin"]) return el.xScale(bar["Bin"]) + (bar["BinNumber"]) / (Object.keys(el.data).length - 1) * el.bars[0].width;
      }
      return 0;
    })
    .y0(() => el.height)
    .y1((bar: any) => {
      return el.yScale(bar.reference);
    });

  return (
    <path
      d={areaGenerator(el.data) || undefined}
      fill={gray}

      style={{ mixBlendMode: "multiply", pointerEvents: "none" }}
    />
  );
};

const BarAreaStacked: React.FC<iBarAreaStacked> = (props) => {

  const targetRef = useRef<HTMLHeadingElement>(null);
  const [dimensions, setDimensions] = useState({ height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current && targetRef.current.parentElement) {
      setDimensions({
        height: targetRef.current.parentElement.offsetHeight,
      });
    }
  }, []);

  // if the first data array has zero element, the list of colors will use the first color for the second data list, therefore we change the color input 
  let dataArrayZeroHasElement: boolean = false;
  if (props.data)
    dataArrayZeroHasElement = props.data.some((element: any) => {
      if (props.keys && element[props.keys[0]] > 0) return true;
      return false;
    });

  return (
    <div ref={targetRef} style={{ height: `${dimensions.height}px`, width: `99%` }}>
      <ResponsiveBar
        tooltip={(el: any, some: any) => <Tooltip point={el} headline={props.tooltipHeadline} disableWrapper={true} />}
        onClick={(elem: any) => {
          if (props.onClick) props.onClick(elem);
          // props.filterKeys
          const params: any = {};

          props.filterKeys?.forEach((el: any) => {
            params[el.key] = elem[el.value];
          });

          // @ts-ignore TODO: do null check
          props.filter(params);
        }}
        data={props.data || []}
        keys={props.keys}
        indexBy={props.indexBy}
        margin={{ top: 60, right: 60, bottom: 60, left: 60 }}
        padding={0.3}

        colors={props.colors || defaultColorPalette}

        maxValue={props.maxValue || "auto"}

        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: props.yLegend,
          legendPosition: "middle",
          legendOffset: -40,
        }}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: props.xLegend,
          legendPosition: "middle",
          legendOffset: 46,
        }}
        axisTop={null}
        axisRight={null}
        enableGridX={false}
        enableGridY={true}
        enableLabel={false}
        legends={[]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        layers={["tooltip" as any, "grid" as any, "axes" as any, Area as any, "bars" as any]}
        onMouseEnter={(elem, event) => {
          if (!props.onClick) return;
          event.currentTarget.style.cursor = "pointer"
          if (props.onMouseEnter) props.onMouseEnter(elem, event);

          if (props.selected === undefined || event.currentTarget !== props.selected) SetBarHovered(event.currentTarget)
        }}
        onMouseLeave={(elem, event) => {
          if (!props.onClick) return;
          event.currentTarget.style.cursor = "auto"

          if (props.selected === undefined || event.currentTarget !== props.selected) SetBarNormal(event.currentTarget);
        }}
      />
    </div>

  );
};

export const SetBarNormal = (bar: SVGRectElement | undefined) => {
  if (bar === undefined) return;

  bar.style.strokeDasharray = "0"
  bar.style.strokeWidth = "0"
  bar.style.opacity = "1"
  bar.style.stroke = bar.style.color
}

export const SetBarHovered = (bar: SVGRectElement | undefined) => {
  if (bar === undefined) return;

  bar.style.strokeDasharray = "0"
  bar.style.strokeWidth = "2"
  bar.style.opacity = "0.8"
  bar.style.stroke = bar.style.color
}

export const SetBarSelected = (bar: SVGRectElement | undefined) => {
  if (bar === undefined) return;

  bar.style.strokeDasharray = "5,5"
  bar.style.strokeWidth = "4"
  bar.style.opacity = "0.8"
  bar.style.stroke = "black"
}

export default BarAreaStacked;
