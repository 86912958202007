import { FETCH_FARMS_LIST_FAILURE, FETCH_FARMS_LIST_REQUEST, FETCH_FARMS_LIST_SUCCESS } from "./constants";

export const initialState: any = {
    loading: false,
    error: null,
    farms: [],
};

export const FarmsReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case FETCH_FARMS_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
                farms: []
            };
        }

        case FETCH_FARMS_LIST_SUCCESS: {
            let result = {
                ...state,
                loading: false,
                farms: action.payload,
            };
            return result;
        }

        case FETCH_FARMS_LIST_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload || action.error
            };
        }
        default:
            return state;
    }
};
