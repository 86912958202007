import { ResponsiveBar } from '@nivo/bar'
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardGuide, CardHeader, CardHeadline } from "../../Card";
import Loader from "../../Loader";
import {
    GET_PROPORTION_OF_DELAYED_SAMPLES_REQUEST,
} from "../../redux/ProportionOfDelayedSamples/constants";
import "./ProportionOfDelayedSamples.scss";

// const data = [
//     {
//       index: "own",
//       sticks: 60,
//     },
//     {
//         index: "other",
//         sticks: 23,
//       },
//   ];

const ProportionOfDelayedSamples: React.FC = (props) => {

    const proportionOfDelayedSamplesState = useSelector((state: any) => state.proportionOfDelayedSamplesState);

    // const targetRef = useRef<HTMLHeadingElement>(null);
    // const [dimensions, setDimensions] = useState({ width: 0 });

    const dispatch = useDispatch();

    useEffect(() => {
        // get questions when component is mounted
        dispatch({ type: GET_PROPORTION_OF_DELAYED_SAMPLES_REQUEST, payload: null });

        // tell hook to skip re-render if state hasnt changed
    }, [dispatch]);

    // useLayoutEffect(() => {
    //     if (targetRef.current && targetRef.current.parentElement) {
    //         setDimensions({
    //             width: targetRef.current.parentElement.offsetWidth,
    //         });
    //     }
    // }, []);

    return (
        <div className="proportion-of-delayed-samples">
            <Card>
                <CardHeader>
                    <CardHeadline text="Proportion of delayed samples" />
                    <CardGuide />
                </CardHeader>
                <div style={{ height: "30px", margin: "18px" }}>
                    {proportionOfDelayedSamplesState.data ? (
                        <ResponsiveBar
                            layout={"horizontal"}
                            data={proportionOfDelayedSamplesState.gotData ? [{
                                "index": " ",
                                "On time": parseInt(proportionOfDelayedSamplesState.data?.GreenPercentage, 10),
                                "Delayed 24-48 hours": parseInt(proportionOfDelayedSamplesState.data?.YellowPercentage, 10),
                                "Delayed 48+ hours": parseInt(proportionOfDelayedSamplesState.data?.RedPercentage, 10),

                            }] : [{
                                "index": " ",
                                "On time": 0,
                                "Delayed 24-48 hours": 0,
                                "Delayed 48+ hours": 0,
                            }]}
                            keys={["On time", "Delayed 24-48 hours", "Delayed 48+ hours"]}
                            indexBy="index"
                            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                            colors={["green", "yellow", "red"]}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "country",
                                legendPosition: "middle",
                                legendOffset: 32,
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "food",
                                legendPosition: "middle",
                                legendOffset: -40,
                            }}
                            enableGridY={false}
                            enableLabel={false}
                            labelTextColor={{ from: "color", modifiers: [["darker", 0]] }}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                        />
                    ) : (
                            <Loader height={30} />
                        )}
                </div>
            </Card>
        </div>
    );
};

export default ProportionOfDelayedSamples;
