import {
  AUTHENTICATE_REQUEST,
  AUTHENTICATE_SUCCESS,
  AUTHENTICATE_FAILURE,
} from "./constants";

export interface IState {
  startDate: Date;
  stopDate: Date;
  gotData: boolean;
  loading: boolean;
  error: null | string;
  data: any;
}

export const initialState: any = {
  gotData: false,
  loading: false,
  error: null,
  scoreData: [],
};

export const AuthentificationReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case AUTHENTICATE_REQUEST:
      return {
        ...state,
        gotData: false,
        loading: true,
        error: null,
      };
    case AUTHENTICATE_SUCCESS:

      const newState = {
        ...state,
        loading: false,
        gotData: true,
      };

      return newState;

    case AUTHENTICATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
