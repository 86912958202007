import React from "react";
import iCheckbox from "./interface";
import './style.scss';

const CheckboxStylish: React.FC<iCheckbox> = (props) => {
  const svgStylish = <svg fill="rgb(1, 79, 139)" className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path></svg>
  const svgEmpty = <svg fill="#999" className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path></svg>
  const svg1 = <svg fill={props.checked ? "rgb(1, 79, 139)" : "#999"} className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M 19 3 H 5 c -1.11 0 -2 0.9 -2 2 v 14 c 0 1.1 0.89 2 2 2 h 14 c 1.11 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.89 -2 -2 -2 Z m -8 5 l -3 1 l 0 -3 L 11 5 l 3 0 L 14 18 l -3 0 z"></path></svg>;
  const svg2 = <svg fill={props.checked ? "rgb(1, 79, 139)" : "#999"} className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M 19 3 H 5 c -1.11 0 -2 0.9 -2 2 v 14 c 0 1.1 0.89 2 2 2 h 14 c 1.11 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.89 -2 -2 -2 Z M 6 9 C 7 4 17 4 17 9 C 17 12 14 14 13 16 L 18 16 L 18 18 L 6 18 C 6 18 6 17 7 16 C 8.6667 14.3333 13 11 13 9 C 13 7 9 7 9 10 Z"></path></svg>;
  const svg3 = <svg fill={props.checked ? "rgb(1, 79, 139)" : "#999"} className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M 19 3 H 5 c -1.11 0 -2 0.9 -2 2 v 14 c 0 1.1 0.89 2 2 2 h 14 c 1.11 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.89 -2 -2 -2 Z M 7.5882 7.3735 V 4.624 h 8.7687 v 2.2294 l -2.9725 3.344 c 1.1146 0.223 2.0064 0.6688 2.6752 1.4862 c 0.5945 0.7431 0.9661 1.6349 0.9661 2.6752 c 0 1.4862 -0.5202 2.6752 -1.5606 3.5669 s -2.3037 1.2633 -3.9385 1.2633 c -1.5606 0 -3.1954 -0.5945 -4.8302 -1.7092 l 1.3376 -2.5266 c 1.3376 0.9661 2.602 1.3538 3.6412 1.4119 c 0.7928 -0.0361 1.0578 -0.1571 1.5606 -0.4458 c 0.4458 -0.2973 0.5945 -0.7431 0.5945 -1.3376 s -0.3743 -0.9857 -0.7431 -1.4119 c -0.4458 -0.3716 -1.1146 -0.5202 -2.0064 -0.5202 c -0.4458 0 -1.1146 0.1486 -1.9321 0.3716 v -2.378 l 2.7495 -3.2697 L 7.5882 7.3735 L 7.5882 7.3735 z"></path></svg>;
  function renderSvg() {
    if (props.checked === false && props.variant === 'stylish') return svgEmpty;

    switch (props.variant) {
      case 'stylish': return svgStylish;
      case '1': return svg1;
      case '2': return svg2;
      case '3': return svg3;
    }
  }
  return <div className="checkbox-stylish">
    <span>
      <input type='checkbox' data-indeterminate="false" aria-label="secondary checkbox" onChange={props.onChange} checked={props.checked} />
      {renderSvg()}
    </span>
  </div>
}

export default CheckboxStylish;