import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, CardGuide, CardHeader, CardHeadline } from "../../Card";
import { useTranslation } from 'react-i18next';
import { KPISpider } from "../../Charts";
import { DataTypeDisplay } from "../../DataType";
import {
  GET_CURRENT_REPRUDCTION_PERFORMANCE_REQUEST,
  GET_KPI_CHART_DATA_REQUEST,
} from "../../redux/CurrentReproductionPerformance/constants";
import { SET_LIBRARY_OPEN_URL, SET_CRP_DB_HOVERED, DASHBOARD_TOGGLE, STICK_CONSUMPTION_TOGGLE, COLOR_SCREME_1, COLOR_SCREME_2 } from "../../redux/Navigation/constants";
import StatusNumber from "../../StatusNumber";
import "./CurrentReproductionPerformance.scss";
import LattecIcon from "../../LattecIcon";
import { handleTriggerStartTour } from "../../utills/intercom";
import { Button } from "../../Input";
import { iCurrentRepPerf } from "./interface";
import PlaceholderLoader from "../../PlaceholderLoader";

const CurrentReproductionPerformance: React.FC<iCurrentRepPerf> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentReproductionPerformanceState = useSelector((state: any) => state.currentReproductionPerformanceState);
  const navigationState = useSelector((state: any) => state.navigationState);
  const { t } = useTranslation();

  let dataType: number;
  if (props.toogleId === DASHBOARD_TOGGLE) dataType = navigationState.dataType;
  else if (props.toogleId === STICK_CONSUMPTION_TOGGLE) dataType = navigationState.dataStickConsumptionType;
  else dataType = navigationState.dataBaseLineReportType;

  useEffect(() => {
    dispatch({ type: GET_CURRENT_REPRUDCTION_PERFORMANCE_REQUEST, payload: null });
    dispatch({ type: GET_KPI_CHART_DATA_REQUEST, payload: { dataType } });
  }, [dispatch, navigationState.dataType, navigationState.dataStickConsumptionType, navigationState.dataBaseLineReportType]);

  const handleSetUrl = (url: string) => dispatch({ type: SET_LIBRARY_OPEN_URL, payload: url });

  const handleMouseOver = (hovered: string[]) => dispatch({ type: SET_CRP_DB_HOVERED, payload: hovered });
  const isOver = (el: string) => navigationState.currentReproductionPerformanceDbHovered.includes(el);

  return (
    <Fragment>
      <div className="current-reproduction-performance" id="current-reproduction-performance">
        <Card embedded={props.embedded}>
          {!props.embedded && (
            <CardHeader>
              <CardHeadline icon={<LattecIcon size={17} icon="kpi" />} text={t("CurrentReproductionPerformance")} />
              <CardGuide
                onOpenLib={() => handleSetUrl("crp/lvl1/summary")}
                openStepGuide={() => handleTriggerStartTour(173456)}
              />
            </CardHeader>
          )}
          <Fragment>
            {!props.simple && (

              <div className="current-reproduction-performance__statistics" id="current-reproduction-performance-statistics">
                <div className="current-reproduction-performance__number">
                  {
                    currentReproductionPerformanceState.gotData ?
                      <Button
                        type="data-point"
                        hovered={props.embedded ? () => null : isOver("hnir")}
                        onMouseOver={props.embedded ? () => null : () => handleMouseOver(["hnir"])}
                        onMouseOut={props.embedded ? () => null : () => handleMouseOver([""])}
                        onClick={props.embedded ? () => null : () => history.push(`/hnadvice/hnir`)}
                      >
                        <StatusNumber
                          number={currentReproductionPerformanceState.data.HNInseminationRate}
                          unit="%"
                          text={t("HNInseminationRate")}
                        >
                          <DataTypeDisplay
                            id="current-reproduction-performance-value-value-bar"
                            referenceValudId="current-reproduction-performance-value-reference-bar"
                            colors={dataType === 0 ? COLOR_SCREME_1 : COLOR_SCREME_2}
                            dataType={dataType}
                            trend={currentReproductionPerformanceState.data.HNInseminationRateTrendIndicator}
                            benchmarkComparison={currentReproductionPerformanceState.data.HNInseminationRateBenchmarkFarmsStickCount}
                            current={parseFloat(currentReproductionPerformanceState.data.HNInseminationRate)}
                            ownComparison={currentReproductionPerformanceState.data.HNInseminationRateOwnFarmStickCont}
                          />
                        </StatusNumber>
                      </Button>
                      :
                      <PlaceholderLoader type='CurrentReproductionPerformance' width='174' height='94' />
                  }
                </div>
                <div className="current-reproduction-performance__number">
                  {
                    currentReproductionPerformanceState.gotData ?
                      <Button
                        type="data-point"
                        hovered={props.embedded ? () => null : isOver("ais")}
                        onMouseOver={props.embedded ? () => null : () => handleMouseOver(["ais"])}
                        onMouseOut={props.embedded ? () => null : () => handleMouseOver([""])}
                        onClick={props.embedded ? () => null : () => history.push(`/hnadvice/ais`)}
                      >
                        <StatusNumber
                          number={currentReproductionPerformanceState.data.SuccessOnFirstAI}
                          unit="%"
                          text={t("SuccessOnFirstAI")}
                        >
                          <DataTypeDisplay
                            referenceValudId="current-reproduction-performance-reference-value-bar"
                            colors={dataType === 0 ? COLOR_SCREME_1 : COLOR_SCREME_2}
                            dataType={dataType}
                            trend={currentReproductionPerformanceState.data.SuccessOnFirstAITrendIndicator}
                            benchmarkComparison={currentReproductionPerformanceState.data.SuccessOnFirstAIBenchmarkFarmsStickCount}
                            current={parseFloat(currentReproductionPerformanceState.data.SuccessOnFirstAI)}
                            ownComparison={currentReproductionPerformanceState.data.SuccessOnFirstAIOwnFarmStickCont}

                          />
                        </StatusNumber>
                      </Button>
                      :
                      <PlaceholderLoader type='CurrentReproductionPerformance' width='174' height='94' />
                  }
                </div>
                <div className="current-reproduction-performance__number">
                  {
                    currentReproductionPerformanceState.gotData ?
                      <Button
                        type="data-point"
                        hovered={props.embedded ? () => null : isOver("cr")}
                        onMouseOver={props.embedded ? () => null : () => handleMouseOver(["cr"])}
                        onMouseOut={props.embedded ? () => null : () => handleMouseOver([""])}
                        onClick={props.embedded ? () => null : () => history.push(`/hnadvice/cr`)}
                      >
                        <StatusNumber
                          number={currentReproductionPerformanceState.data.IrregularCycles}
                          unit="%"
                          text={t("RegularCycles")}
                        >
                          <DataTypeDisplay
                            referenceValudId="current-reproduction-performance-reference-value-bar"
                            colors={dataType === 0 ? COLOR_SCREME_1 : COLOR_SCREME_2}
                            dataType={dataType}
                            trend={currentReproductionPerformanceState.data.IrregularCyclesTrendIndicator}
                            benchmarkComparison={currentReproductionPerformanceState.data.IrregularCyclesBenchmarkFarmsStickCount}
                            current={parseFloat(currentReproductionPerformanceState.data.IrregularCycles)}
                            ownComparison={currentReproductionPerformanceState.data.IrregularCyclesOwnFarmStickCont}
                          />
                        </StatusNumber>
                      </Button>
                      :
                      <PlaceholderLoader type='CurrentReproductionPerformance' width='174' height='94' />
                  }
                </div>
              </div>
            )}
          </Fragment>
          {/* ) : null} */}
          <div className={`current-reproduction-performance__chart-table ${props.embedded ? "current-reproduction-performance__chart-table--embedded" : ""}`} id="current-reproduction-performance-chart-and-table">
            <div className="current-reproduction-performance__chart" id="current-reproduction-performance-chart">
              {
                currentReproductionPerformanceState.loading ?
                  <PlaceholderLoader type='Spiderchart' width="555" height="555" />
                  :
                  <KPISpider
                    data={currentReproductionPerformanceState.kpiChartData}
                    colors={dataType === 0 ? COLOR_SCREME_1 : COLOR_SCREME_2}
                    dataType={dataType}
                  />
              }
            </div>
            <div className="current-reproduction-performance__table" id="current-reproduction-performance-table">
              {props.embedded ? currentReproductionPerformanceState.kpiChartData.map((el: any) => {
                return (
                  <Button
                    key={el.key}
                    hovered={isOver(el.key)}
                    type="table-element"
                  >
                    <div className="current-reproduction-performance__table-element">
                      <div className="current-reproduction-performance__icon">
                        <LattecIcon icon={el.key} color={isOver(el.key) ? "white" : "#103D82"} />
                      </div>
                      <p className="current-reproduction-performance__text">{el.kpi}</p>
                    </div>
                    <p className="current-reproduction-performance__text">
                      {el.value ? (
                        <Fragment>
                          <span>{el.value}</span>
                          <i style={{ color: el.trend < 0 ? "red" : el.trend > 0 ? "green" : "gray" }} className={`fas fa-${el.trend < 0 ? "arrow-alt-circle-down" : el.trend > 0 ? "arrow-alt-circle-up" : "grip-lines"} current-reproduction-performance__kpi-chart-icon`} />
                        </Fragment>
                      ) : (
                          <span>Not available</span>
                        )}
                    </p>
                  </Button>
                )
              }) : currentReproductionPerformanceState.kpiChartData.map((el: any, index: number) => {
                return (
                  <Button
                    key={el.key}
                    hovered={isOver(el.key)}
                    onMouseOver={() => handleMouseOver([el.key])}
                    onMouseOut={() => handleMouseOver([""])}
                    type="table-element"
                    onClick={() => history.push(`/hnadvice/${el.key}`)}
                  >
                    <div className="current-reproduction-performance__table-element">
                      <div className="current-reproduction-performance__icon">
                        <LattecIcon icon={el.key} color={isOver(el.key) ? "white" : "#103D82"} />
                      </div>
                      <p className="current-reproduction-performance__text">{el.kpi}</p>
                    </div>
                    <p className="current-reproduction-performance__text">
                      {el.value ? (
                        <Fragment>
                          <span >{el.value}</span>
                          <i style={{ color: el.trend < 0 ? "red" : el.trend > 0 ? "green" : "gray" }} className={`fas fa-${el.trend < 0 ? "arrow-alt-circle-down" : el.trend > 0 ? "arrow-alt-circle-up" : "grip-lines"} current-reproduction-performance__kpi-chart-icon`} />
                        </Fragment>
                      ) : (
                          <span>Not available</span>
                        )}
                    </p>
                  </Button>
                );
              })}
            </div>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

export default CurrentReproductionPerformance;
