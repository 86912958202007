import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { postXML } from "../../api/requests";
import {  convertToOverviewChartData } from "../../utills/dataFormatting";
import { CycleRegularitySpecTag } from "../../api/SpecTags"

import {
    GET_CRBWP_REQUEST,
    GET_CRBWP_SUCCESS,
    GET_CRBWP_FAILURE,
} from "./constants";

function* getCRBWPSaga(action: Action<any>): SagaIterator {
    try {
        const scoreData = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/crbwp/csummary", {
            startDate: action.payload.startDate,
            stopDate: action.payload.stopDate,
            specTag: CycleRegularitySpecTag,
        });

        const convertedChartData = convertToOverviewChartData(scoreData);

        yield put({ type: GET_CRBWP_SUCCESS, payload: { scoreData: convertedChartData } });
    } catch (error) {
        console.log(GET_CRBWP_FAILURE, error);
        yield put({ type: GET_CRBWP_FAILURE, payload: "Unable to get data" });
    }
}

export function* watchGetCRBWPSaga(): SagaIterator {
    yield takeEvery(GET_CRBWP_REQUEST, getCRBWPSaga);
}
