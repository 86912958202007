import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { postXML } from "../../api/requests";
import { convertToOverviewChartData } from "../../utills/dataFormatting";

import {
    GET_OD_FAILURE,
    GET_OD_REQUEST,
    GET_OD_SUCCESS,
} from "./constants";

function* getODaga(action: Action<any>): SagaIterator {
    try {
        const scoreData = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/od/cicpsummary", {
            startDate: action.payload.startDate,
            stopDate: action.payload.stopDate,
        });

        const convertedChartData = convertToOverviewChartData(scoreData);

        // remove redundant data from the api (every item comes twice)
        let binDataBeginDate: any;
        const data1 = scoreData.filter(function(item : any) {
            if (binDataBeginDate !== item.BinDataBeginDate) {
                binDataBeginDate = item.BinDataBeginDate;
                return true;
            }
            return false;
        })

        const data25 = {id : "Projected days open", data :[]};
        const data25data = data1.filter((el: any) => {
            return el.Median;
        }) 
        data25.data = data25data.map((el: any) => {
            return {
                shape: "circle",
                color: "#1FA5FF",
                x: `${new Date(el.BinDataEndDate).toLocaleDateString("en-GB")}`,
                y: Number((el.Median).toFixed(0)),
            };
        });

        const data75 = {id : "Days open", data :[]};
        const data75data = data1.filter((el: any) => {
            return el.MedianComplete;
        }) 
        data75.data = data75data.map((el: any) => {
            return {
                shape: "circle",
                color: "#0062A3",
                x: `${new Date(el.BinDataEndDate).toLocaleDateString("en-GB")}`,
                y: Number((el.MedianComplete).toFixed(0)),
            };
        });

        const l1data25 = {id : "Projected days open (L1)", data :[]};
        const l1data25data = data1.filter((el: any) => {
            return el.MedianLactation1;
        }) 
        l1data25.data = l1data25data.map((el: any) => {
            return {
                shape: "plus",
                color: "#1FA5FF",
                x: `${new Date(el.BinDataEndDate).toLocaleDateString("en-GB")}`,
                y: Number((el.MedianLactation1).toFixed(0)),
            };
        });

        const l1data75 = {id : "Days open (L1)", data :[]};
        const l1data75data = data1.filter((el: any) => {
            return el.MedianCompleteLactation1;
        }) 
        l1data75.data = l1data75data.map((el: any) => {
            return el.MedianCompleteLactation1 && {
                shape: "plus",
                color: "#0062A3",
                x: `${new Date(el.BinDataEndDate).toLocaleDateString("en-GB")}`,
                y: Number((el.MedianCompleteLactation1).toFixed(0)),
            };
        });

        const l2data25 = {id : "Projected days open (L2)", data :[]};
        const l2data25data = data1.filter((el: any) => {
            return el.MedianLactation2;
        }) 
        l2data25.data = l2data25data.map((el: any) => {
            return {
                shape: "cross",
                color: "#1FA5FF",
                x: `${new Date(el.BinDataEndDate).toLocaleDateString("en-GB")}`,
                y: Number((el.MedianLactation2).toFixed(0)),
            };
        });

        const l2data75 = {id : "Days open (L2)", data :[]};
        const l2data75data = data1.filter((el: any) => {
            return el.MedianCompleteLactation2;
        }) 
        l2data75.data = l2data75data.map((el: any) => {
            return {
                shape: "cross",
                color: "#0062A3",
                x: `${new Date(el.BinDataEndDate).toLocaleDateString("en-GB")}`,
                y: Number((el.MedianCompleteLactation2).toFixed(0)),
            };
        });

        const l3data25 = {id : "Projected days open (L3)", data :[]};
        const l3data25data = data1.filter((el: any) => {
            return el.MedianLactation3;
        }) 
        l3data25.data = l3data25data.map((el: any) => {
            return {
                shape: "triangle",
                color: "#1FA5FF",
                x: `${new Date(el.BinDataEndDate).toLocaleDateString("en-GB")}`,
                y: Number((el.MedianLactation3).toFixed(0)),
            };
        });

        const l3data75 = {id : "Days open (L3)", data :[]};
        const l3data75data = data1.filter((el: any) => {
            return el.MedianCompleteLactation3;
        }) 
        l3data75.data = l3data75data.map((el: any) => {
            return {
                shape: "triangle",
                color: "#0062A3",
                x: `${new Date(el.BinDataEndDate).toLocaleDateString("en-GB")}`,
                y: Number((el.MedianCompleteLactation3).toFixed(0)),
            };
        });

        yield put({ type: GET_OD_SUCCESS, payload: { scoreData: convertedChartData, graphData : [data25, data75, l1data25, l1data75, l2data25, l2data75, l3data25, l3data75] } });

    } catch (error) {
        console.log(GET_OD_FAILURE, error);
        yield put({ type: GET_OD_FAILURE, payload: "Unable to get data" });
    }
}

export function* watchGetODSaga(): SagaIterator {
    yield takeEvery(GET_OD_REQUEST, getODaga);
}
