import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Collabsible from "../../Collabsible";
import LattecIcon from "../../LattecIcon";
import { SET_LIBRARY_OPEN_URL, TOGGLE_NOTIFICATION_BAR } from "../../redux/Navigation/constants";
import scrollIntoView from 'scroll-into-view-if-needed';
import Section from "../../Section";
import "../Layout.scss";
import { Button } from "../../Input";

// tslint:disable-next-line: no-var-requires
const stepguideSpiderStep1: string = require("../../Images/StepguideSpiderStep1.png");
// tslint:disable-next-line: no-var-requires
const stepguideSpiderDOA: string = require("../../Images/StepguideSpiderDOA.PNG");
// tslint:disable-next-line: no-var-requires
const stepguideSpiderAIS: string = require("../../Images/StepguideSpiderAIS.PNG");
// tslint:disable-next-line: no-var-requires
const stepguideSpiderCR: string = require("../../Images/StepguideSpiderCR.PNG");
// tslint:disable-next-line: no-var-requires
const stepguideSpiderOD: string = require("../../Images/StepguideSpiderOD.PNG");
// tslint:disable-next-line: no-var-requires
const stepguideSpiderPBSS: string = require("../../Images/StepguideSpiderPBSS.PNG");
// tslint:disable-next-line: no-var-requires
const stepguideSpiderSEA: string = require("../../Images/StepguideSpiderSEA.PNG");


const LayoutNotificationBar: React.FC = (props) => {
  const itemRefs = {};
  const navigationState = useSelector((state: any) => state.navigationState);
  const targetRef = useRef<HTMLHeadingElement>(null);

  const dispatch = useDispatch();

  const handleSetUrl = (url: string) => dispatch({ type: SET_LIBRARY_OPEN_URL, payload: url });
  const handleCloseCollabsible = () => {

    dispatch({ type: SET_LIBRARY_OPEN_URL, payload: "" });
  }

  const classNameBackground = "layout__notification-bar-background";

  const handleIsOpen = (arr: string[]) => {
    return arr.indexOf(navigationState.libraryOpenUrl) > -1;
  }

  const handleClickOutside = (event: any) => {
    if (event.composedPath()[0].className === classNameBackground) {
      dispatch({ type: TOGGLE_NOTIFICATION_BAR, payload: null });
    }
  }

  useEffect(() => {
    if (itemRefs[navigationState.libraryOpenUrl] !== null && itemRefs[navigationState.libraryOpenUrl] !== undefined) {
      scrollIntoView(itemRefs[navigationState.libraryOpenUrl], {
        behavior: "smooth",
        scrollMode: "always",
      });
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div ref={targetRef} className="layout__notification-bar-wrapper">
      <div className={classNameBackground} />

      <div className="layout__notification-bar">
        <Collabsible
          ref={(el) => (itemRefs["crp/lvl1/summary"] = el)}
          onClose={handleCloseCollabsible}
          open={true}
          hideIcon={true}
          onClick={() => handleSetUrl("crp/lvl1/summary")}
          title="Current Reproduction Performance"
          icon={<LattecIcon icon="kpi" size={15} color={"black"} />}
          level={1}
        >

          <Section
            body={['These KPIs are based on progesterone measurements. The KPIs are not based on the average performance, instead they take into account both the "average cow" and the outliers to give an accurate overview of all cows.', "The KPIs are unique to Herd Navigator and are designed to measure your cows' reproduction performance in detail and close to real-time. The 7 KPIs are linked to specific events in the cow's reproduction cycle as shown on the illustration below. The KPIs will help you identify areas to improve in collaboration with your advisor."]}
          />

          <Section
            images={[{ src: stepguideSpiderStep1, alt: "stepguide spider step 1" }]}
          />

          <Section
            headline="Good to know"
            headlineType={"h5"}
            body={["All KPIs are based on the past 60 days from today, which means you can follow changes in your farm's performance almost real-time. This also means that you may see variation in numbers from day to day, as cows move in and out of the 60-day window. The real-time approach allows you to see both positive and negative trends quickly, which allows you to detect and correct negative performance before it has a significant effect on overall herd performance."]}
          />

          {/* ANOESTRUS LENGTH KPI */}
          <Collabsible
            ref={(el) => (itemRefs["doa/lvl2/summary"] = el)}
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["doa/lvl2/summary", "doa/lvl3/al/summary", "doa/lvl3/flp/summary"])}
            onClick={() => handleSetUrl("doa/lvl2/summary")}
            title="KPI: Anoestrus Length"
            level={2}
            icon={<LattecIcon icon="doa" size={15} color={"black"} />}
          >
            <Section
              body={["This is number of days from calving to the first increase in progesterone as illustrated below. The score shown on the spiderweb includes all cows which have been anoestrus within the past 60 days.", "The first increase in progesterone indicates resumption of activity in the reproductive system of the cow, which means she is recovering after calving.", "Progesterone is expected to increase before 35DIM for normal healthy cows. If the anoestrus period is generally longer than 35 days, then the reasons behind should be investigated. By monitoring the cows’ ability to resume cyclicity after calving, we have an effective tool to follow-up on transition cow management. For example, a large variation among the cows may be an indication that a certain group of cows needs extra attention, while a consistently long anoestrus period for most cows may indicate that general adjustments needs to be made."]}
            />

            <Section
              images={[{ src: stepguideSpiderDOA, alt: "Anoestrus length chart" }]}
            />

            <Section
              headline="Good to know"
              headlineType={"h5"}
              body={["The cows included in this KPI are the cows which have completed their anoestrus period within the past 60 days from today, i.e. have had their first increase in progesterone. The KPI also includes cows, which are more than 35 DIM and have not yet completed their anoestrus period. These cows should be identical to the cows you see on your DelPro report on cows with Prolonged anoestrus. Also be aware that this KPI will only include cows which have had their first progesterone measurement before 35 DIM. If cows are not measured for progesterone before 35DIM, their anoestrus lenght cannot be accurately calculated."]}
            />

            <Collabsible
              onClose={handleCloseCollabsible}
              open={handleIsOpen(["doa/lvl3/al/summary"])}
              ref={(el) => (itemRefs["doa/lvl3/al/summary"] = el)}
              onClick={() => handleSetUrl("doa/lvl3/al/summary")}
              title="Anoestrus Length"
              icon={"far fa-chart-bar"}
              level={3}
              button={<Button onClick={e => { window.location.href = "/hnadvice/doa" }}><i className="fas fa-external-link-alt" /></Button>}
            >
              <Section
                body={["This chart shows the distribution of the cows which have been anoestrus within the past 60 days.", "Look at the blue bars, which shows your cows, compared to the position of the grey reference area in the back. The grey reference curve show a realistic but good distribution of the anoestrus length. To achieve a good score on this KPI, your cows should be close to the grey area. "]}
              />

              <Section
                headline="Good to know"
                headlineType={"h5"}
                body={["By monitoring the cows’ ability to resume cyclicity after calving, we have an effective tool to follow-up on transition cow management. For example, a large variation among the cows may be an indication that a certain group of cows needs extra attention, while a consistently long anoestrus period for most cows may indicate that general adjustments needs to be made."]}
              />
            </Collabsible>
            <Collabsible
              onClose={handleCloseCollabsible}
              open={handleIsOpen(["doa/lvl3/flp/summary"])}
              onClick={() => handleSetUrl("doa/lvl3/flp/summary")}
              ref={(el) => (itemRefs["doa/lvl3/flp/summary"] = el)}
              title="First luteal phase"
              icon={"far fa-chart-bar"}
              level={3}
              button={<Button onClick={e => { window.location.href = "/hnadvice/doa" }}><i className="fas fa-external-link-alt" /></Button>}
            >
              <Section
                body={["This chart shows the distribution of the cows which have completed their first luteal phase within the past 60 days.", "Look at the blue bars, which shows your cows, compared to the position of the grey reference area in the back. The grey reference curve show a realistic but good distribution of the length of the first luteal phase. This data is not included in the score on Anoestrus length, but is a good supplement when analyzing the herd performance. Many cows outside of the optimal area can be related to uterine health and a suboptimal fresh cow period."]}
              />
              <Section
                headline="Good to know"
                headlineType={"h5"}
                body={["Anything above 14 days is less optimal, so a relatively small proportion of the cows should be in those bins. If you see a high occurrence of very short first luteal phases, this can be related to when cows are sampled for progesterone for the first time. To get an accurate measure of both anoestrus length and the length of the first luteal phase, sampling of the cows should start no later than 20 DIM."]}
              />
            </Collabsible>
          </Collabsible>




          {/* CYCLE REGULARITY */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["cr/lvl2/summary", "cr/lvl3/cl/summary", "cr/lvl3/fpl/summary"])}
            onClick={() => handleSetUrl("cr/lvl2/summary")}
            ref={(el) => (itemRefs["cr/lvl2/summary"] = el)}
            title="KPI: Cycle regularity"
            level={2}
            icon={<LattecIcon icon="cr" size={15} color={"black"} />}
          >
            <Section
              body={["Cycle regularity describes the regularity of the oestrus cycles for cyclic cows and is assessed by cycle length. Cycle length is defined as the number of days from one heat to the next in the individual cow. Cycle length is calculated for all cows which were not inseminated on cycle onset, as shown on the illustration below.", " A regular cycle is 20-23 days, while anything outside of this is considered irregular. Irregular cycles indicate, that the reproductive system has not fully recovered after calving. In the aim of getting the cows pregnant as effortlessly as possible after voluntary waiting period, the cows should cycle as regular as possible by the time the farmer starts inseminating. Hence, cycle regularity is a major factor in timing of insemination and in the ability of the cow to get pregnant – and stay pregnant."]}
            />

            <Section
              images={[{ src: stepguideSpiderCR, alt: "Cycle regularity chart" }]}
            />

            <Section
              headline="Good to know"
              headlineType={"h5"}
              body={["The score shown on the spiderweb includes all completed cycles within the past 60 days as well as incompleted cycles which are longer than 23 days on the day of evaluation (today)."]}
            />
            <Collabsible
              onClose={handleCloseCollabsible}
              open={handleIsOpen(["cr/lvl3/cl/summary"])}
              onClick={() => handleSetUrl("cr/lvl3/cl/summary")}
              ref={(el) => (itemRefs["cr/lvl3/cl/summary"] = el)}
              title="Cycle Length"
              icon={"far fa-chart-bar"}
              level={3}
              button={<Button onClick={e => { window.location.href = "/hnadvice/cr" }}><i className="fas fa-external-link-alt" /></Button>}
            >
              <Section
                body={["This chart shows the distribution of the completed cycles within the past 60 days (dark clue) as well as cycles longer than 23 days, which were not completed on evaluation day (light blue).", "Look at the blue bars, which shows your cows, compared to the position of the grey reference area in the back. The grey reference curve show a realistic but good distribution of the anoestrus length. To achieve a good score on this KPI, your cows should be close to the grey area. "]}
              />

              <Section
                headline="Good to know"
                headlineType={"h5"}
                body={["The same cow may occur 2 or even 3 times in this chart within the 60 days window, as long as she is not inseminated."]}
              />

            </Collabsible>
            <Collabsible
              onClose={handleCloseCollabsible}
              open={handleIsOpen(["cr/lvl3/fpl/summary"])}
              onClick={() => handleSetUrl("cr/lvl3/fpl/summary")}
              ref={(el) => (itemRefs["cr/lvl3/fpl/summary"] = el)}
              title="Follicular phase length"
              icon={"far fa-chart-bar"}
              level={3}
              button={<Button onClick={e => { window.location.href = "/hnadvice/cr" }} ><i className="fas fa-external-link-alt" /></Button>}
            >
              <Section
                body={["This chart shows the distribution of follicular phases within the past 60 days.", "Look at the blue bars, which shows your cows, compared to the position of the grey reference area in the back. The grey reference curve show a realistic but good distribution of the length of the follicular phase. This data is not included in the score on Cycle regularity, but is a good supplement when analyzing the herd performance. Prolonged and irregular follicular phases may make it difficult to time insemination correctly."]}
              />

              <Section
                headline="Good to know"
                headlineType={"h5"}
                body={["The follicular phases registered by the Herd Navigator system cannot be directly compared to traditional literature on follicular phase lengths because of the optimized sampling pattern of the Herd Navigator."]}
              />

            </Collabsible>
          </Collabsible>




          {/* HN INSEMINATION RATE */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["hnir/lvl2/summary", "hnir/lvl3/iodim/summary", "hnir/lvl3/havwp/summary"])}
            onClick={() => handleSetUrl("hnir/lvl2/summary")}
            ref={(el) => (itemRefs["hnir/lvl2/summary"] = el)}
            title="KPI: HN Insemination rate"
            level={2}
            icon={<LattecIcon icon="hnir" size={15} color={"black"} />}
          >
            <Section
              body={["HN Insemination rate describes how often a detected heat in a cow, which is eligible for breeding, is accompanied by an insemination.", "This KPI is interesting because it is a direct measure of how often we succeed in actually inseminating on the cows, which we are interested in getting pregnant. A KPI of 100 shows that you inseminate all cows that have a Heat alarm. A KPI of 50 shows that you only inseminate on half of the cows with a Heat alarm."]}
            />

            <Section
              headline="Good to know"
              headlineType={"h5"}
              body={["Cows are considered eligible for breeding, when they are above your selected voluntary waiting period. The voluntary waiting period can be specified in the settings option and may be specified separately for cows in first or second and higher lactation, if desired"]}
            />

            <Collabsible
              onClose={handleCloseCollabsible}
              open={handleIsOpen(["hnir/lvl3/iodim/summary"])}
              onClick={() => handleSetUrl("hnir/lvl3/iodim/summary")}
              ref={(el) => (itemRefs["hnir/lvl3/iodim/summary"] = el)}
              title="Inseminations on Days in Milk"
              icon={"far fa-chart-scatter"}
              level={3}
              button={<Button onClick={e => { window.location.href = "/hnadvice/hnir" }}><i className="fas fa-external-link-alt" /></Button>}
            >
              <Section
                body={["This chart shows a lot of information in one chart. Each dot is a detected heat occuring within the past 60 days, and the color of the dot shows whether this heat was accompanied by a recorded insemination.", "In the chart there is also information about which heat number it was (counting only heats occuring after voluntary waiting period) and how many DIM the cow was on that heat."]}
              />
            </Collabsible>

            <Collabsible
              onClose={handleCloseCollabsible}
              open={handleIsOpen(["hnir/lvl3/havwp/summary"])}
              onClick={() => handleSetUrl("hnir/lvl3/havwp/summary")}
              ref={(el) => (itemRefs["hnir/lvl3/havwp/summary"] = el)}
              title="Heats after voluntary waiting period"
              icon={"far fa-chart-bar"}
              level={3}
              button={<Button onClick={e => { window.location.href = "/hnadvice/hnir" }}><i className="fas fa-external-link-alt" /></Button>}
            >
              <Section
                body={["This chart shows directly how often heat number 1, 2, 3 etc after voluntary waiting period is accompanied by an insemination - without considering the DIM of the cows.", 'This chart can help you identify whether there are unconscious habits to missing an insemination on a heat. Cows which are no longer inseminated ("Cull cows"), but which are still sampled for progesterone will also show up on this chart.']}
              />
            </Collabsible>

          </Collabsible>




          {/* INSEMINATION SUCCESS */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["ais/lvl2/summary", "ais/lvl3/isodim/summary"])}
            onClick={() => handleSetUrl("ais/lvl2/summary")}
            ref={(el) => (itemRefs["ais/lvl2/summary"] = el)}
            title="KPI: Insemination succes"
            level={2}
            icon={<LattecIcon icon="ais" size={15} color={"black"} />}
          >
            <Section
              body={["Insemination Success is based on how many percent of the cows have a successful insemination.", " An insemination is considered successful if the cow proceeds past 25 days from insemination (DFAI) with a high progesterone level and without the occurrence of a new heat. In the illustration below, the cow is inseminated two times; The first insemination is non-successful as the cow comes into heat again and receives another insemination. However, the second insemination is successful, as the cow succesfully passes 25 DFAI."]}
            />

            <Section
              images={[{ src: stepguideSpiderAIS, alt: "Insemination succes chart" }]}
            />

            <Section
              headline="Good to know"
              headlineType={"h5"}
              body={["Successful insemination is key to getting the cows pregnant at the right time at a minimum cost. An important aspect to efficient reproduction is that the cows are ready to become pregnant, when we want them to."]}
            />

            <Collabsible
              onClose={handleCloseCollabsible}
              open={handleIsOpen(["ais/lvl3/isodim/summary"])}
              onClick={() => handleSetUrl("ais/lvl3/isodim/summary")}
              ref={(el) => (itemRefs["ais/lvl3/isodim/summary"] = el)}
              title="Insemination Success on Days in Milk"
              icon={"far fa-chart-scatter"}
              level={3}
              button={<Button onClick={e => { window.location.href = "/hnadvice/ais" }}><i className="fas fa-external-link-alt" /></Button>}
            >
              <Section
                body={["This chart shows a lot of information in one chart. Each dot is an insemination occuring within the past 60 days, and the color of the dot shows whether this insemination was succesful (pregnant cow) or not (not pregnant cow).", "In the chart there is also information about which insemination number it was and how many DIM the cow was on that insemination."]}
              />

              <Section
                headline="Good to know"
                headlineType={"h5"}
                body={["Be aware that a cow can be pregnant at 25 days from insemination, but still lose the pregnancy. This number does not take into account pregnancy loss after 25 Days, please refer to the Embryo attachment and establishment of pregnancy KPIs for more details on pregnancy loss."]}
              />
            </Collabsible>
          </Collabsible>




          {/* EMBRYO ATTACHMENT */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["sea/lvl2/summary", "sea/lvl3/eaaooeed/summary"])}
            onClick={() => handleSetUrl("sea/lvl2/summary")}
            ref={(el) => (itemRefs["sea/lvl2/summary"] = el)}
            title="KPI: Embryo attachment"
            level={2}
            icon={<LattecIcon icon="sea" size={15} color={"black"} />}
          >
            <Section
              body={["This KPI focuses on the embryo survival (absence of Early Embryo Deaths) in the herd. Early embryo death occurs when the embryo does not successfully attach to the uterus between day 26 and 35 after insemination. If attachment fails, progesterone levels will drop within this period and the cow will have another heat. ", "A small number of embryo deaths is completely normal, however, successful embryo attachment is important for the final reproduction result on the farm. If attachment fails, the days open of the individual cow increases significantly. This may not be a problem in a single cow, but on herd level many or late early embryo deaths will impact the overall reproduction performance."]}
            />

            <Section
              images={[{ src: stepguideSpiderSEA, alt: "Embryo attachment chart" }]}
            />

            <Collabsible
              onClose={handleCloseCollabsible}
              open={handleIsOpen(["sea/lvl3/eaaooeed/summary"])}
              onClick={() => handleSetUrl("sea/lvl3/eaaooeed/summary")}
              ref={(el) => (itemRefs["sea/lvl3/eaaooeed/summary"] = el)}
              title="Embryo attachement and occurrence of early embryo death"
              icon={"far fa-chart-bar"}
              level={3}
              button={<Button onClick={e => { window.location.href = "/hnadvice/sea" }}><i className="fas fa-external-link-alt" /></Button>}
            >
              <Section
                body={["This is an overview of when in lactation embryo death occurs.", "As mentioned, a small number of embryo deaths is normal, however, be aware of sudden unexplained increases in embryo deaths and whether they can be related to changes in feeding management or feedstuff, outside temperatures or humidity, regrouping of cows etc."]}
              />

              <Section
                headline="Good to know"
                headlineType={"h5"}
                body={["In case you were wondering, this chart is shown in two KPI pages: Embryo attachment and Final establishment of pregnancy."]}
              />
            </Collabsible>
          </Collabsible>




          {/* FINAL ESTABLISHMENT OF PREGNANCY */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["pbss/lvl2/summary", "pbss/lvl3/feopaooea/summary"])}
            onClick={() => handleSetUrl("pbss/lvl2/summary")}
            ref={(el) => (itemRefs["pbss/lvl2/summary"] = el)}
            title="KPI: Final establishment of pregnancy"
            level={2}
            icon={<LattecIcon icon="pbss" size={15} color={"black"} />}
          >
            <Section
              body={["Establishment of pregnancy focuses on (the absence of) Early abortions in the herd and is evaluated in percent successes. ", "Early abortion occurs when the embryo is lost between day 36 and 55 days from insemination (DFAI) as illustrated below. If a cow has an early abortion, progesterone levels will drop within the period and the cow will have another heat. A small number of abortions is completely normal, however, successful establishment of pregnancy is important for the final reproduction result on the farm. If final establishment fails, the days open of the individual cow increases significantly. This may not be a problem in a single cow, but on herd level many early abortions will impact the overall reproduction performance."]}
            />

            <Section
              headline="Good to know"
              headlineType={"h5"}
              body={["The percentage of succesful final establishments of pregnancy is translated into a score, which means that the percentage is not directly applied in the spiderweb."]}
            />

            <Section
              images={[{ src: stepguideSpiderPBSS, alt: "Final establishment of pregnancy chart" }]}
            />

            <Collabsible
              onClose={handleCloseCollabsible}
              open={handleIsOpen(["pbss/lvl3/feopaooea/summary"])}
              onClick={() => handleSetUrl("pbss/lvl3/feopaooea/summary")}
              ref={(el) => (itemRefs["pbss/lvl3/feopaooea/summary"] = el)}
              title="Final establishment of pregnancy and occurrence of early abortion"
              icon={"far fa-chart-bar"}
              level={3}
              button={<Button onClick={e => { window.location.href = "/hnadvice/pbss" }}><i className="fas fa-external-link-alt" /></Button>}
            >
              <Section
                body={["This is an overview of when in lactation early abortions occur.", "Early abortions (occuring after 35 Days from insemination) is often related to negative energy balance and can generally often be seen in early lactation, if cows are not fit to sustain pregnancy after insemination. As mentioned, a small number of early abortions is normal, however, be aware of sudden unexplained increases in abortion rates and whether they can be related to changes in feeding management or feedstuff, outside temperatures or humidity, regrouping of cows etc."]}
              />

              <Section
                headline="Good to know"
                headlineType={"h5"}
                body={["In case you were wondering, this chart is shown in two KPI pages: Final establishment of pregnancy and Embryo attachment."]}
              />
            </Collabsible>
          </Collabsible>




          {/* PROJECTED DAYS OPEN */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["od/lvl2/summary"])}
            onClick={() => handleSetUrl("od/lvl2/summary")}
            ref={(el) => (itemRefs["od/lvl2/summary"] = el)}
            title="KPI: Projected days open"
            level={2}
            icon={<LattecIcon icon="od" size={15} color={"black"} />}
          >
            <Section
              body={["Projected Days Open is based on non-pregnant cows, instead of on pregnant cows as Days Open traditionally are.", "Days Open is one of the final KPIs which summarizes the overall performance on the farm. Each day above the target Days Open may result in increased calving interval, less marginal milk production, increased need for replacement heifers and in the end reduced income. By usign Projected Days Open instead of considering Days Open for pregnant cows, we are able to follow the trend of the group of cows in the herd, which is not pregnant yet. This allows us to make sure that the future Days open on the farm does not increase unnoticed and that we keep track on the group of cows which is taking a little longer to get pregnant."]}
            />

            <Section
              images={[{ src: stepguideSpiderOD, alt: "Projected days open chart" }]}
            />

            <Section
              headline="Good to know"
              headlineType={"h5"}
              body={["Projected Days Open for non-pregnant cows is based on the most recent insemination, if the cow was inseminated. If the cow was not inseminated on her most recent heat, Projected Days Open is based on heat date + 21 days. 21 days is added to the heat date, because the next possible option for getting a non-inseminated cow pregnant is her next heat."]}
            />
          </Collabsible>
        </Collabsible>
      </div>
    </div>
  );
};

export default LayoutNotificationBar;
