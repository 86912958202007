import React, { Fragment, useEffect, useState } from "react";
import { Button } from "../../Input";
import Modal from "../../Modal";
import Questionaire from "../../Questionaire";
import { getFarmId, getFarmName, setFarmId, setFarmName, remUsername, remToken } from "../../utills/authorization";
import { useDispatch, useSelector } from "react-redux";
import { mediumScreenSizeDetection } from "../../utills/deviceDetection";
import { TOGGLE_NOTIFICATION_BAR, TOGGLE_SETTINGS, TOGGLE_HN_INSIGHTS } from "../../redux/Navigation/constants";
import Settings from "../../Settings";
import HoverGuide from "../../HoverGuide";
import "../Layout.scss";
import './style.scss';
import { getFarms } from "../../redux/Farms/actions";
import Select, { ValueType } from 'react-select';

// tslint:disable-next-line: no-var-requires
const logo: string = mediumScreenSizeDetection() ? require("../../Images/Logo_mini_blue.svg") : require("../../Images/Logo_full_blue.svg");



const LayoutHeader: React.FC = (props) => {
    const navigationState = useSelector((state: any) => state.navigationState);
    const farmsState: { farms: { DimFarmId: number, FarmName: string }[], error: null | string, loading: boolean } = useSelector((state: any) => state.farms);
    const dispatch = useDispatch();
    const handleToggleNotificationBar = () => dispatch({ type: TOGGLE_NOTIFICATION_BAR });
    const handleToggleSettings = () => dispatch({ type: TOGGLE_SETTINGS });
    const handleToggleHnInsights = () => dispatch({ type: TOGGLE_HN_INSIGHTS });

    let [farmLocal, setFarmLocal] = useState<null | ValueType<{ value: any; label: any; }, false>>();

    useEffect(() => {
        let globalFarmId = getFarmId();
        let globalFarmName = getFarmName();
        setFarmLocal({ label: globalFarmName, value: globalFarmId });
    }, [])

    useEffect(() => {
        dispatch(getFarms({}))
    }, []);


    function selectFarm(val: ValueType<{ value: any; label: any; }, false>) {
        if (!val) return;

        setFarmName(val.label);
        setFarmId(val.value);
        setFarmLocal(val);
        window.location.reload();
    }

    function logOutUser() {
        remToken();
        remUsername();
        window.location.href = "/login"
    }

    function isProduction() {
        return window.location.href.includes('hninsights.herdnavigator.com') || window.location.href.includes('hninsights.herdnavigator.com');

    }

    return (
        <Fragment>
            <div className="layout__header">
                <div className="layout__wrapper">
                    <img className="layout__logo" src={logo} alt="Delaval logo" />
                    {
                        !isProduction() &&
                        <Select
                            className={'Select'}
                            value={farmLocal}
                            onChange={selectFarm}
                            options={
                                farmsState?.farms?.map((x: any) => {
                                    return { value: x.DimFarmId, label: x.FarmName }
                                })
                            }
                        />
                    }
                </div>
                <div className="layout__actions">
                    <div className="layout__icons">
                        <HoverGuide content="Log out" direction="bottom">
                            <Button style={{ margin: "0 9px" }} onClick={() => logOutUser()}>
                                <i className="layout__icon far fa-sign-out-alt"></i>
                            </Button>
                        </HoverGuide>
                        <HoverGuide content="User settings" direction="bottom">
                            <Button style={{ margin: "0 9px" }} onClick={handleToggleSettings}>
                                <i className="layout__icon fal fa-cog" />
                            </Button>
                        </HoverGuide>

                        <HoverGuide content="Open library" direction="bottom">
                            <Button style={{ margin: "0 9px" }} onClick={handleToggleNotificationBar}>
                                <i className="layout__icon fal fa-books" />
                            </Button>
                        </HoverGuide>
                    </div>
                </div>
            </div>
            <Modal
                visible={navigationState.hnInsightsOpen}
                close={handleToggleHnInsights}
                headline={"Reproduction insights"}
                width={75}
            >
                <Questionaire close={handleToggleHnInsights} />
            </Modal>

            <Modal
                style={{ margin: "36px 250px" }}
                visible={navigationState.settingsOpen}
                close={handleToggleSettings}
                headline={"Settings"}
            >
                <Settings />
            </Modal>


        </Fragment>
    );
};

export default LayoutHeader;
