import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { postXML } from "../../api/requests";
import { boil, merge } from "../../utills/dataFormatting";

import {
    GET_FIRST_LUTEAL_PHASE_REQUEST,
    GET_FIRST_LUTEAL_PHASE_SUCCESS,
    GET_FIRST_LUTEAL_PHASE_FAILURE,
} from "./constants";

function* getFirstLuthealPhase(action: Action<any>): SagaIterator {
    try {

        const farmData = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/doa/flp", {
            startDate: action.payload.startDate,
            stopDate: action.payload.stopDate,
            L1: action.payload.filters.l1Checked ? 1 : 0,
            L2: action.payload.filters.l2Checked ? 1 : 0,
            L3P: action.payload.filters.l3Checked ? 1 : 0,
        });

        // arr: any, compareKey: string, conditionKey: string, dataKey: string, labelKey: string
        const boiledFarmData = boil(farmData, "BinNumber", "IsComplete", "CowsInBin", "Bin", ["Completed", "InComplete"]);

        // https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/refp2p3/doa/p3

        const reference = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farms/reproduction/refp2p3/doa/p3");

        const mergedData = merge(boiledFarmData, reference, "YValueL3P")


        yield put({ type: GET_FIRST_LUTEAL_PHASE_SUCCESS, payload: mergedData });



    } catch (error) {
        console.log(GET_FIRST_LUTEAL_PHASE_FAILURE, error);
        yield put({ type: GET_FIRST_LUTEAL_PHASE_FAILURE, payload: "Unable to get data" });
    }
}

export function* watchGetFirstLuthealPhase(): SagaIterator {
    yield takeEvery(GET_FIRST_LUTEAL_PHASE_REQUEST, getFirstLuthealPhase);
}
