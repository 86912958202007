import React from "react";
import { Checkbox, Dropdown, CheckboxStylish } from "../Input";
import "./KPIPageFilters.scss";

const KPIPageFilters = ({ filtersState, handleToggleLactationFilter, handleSelectTimePeriod, hasAllFilter, hasDelayedFilter = false }: any) => {
    return (
        <div className="kpi-page-filters">
            <div className="row">
                <h4 className="kpi-page-filters__label">Selected evaluation date</h4>
                <div className=''>{`${new Date(filtersState.today).toLocaleDateString("en-GB")}`}</div>
                <Dropdown list={filtersState.anoestrusLengthTimePeriods} onChange={(e: any) => handleSelectTimePeriod(e, "anoestrusLength")} value={filtersState.anoestrusLengthSelectedTimePeriod.key} />
            </div>
            <div className='row margin-top'>
                <div className='row border-right'>
                    <h4>Displayed lactation numbers:</h4>
                    <CheckboxStylish variant='1' checked={filtersState.lactationFilters.l1Checked} onChange={() => handleToggleLactationFilter("l1")} />
                    <CheckboxStylish variant='2' checked={filtersState.lactationFilters.l2Checked} onChange={() => handleToggleLactationFilter("l2")} />
                    <CheckboxStylish variant='3' checked={filtersState.lactationFilters.l3Checked} onChange={() => handleToggleLactationFilter("l3")} />
                </div>
                <div className='row border-right'>
                    <h4>Display culled cows</h4>
                    <CheckboxStylish variant="stylish" checked={filtersState.lactationFilters.culledChecked} onChange={() => handleToggleLactationFilter("culled")} />
                </div>
                {hasDelayedFilter && (
                    <div className='row'>
                        <h4>Display delayed samples</h4>
                        <CheckboxStylish variant="stylish" checked={filtersState.lactationFilters.delayedChecked} onChange={() => handleToggleLactationFilter("delayed")} />
                    </div>
                )}
                {hasAllFilter && (
                    <div className="row">
                        <h4>Display all</h4>
                        <CheckboxStylish variant="stylish" checked={filtersState.lactationFilters.lAllChecked} onChange={() => handleToggleLactationFilter("lAll")} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default KPIPageFilters;

