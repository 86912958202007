export const GET_HNINSIGHTS_QUESTIONS_REQUEST = "@@ddw.herdnet.sandbox.hnadvice/GET_HNINSIGHTS_QUESTIONS_REQUEST";
export const GET_HNINSIGHTS_QUESTIONS_SUCCESS = "@@ddw.herdnet.sandbox.hnadvice/GET_HNINSIGHTS_QUESTIONS_SUCCESS";
export const GET_HNINSIGHTS_QUESTIONS_FAILURE = "@@ddw.herdnet.sandbox.hnadvice/GET_HNINSIGHTS_QUESTIONS_FAILURE";

export const GET_HNINSIGHTS_QUESTION_REQUEST = "@@ddw.herdnet.sandbox.hnadvice/GET_HNINSIGHTS_QUESTION_REQUEST";
export const GET_HNINSIGHTS_QUESTION_SUCCESS = "@@ddw.herdnet.sandbox.hnadvice/GET_HNINSIGHTS_QUESTION_SUCCESS";
export const GET_HNINSIGHTS_QUESTION_FAILURE = "@@ddw.herdnet.sandbox.hnadvice/GET_HNINSIGHTS_QUESTION_FAILURE";

export const POST_HNINSIGHTS_ANSWER_REQUEST = "@@ddw.herdnet.sandbox.hnadvice/POST_HNINSIGHTS_ANSWER_REQUEST";
export const POST_HNINSIGHTS_ANSWER_SUCCESS = "@@ddw.herdnet.sandbox.hnadvice/POST_HNINSIGHTS_ANSWER_SUCCESS";
export const POST_HNINSIGHTS_ANSWER_FAILURE = "@@ddw.herdnet.sandbox.hnadvice/POST_HNINSIGHTS_ANSWER_FAILURE";

export const SET_HNINSIGHTS_STAGE = "@@ddw.herdnet.sandbox.hnadvice/SET_HNINSIGHTS_STAGE";
export const SET_HNINSIGHTS_STAGE_SUCCESS = "@@ddw.herdnet.sandbox.hnadvice/SET_HNINSIGHTS_STAGE_SUCCESS";
export const SET_HNINSIGHTS_STAGE_FINISH = "@@ddw.herdnet.sandbox.hnadvice/SET_HNINSIGHTS_STAGE_FINISH";

export const SET_HNINSIGHTS_STATUS = "@@ddw.herdnet.sandbox.hnadvice/SET_HNINSIGHTS_STATUS";

export const GET_INSIGHTS_LIST_REQUEST = "@@ddw.herdnet.sandbox.hnadvice/GET_INSIGHTS_LIST_REQUEST";
export const GET_INSIGHTS_LIST_SUCCESS = "@@ddw.herdnet.sandbox.hnadvice/GET_INSIGHTS_LIST_SUCCESS";
export const GET_INSIGHTS_LIST_FAILURE = "@@ddw.herdnet.sandbox.hnadvice/GET_INSIGHTS_LIST_FAILURE";

export const GO_TO_PREVIOUS_QUESTION_REQUEST = "@@ddw.herdnet.sandbox.hnadvice/GO_TO_PREVIOUS_QUESTION_REQUEST";
export const GO_TO_PREVIOUS_QUESTION_SUCCESS = "@@ddw.herdnet.sandbox.hnadvice/GO_TO_PREVIOUS_QUESTION_SUCCESS";
export const GO_TO_PREVIOUS_QUESTION_FAILURE = "@@ddw.herdnet.sandbox.hnadvice/GO_TO_PREVIOUS_QUESTION_FAILURE";

export const PIN_INSIGHT_TO_DASHBOARD_REQUEST = "@@ddw.herdnet.sandbox.hnadvice/PIN_INSIGHT_TO_DASHBOARD_REQUEST";
export const PIN_INSIGHT_TO_DASHBOARD_SUCCESS = "@@ddw.herdnet.sandbox.hnadvice/PIN_INSIGHT_TO_DASHBOARD_SUCCESS";
export const PIN_INSIGHT_TO_DASHBOARD_FAILURE = "@@ddw.herdnet.sandbox.hnadvice/PIN_INSIGHT_TO_DASHBOARD_FAILURE";

export const REPLACE_INSIGHT_REQUEST = "@@ddw.herdnet.sandbox.hnadvice/REPLACE_INSIGHT_REQUEST";
export const REPLACE_INSIGHT_SUCCESS = "@@ddw.herdnet.sandbox.hnadvice/REPLACE_INSIGHT_SUCCESS";
export const REPLACE_INSIGHT_FAILURE = "@@ddw.herdnet.sandbox.hnadvice/REPLACE_INSIGHT_FAILURE";

export const SET_SELECTED_ANSWER = "@@ddw.herdnet.sandbox.hnadvice/SET_SELECTED_ANSWER";

export const STAGES = {
    CONFIG_INTRO: "config_intro",
    CONFIG_QUESTIONAIRE: "config_questionaire",
    CONFIG_FINISH: "config_finish",
    ANALYSIS_INTRO: "analysis_intro",
    ANALYSIS_QUESTIONAIRE: "analysis_questionaire",
    ANALYSIS_FINISH: "analysis_finish",
    INSIGHTS_LIST: "insights_list",
};

// export const START_QUESTIONAIRE_REQUEST = "@@ddw.herdnet.sandbox.hnadvice/START_QUESTIONAIRE_REQUEST";
// export const START_QUESTIONAIRE_SUCCESS = "@@ddw.herdnet.sandbox.hnadvice/START_QUESTIONAIRE_SUCCESS";
// export const START_QUESTIONAIRE_FAILURE = "@@ddw.herdnet.sandbox.hnadvice/START_QUESTIONAIRE_FAILURE";


