export const AIsPrCowSpecTag: string = "BL-05";
export const AIsPrPregnancySpecTag: string = "BL-06";
export const CalvingToFirstAIIntervalSpecTag: string = "BL-07";
export const FirstToLastAIIntervalSpecTag: string = "BL-08";
export const PregnancyRateAt120DFCSpecTag: string = "BL-09";
export const OpenDaysSpecTag: string = "BL-16";
export const ProportionOfCowsBredSpecTag: string = "BL-17";

export const AnoestrusLengthSpecTag: string = "R-01";
export const CycleRegularitySpecTag: string = "R-02";
export const DaysToFirstInsemination: string = "R-03";
export const InseminationSuccessSpecTag: string = "R-04";
export const EmbryoAttachmentSpecTag: string = "R-05";
export const EstablishmentOfPregnancySpecTag: string = "R-06";
export const DaysOpenSpecTag: string = "R-07";
export const FirstLutealPhaseSpecTag: string = "R-08";
export const HNInseminationRateSpecTag: string = "R-09";
export const SuccessOnFirstAISpecTag: string = "R-10";
export const RegularCyclesSpecTag: string = "R-11";

export const AverageSticksPerCowSpecTag: string = "SC-01";
export const AverageSticksPerPregnancySpecTag: string = "SC-02";
export const SticksUsedFromFirstToLastAISpecTag: string = "SC-03";
export const AverageSticksFollicularCystsSpecTag: string = "SC-04";
export const AverageSticksLutealCystsSpecTag: string = "SC-06";

export const LoginSpecTag: string = "LOGIN-01";
