
import React from "react";
import { iIcon } from "./interface";

const Triangle: React.FC<iIcon> = (props) => (
    <svg fill={props.color ? props.color : "currentColor"} stroke={props.color ? props.color : "currentColor"} width={props.size ? props.size : "38"} height={props.size ? props.size : "38"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="-6px" y="-9px" viewBox="0 0 76 76" xmlSpace="preserve">
        <path strokeWidth="10px" fill={props.color ? props.color : "currentColor"} stroke={props.color ? props.color : "currentColor"} d="M72.72,65.686H0L36.36,7.034L72.72,65.686z M5.388,62.686h61.943L36.36,12.727L5.388,62.686z"/>
    </svg>
)

export default Triangle;
