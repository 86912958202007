import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import sagas from "./sagas";
import reducers from "./reducers";

const sagaMiddleware = createSagaMiddleware();

export const composeEnhancers = (
  process.env.NODE_ENV === "development" &&
  window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })
) || compose;

export const history = createBrowserHistory({ forceRefresh: false });

export function configureStore(initialState = {}) {
  const middleware = [sagaMiddleware, routerMiddleware(history)];

  const enhancer = composeEnhancers(
    applyMiddleware(...middleware),
  );

  return createStore(reducers(history), initialState, enhancer);
}

export default configureStore({});

sagaMiddleware.run(sagas);
