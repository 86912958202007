import React, { useEffect  } from "react";
import { Dropdown, Button } from "../Input";
import { SELECT_SETTINGS_DROPDOWN_VALUE, UPDATE_TEXT_VALUE, SET_NEW_SETTINGS_REQUEST } from "../redux/Settings/constants";
import { initialSettings } from "../redux/Settings/reducer";
import { useSelector, useDispatch } from "react-redux";
import { GET_SETTINGS_REQUEST } from "../redux/Settings/constants";
import "./Settings.scss";

const Settings: React.FC = (props) => {
    const settingsState = useSelector((state: any) => state.settingsState);

    const dispatch = useDispatch();

    const selectDropdownValue = (e: any, key: string) => {
        dispatch({ type: SELECT_SETTINGS_DROPDOWN_VALUE, payload: { index: e.target.selectedIndex, key } });
    }
    const handleUpdateTextField = (e: any, key: string) => dispatch({ type: UPDATE_TEXT_VALUE, payload: { value: e.target.value, key } });
    const handleUpdateSettings = () => dispatch({ type: SET_NEW_SETTINGS_REQUEST, payload: settingsState.settings });
    const handleResetSettings = () =>  dispatch({ type: SET_NEW_SETTINGS_REQUEST, payload: initialSettings });

    useEffect(() => {
        // get questions when component is mounted
        dispatch({ type: GET_SETTINGS_REQUEST, payload: null });

        // tell hook to skip re-render if state hasnt changed
      }, [dispatch]);

    return (
        <div className="settings">
            <div className="settings__wrapper">
                <div className="settings__inner">
                    <div className="settings__section">
                        <h2 className="settings__section-headline">Voluntary waiting period</h2>
                    </div>
                    <div className="settings__section">
                        <label className="settings__label">1st lactation <span className="settings__label-sub">(50-100)</span>:</label>
                        <input
                            className="settings__input"
                            type="text"
                            placeholder="Ex. 55"
                            value={settingsState.settings.VoluntaryWaitingPeriodL1}
                            onChange={(e: any) => handleUpdateTextField(e, "VoluntaryWaitingPeriodL1")}
                        />
                    </div>
                    <div className="settings__section">
                        <label className="settings__label">2nd lactation <span className="settings__label-sub">(50-100)</span>:</label>
                        <input
                            className="settings__input"
                            type="text"
                            placeholder="Ex. 55"
                            value={settingsState.settings.VoluntaryWaitingPeriodL2P}
                            onChange={(e: any) => handleUpdateTextField(e, "VoluntaryWaitingPeriodL2P")}
                        />
                    </div>
                </div>

                <div className="settings__inner">
                    <div className="settings__section">
                        <h2 className="settings__section-headline">Benchmark settings</h2>
                    </div>
                    <div className="settings__section">
                        <label className="settings__label">Baseline report basis:</label>
                        <Dropdown
                            onChange={(e: any) => selectDropdownValue(e, "DimZeroMeasurementBasis")}
                            value={settingsState.DimZeroMeasurementBasisIdSelected.value}
                            list={settingsState.DimZeroMeasurementBasisList}
                            style={{ padding: "18px", border: "1px solid #ECECEC", display: "flex", flex: "1", marginRight: "18px" }}
                        />
                    </div>


                    {/*  DISABLE THESE START  */}
                    <div className="settings__section">
                        <label className="settings__label">Geographical basis:</label>
                        <Dropdown
                            onChange={(e: any) => selectDropdownValue(e, "DimBenchmarkFilterGeographicalRegion")}
                            value={settingsState.DimBenchmarkFilterGeographicalRegionIdSelected.value}
                            list={settingsState.DimBenchmarkFilterGeographicalRegionList}
                            style={{ padding: "18px", border: "1px solid #ECECEC", display: "flex", flex: "1", marginRight: "18px" }}
                        />
                    </div>
                    <div className="settings__section">
                        <label className="settings__label">Performance level:</label>
                        <Dropdown
                            onChange={(e: any) => selectDropdownValue(e, "DimBenchmarkFilterPerformanceLevel")}
                            value={settingsState.DimBenchmarkFilterPerformanceLevelIdSelected.value}
                            list={settingsState.DimBenchmarkFilterPerformanceLevelList}
                            style={{ padding: "18px", border: "1px solid #ECECEC", display: "flex", flex: "1", marginRight: "18px" }}
                            disabled={true}
                        />
                    </div>
                    {/*  DISABLE THESE END  */}
                </div>
            </div>
            <div className="settings__footer">
                <Button
                    onClick={handleUpdateSettings}
                    text="Save settings"
                    isLoading={settingsState.loading}
                    disabled={settingsState.loading}
                />
                <Button disabled={settingsState.loading} type="danger-outlined" onClick={handleResetSettings} text="Reset to default settings" />
            </div>
        </div>
    );
};

export default Settings;
