export const StepGuidesTranslations = {
    da: {

    },
    options: {

    },
    en: {
        "StickConsumptionStepGuideHeader": "Stick consumption",
        "StickConsumptionStepGuideWhatSubHeader": "What is this?",
        "StickConsumptionStepGuideWhatBodyText": "This is a summary of the number of sticks used per pregnancy. Be aware that it only includes sticks, where the result is used in the biological model. Sticks that have been discarded for technical reasons are not included in this number, which means that your actual number of daily sticks may be higher because of reduced technical performance.",
        "StickConsumptionStepGuideHowSubHeader": "How do i read it?",
        "StickConsumptionStepGuideHowBodyText": "The dark line shows your farm’s data for the past 12 months. The reference line is based on the average stick consumption of your selected benchmark group.",

        "ZeroMeasurementStepGuideHeader": "Baseline report",
        "ZeroMeasurementStepGuideWhatSubHeader": "What is this?",
        "ZeroMeasurementStepGuideWhatBodyText": "The baseline report enables you to monitor your farms performance with commonly used reproduction KPIs. Here on the dashboard, we show a selection of the KPIs from the full baseline report. \n \n Click the “Baseline report” header to go the full Baseline report. Your selected baseline is:",
        "ZeroMeasurementStepGuideWhatBodyText2": "By default the baseline will be 30 days before start of progesterone measurements, if this was done less than 1 year ago. If progesterone sampling has been running for more than 1 year, then the default baseline will be 1 year ago. If desired, you are able to select the date which you want to use as Baseline point of reference in settings (not avilable in test version).",
        "ZeroMeasurementStepGuideHowSubHeader": "How do i read it?",
        "ZeroMeasurementStepGuideHowBodyText": "The progress bars show the difference between today’s performance and your selected baseline. This allows you to monitor, if you are actually achieving an improvement in your herds reproduction performance. \n \n The progress bars are coloured according to whether the difference between today and baseline is positive or negative. \n \n The number of bars show how big the difference is: 1 bar = 0-5%, 2 bars = 5-10% and 3 bars is more than 10%. \n \n All KPIs in the baseline report are calculated based on 6 months intervals. So, the KPI values for today is based on the last 6 months from today, while the KPI values for the Baseline reference point is based on the last 6 months from Baseline date.",

        "CurrentReproductionPerformanceStepGuideHeader": "Current Reproduction Performance",
        "CurrentReproductionPerformanceStepGuideWhatSubHeader": "What is this?",
        "CurrentReproductionPerformanceStepGuideWhatBodyText": "These three KPIs are key to maintaining a good overview of your farms current reproduction performance.",
        "CurrentReproductionPerformanceStepGuideHowSubHeader": "How do i read it?",
        "CurrentReproductionPerformanceStepGuideHowBodyText": "Each KPI is shown as a percentage (large number in black) and a trend indicated by the arrow. \n \n The direction and the color of the arrow indicates whether the trend is positive or negative; For all three KPI’s, the higher the value, the better. The trend is calculated as the difference between the current value and the value from 60 days ago. \n \n The small number in (brackets) is a reference number based on your selected benchmark farms. This allows you to comapare your own performance on these KPIs directly with the current performance of similar farms.",

       // HN Insemination Rate:
        "HNInseminationRateStepGuideHeader": "HN Insemination Rate",
        "HNInseminationRateStepGuideWhatSubHeader": "What is it?",
        "HNInseminationRateStepGuideWhatBodyText": "HN Insemination rate describes how often a detected heat in a cow, which is eligible for breeding, is accompanied by an insemination.",
        "HNInseminationRateStepGuideWhySubHeader": "Why is it interesting",
        "HNInseminationRateStepGuideWhyBodyText": "This KPI is interesting because it is a direct measure of how often we succeed in actually inseminating on the cows, which we are interested in getting pregnant. \n \n A KPI of 100 shows that you inseminate all cows that have a Heat alarm. A KPI of 50 shows that you only inseminate on half of the cows with a Heat alarm",


        // Success on first AI:
        "SuccessOnFirstAIHeader": "Success on first insemination",
        "SuccessOnFirstAIWhatSubheader": "What is it?",
        "SuccessOnFirstAIWhatBodyText": "Success on first AI describes the proportion of cows, who get pregnant on their first insemination based on 25 days from insemination.",
        "SuccessOnFirstAIWhySubHeader": "Why is it interesting?",
        "SuccessOnFirstAIWhyBodyText": "This KPI is interesting because breeding cows too early will give a low success on the first insemination. This KPI will help you keep track on whether your success on first insemination is acceptable or if adjustments should be made in either fresh cow management or the timing of first insemination.",


        // Regular cycles:
        "RegularCyclesHeader": "Regular cycles",
        "RegularCyclesWhatSubheader": "What is it?",
        "RegularCyclesWhatBodyText": "Regular cycles describes the proportion of cows which have a regular cycle before they are inseminated. A cycle is defined as the number of days between two heats, and it is considered to be normal if it is between 20 and 23 days.",
        "RegularCyclesWhySubHeader": "Why is it interesting?",
        "RegularCyclesWhyBodyText": "This KPI is interesting because when cows have regular cycles before insemination, the timing of the insemination is easier and the chance of getting the cow pregnant is greater.",


        // BASELINE TABLE KPI TRANSLATIONS
        "ProportionOfCowsBredStepGuideHeader": "Proportion of cows bred",
        "ProportionOfCowsBredStepGuideWhatSubHeader": "What is it?",
        "ProportionOfCowsBredStepGuideWhatBodyText": "Proportion of cows bred describes how many cows above voluntary waiting period has been inseminated. \n \n A cow is considered to be eligible for breeding when she has passed voluntary waiting period and she has not received a cull mark. \n \n By default voluntary waiting period has been set to 70DIM. If your voluntary waiting period is a lot different from 70, please make sure to adjust it in settings (not avilable in test version) as this has a big impact on a number of KPIs.",
        "ProportionOfCowsBredStepGuideHowSubHeader": "Why is it interesting?",
        "ProportionOfCowsBredStepGuideHowBodyText": "The KPI helps you evaluate how good you are at breeding on all of your eligible cows. \n \n A KPI of 100 shows that you breed on all your eligible cows, and a KPI of 50 shows that you only bred on half of your eligible cows",

        "CalvingToFirstAIIntervalStepGuideHeader": "Calving to first AI interval",
        "CalvingToFirstAIIntervalStepGuideWhatSubHeader": "What is it?",
        "CalvingToFirstAIIntervalStepGuideWhatBodyText": "Calving to first AI interval is the average number of days from calving to first insemination for cows which have received their first insemination within the last 6 months.",
        "CalvingToFirstAIIntervalStepGuideHowSubHeader": "Why is it interesting?",
        "CalvingToFirstAIIntervalStepGuideHowBodyText": "The KPI helps you evaluate how good you are at achieving your breeding strategy goals. It helps you evaluate if you start inseminating on your cows at the desired time after calving. A KPI less than your chosen voluntary waiting period shows that you start inseminating your cows earlier than your breeding strategy goals. \n \n A KPI significantly longer than your chosen voluntary waiting period shows that you start inseminating your cows later than your breeding strategy goals.",

        "InseminationRateStepGuideHeader": "Insemination rate",
        "InseminationRateStepGuideWhatSubHeader": "What is it?",
        "InseminationRateStepGuideWhatBodyText": "...",
        "InseminationRateStepGuideHowSubHeader": "Why is it interesting?",
        "InseminationRateStepGuideHowBodyText": "...",

        "FirstToLastAIIntervalStepGuideHeader": "First to last AI interval",
        "FirstToLastAIIntervalStepGuideWhatSubHeader": "What is it?",
        "FirstToLastAIIntervalStepGuideWhatBodyText": "First to last AI interval describes the average number of days from each cow's first insemination to her last insemination, if she gets pregnant. \n \n The KPI only includes cows, which have received a positive pregnancy confirmation within the last 6 months.",
        "FirstToLastAIIntervalStepGuideHowSubHeader": "Why is it interesting?",
        "FirstToLastAIIntervalStepGuideHowBodyText": "The KPI helps you evaluate how quick your cows become pregnant and how fast you re-inseminate after an unsuccessful insemination. \n \n A KPI of 0 shows that you get all your inseminated cows pregnant at the first insemination. A KPI of 35 shows that you on average use 1.5 cycles to get your cows pregnant.",

        "AIsPrCowStepGuideHeader": "AIs per cow",
        "AIsPrCowStepGuideWhatSubHeader": "What is it?",
        "AIsPrCowStepGuideWhatBodyText": "AIs per cow shows the average number of inseminations per cow within her current lactation. This KPI includes all pregnant and non pregnant cows, who are have been active within the past 6 months (not cull marked).",
        "AIsPrCowStepGuideHowSubHeader": "Why is it interesting?",
        "AIsPrCowStepGuideHowBodyText": "The KPI helps you evaluate how many inseminations you use per cow independently if a pregnancy is achieved or not. \n \n A KPI of 1 shows that you on average inseminate your cows once. A KPI of 3 shows that you on average inseminate your cows three times. \n \n Try to compare this number with the average number of inseminations per pregnancy. Do you on average have more inseminations per cow than per pregnancy? That indicates that there are a lot of cows, who receive more inseminations than average without getting pregnant.",

        "ConceptionRateStepGuideHeader": "Conception rate",
        "ConceptionRateStepGuideWhatSubHeader": "What is it?",
        "ConceptionRateStepGuideWhatBodyText": "...",
        "ConceptionRateStepGuideHowSubHeader": "Why is it interesting?",
        "ConceptionRateStepGuideHowBodyText": "...",

        "AIsPrPregnancyStepGuideHeader": "AIs per pregnancy",
        "AIsPrPregnancyStepGuideWhatSubHeader": "What is it?",
        "AIsPrPregnancyStepGuideWhatBodyText": "AIs per pregnancy is the average number of inseminations performed in current lactation for cows which have received a positive pregnancy confirmation within the past 6 months. Only active cows are included, which means if a cow has received a postive pregnancy confirmation within the past 6 months but has exited the herd afterwards, she is not included.",
        "AIsPrPregnancyStepGuideHowSubHeader": "Why is it interesting?",
        "AIsPrPregnancyStepGuideHowBodyText": "The KPI helps you evaluate how many inseminations you use on average to get a cow pregnant. \n \n A KPI of 1 shows that you on average use one insemination to get your cows pregnant. A KPI of 3 shows that you on average inseminate your cows three times to get them pregnant.  ",

        "PregnancyRateAt120DFCStepGuideHeader": "Pregnancy rate at 120 DIM",
        "PregnancyRateAt120DFCStepGuideWhatSubHeader": "What is it?",
        "PregnancyRateAt120DFCStepGuideWhatBodyText": "Pregnancy rate at 120 DIM shows how many cows are pregnant when they pass 120 DIM. Note that cows who are pregnant at 120 DIM but abort later are still considered to have been pregnant at 120DIM.",
        "PregnancyRateAt120DFCStepGuideHowSubHeader": "Why is it interesting?",
        "PregnancyRateAt120DFCStepGuideHowBodyText": "Evaluation of how many cows are pregnant at a certain point in lactation is a good measure of how well the general reproduction management is performing, because this is not an average. The raw percentage of cows who are pregnant includes both top performers and outliers.",

        "OpenDaysStepGuideHeader": "Days Open",
        "OpenDaysStepGuideWhatSubHeader": "What is it?",
        "OpenDaysStepGuideWhatBodyText": "Days Open is the average number of days from calving to the date of the insemination, where the cow becomes pregnant again. Active (not cull marked) who have received a postive pregnancy confirmation within the past 6 months are included. If a cow aborts, she is removed from the calculation until she receives a new postive pregnancy confirmation. Cows who exit the herd after receiving a positive pregnancy confirmation are removed from the calculation, when they are not longer active. \n \n Be aware that this KPI does not reflect variation between cows, as it is an average.",
        "OpenDaysStepGuideHowSubHeader": "Why is it interesting?",
        "OpenDaysStepGuideHowBodyText": "The KPI helps you evaluate how good you are at achieving your breeding strategy goals. The KPI helps you assess your expected calving interval and evaluate if you get your cows pregnant, when you want to. \n \n A KPI close to your chosen voluntary waiting period shows that you get your cows pregnant right after you start to breed them. A KPI significantly longer than your chosen voluntary waiting period + 42 days (2 heat cycles) shows that you do not get your cows pregnant when you plan to.",



        // KPI Chart
        "KPIChartStepGuideHeader": "Spiderchart",
        "KPIChartStepGuideWhatSubHeader": "What is this?",
        "KPIChartStepGuideWhatBodyText": "This chart shows your current reproduction performance. \n \n Each of the KPIs are linked to specific measurable events in the reproduction cycle, as shown on the illustration below.",
        "KPIChartStepGuideWhySubHeader": "How do I read it?",
        "KPIChartStepGuideWhyBodyText": "Each KPI is shown on a scale of 0 (worst) to 100 (best). \n \n The shaded area shows the performance of your selected benchmark farms for comparison. \n \n For troubleshooting and identification of areas to improve, consider the KPIs which have the lowest performance or the KPI where your farm's performance deviates the most from the performance of the benchmark farms.",

        // DOA
        "KPIChartStepGuideHeaderDOA": "Anoestrus Length",
        "KPIChartStepGuideWhatSubHeaderDOA": "What is this?",
        "KPIChartStepGuideWhatBodyTextDOA": "Anoestrus length describes the number of days from calving to the first increase in progesterone as illustrated below.",
        "KPIChartStepGuideWhySubHeaderDOA": "Why is it interesting?",
        "KPIChartStepGuideWhyBodyTextDOA": "The first increase in progesterone indicates resumption of activity in the reproductive system of the cow, which means she is recovering after calving. \n \n By monitoring the cows’ ability to resume cyclicity after calving, we have an effective tool to follow-up on transition cow management. For example, a large variation among the cows may be an indication that a certain group of cows needs extra attention, while a consistently long anoestrus period for most cows may indicate that general adjustments needs to be made. \n \n More information is available in the Anoestrus Lenght page.",

        // CR
        "KPIChartStepGuideHeaderCR": "Cycle Regularity",
        "KPIChartStepGuideWhatSubHeaderCR": "What is this?",
        "KPIChartStepGuideWhatBodyTextCR": "Cycle regularity describes the herd variation in number of days between two heats as illustrated below. A regular cycle is 20-23 days between two heats, while anything outside of this is considered irregular. \n \n Prolonged cycles are caused by prolonged follicular and/or luteal phase, as indicated by the arrows in the illustration, which increases the time until the next heat.",
        "KPIChartStepGuideWhySubHeaderCR": "Why is it interesting?",
        "KPIChartStepGuideWhyBodyTextCR": " Irregular cycles indicate, that the reproductive system has not fully recovered after calving. In the aim of getting the cows pregnant as effortlessly as possible after voluntary waiting period, the cows should cycle as regular as possible by the time the farmer starts inseminating. \n \n Hence, cycle regularity is a major factor in timing of insemination and in the ability of the cow to get pregnant – and stay pregnant. ",

        // DAI
        "KPIChartStepGuideHeaderDAI": "Days to First Insemination",
        "KPIChartStepGuideWhatSubHeaderDAI": "What is this?",
        "KPIChartStepGuideWhatBodyTextDAI": "Days to first insemination describes the number of days from calving until the cow receives the first insemination as illustrated below.",
        "KPIChartStepGuideWhySubHeaderDAI": "Why is it interesting?",
        "KPIChartStepGuideWhyBodyTextDAI": "Generally we are aware of when we aim to start insemination, however, the tails of the distribution is often overlooked; Meaning sometimes we are not aware of how late a minority of the cows is inseminated for the first time. \n \n Inseminating some cows a little later than others is completely normal and acceptable, but it is crucial to be aware of how many are inseminated later (and how much later) – and whether this is a conscious choice or not.",

        // AIS
        "KPIChartStepGuideHeaderAIS": "Insemination Success",
        "KPIChartStepGuideWhatSubHeaderAIS": "What is this?",
        "KPIChartStepGuideWhatBodyTextAIS": "Insemination Success is based on how many percent of the cows have a successful insemination. An insemination is considered successful if the cow proceeds past 25 days from insemination (DFAI) with a high progesterone level and without the occurrence of a new heat. \n \n In the illustration below, the cow is inseminated two times; The first insemination is non-successful as the cow comes into heat again and receives another insemination. However, the second insemination is successful, as the cow succesfully passes 25 DFAI. ",
        "KPIChartStepGuideWhySubHeaderAIS": "Why is it interesting?",
        "KPIChartStepGuideWhyBodyTextAIS": "Successful insemination is key to getting the cows pregnant at the right time at a minimum cost. An important aspect to efficient reproduction is that the cows are ready to become pregnant, when the farmer wants them to. The KPI Insemination Success helps keeping track of this.",

        // SEA
        "KPIChartStepGuideHeaderSEA": "Embryo Attachment",
        "KPIChartStepGuideWhatSubHeaderSEA": "What is this?",
        "KPIChartStepGuideWhatBodyTextSEA": "Embryo attachment focuses on embryo survival (absence of Early embryo death) in the herd and is evaluated in percent successes. \n \n Early embryo death occurs when the embryo does not attach to the uterus between day 26 and 36 after successful insemination as illustrated below. If attachment fails, progesterone levels will drop within this period and the cow will have another heat.",
        "KPIChartStepGuideWhySubHeaderSEA": "Why is it interesting?",
        "KPIChartStepGuideWhyBodyTextSEA": "A small number of embryo deaths is completely normal, however, successful embryo attachment is important for the final reproduction result on the farm. If attachment fails, the days open of the individual cow increases significantly. This may not be a problem in a single cow, but on herd level many or late early embryo deaths will impact the overall reproduction performance.",

        // PBSS
        "KPIChartStepGuideHeaderPBSS": "Establishment of Pregnancy",
        "KPIChartStepGuideWhatSubHeaderPBSS": "What is this?",
        "KPIChartStepGuideWhatBodyTextPBSS": "Establishment of pregnancy focuses on (the absence of) Early abortions in the herd and is evaluated in percent successes. \n \n Early abortion occurs when the embryo is lost between day 36 and 55 days from insemination (DFAI) as illustrated below. If a cow has an early abortion, progesterone levels will drop within the period and the cow will have another heat.",
        "KPIChartStepGuideWhySubHeaderPBSS": "Why is it interesting?",
        "KPIChartStepGuideWhyBodyTextPBSS": " A small number of abortions is completely normal, however, successful establishment of pregnancy is important for the final reproduction result on the farm. If final establishment fails, the days open of the individual cow increases significantly. This may not be a problem in a single cow, but on herd level many early abortions will impact the overall reproduction performance.",

        // OD
        "KPIChartStepGuideHeaderOD": "Projected Days Open",
        "KPIChartStepGuideWhatSubHeaderOD": "What is this?",
        "KPIChartStepGuideWhatBodyTextOD": "Projected Days Open is based on non-pregnant cows, instead of on pregnant cows as Days Open traditionally are. \n \n Projected Days Open for non-pregnant cows is based on the most recent insemination, if the cow was inseminated. If the cow was not inseminated on her most recent heat, Projected Days Open is based on heat date + 21 days. 21 days is added to the heat date, because the next possible option for getting a non-inseminated cow pregnant is her next heat.",
        "KPIChartStepGuideWhySubHeaderOD": "Why is it interesting?",
        "KPIChartStepGuideWhyBodyTextOD": "Days Open is one of the final KPIs which summarizes the overall performance on the farm. Each day above the target Days Open may result in increased calving interval, less marginal milk production, increased need for replacement heifers and in the end reduced income. \n \n By usign Projected Days Open instead of considering Days Open for pregnant cows, we are able to follow the trend of the group of cows in the herd, which is not pregnant yet. This allows us to make sure that the future Days open on the farm does not increase unnoticed and that we keep track on the group of cows which is taking a little longer to get pregnant.",

        // New translation Strings
        "StickConsumptionNumberProgressStepGuideHeader": "Step header",
        "StickConsumptionNumberProgressStepGuideWhatSubHeader": "Some sub header",
        "StickConsumptionNumberProgressStepGuideWhatBodyText": "Some body text",

        "StickConsumptionChartStepGuideHeader": "Step header",
        "StickConsumptionChartStepGuideWhatSubHeader": "Step sub header",
        "StickConsumptionChartStepGuideWhatBodyText": "Body text",

         // StickConsumption Page
		// Step 1
        "StickConsumptionTableStepGuideHeader": "Stick consumption",
        "StickConsumptionTableStepGuideWhatSubHeader": "What is this?",
        "StickConsumptionTableStepGuideWhatBodyText": "This table contains selected KPIs related to stick consuption on your farm.",
        "StickConsumptionTableStepGuideHowSubHeader": "How do I read it?",
        "StickConsumptionTableStepGuideHowBodyText": "The 'Sticks used' column is the current number, while the 'Reference' is the number of sticks used on your selected benchmark farms. \n \n The current value is based on the past 60 days. \n \n The 'Trend' column is based on your farms trend compared to 60 days ago. \n \n Each bar in the trend column indicates a 5% change; 1 bar means 0-5% change, 2 bars means 5-10% change and 3 bars means more than 10% change. The color indicates whether the change is positive (green) or negative (red), while the direction of the bars indicates whether there was an increase (bars moving right) or a decrease (bars moving left).",

		// Average sticks per pregnancy
        "SticksUsedPrPregnancyStepGuideHeader": "Average sticks per pregnancy",
        "SticksUsedPrPregnancyStepGuideWhatSubHeader": "What is this?",
        "SticksUsedPrPregnancyStepGuideWhatBodyText": "This is the average number of sticks used per pregnancy in cows which are pregnant and have stopped sampling of progesterone within the past 60 days. \n \n Note that only cows that have been sampled in their entire current lactation are included in this number. This means that recently started farms will not have cows contributing to this KPI before 4-6 months after start-up.",
        "SticksUsedPrPregnancyStepGuideHowSubHeader": "Why is it interesting?",
        "SticksUsedPrPregnancyStepGuideHowBodyText": "This KPI helps you keep track of how many sticks it takes on average to get a cow pregnant. The number is highly dependant on how many cycles (heats) the cow has before she is pregnant (the more cycles, the more sticks used) and on occurrence of cysts (sick cows are sampled more).",

		// Average sticks per cow
        "SticksUsedPrCowStepGuideHeader": "Average sticks per cow",
        "SticksUsedPrCowStepGuideWhatSubHeader": "What is this?",
        "SticksUsedPrCowStepGuideWhatBodyText": "This is the average number of sticks used per cow in cows that have been sampled for progesterone within the past 60 days. The number includes both pregnant and non pregnant cows. On recently started farms, this number will increase for 4-6 months, because the number of samples per cow increases until it reaches a stable level. This is normal. Use the reference number of your benchmark farms to see if you are spending more sticks than you should.",
        "SticksUsedPrCowStepGuideHowSubHeader": "Why is it interesting?",
        "SticksUsedPrCowStepGuideHowBodyText": "As this number includes all cows, it is an efficient tool when used in comparison to Average number of sticks per pregnancy. Notice whether the number of sticks per cow is higher or lower than the number of sticks per pregnancy. If the number is higher, this can be because you are sampling on a number of cows, which are still not pregnant by the time they should be.",

		// Average sticks from first to last AI
        "SticksUsedFromFirstToLastAIStepGuideHeader": "Average sticks from first to last AI",
        "SticksUsedFromFirstToLastAIStepGuideWhatSubHeader": "What is this?",
        "SticksUsedFromFirstToLastAIStepGuideWhatBodyText": "This is the average number of sticks used per pregnant cow from the date where they recieve their first insemination to the date where they received the last insemination. The KPI includes all cows that have been diagnosed as pregnant within the past 60 days.",
        "SticksUsedFromFirstToLastAIStepGuideHowSubHeader": "Why is it interesting?",
        "SticksUsedFromFirstToLastAIStepGuideHowBodyText": "This is another way of assessing the time it takes to get a cow pregnant as we can see directly how many sticks is used, when the time from first to last AI changes.",

		// Average sticks on follicular cysts
        "SticksUsedOnFollicularCystsStepGuideHeader": "Average sticks on follicular cysts",
        "SticksUsedOnFollicularCystsStepGuideWhatSubHeader": "What is this?",
        "SticksUsedOnFollicularCystsStepGuideWhatBodyText": "This is the average number of sticks used on cows which follicular cysts in the period where they have had the follicular cyst. All cows which have had a follicular cysts within the past 60 days are included.",
        "SticksUsedOnFollicularCystsStepGuideHowSubHeader": "Why is it interesting?",
        "SticksUsedOnFollicularCystsStepGuideHowBodyText": "This number helps assess whether there is a change in the length of the period, where cows have a follicular cyst. This enables you to evaluate conscious and unexpected changes in how cows with follicular cysts are treated. Be aware of sudden unexpected increases, because this indicates that cows are taking longer to recover than usual. You can also compare your own strategy to other farms and see if your cows generally have longer or shorter periods with follicular cysts. ",
		
		// Average sticks on luteal cysts
        "SticksUsedOnLutealCystsStepGuideHeader": "Average sticks on luteal cysts",
        "SticksUsedOnLutealCystsStepGuideWhatSubHeader": "What is this?",
        "SticksUsedOnLutealCystsStepGuideWhatBodyText": "This is the average number of sticks used on cows which luteal cysts in the period where they have had the luteal cyst. All cows which have had a luteal cysts within the past 60 days are included.",
        "SticksUsedOnLutealCystsStepGuideHowSubHeader": "Why is it interesting?",
        "SticksUsedOnLutealCystsStepGuideHowBodyText": "This number helps assess whether there is a change in the length of the period, where cows have a luteal cyst. This enables you to evaluate conscious and unexpected changes in how cows with luteal cysts are treated. Be aware of sudden unexpected increases, because this indicates that cows are taking longer to recover than usual. You can also compare your own strategy to other farms and see if your cows generally have longer or shorter periods with luteal cysts. ",
    }
}
