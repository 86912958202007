import React from "react";
import DOAIcon from "../../Icons/DOA";
import CRIcon from "../../Icons/CR";
import HNIRIcon from "../../Icons/HNIR";
import AISIcon from "../../Icons/AIS";
import SEAIcon from "../../Icons/SEA";
import PBSSIcon from "../../Icons/PBSS";
import ODIcon from "../../Icons/OD";
import Button from "../../Input/Button";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HoverGuide from "../../HoverGuide";
import { SELECT_DATA_TME_PERIOD, SELECT_DATA_TME_START } from "../../redux/Filters/constants";
import { TOGGLE_COW_LIST } from "../../redux/Navigation/constants";


import "../Layout.scss";

const LayoutKPISidebar = ({ component: Component, ...rest }: any) => {
    let history = useHistory();
    const navigationState = useSelector((state: any) => state.navigationState);

    const dispatch = useDispatch();

    const navigate = (url: string) => {
        const today = new Date();
        // close the cow list if not pinned
        if (!navigationState.cowListPinned && navigationState.cowListOpen) dispatch({ type: TOGGLE_COW_LIST });
        dispatch({ type: SELECT_DATA_TME_PERIOD, payload: { index: 0, key: "anoestrusLength" } });
        dispatch({ type: SELECT_DATA_TME_START, payload: { today} });

        history.push(url);
    }
    
    return (

        <div className="layout__kpi-sidebar">
            <HoverGuide content="Anoestrus length">
                <Button iconButton={true} style={{ margin: "9px" }} onClick={() => navigate("/hnadvice/doa")}>
                    <DOAIcon size={25} />
                </Button>
            </HoverGuide>
            <HoverGuide content="Cycle regularity">
                <Button iconButton={true} style={{ margin: "9px" }} onClick={() => navigate("/hnadvice/cr")}>
                    <CRIcon size={25} />
                </Button>
            </HoverGuide>

            <HoverGuide content="HN insemination rate">
                <Button iconButton={true} style={{ margin: "9px" }} onClick={() => navigate("/hnadvice/hnir")}>
                    <HNIRIcon size={25} />
                </Button>
            </HoverGuide>

            <HoverGuide content="Insemination success">
                <Button iconButton={true} style={{ margin: "9px" }} onClick={() => navigate("/hnadvice/ais")}>
                    <AISIcon size={25} />
                </Button>
            </HoverGuide>

            <HoverGuide content="Embryo attachment">
                <Button iconButton={true} style={{ margin: "9px" }} onClick={() => navigate("/hnadvice/sea")}>
                    <SEAIcon size={25} />
                </Button>
            </HoverGuide>


            <HoverGuide content="Establishment of pregnancy">
                <Button iconButton={true} style={{ margin: "9px" }} onClick={() => navigate("/hnadvice/pbss")}>
                    <PBSSIcon size={25} />
                </Button>
            </HoverGuide>

            <HoverGuide content="Projected open days">
                <Button iconButton={true} style={{ margin: "9px" }} onClick={() => navigate("/hnadvice/od")}>
                    <ODIcon size={25} />
                </Button>
            </HoverGuide>
        </div>
    );
};

export default LayoutKPISidebar;
