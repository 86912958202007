import React from "react";
import "../Input.scss";

interface IDropdown {
    key: string;
    value: string;
}

const ButtonGroup: React.FC = (props) => {
    return (
        <div className={`input__button-group ${React.Children.count(props.children) === 1 ? "input__button-group--alone" : ""}`}>
            {props.children}
        </div>

    )
};

export default ButtonGroup;
