import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, CardGuide, CardHeader, CardHeadline } from "../../Card";
import { BarAreaStacked, ScatterChart } from "../../Charts";
import { SetBarNormal, SetBarHovered, SetBarSelected } from "../../Charts/BarAreaStacked";
import { ContentHeader, ContentHeadline } from "../../Content";
import { GET_COWS_LIST_REQUEST, HANDLE_FILTER_COW_LIST_INITIALIZE_LOCAL, HANDLE_COW_LIST_SELECT, HANDLE_COW_LIST_HOVERED, HANDLE_SELECTION_COW } from "../../redux/CowsList/constants";
import { SELECT_DATA_TME_PERIOD, SELECT_LACTATION_FILTERS } from "../../redux/Filters/constants";
import { IFilterState } from "../../redux/Filters/reducer";
import { OPEN_COW_LIST, SET_LIBRARY_OPEN_URL } from "../../redux/Navigation/constants";
import "./HNIR.scss";
import { contrastedTwoColorPalette } from "../../styles/ColorCodes";
import { handleTriggerStartTour } from "../../utills/intercom";
import { GET_PERFORMANCE_SCORE_HISTORY_REQUEST } from "../../redux/PerformanceScoreHistory/constants";
import KPIPageFilters from "../../KPIPageFilters";
import OverView12Month from "../../Cards/OverView12Month"
import { Column, Row } from "../../Layout";
import { BarExtendedDatum } from "@nivo/bar";
import { iPoint } from "@src/Charts/ScatterChart/interface";
const HNIR: React.FC = (props) => {

    const filtersState: IFilterState = useSelector((state: any) => state.filtersState);
    const cowsListState = useSelector((state: any) => state.cowsListState);
    const performanceScoreHistoryState = useSelector((state: any) => state.performanceScoreHistoryState);
    const [secondaryExpandedState, handleToggleSecondaryExpandedState] = useState(false);

    const dispatch = useDispatch();
    const keys = ["Inseminated", "Not Inseminated"];
    const filters: any = {};

    useEffect(() => {
        const startDate = new Date(filtersState.today);
        startDate.setDate(startDate.getDate() - filtersState.anoestrusLengthSelectedTimePeriod.days);
        filtersState.lactationFilters.culledChecked = filtersState.lactationFilters.l1Checked = filtersState.lactationFilters.l2Checked = filtersState.lactationFilters.l3Checked = true;
        filtersState.lactationFilters.delayedChecked = false;

        // get the cowlist
        dispatch({
            type: GET_COWS_LIST_REQUEST,
            payload: {
                filters,
                keys,
                path: "hnir",
                startDate: startDate,
                stopDate: filtersState.today,
                lactationFilters: filtersState.lactationFilters,
            },
        });

        dispatch({ type: GET_PERFORMANCE_SCORE_HISTORY_REQUEST, payload: { kpi: "HNIR" } });
    }, [dispatch, filtersState.anoestrusLengthSelectedTimePeriod, filtersState.today]);

    const handleSelectTimePeriod = (e: any, key: string) => dispatch({ type: SELECT_DATA_TME_PERIOD, payload: { index: e.target.selectedIndex, key } });

    const handleToggleLactationFilter = (key: string) => {
        SetBarNormal(cowsListState.selected)
        dispatch({ type: HANDLE_COW_LIST_SELECT, payload: undefined });

        dispatch({ type: SELECT_LACTATION_FILTERS, payload: { key } });
        const newFilter = filtersState.lactationFilters;
        newFilter[`${key}Checked`] = !newFilter[`${key}Checked`];
        dispatch({
            type: HANDLE_FILTER_COW_LIST_INITIALIZE_LOCAL,
            payload: {
                filters,
                keys,
                path: "hnir",
                data: cowsListState.data,
                lactationFilters: newFilter,
                p2reference: cowsListState.p2reference,
                p3reference: cowsListState.p3reference,
            },
        });
    }

    const handleSetCowListFilter = (filters: any, keys: string[], scatter?: boolean) => {
        if (scatter) dispatch({ type: OPEN_COW_LIST });

        dispatch({
            type: HANDLE_FILTER_COW_LIST_INITIALIZE_LOCAL,
            payload: {
                filters: (scatter ? filters : (cowsListState.selected === cowsListState.hovered ? {} : filters)),
                keys,
                path: "hnir",
                data: cowsListState.data,
                lactationFilters: filtersState.lactationFilters,
                p2reference: cowsListState.p2reference,
                p3reference: cowsListState.p3reference,
            },
        });
    }

    const handleSelection = (data: iPoint[], keys: string[]) => {
        SetBarNormal(cowsListState.selected)
        dispatch({ type: OPEN_COW_LIST })
        dispatch({
            type: HANDLE_SELECTION_COW,
            payload: {
                keys,
                path: "hnir",
                data: cowsListState.data,
                selection: data
            },
        });
    }

    const handleShowCows = (elem: any) => {
        if (cowsListState.selected !== cowsListState.hovered) {
            if (cowsListState.hovered !== undefined) {
                // set selected
                SetBarSelected(cowsListState.hovered);
            }
            if (cowsListState.selected !== undefined) {
                // clear selected
                SetBarNormal(cowsListState.selected);
            }
            dispatch({ type: HANDLE_COW_LIST_SELECT, payload: cowsListState.hovered });
        } else {
            if (cowsListState.selected !== undefined) {
                // de-select selected, so set hovered
                SetBarHovered(cowsListState.selected)
            }
            dispatch({ type: HANDLE_COW_LIST_SELECT, payload: undefined });
        }

        dispatch({ type: OPEN_COW_LIST });
    }

    const handleMouseEnter = (elem: BarExtendedDatum, event: React.MouseEvent) => {
        dispatch({ type: HANDLE_COW_LIST_HOVERED, payload: event.currentTarget });
    };

    const handleSetUrl = (url: string) => dispatch({ type: SET_LIBRARY_OPEN_URL, payload: url });

    return (
        <Fragment>
            <Row first={true} flex={1}>
                <Column flex={3} first={true}>
                    <ContentHeader>
                        <ContentHeadline text="HN Insemination Rate" subHeader="Evaluates how many HN heats (occuring after voluntary waiting period), which are accompanied by an insemination." />
                        <KPIPageFilters handleSelectTimePeriod={handleSelectTimePeriod} filtersState={filtersState} handleToggleLactationFilter={handleToggleLactationFilter} hasDelayedFilter={true} />
                    </ContentHeader>
                </Column>
                <Column flex={2}>
                    <OverView12Month
                        performanceScore={performanceScoreHistoryState}
                        stepGuide={178794}
                        kpi={"hnir"}
                    />
                </Column>
            </Row>
            <Row last={true} flex={3}>
                <Column first={true} flex={secondaryExpandedState ? 1 : 3}>
                    <Card>
                        <CardHeader>
                            <CardHeadline text="Inseminations on Days in Milk" />
                            <CardGuide
                                onOpenLib={() => handleSetUrl("hnir/lvl3/iodim/summary")}
                                openStepGuide={() => handleTriggerStartTour(178789)}
                                toggleExpand={() => handleToggleSecondaryExpandedState(!secondaryExpandedState)}
                                isExpanded={!secondaryExpandedState}
                            />
                        </CardHeader>
                        <CardContent>
                            <ScatterChart
                                data={cowsListState.scatterP1HNIR}
                                yLegend="Heat number"
                                xLegend="DIM on heat"
                                colors={contrastedTwoColorPalette}
                                filter={(filters: any) => handleSetCowListFilter(filters, [], true)}
                                stepSizeX={20}
                                stepSizeY={1}
                                onSelection={(data: iPoint[]) => handleSelection(data, ["DaysFromCalving", "HeatNo"])}
                            />
                        </CardContent>
                    </Card>
                </Column>
                <Column last={true} flex={secondaryExpandedState ? 3 : 1}>
                    <Card>
                        <CardHeader>
                            <CardHeadline text="Heat after Voluntary waiting period" /> {/*Cows with no AI on heat alarm*/}
                            <CardGuide
                                onOpenLib={() => handleSetUrl("hnir/lvl3/havwp/summary")}
                                openStepGuide={() => handleTriggerStartTour(178791)}
                                toggleExpand={() => handleToggleSecondaryExpandedState(!secondaryExpandedState)}
                                isExpanded={secondaryExpandedState}
                            />
                        </CardHeader>
                        <CardContent>
                            <BarAreaStacked
                                colors={contrastedTwoColorPalette}
                                filter={(filters: any) => handleSetCowListFilter(filters, ["HeatNo", "HNIRState"])}
                                filterKeys={[{ key: "HNIRState", value: "id" }, { key: "ranges", value: "indexValue" }]}
                                tooltipHeadline={``}
                                data={cowsListState.graphP3Data}
                                keys={["Inseminated", "Not Inseminated"]}
                                indexBy="Bin"
                                yLegend="Number of cows"
                                xLegend="Heat number"
                                onClick={(elem: any) => handleShowCows(elem)}
                                maxValue={cowsListState.graphP3Max}
                                onMouseEnter={(elem: BarExtendedDatum, event: React.MouseEvent) => handleMouseEnter(elem, event)}
                                selected={cowsListState.selected}
                            />
                        </CardContent>
                    </Card>
                </Column>
            </Row>
        </Fragment>
    );
};

export default HNIR;

