import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { IBasicPayload } from "../../actions";
import { postXML } from "../../api/requests";
import {
    GET_SETTINGS_FAILURE,
    GET_SETTINGS_REQUEST,
    GET_SETTINGS_SUCCESS,
    SET_NEW_SETTINGS_FAILURE,
    SET_NEW_SETTINGS_REQUEST,
    SET_NEW_SETTINGS_SUCCESS,
} from "./constants";

function* getSettings(action: Action<IBasicPayload>): SagaIterator {
    try {

        /*
        [
            {
                "VoluntaryWaitingPeriodL1": 70,
                "VoluntaryWaitingPeriodL2P": 70,
                "DimZeroMeasurementBasisId": 1,
                "ZeroMeasurementBasisDescription": "Before HN",
                "DimBenchmarkFilterGeographicalRegionId": 2,
                "DimBenchmarkFilterGeographicalRegionDescription": "Own Country",
                "DimBenchmarkFilterPerformanceLevelId": 0,
                "PerformanceLevel": 50,
            }
        ]
        */

        const payload = yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farm/settings");

        yield put({ type: GET_SETTINGS_SUCCESS, payload: payload[0] });
    } catch (error) {
        console.log(GET_SETTINGS_FAILURE, error);
        yield put({ type: GET_SETTINGS_FAILURE, payload: "An error occured" });
    }
}

function* setNewSettings(action: any): SagaIterator {
    try {

        yield call(postXML, "https://HNNEXTUX0301.herdnavigatorlive.com/farm/newsettings", action.payload, "FarmSettings");
        yield put({ type: SET_NEW_SETTINGS_SUCCESS, payload: null });

        // refresh page here
        window.location.reload();
    } catch (error) {
        console.log(SET_NEW_SETTINGS_FAILURE, error);
        yield put({ type: SET_NEW_SETTINGS_FAILURE, payload: "Unable to get data" });
    }
}

export function* watchSettingsSaga(): SagaIterator {
    yield takeEvery(GET_SETTINGS_REQUEST, getSettings);
    yield takeEvery(SET_NEW_SETTINGS_REQUEST, setNewSettings);
}
