import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TOGGLE_COW_LIST, TOGGLE_COW_LIST_PINNED } from "../../redux/Navigation/constants";
import Button from "../../Input/Button"
import "../Layout.scss";
import "./CowsList.scss";
import { iCowListElement } from "./interface";

const LayoutCowList: React.FC = (props) => {

    // cowsListState
    const cowsListState = useSelector((state: any) => state.cowsListState);
    const navigationState = useSelector((state: any) => state.navigationState);

    const dispatch = useDispatch();

    const CowListElement: React.FC<iCowListElement> = (props) => {
        return (
            <div className="cows-list__element">
                <div className="cows-list__element-item">
                    <p className="cows-list__label"><b>Cow</b></p>
                    <p className="cows-list__data"><b>{props.el.CowNo}</b></p>
                </div>

                <div className="cows-list__element-item">
                    <p className="cows-list__label">Lactation number</p>
                    <p className="cows-list__data">{props.el.LactationNo}</p>
                </div>

                {props.el.DOAState && props.el.CullState && (
                    <div className="cows-list__element-item">
                        <p className="cows-list__label">Current status</p>
                        <p className="cows-list__data">{props.el.CullState !== "Not culled" ? props.el.CullState : props.el.DOAState}</p>
                    </div>
                )}

                {props.el.AnoestrusLength && (
                    <div className="cows-list__element-item-with-padding">
                        <p className="cows-list__label">Anoestrus length</p>
                        <p className="cows-list__data">{props.el.DOAState === "Anoestrus" ? "Not completed" : props.el.AnoestrusLength}</p>
                    </div>
                )}

                {props.el.DaysFromCalving && (
                    <div className="cows-list__element-item">
                        <p className="cows-list__label">DIM on insemination</p>
                        <p className="cows-list__data">{props.el.DaysFromCalving}</p>
                    </div>
                )}

                {!props.el.DOAState && props.el.CullState !== "Not culled" && (
                    <div className="cows-list__element-item">
                        <p className="cows-list__label">Cull status</p>
                        <p className="cows-list__data">{props.el.CullState}</p>
                    </div>
                )}

                {props.el.HeatNo && (
                    <div className="cows-list__element-item-with-padding">
                        <p className="cows-list__label">Heat number after VWP</p>
                        <p className="cows-list__data">{props.el.HeatNo}</p>
                    </div>
                )}

                {props.el.HNIRState && (
                    <div className="cows-list__element-item">
                        <p className="cows-list__label">Inseminated</p>
                        <p className="cows-list__data">{props.el.HNIRState === "Inseminated" ? "Yes" : "No"}</p>
                    </div>
                )}

                {props.el.AINumber && (
                    <div className="cows-list__element-item">
                        <p className="cows-list__label">AI number</p>
                        <p className="cows-list__data">{props.el.AINumber}</p>
                    </div>
                )}

                {props.el.InseminationState && (
                    <div className="cows-list__element-item">
                        <p className="cows-list__label">Insemination result</p>
                        <p className="cows-list__data">{props.el.InseminationState}</p>
                    </div>
                )}

                {!props.el.DOAState && props.el.CurrentInseminationState && (
                    <div className="cows-list__element-item">
                        <p className="cows-list__label">Current status</p>
                        <p className="cows-list__data">{props.el.CurrentInseminationState}</p>
                    </div>
                )}

                {props.el.OpenDays && (
                    <div className="cows-list__element-item">
                        <p className="cows-list__label">Open days</p>
                        <p className="cows-list__data">{props.el.OpenDays}</p>
                    </div>
                )}

                {props.el.MAXAINumber && (
                    <div className="cows-list__element-item-with-padding">
                        <p className="cows-list__label">Max AI number</p>
                        <p className="cows-list__data">{props.el.MAXAINumber}</p>
                    </div>
                )}

                {props.el.CompletionStatus && (
                    <div className="cows-list__element-item">
                        <p className="cows-list__label">Current repro. state</p>
                        <p className="cows-list__data">{props.el.CompletionStatus}</p>
                    </div>
                )}

                {props.el.HoursBetweenHeatAndAI && (
                    <div className="cows-list__element-item-with-padding">
                        <p className="cows-list__label">Hours between heat and AI</p>
                        <p className="cows-list__data">{props.el.HoursBetweenHeatAndAI}</p>
                    </div>
                )}

                {props.el.FPLength && (
                    <div className="cows-list__element-item">
                        <p className="cows-list__label">Follicular phase length</p>
                        <p className="cows-list__data">{props.el.FPLength}</p>
                    </div>
                )}

                {props.el.State && (
                    <div className="cows-list__element-item">
                        <p className="cows-list__label">State</p>
                        <p className="cows-list__data">{props.el.State}</p>
                    </div>
                )}

                {props.el.SEAState && (
                    <div className="cows-list__element-item-with-padding">
                        <p className="cows-list__label">Embryo attachment</p>
                        <p className="cows-list__data">{props.el.SEAState}</p>
                    </div>
                )}

                {props.el.PBSSState && (
                    <div className="cows-list__element-item-with-padding">
                        <p className="cows-list__label">Final establishment</p>
                        <p className="cows-list__data">{props.el.PBSSState}</p>
                    </div>
                )}

                {props.el.DOADelay !== undefined && props.el.DOAState !== undefined && props.el.DOADelay !== "Not delayed" && (
                    <div className="cows-list__element-item">
                        <p className="cows-list__label">Sample delayed</p>
                        <p className="cows-list__data">{props.el.DOADelay}</p>
                    </div>
                )}

                {props.el.FirstLutealPhaseLength && props.el.FPLState && (
                    <div className="cows-list__element-item-with-padding">
                        <p className="cows-list__label">First luteal phase length</p>
                        <p className="cows-list__data">{props.el.FPLState === "Completed" ? props.el.FirstLutealPhaseLength : "Not completed"}</p>
                    </div>
                )}

                {props.el.FLPDelay && props.el.FPLState !== undefined && props.el.FLPDelay !== "Not delayed" && (
                    <div className="cows-list__element-item">
                        <p className="cows-list__label">Sample delayed</p>
                        <p className="cows-list__data">{props.el.FLPDelay}</p>
                    </div>
                )}

                {props.el.InseminationData && props.el.InseminationData.map((el: any, index: number) => {
                    return (
                        <div className="__inner-element">
                            <div className="cows-list__element-item-with-padding">
                                <p className="cows-list__label">Heat number after VWP</p>
                                <p className="cows-list__data">{el.HeatNo}</p>
                            </div>
                            <div className="cows-list__inner-element-item">
                                <p className="cows-list__label">DIM on insemination</p>
                                <p className="cows-list__data">{el.DaysFromCalving}</p>
                            </div>
                            <div className="cows-list__inner-element-item">
                                <p className="cows-list__label">Inseminated</p>
                                <p className="cows-list__data">{el.HNIRState === "Inseminated" ? "Yes" : "No"}</p>
                            </div>
                            {el.FilterSampleDelay !== undefined && el.FilterSampleDelay !== "Not delayed" && (
                                <div className="cows-list__inner-element-item">
                                    <p className="cows-list__label">Delay</p>
                                    <p className="cows-list__data">{el.FilterSampleDelay}</p>
                                </div>
                            )}
                        </div>
                    )
                }
                )}

                {props.el.CycleData && props.el.CycleData.map((el: any, index: number) => {
                    return (
                        <div className="__inner-element">
                            <div className="cows-list__element-item-with-padding">
                                <p className="cows-list__label">Cycle on set</p>
                                <p className="cows-list__data">{new Date(el.CycleOnset).toLocaleDateString("en-GB")}</p>
                            </div>
                            {el.CLState && el.CLState === "Completed" && el.CycleLength && (
                                <div className="cows-list__inner-element-item">
                                    <p className="cows-list__label">Cycle length</p>
                                    <p className="cows-list__data">{el.CycleLength}</p>
                                </div>
                            )}
                            {el.CLState && el.CLState !== "Completed" && (
                                <div className="cows-list__inner-element-item">
                                    <p className="cows-list__label">Cycle length</p>
                                    <p className="cows-list__data">{el.CLState}</p>
                                </div>
                            )}
                            {el.FPLState && el.FPLState === "Completed" && el.FolPhaseLength && (
                                <div className="cows-list__inner-element-item">
                                    <p className="cows-list__label">Follicular phase length</p>
                                    <p className="cows-list__data">{el.FolPhaseLength}</p>
                                </div>
                            )}
                            {el.FPLState && el.FPLState !== "Completed" && (
                                <div className="cows-list__inner-element-item">
                                    <p className="cows-list__label">Follicular phase length</p>
                                    <p className="cows-list__data">{el.FPLState}</p>
                                </div>
                            )}
                            {el.FilterSampleDelay !== undefined && el.FilterSampleDelay !== "Not delayed" && (
                                <div className="cows-list__inner-element-item">
                                    <p className="cows-list__label">Delay</p>
                                    <p className="cows-list__data">{el.FilterSampleDelay}</p>
                                </div>
                            )}
                        </div>
                    )
                }
                )}
            </div>
        )
    }

    return (
        <div className="layout__cow-list">
            <div className="cows-list__header">
                <Button disableRipple={true} invert={true} onClick={() => dispatch({ type: TOGGLE_COW_LIST_PINNED })}><i className={`${navigationState.cowListPinned ? 'fas' : 'fal'} fa-thumbtack`} /></Button>
                <Button disableRipple={true} invert={true} onClick={() => dispatch({ type: TOGGLE_COW_LIST })}><i className="fas fa-chevron-right" /></Button>
            </div>
            {cowsListState.filterMode ? cowsListState.filteredData.map((el: any, index: number) => {
                return (
                    <CowListElement key={`cl-${index}`} el={el} index={index} />
                )
            }) : cowsListState.data.length > 0 && cowsListState.data.map((el: any, index: number) => {
                return (
                    <CowListElement key={`cl-${index}`} el={el} index={index} />
                );
            })}
        </div>
    );
};

export default LayoutCowList;
