// import 'whatwg-fetch';
import { getToken } from "../../utills/authorization";
import { Api } from "../Api";
import { getFarmsUrl } from "../Common/RestUrlBuilder";

export function TryGetFarmSelectionData(userName: string) {

    const url: string = getFarmsUrl();
    const token: any = getToken();

    return fetch(url, {
        method: "POST",
        headers: new Headers({
            "Authorization": `Bearer ${token}`,
            "Access-Control-Allow-Origin": "true",
        }),
        body: userName,
    })
    .then(Api.utilities.statusHelper)
    .then(Api.utilities.toJSON);
}
