import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Card, CardGuide, CardHeader, CardHeadline } from "../../Card";
import { DataTypeDisplay } from "../../DataType";
import {
    GET_ADVANCED_BASELINE_REPORT_REQUEST,
    GET_SIMPLE_BASELINE_REPORT_REQUEST,
} from "../../redux/BaselineReort/constants";
import { SET_CRP_DB_HOVERED, DASHBOARD_TOGGLE, STICK_CONSUMPTION_TOGGLE, COLOR_SCREME_1, COLOR_SCREME_2 } from "../../redux/Navigation/constants";
import { handleTriggerStartTour } from "../../utills/intercom";
import LattecIcon from "../../LattecIcon";
import Loader from "../../Loader";

import "../../Table/Table.scss";
import "./BaselineReport.scss";
import { iBaselineReport } from "./interface";
import PlaceholderLoader from "../../PlaceholderLoader";

const BaselineReport: React.FC<iBaselineReport> = (props) => {

    const baselineReportState = useSelector((state: any) => state.baselineReportState);
    const navigationState = useSelector((state: any) => state.navigationState);

    const { t } = useTranslation();

    const [isClicked, setIsClicked] = useState(false);
    const [selectedElement, setSelectedElement] = useState("");
    const targetRef = useRef<HTMLHeadingElement>(null);

    const dispatch = useDispatch();

    const adjustColumnWidth = (data: any, index: number) => (index === 0 && props.simple) ? "100%" : index === 0 ? `${(100 / data.length)}%` : `${100 / data.length}%`;

    const fontColor = (KPIName: string, value: number) => {
        if (value.toFixed(1) === "0.0") return "#edf353";
        if (KPIName === "ProportionOfCowsBred" || KPIName === "InseminationRate" || KPIName === "PregnancyRate") {
            if(isNaN(value)) {
                return "black"
            } else if (value > 0) return "green";
            else return "red";
        }
        if(isNaN(value)) {
            return "black"
        } else if (value > 0) return "red";
        else return "green";
    }

    const handleHoverRelation = (kpi: string, index: number) => {
        let hoveredKpiList = [""];

        switch (kpi) {
            case "ProportionOfCowsBred":
                hoveredKpiList = ["hnir"];
                break;

            case "CalvingToFirstAIInterval":
                hoveredKpiList = ["doa", "hnir"];
                break;

            case "FirstToLastAIInterval":
                hoveredKpiList = ["hnir", "ais", "sea", "pbss"];
                break;

            case "AIsPrCow":
                hoveredKpiList = ["ais", "sea", "pbss"];
                break;

            case "InseminationRate":
                hoveredKpiList = ["doa", "crbwp", "hnir"];
                break;

            case "ConceptionRate":
            case "AIsPrPregnancy":
                hoveredKpiList = ["ais", "sea", "pbss"];
                break;

            case "PregnancyRate":
                hoveredKpiList = ["hnir", "ais", "sea", "pbss"];
                break;

            case "OpenDays":
                hoveredKpiList = ["od"];
                break;
        }

        dispatch({ type: SET_CRP_DB_HOVERED, payload: hoveredKpiList });
    };

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (targetRef.current && (event.target.className !== "table__element" && event.target.className !== "table__content" && event.target.className !== "table__label" && event.target.className !== "table__column" && event.target.className !== "table__benchmark-container")) {
                setIsClicked(false);
                setSelectedElement("");

                dispatch({ type: SET_CRP_DB_HOVERED, payload: [""] });
            }
        };

        // get questions when component is mounted
        if (props.simple) {
            dispatch({ type: GET_SIMPLE_BASELINE_REPORT_REQUEST, payload: null });
        } else {
            dispatch({ type: GET_ADVANCED_BASELINE_REPORT_REQUEST, payload: null });
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [dispatch, props.simple]);


    const handleHoverOnInteractiveElement = (kpi: string, index: number) => {
        if (isClicked === false) {
            handleHoverRelation(kpi, index);
        }
    };

    const handleClickOnInteractiveElement = (kpi: string, index: number, event: any) => {
        setIsClicked(true);
        setSelectedElement(kpi);
        handleHoverRelation(kpi, index);
    };

    let dataType: number;
    if (props.toogleId === DASHBOARD_TOGGLE) dataType = navigationState.dataType;
    else if (props.toogleId === STICK_CONSUMPTION_TOGGLE) dataType = navigationState.dataStickConsumptionType;
    else dataType = navigationState.dataBaseLineReportType;

    if (!props.simple && baselineReportState.data && baselineReportState.data[0]) {
        props.headers[1] = dataType === 0 ? "Benchmark data " : "Baseline start"
    }

    const formatNumber = (value: number) => {
        if (value === 0 || isNaN(value)) return "-";
        return (value > 0 ? "+" : "") + value.toFixed(1);
    }

    return (
        <div className="baseline-report" id="baseline-report">
            <Card>
                <CardHeader>
                    <CardHeadline icon={<LattecIcon size={17} icon="baseline" />} text={t("BaselineReport")} link={props.link} />
                    <CardGuide openStepGuide={() => handleTriggerStartTour(191008)} />
                </CardHeader>
                {baselineReportState.loading ? (
                    <>
                        <PlaceholderLoader type='Baseline' height='98' />
                        <PlaceholderLoader type='Baseline' height='98' />
                        <PlaceholderLoader type='Baseline' height='98' />
                        <PlaceholderLoader type='Baseline' height='98' />
                        <PlaceholderLoader type='Baseline' height='98' />
                    </>
                ) : (
                        <div className="table">
                            {props.headers && (
                                <div className="table__header" style={{ margin: (props.headers[0] && props.headers[0].length > 0) ? "9px 0" : "0px" }}>
                                    {props.headers.map((el: string, index: number) => {
                                        return (
                                            <h6 key={index} style={{ width: adjustColumnWidth(props.headers, index) }} className="table__headline">{el} {el === "Baseline start" ? <p className="table__headline">{el === "Baseline start" && baselineReportState.data[0] ? new Date(baselineReportState.data[0].StartingPointDate).toLocaleDateString("en-GB") : ""}</p> : ""}</h6>
                                        );
                                    })}
                                </div>
                            )}
                            <Fragment>
                                {baselineReportState.data.length && baselineReportState.data.map((el: any, index: number) => {
                                    return (
                                        <div ref={targetRef} onClick={(event: any) => handleClickOnInteractiveElement(el.KPIName, index, event)} onMouseOver={() => handleHoverOnInteractiveElement(el.KPIName, index)} onMouseOut={() => handleHoverOnInteractiveElement("", -1)} key={`crp__${index}`} className="table__element" style={{ border: selectedElement === el.KPIName ? "2px solid #38537d" : "" }}>
                                            <div className="table__content">
                                                <div className="table__column" style={{ width: adjustColumnWidth(props.headers, 0) }}>
                                                    <p className="table__label" style={{ textAlign: "start" }}>{t(el.KPIName)}</p>
                                                </div>
                                                {!props.simple && (
                                                    <div className="table__column" style={{ width: adjustColumnWidth(props.headers, 1) }}>
                                                        <p className="table__label">{dataType === 0 ? el.BenchmarkValue : el.StartingPointValue}</p>
                                                    </div>
                                                )}

                                                {!props.simple && (
                                                    <div className="table__column" style={{ width: adjustColumnWidth(props.headers, 2) }}>
                                                        <p className="table__label">{el.CurrentPointValue}</p>
                                                    </div>
                                                )}

                                                <div className="table__column" style={{ width: adjustColumnWidth(props.headers, 3) }}>
                                                    <div className="table__benchmark-container">
                                                        <DataTypeDisplay
                                                            currentValueId="baseline-report-current-value-bar"
                                                            referenceValudId="baseline-report-reference-value-bar"
                                                            dataType={dataType}
                                                            layout="horizontal"
                                                            colors={dataType === 0 ? COLOR_SCREME_1 : COLOR_SCREME_2}
                                                            trend={el.Trend}
                                                            benchmarkComparison={el.BenchmarkValue}
                                                            current={parseFloat(el.CurrentPointValue)}
                                                            ownComparison={parseFloat(el.StartingPointValue)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="table__column" style={{ width: adjustColumnWidth(props.headers, 4), display: "flex", justifyContent: "flex-end" }}>
                                                    {<span className="table__label" style={{ fontWeight: "bold", color: fontColor(el.KPIName, dataType === 0 ? parseFloat(el.CurrentPointValue) - parseFloat(el.BenchmarkValue) : parseFloat(el.CurrentPointValue) - parseFloat(el.StartingPointValue)) }}>
                                                        {/*(dataType === 0 ? parseFloat(el.CurrentPointValue) - parseFloat(el.BenchmarkValue) : parseFloat(el.CurrentPointValue) - parseFloat(el.StartingPointValue)) > 0 ? "+" : ""}
                                                    {(dataType === 0 ? parseFloat(el.CurrentPointValue) - parseFloat(el.BenchmarkValue) : parseFloat(el.CurrentPointValue) - parseFloat(el.StartingPointValue)).toFixed(1)*/}

                                                        {formatNumber(dataType === 0 ? parseFloat(el.CurrentPointValue) - parseFloat(el.BenchmarkValue) : parseFloat(el.CurrentPointValue) - parseFloat(el.StartingPointValue))}
                                                    </span>}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </Fragment>
                        </div>
                    )}
            </Card>
        </div>
    );
};

export default BaselineReport;

/*
BenchmarkValue: 72.5
ColourIndicator: 0
CurrentPointDate: "2020-09-16T00:00:00"
CurrentPointValue: "83.8"
KPIName: "ProportionOfCowsBred"
OwnFarmValue: 83.8
Progress: "-"
StartingPointDate: "2011-12-17T00:00:00"
StartingPointValue: "-"
Trend: 2
*/
