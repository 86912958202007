import React from "react";
import { iIcon } from "./interface";

const DOA: React.FC<iIcon> = (props) => {
    return (
        <svg  xmlns="http://www.w3.org/2000/svg" width={props.size ? props.size : "38"} height={props.size ? props.size : "38"} viewBox="0 0 38 38">
            <g transform="translate(-279 -401.5)">
                <rect width="38" height="38" transform="translate(279 401.5)" fill="none"/>
                <rect width="27" height="3.3" transform="translate(279 429.6)" fill={props.color ? props.color : "currentColor"} />
                <path d="M305.5,422.9H279v-1.5h26.3c2.5.1,3.7-2.5,3.8-2.6l6.5-12.9,1.3.7-6.5,12.9A5.646,5.646,0,0,1,305.5,422.9Z" fill={props.color ? props.color : "currentColor"} />
                <circle cx="4.7" cy="4.7" r="4.7" transform="translate(301.3 426.6)" fill={props.color ? props.color : "currentColor"} />
            </g>
        </svg>
    );
};

export default DOA;
