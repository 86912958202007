// @ts-nocheck
import * as PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import CardPlaceholder from "./CardPlaceholder";
import "./Card.scss";
interface IProps {
    col?: any;
    row?: any;
    loading?: boolean;
    transparent?: boolean;
    scrollable?: boolean;
    chrildren?: any;
    simple?: boolean;
    id?: string;
    isCardChild?: boolean;
    gotData?: boolean;
    error?: string;
    style?: {
        border: string;
    }
}

interface IState {
    visible: boolean;
}

class Card extends Component<IProps, IState> {
    public static contextTypes: { t: PropTypes.Requireable<() => void>; } = { t: PropTypes.func };
    private scrollBox: any;

    constructor(props: IProps) {
        super(props);

        this.scrollBox = React.createRef();

        this.state = {
            visible: true,
        };
    }

    public handleScroll = () => {
        this.setState({ visible: false });
    }

    public render() {

        const { col, row, transparent, scrollable, simple, children, id, loading, gotData, error, isCardChild } = this.props;
        const { visible } = this.state;

        const style = col && row && {
            paddingBottom: this.props.isCardChild ? "18px" : "0",
            marginBottom: this.props.isCardChild ? "35px" : "0",
            gridColumnStart: col.start,
            gridColumnEnd: col.end,
            gridRowStart: row.start,
            gridRowEnd: row.end,
            background: transparent ? "transparent" : "auto",
            border: (transparent || isCardChild) ? "none" : "auto",
            overflow: scrollable ? "auto" : "",
            marginLeft: "1px",
            ...this.props.style,
        };

        return (
            <div id={id} ref={(ref) => this.scrollBox = ref} className={`card ${simple ? "card--simple" : ""}`} style={style} onScroll={this.handleScroll}>
                {gotData ? (
                    <Fragment>
                        {simple ? (
                            <p className="flex-center">{children}</p>
                        ) : (
                            <Fragment>
                                {children}
                            </Fragment>
                        )}
                        {scrollable && visible && typeof this.scrollBox === "object" && this.scrollBox.offsetHeight <= this.scrollBox.scrollHeight && (
                            <div className="card__scroll-container">
                                {this.context.t("ScrollToSeeMore")}
                            </div>
                        )}
                    </Fragment>
                ) : loading ? (
                    <Fragment>
                        <CardPlaceholder loading={loading} />
                    </Fragment>
                ) : error ? (
                    <div className="flex-center">
                        {error}
                    </div>
                ) : (
                    <div>
                        <CardPlaceholder loading={false} />
                    </div>
                )}
            </div>
        );
    }
}

const CardToggler: React.FC<{onClick:any, title:any}> = (props) => (
    <p
        onClick={props.onClick}
        style={{ fontWeight: "bold", color: "#00A6FF", padding: "18px", fontSize: "18px", display: "flex", justifyContent: "center" }}
    >
        {props.title}
    </p>
);

const CardContent: React.FC<{centerContent: any, segments: any, fullHeight: any}> = (props) => (
    <div className={`card-content ${props.centerContent ? "card-content--centered" : ""} ${props.segments ? "card-content--segments" : ""} ${props.fullHeight ? "card-content--full-height" : ""} ${props.flex ? "card-content--flex" : ""}`}>
        {props.children}
    </div>
);

const CardSegment: React.FC = (props) => (
    <div
        className={`card-segment ${props.step === props.index ? "card__active-step" : ""}`}
        onMouseLeave={props.onMouseLeave ? props.onMouseLeave : null}
        onMouseEnter={props.onMouseEnter ? props.onMouseEnter : null}
        onClick={props.onClick}
    >
        {props.children}
    </div>
);

const CardSegmentElement: React.FC = (props) => {
    const green = "#3cc480cc";
    const red = "#f8999c";
    const yellow = "#ffe845";
    let trendColor;

    if (props.reverseTrend) {
        trendColor = props.trend < 0 ? green : props.trend > 0 ? red : yellow;
    } else {
        trendColor = props.trend < 0 ? red : props.trend > 0 ? green : yellow;
    }
    
    // Transform the trend arrow
    const forecastTransform = props.trend < 0 ? "scale(1, -1)" : props.trend > 0 ? "rotate(45deg)" : "";

    return (
        <Fragment>
            <div style={props.style} className={`card-segment__container ${props.hoverable ? "card__hoverable" : ""} ${props.step === 1 ? "card__active-step" : ""}`} onClick={props.handleGoToStep ? (e: any) => props.handleGoToStep(1) : () => null}>

                {props.number !== null && !isNaN(props.number) ? (
                    <div className="card-segment__numbers">
                        <p className="card-segment__number">{props.number}{props.unit}</p>
                        {props.progress ? (
                            <div className="card__progress">
                                <i className={`fas fa-sort-${props.trend < 0 ? "down" : props.trend > 0 ? "up" : "up"}`} color={trendColor} />
                                <p style={{ color: trendColor }} className="card__progress-number">{parseInt(props.progress, 10)}</p>
                            </div>
                        ) : props.baseline ? (
                            <div className="card__baseline">
                                <h4>({props.baseline})</h4>
                            </div>
                        ) : null}
                    </div>
                ) : (
                    <div>
                        <h4 style={{ color: "gray" }}>Data not available</h4>
                    </div>
                )}
                <p className="card-segment__title">{props.title}</p>
            </div>
            {props.hasProgressIcon && props.number !== null && !isNaN(props.number) && (
                <div>
                    <div className="card__progress-icon" style={{ border: `2px solid ${trendColor}` }}>
                        <svg transform={forecastTransform} height="30px" version="1.1" viewBox="2.5 0 25 25" width="30px">
                            <g fill="none" fillRule="evenodd" id="action" stroke="none" strokeWidth="1">
                                <g fill={trendColor} id="action_050-trending_up-arrow-chart-increase" transform="translate(-450.000000, -225.000000)">
                                    <path d="M474,237 L465,237 L465,242 L455,242 L455,243 L466,243 L466,238 L474,238 L474,240 L477,237.5 L474,235 L474,237 Z" transform="translate(466.000000, 239.000000) scale(1, -1) rotate(-315.000000) translate(-466.000000, -239.000000)"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

const CardHeader: React.FC = (props) => (
    <div id={props.id} className={`card-header ${props.borderBottom ? "card-header--border-bottom" : ""}`}>
        {props.children}
    </div>
);

const CardHeaderActions: React.FC = (props) => (
    <Fragment>
        <div className="card-header-actions">
            {props.children && (
                <Fragment>
                    {props.children}
                </Fragment>
            )}
            {props.onClick && (
                <button onClick={props.onClick} className="card-header__guide">
                    <svg color="red" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                        <path d="M13.05 9.79L10 7.5v9l3.05-2.29L16 12zm0 0L10 7.5v9l3.05-2.29L16 12zm0 0L10 7.5v9l3.05-2.29L16 12zM11 4.07V2.05c-2.01.2-3.84 1-5.32 2.21L7.1 5.69c1.11-.86 2.44-1.44 3.9-1.62zM5.69 7.1L4.26 5.68C3.05 7.16 2.25 8.99 2.05 11h2.02c.18-1.46.76-2.79 1.62-3.9zM4.07 13H2.05c.2 2.01 1 3.84 2.21 5.32l1.43-1.43c-.86-1.1-1.44-2.43-1.62-3.89zm1.61 6.74C7.16 20.95 9 21.75 11 21.95v-2.02c-1.46-.18-2.79-.76-3.9-1.62l-1.42 1.43zM22 12c0 5.16-3.92 9.42-8.95 9.95v-2.02C16.97 19.41 20 16.05 20 12s-3.03-7.41-6.95-7.93V2.05C18.08 2.58 22 6.84 22 12z"/>
                    </svg>
                </button>
            )}
            {props.close && (
                <button onClick={props.close} className="card-header__guide" style={{ marginLeft: "6px" }}>
                    <i className="fas fa-times" />
                </button>
            )}
        </div>
    </Fragment>
);

const CardHeadline: React.FC = (props) => {
    return (
        <Fragment>
            {props.navigate ? (
                <button className="card-headline card-headline--link" onClick={props.hasOpenStepguide ? null : props.navigate}>
                    {props.title}
                    <i className="fas fa-chevron-right card-headline card-headline--icon" />
                </button>
            ) : (
                <h4 className="card-headline">{props.title}</h4>
            )}
        </Fragment>
    );
};

const CardFooter: React.FC = (props) => (
    <div onClick={() => props.handleGoToStep ? props.handleGoToStep(2) : null} className={`card-footer ${props.sticky ? "card-footer--sticky" : ""} ${props.hoverable ? "card__hoverable" : ""} ${(props.desiredActive && props.step === props.desiredActive) ? "card__active-step" : ""}`}>
        {props.children}
    </div>
);

const CardLegend: React.FC = (props) => (
    <div id={props.id} className={`card__legend ${props.stacked ? "card__legend--stacked" : ""}`}>
        {props.labels.map((el: any, i: number) => {
            return (
                <span className="card__legend-element" key={i}>
                    <div className="card__legend-color" style={{ border: el.border ? `2px solid ${el.border}` : "none", backgroundColor: el.color, opacity: el.opacity ? el.opacity : 0.5 }} />
                    {el.label}
                </span>
            );
        })}
    </div>
);

const CardData: React.FC = (props) => {
    const convertedNum = parseInt(props.diff, 10);
    const notANumber = isNaN(convertedNum);

    return (
        <div className="card__data">
            <p onClick={() => props.handleGoToStep(1)} className={`card-segment__number ${props.hoverable ? "card__hoverable" : ""} ${props.step === 1 ? "card__active-step" : ""}`}>{props.number}<span className="card-segment__label">{props.unitLabel}</span></p>
            <span onClick={() => props.handleGoToStep(2)} className={`card__progress ${props.hoverable ? "card__hoverable" : ""} ${props.step === 2 ? "card__active-step" : ""}`}>
                <i className={convertedNum > 0 && !notANumber ? "fas fa-sort-up" : "fas fa-sort-up"} /> <p className="card__progress-number">{props.diff}</p>
            </span>
        </div>
    );
};

export {
    Card,
    CardContent,
    CardHeader,
    CardHeaderActions,
    CardHeadline,
    CardFooter,
    CardSegment,
    CardSegmentElement,
    CardLegend,
    CardData,
    CardToggler,
};
