import React, { Fragment, useEffect,  } from "react";
import { useDispatch, useSelector } from "react-redux";
import logoutImage from "./Images/Login_HN_Advice.png";
import "./Maintenance.scss";
import { Card } from "./components/Common/Card/Card";
import { GET_MAINTENANCE_REQUEST } from "./redux/Maintenance/constants";

const MaintenanceChecker = (props: any) => {
    const maintenanceState = useSelector((state: any) => state.maintenanceState);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: GET_MAINTENANCE_REQUEST, payload: null });
      }, [dispatch]);

    return (
        <Fragment>
            {maintenanceState.gotData === true && !maintenanceState.isInMaintenanceMode ? (
                <Fragment>
                    {props.children}
                </Fragment>
            ) : maintenanceState.data !== null ? (
                <Fragment>
                    <div className="maintenance__background" />
                    <div className="maintenance">
                    <Card gotData={maintenanceState.gotData}>
                        <div className="maintenance__container">
                            <div className="maintenance__wrapper">
                                <h1>{maintenanceState.data.HeaderTag}</h1>
                            </div>
                            <div className="maintenance__wrapper">
                                <p className="maintenance__message">
                                    {maintenanceState.data.DescriptionTag}
                                </p>
                            </div>
                            <div className="maintenance__wrapper">
                                {props.maintenanceState.data.MessageBegin && props.maintenanceState.data.MessageEnd && (
                                    <p className="maintenance__message">
                                        {`${new Date(props.maintenanceState.data.MessageBegin).toLocaleDateString("en-GB")} - ${new Date(props.maintenanceState.data.MessageEnd).toLocaleDateString("en-GB")}`}
                                    </p>
                                )}
                            </div>
                            <div className="maintenance__wrapper">
                                <img alt="cow logo" src={logoutImage} width="166" />
                            </div>
                        </div>
                    </Card>
                    </div>
                </Fragment>
            ) : null}
        </Fragment>
    )
}

export default MaintenanceChecker;


//     public static contextTypes: { t: PropTypes.Requireable<() => void>; } = { t: PropTypes.func };

//     public componentDidMount() {
//         this.props.doGetMaintencanceAsyncAction();
//     }

//     public render() {
//         const { maintenanceState, children } = this.props;

        
//     }
// }

// const mapStateToProps = (state: any) => ({
//     maintenanceState: state.hnadvice.maintenanceState,
// });

// export default connect(mapStateToProps, {
//     doGetMaintencanceAsyncAction: doTryGetMaintenance.request,
// })(MaintenanceChecker);