import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardGuide, CardHeader, CardHeadline } from "../../Card";
import { GET_TOP_STICK_CONSUMING_COWS_REQUEST } from "../../redux/StickConsumption/constants";
import "./TopTenCows.scss";

const TopTenCows: React.FC = (props) => {
    const stickConsumptionState = useSelector((state: any) => state.stickConsumptionState);
    const { t } = useTranslation();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: GET_TOP_STICK_CONSUMING_COWS_REQUEST, payload: null });
    }, [dispatch]);

    return (
        <div className="top-ten-cows">
            <Card>
                <CardHeader>
                    <CardHeadline text={t("TopTenHighConsumers")} />
                    <CardGuide />
                </CardHeader>
                <Fragment>
                    <table className="top-ten-cows__table">
                        <tr className="headline">
                            <th>
                                <h6 className="header">{t("Rank")}</h6>
                            </th>
                            <th>
                                <h6 className="header">{t("CowNumber")}</h6>
                            </th>
                            <th>
                                <h6 className="header">{t("DIM")}</h6>
                            </th>
                            <th>
                                <h6 className="header">{t("SticksUsed")}</h6>
                            </th>
                        </tr>
                        {/* <Fragment> */}
                        {stickConsumptionState.topTenCowsData.map((el: any, index: number) => {
                            return (
                                <tr>
                                    <td className="ranking">
                                        {index + 1}
                                    </td>
                                    <td className="cowNumbers">
                                        {el.CowNo}
                                    </td>
                                    <td>
                                        {el.DFC}
                                    </td>
                                    <td>
                                        {el.ActualSticksUsed}
                                    </td>
                                </tr>


                                // <div key={`top-ten-cows__${index}`} className="top-ten-cows__element">
                                //     <div className="top-ten-cows__column top-ten-cows__column--first">
                                //         <p className="top-ten-cows__rank-label">{index + 1}</p>
                                //     </div>
                                //     <div className="top-ten-cows__column top-ten-cows__column--second">
                                //         <p className="top-ten-cows__label">{el.CowNo}</p>
                                //     </div>
                                //     <div className="top-ten-cows__column top-ten-cows__column--third">
                                //         <p className="top-ten-cows__label">{el.DFC}</p>
                                //     </div>
                                //     <div className="top-ten-cows__column top-ten-cows__column--last">
                                //         <p className="top-ten-cows__label">{el.ActualSticksUsed}</p>
                                //     </div>
                                // </div>
                            );
                        })}
                        {/* </Fragment> */}
                    </table>
                </Fragment>
            </Card>
        </div>
    );
};

export default TopTenCows;
