import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Input/Button";
import { TOGGLE_CHART_TYPE } from "../../redux/Navigation/constants";
import "../ChartType.scss";

const ChartTypeToggle: React.FC = (props) => {

    const navigationState = useSelector((state: any) => state.navigationState);

    const dispatch = useDispatch();

    const handleToggleChartTypeToggle = (key: number) => dispatch({ type: TOGGLE_CHART_TYPE, payload: key });

    return (
        <div className="chart-type">
            <div className="chart-type__toggle-actions-wrapper">
                <Button onClick={() => handleToggleChartTypeToggle(0)}>
                    <span className={`chart-type__action ${navigationState.chartType === 0 ? " chart-type__action--active" : "" }`}>Timing of AI</span>
                </Button>
            </div>
            {" | "}
            <div className="chart-type__toggle-actions-wrapper">
                <Button onClick={() => handleToggleChartTypeToggle(1)}>
                    <span className={`chart-type__action ${navigationState.chartType === 1 ? " chart-type__action--active" : "" }`}>Timing vs. follicular phase</span>
                </Button>
            </div>
        </div>
    );
};

export default ChartTypeToggle;
